import React, { useEffect, useState } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useUserInfo } from 'providers/UserInfoProvider';
import ServiceHelper from 'stores/ServiceHelper';
import { useStoreWrapper } from 'stores/StoreHelper';
import { useTranslation } from 'react-i18next';
import LoginConfirmModal from './LoginConfirmModal';

const FavoriteButton = ({
  listingId,
  isFavorite,
  theme = 'dark',
  onFavoriteChange,
}: {
  listingId: number;
  isFavorite: boolean;
  theme?: 'light' | 'dark';
  onFavoriteChange?: () => void;
}) => {
  const { loginStatus } = useUserInfo();
  const { uiStore } = useStoreWrapper();
  const { t } = useTranslation();

  const [isInternalFavorite, setIsInternalFavorite] = useState(false);

  const showLogin = () => {
    // uiStore?.modal.show({
    //   title: t('loginRequired'),
    //   style: {
    //     width: 600,
    //     height: 400,
    //   },
    //   children: <LoginConfirmModal listingId={listingId} />,
    // });

    uiStore?.universalModal.show({
      title: t('loginRequired'),
      modalOption: {
        style: {
          width: 600,
          height: 400,
        },
      },
      children: <LoginConfirmModal listingId={listingId} />,
    });
  };

  const handleClickFavorite = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (!loginStatus) {
      showLogin();
      return;
    }
    setIsInternalFavorite(!isInternalFavorite);
    if (loginStatus) {
      if (isInternalFavorite) {
        ServiceHelper.myAccountService?.removeFavoriteListing(listingId).then((res) => {
          toast.success('찜하기가 해제되었습니다.');
          onFavoriteChange?.();
        });
      } else {
        ServiceHelper.myAccountService?.addFavoriteListing(listingId).then((res) => {
          toast.success('찜하기가 추가되었습니다.');
          onFavoriteChange?.();
        });
      }
    } else {
      localStorage.setItem('favorite', JSON.stringify([listingId]));
    }
  };

  useEffect(() => {
    if (isFavorite !== undefined) {
      setIsInternalFavorite(isFavorite);
    }
  }, [isFavorite]);

  return (
    <FavoriteWrapper
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        handleClickFavorite(e);
      }}
    >
      {isInternalFavorite ? (
        <FavoriteIcon style={{ color: theme === 'dark' ? '#fff' : '#000' }} />
      ) : (
        <FavoriteBorderIcon style={{ color: theme === 'dark' ? '#fff' : '#000' }} />
      )}
    </FavoriteWrapper>
  );
};
const FavoriteWrapper = styled.div``;
export default FavoriteButton;
