/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { RegionDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class RegionService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags RegionService
   * @name GetRegions
   * @summary Get Regions
   * @request GET:/v1/regions
   * @secure
   */
  getRegions = (
    query: {
      searchKeyword?: string;
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 20
       */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: string[];
      locale: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<RegionDto[], any>({
      path: `/v1/regions`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags RegionService
   * @name GetRegion
   * @summary Get Region by ID
   * @request GET:/v1/regions/{regionId}
   * @secure
   */
  getRegion = (
    regionId: number,
    query: {
      locale: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<RegionDto, any>({
      path: `/v1/regions/${regionId}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
