import React from 'react';
import { useStoreWrapper } from 'stores/StoreHelper';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { LandmarkDto } from 'services/data-contracts';
import { Typography } from '@mui/material';
import { getSimplifiedKoreanAddress } from 'libs/helper/utils';
const AllLandmarkModal = ({ landmarks }: { landmarks: LandmarkDto[] }) => {
  const { t } = useTranslation();
  const { uiStore } = useStoreWrapper();

  return (
    <ModalContainer>
      <LandmarkContainer>
        {landmarks.map((landmark) => (
          // <LandmarkCard key={landmark.id} landmark={landmark} />
          <StyledLandmarkCard key={landmark.id}>
            <StyledImageContainer>
              <StyledCardImage src={landmark?.images?.[0]?.url} alt={landmark?.name} />
            </StyledImageContainer>
            <ContentContainer>
              <StyledTitle>{landmark?.name}</StyledTitle>
              <StyledAddress>{getSimplifiedKoreanAddress(landmark?.address)}</StyledAddress>
            </ContentContainer>
          </StyledLandmarkCard>
        ))}
      </LandmarkContainer>
      <ConfirmButtonWrapper>
        <ConfirmButton
          onClick={() => {
            uiStore?.modal.close();
          }}
        >
          {t('Confirm')}
        </ConfirmButton>
      </ConfirmButtonWrapper>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 16px 16px;
  justify-content: space-between;
`;

const LandmarkContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 32px;
`;

const StyledLandmarkCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
`;

const StyledImageContainer = styled.div`
  width: 100%;
  height: 163px;
  border-radius: 8px;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`;

const StyledCardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 4px;
`;

const StyledTitle = styled(Typography)`
  font-size: 15px !important;
  font-weight: 600 !important;
  color: var(--gray-gray-90, #1d1d1d);
  font-family: Pretendard;
  font-style: normal;
  line-height: 150%;
`;

const StyledAddress = styled(Typography)`
  font-size: 12px !important;
  color: var(--gray-gray-60, #717171);
  font-family: Pretendard;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

const ConfirmButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 16px;
`;

const ConfirmButton = styled.button`
  height: 48px;
  border-radius: 8px;
  background: var(--4-e-433-c, #4e433c);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.037px;
  color: var(--Grayscale-0, #fff);
  cursor: pointer;
  width: 100%;
`;

export default AllLandmarkModal;
