import React from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from 'ServiceWorker';
import RootProvider from 'providers/RootProvider';
import './i18n';

import { LocalStore } from 'stores/LocalRootStore';
import ErrorBoundary from 'ErrorBoundary';
import { CustomProvider, MediaProvider, saladTheme } from 'saladsoft-primitive';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { ThemeProvider } from 'styled-components';
import { UserInfoProvider } from 'providers/UserInfoProvider';
import { PolicyProvider } from 'providers/PolicyProvider';

ReactDOM.render(
  <RootProvider>
    <ErrorBoundary>
      <PolicyProvider>
        <UserInfoProvider>
          <CustomProvider localStores={LocalStore}>
            <ThemeProvider theme={saladTheme}>
              <MediaProvider>
                <React.StrictMode>
                  <App />
                </React.StrictMode>
              </MediaProvider>
            </ThemeProvider>
          </CustomProvider>
        </UserInfoProvider>
      </PolicyProvider>
    </ErrorBoundary>
  </RootProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
registerServiceWorker();
