import React, { useEffect, useState, useRef } from 'react';
import { useMediaContext } from 'saladsoft-primitive';
import { BookInfo } from 'types/CommonTypes';

import PageContainer from 'containers/pc/PageContainer';
import styled, { createGlobalStyle } from 'styled-components';
import useDetectScroll from '@smakss/react-scroll-direction';
import { useLocation } from 'react-router-dom';
import { useStoreWrapper } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import { useTranslation } from 'react-i18next';
import AnimateOnScroll from 'components/commons/AnimateOnScroll';
import HanokValue from 'components/pages/investor/HanokValue';
import Section01 from 'components/pages/investor/Section01';
import Section02 from 'components/pages/investor/Section02';
import Section03 from 'components/pages/investor/Section03';
import Section04 from 'components/pages/investor/Section04';
import Section05 from 'components/pages/investor/Section05';
import Section06 from 'components/pages/investor/Section06';
import Section07 from 'components/pages/investor/Section07';
const GlobalStyle = createGlobalStyle`
  .page-content-container {
    padding: 0 !important;
  }
`;

const InvestorPage = observer(() => {
  const { isMobile } = useMediaContext();

  const location = useLocation();



  useEffect(() => {
    // 스크롤 위치 초기화
    window.scrollTo({
      top: 0,
    });
  }, [location.search]);

  return (
    <PageContainer>
      <GlobalStyle />
      <ImageContainer>
        <StyledContainer>
          <StyledContent>
            <StyledHeader>
              <StyledLogoContainer>
                <StyledLogo src="/images/white_logo.svg" alt="Logo" />
              </StyledLogoContainer>
            </StyledHeader>
            <StyledMainText>
              <span>버틀러리와 함께하실</span><br />
              <StyledBoldText>한옥 소유주와 투자자</StyledBoldText>
              <span>를 찾습니다</span>
            </StyledMainText>
            <StyledDivider />
            <StyledSubText>
              저희는 수많은 시행착오를 거치면서 빠르게 성장 중입니다. 성장하는
              버틀러리의 결실을 파트너와 함께 나누고자 합니다
            </StyledSubText>
            <StyledButton onClick={() => {
              window.open('https://proudlee.typeform.com/to/HEPzMsY0', '_blank');
            }}>상담하기</StyledButton>
          </StyledContent>
        </StyledContainer>
      </ImageContainer>
      <AnimatedContainer style={{
        backgroundColor: '#F6F3F1',
        padding: '100px 0',
      }}>
        <AnimateOnScroll direction="up">
          <HanokValue />
        </AnimateOnScroll>
      </AnimatedContainer>
      <Section className="page-container" style={{
        padding: isMobile ? '100px 0' : '200px 0',
      }}>
        <AnimateOnScroll direction="down" delay={0.2}>
          <Section01 />
        </AnimateOnScroll>
        <AnimateOnScroll direction="left" delay={0.2}>
          <Section02 />
        </AnimateOnScroll>
        <AnimateOnScroll direction="right" delay={0.2}>
          <Section03 />
        </AnimateOnScroll>
      </Section>
      <Section className="page-container" style={{
        padding: '0px 0',
      }}>
        <Section04 />

      </Section>
      <Section className="page-container" style={{
        padding: '0px 0',
      }}>
        <AnimateOnScroll direction="down" delay={0.2}>
          <Section05 />
          <div style={{ marginTop: 50, textAlign: 'center' }}>
            <img src="/images/section_07.png" alt="section_07" style={{ width: '100%' }} />
          </div>
        </AnimateOnScroll>
      </Section>
      <Section style={{
        backgroundColor: '#1F1E1D',
      }}>
        <AnimateOnScroll direction="right" delay={0.3} className="page-container">
          <Section06 />
        </AnimateOnScroll>
      </Section>

      <Section>
        <AnimateOnScroll direction="up" delay={0.4}>
          <Section07 />
        </AnimateOnScroll>
      </Section>
    </PageContainer>
  );
});

const ImageContainer = styled.div`
  width: 100%;
  height: 706px;
  background-image: url('/images/invest.jpg');
  background-size: cover;
  background-position: center;
  object-fit: contain;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${(props) => props.theme.media.mobile} {
    height: 598px;
  }
`;

const StyledContainer = styled.main`
  width: 460px;
  height: 403px;
  position: relative;

  @media (max-width: 991px) {
    width: 100%;
    height: auto;
    min-height: 403px;
  }
`;

const StyledContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledHeader = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledLogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const StyledIconWrapper = styled.div`
  width: 26px;
  height: 26px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledLogo = styled.img`
  height: 26px;
  margin-left: 2px;
`;

const StyledMainText = styled.div`
  color: #fff;
  text-align: center;
  font-family: "Pretendard", sans-serif;
  font-size: 36px;
  line-height: 140%;
  letter-spacing: -0.09px;
  margin-top: 40px;
  width: 500px;

  @media (max-width: 991px) {
    width: 100%;
    font-size: 32px;
  }

  @media (max-width: 640px) {
    font-size: 28px;
    padding: 0 20px;
  }
`;

const StyledBoldText = styled.span`
  font-weight: 700;
`;

const StyledDivider = styled.div`
  width: 45px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.7);
  margin: 32px auto;
`;

const StyledSubText = styled.p`
  color: #f0f0f0;
  text-align: center;
  font-size: 17px;
  line-height: 150%;
  letter-spacing: -0.042px;
  width: 392px;
  margin: 0 auto;

  @media (max-width: 991px) {
    width: 90%;
    font-size: 16px;
  }

  @media (max-width: 640px) {
    font-size: 15px;
  }
`;

const StyledButton = styled.button`
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.042px;
  padding: 24px 80px;
  border: 1px solid #fff;
  cursor: pointer;
  margin-top: 100px;
  background: transparent;

  @media (max-width: 640px) {
    padding: 20px 60px;
  }
`;

const AnimatedContainer = styled.div`
  // Add your styles here
`;

const Title = styled.h1`
  // Add your styles here
`;

const Subtitle = styled.h2`
  // Add your styles here
`;

const Section = styled.section`
  // Add your styles here
`;

const ContentBlock = styled.div`
  // Add your styles here
`;

export default InvestorPage;
