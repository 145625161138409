import React from 'react';
import styled, { css } from 'styled-components';

const Box = ({
  selected,
  title,
  children,
  className,
  style,
  onClick,
}: {
  selected?: boolean;
  title?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  className?: string;
  style?: React.CSSProperties;
}) => {
  return (
    <Container selected={selected} onClick={onClick} className={className} style={style}>
      <Title selected={selected}>{title}</Title>
      {children}
    </Container>
  );
};

const Container = styled.div<{ selected?: boolean }>`
  cursor: pointer;
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.03);
  width: 100%;
  padding: 10px 20px;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  background-color: #fff;
  border-radius: var(--border-radius);
  color: #000;
  ${({ selected }) =>
    selected &&
    css`
      border: 2px solid var(--point-color);
      box-shadow: none;
    `}
`;

const Title = styled.div<{ selected?: boolean }>`
  font-size: 16px;
  font-weight: 400;
  ${({ selected }) =>
    selected &&
    css`
      font-weight: 700;
    `}
`;

export default Box;
