import React from 'react';
import styled from 'styled-components';
import BodyText from './BodyText';
import { ListingDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';

const ParkingComponent = ({ house }: { house: ListingDto }) => {
  const { t } = useTranslation();
  return (
    <ParkingInfoWrapper>
      <svg xmlns="http://www.w3.org/2000/svg" width="2" height="12" viewBox="0 0 2 12" fill="none">
        <path d="M0.710938 0V12" stroke="#E4E4E4" />
      </svg>
      {house?.hasParking ? (
        <img src="/images/parking.svg" alt="no-parking" style={{ marginLeft: '1px' }} />
      ) : (
        <img src="/images/no-parking.svg" alt="parking" style={{ marginLeft: '1px' }} />
      )}
      <BodyText type="F" style={{ color: '#555555' }}>
        {house?.hasParking ? t('parkingAvailable') : t('parkingUnavailable')}
      </BodyText>
    </ParkingInfoWrapper>
  );
};

const ParkingInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
`;

export default ParkingComponent;
