import BTButton from 'components/input/BTButton';
import * as React from 'react';
import styled from 'styled-components';
import { ListingPriceDto } from 'services/data-contracts';
import { NumberFormat, Row } from 'saladsoft-primitive';
import { useEffect, useRef, useState } from 'react';
import { ConvertStringToParenthesisDate, getUrlParam } from 'libs/helper/utils';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStoreWrapper } from 'stores/StoreHelper';

const PriceBox = observer(
  ({ onClick, priceInfo }: { onClick: () => void; priceInfo?: ListingPriceDto }) => {
    const maintenanceRef = useRef<HTMLDivElement>(null);

    const { t } = useTranslation();
    const { calendarStore } = useStoreWrapper();

    const [dates, setDates] = useState<string>();
    const [guests, setGuests] = useState<string>('2');
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [showMaintenance, setShowMaintenance] = useState(false);

    const handleOnClick = () => {
      if (isValid) {
        onClick();
      }
    };

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (maintenanceRef.current && !maintenanceRef.current.contains(event.target as Node)) {
          setShowMaintenance(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    useEffect(() => {
      const startDate = getUrlParam('startdate');
      const endDate = getUrlParam('enddate');
      const guests = getUrlParam('guests');

      // 날짜 유효성 검사 및 설정
      if (startDate && endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);

        if (!isNaN(start.getTime()) && !isNaN(end.getTime()) && start <= end) {
          setDates(
            `${ConvertStringToParenthesisDate(startDate)} - ${ConvertStringToParenthesisDate(endDate)}`,
          );
          setIsValid(true);
        } else {
          setDates('');
          setIsValid(false);
        }
      } else {
        setDates('');
        setIsValid(false);
      }

      if (guests) {
        setGuests(guests);
      }
    }, [location.search]);

    useEffect(() => {
      if (priceInfo) {
        setTotalPrice(Number(priceInfo.price));
      }
      setIsValid(!!priceInfo?.nights);
    }, [priceInfo]);

    return (
      <Div>
        <Div2>
          <Div3>
            <Div4>
              <Div5 style={{ position: 'relative' }}>
                <Div6>
                  {priceInfo && (
                    <Row>
                      <NumberFormat value={totalPrice} prefix="₩" />
                      <>{!priceInfo?.nights && <div>~</div>}</>
                    </Row>
                  )}
                </Div6>
                <Img
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setShowMaintenance(true);
                    console.log('Icon clicked');
                  }}
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/59eddc1df2986e4345b7bf5efc606430c75790735d4990f4c083a302f0852853?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
                />
                {showMaintenance && (
                  <PopOverStyle
                    ref={maintenanceRef}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    {t('maintenance')}
                  </PopOverStyle>
                )}
              </Div5>
            </Div4>
            {priceInfo?.nights && (
              <Div7>
                {priceInfo?.nights}
                {t('Nights')} {priceInfo?.nights && priceInfo?.nights + 1}
                {t('Days')} / {guests}
                {t('people2')}
              </Div7>
            )}
          </Div3>

          <Div8
            onClick={() => {
              calendarStore.setCalendarShow(true);
            }}
          >
            {dates && dates !== '' ? dates : t('please select date')}
          </Div8>
        </Div2>
        <BTButton style={{ marginTop: 24 }} onClick={handleOnClick} disabled={!isValid}>
          {t('book')}
        </BTButton>
      </Div>
    );
  },
);

const Div = styled.div`
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Grayscale-20, #e4e4e4);
  background: var(--Grayscale-0, #fff);
  background-color: var(--Grayscale-0, #fff);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Pretendard, sans-serif;
  justify-content: start;
  padding: 24px 20px 20px;
`;

const Div2 = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;

const Div3 = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: start;
`;

const Div4 = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 24px;
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 600;
  white-space: nowrap;
  letter-spacing: -0.06px;
  justify-content: start;
  margin: auto 0;
`;

const Div5 = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  margin: auto 0;
`;

const Div6 = styled.div`
  align-self: stretch;
  margin: auto 0;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  align-self: stretch;
  margin: auto 0;
`;

const Div7 = styled.div`
  align-self: stretch;
  gap: 4px;
  font-size: 14px;
  color: var(--Grayscale-50, #8e8e8e);
  font-weight: 400;
  letter-spacing: -0.04px;
  margin: auto 0;
`;

const Div8 = styled.div`
  cursor: pointer;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  align-self: stretch;
  margin-top: 14px;
  gap: 4px;
  font-size: 15px;
  color: var(--Grayscale-70, #555);
  font-weight: 500;
  letter-spacing: -0.04px;
`;

const PopOverContainer = styled.div`
  position: absolute;
  left: 40px;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 1000;
`;

const PopOverArrow = styled.div`
  position: absolute;
  left: -12px;
  transform: rotate(-90deg);
  z-index: 1001;
`;

const PopOverStyle = styled.div`
  position: absolute;
  left: 40px;
  margin-top: 10px;
  min-width: 200px;
  max-width: 500px;
  max-height: 300px;
  white-space: pre-wrap;
  background-color: #fff;
  color: #000;
  padding: 10px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.03px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  white-space: pre-line;

  &::before {
    content: '';
    position: absolute;
    top: 56px;
    left: 20px;
    width: 12px;
    height: 8px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='7' viewBox='0 0 12 7' fill='none'%3E%3Cpath d='M4.4 5.86667L0 0L12 1.04907e-06L7.6 5.86667C6.8 6.93333 5.2 6.93333 4.4 5.86667Z' fill='white'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    transform: rotate(360deg);
  }
`;

export default PriceBox;
