import React, { CSSProperties, useEffect, useState } from 'react';

const FONT_FAMILY = 'Pretendard';

/**
 * Subtitle 컴포넌트의 타입별 스타일 정의
 *
 * type 'A': 메인 서브타이틀 (22px, Bold)
 * - fontSize: 1.375rem (22px)
 * - fontWeight: 600
 *
 * type 'B': 중요 섹션 헤더 (19px, Bold)
 * - fontSize: 1.1875rem (19px)
 * - fontWeight: 600
 *
 * type 'C': 일반 섹션 헤더 (17px, Bold)
 * - fontSize: 1.0625rem (17px)
 * - fontWeight: 600
 *
 * type 'D': 보조 헤더 (17px, Medium)
 * - fontSize: 1.0625rem (17px)
 * - fontWeight: 500
 *
 * type 'E': 일반 텍스트 헤더 (17px, Regular)
 * - fontSize: 1.0625rem (17px)
 * - fontWeight: 400
 *
 * default: 기본 스타일 (16px)
 * - fontSize: 1rem (16px)
 */
const Subtitle = ({
  children,
  style,
  type,
}: {
  children: any;
  style?: CSSProperties;
  type?: 'A' | 'B' | 'C' | 'D' | 'E';
}) => {
  const [textStyle, setTextStyle] = useState<CSSProperties>({});

  useEffect(() => {
    let tempStyle: CSSProperties = { fontFamily: FONT_FAMILY };
    switch (type) {
      case 'A':
        // 메인 서브타이틀 - 가장 큰 크기 (22px), 볼드체
        tempStyle = {
          ...tempStyle,
          fontSize: '1.375rem',
          fontWeight: '600',
        };
        break;
      case 'B':
        // 중요 섹션 헤더 - 중간 크기 (19px), 볼드체
        tempStyle = {
          ...tempStyle,
          fontSize: '1.1875rem',
          fontWeight: '600',
        };
        break;
      case 'C':
        // 일반 섹션 헤더 - 기본 크기 (17px), 볼드체
        tempStyle = { ...tempStyle, fontSize: '1.0625rem', fontWeight: '600' };
        break;
      case 'D':
        // 보조 헤더 - 기본 크기 (17px), 미디엄체
        tempStyle = { ...tempStyle, fontSize: '1.0625rem', fontWeight: '500' };
        break;
      case 'E':
        // 일반 텍스트 헤더 - 기본 크기 (17px), 레귤러체
        tempStyle = { fontSize: '1.0625rem', fontWeight: '400' };
        break;
      default:
        // 기본 스타일 - 16px
        tempStyle = { ...tempStyle, fontSize: '1rem' };
        break;
    }

    setTextStyle(tempStyle);
  }, [type]);
  return <div style={{ ...textStyle, ...style }}>{children}</div>;
};

export default Subtitle;
