import PageContainer from 'containers/pc/PageContainer';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useModelStateContext } from 'saladsoft-primitive';
import { SocialSignUpDto } from 'services/data-contracts';
import styled from 'styled-components';
import useLocaleNavigate from 'hooks/useLocaleNavigate';

const SignupComplete = () => {
  const { t } = useTranslation();
  const navigate = useLocaleNavigate();
  const [userName, setUserName] = useState('');
  const { stateModel } = useModelStateContext<SocialSignUpDto>();

  useEffect(() => {
    // 페이지 진입 시 맨 위로 스크롤
    window.scrollTo(0, 0);

    let formattedName = '';
    if (i18n.language === 'en') {
      // 영어인 경우 lastName + firstName 형식
      formattedName =
        `${stateModel?.profile?.firstName || ''} ${stateModel?.profile?.lastName || ''}`.trim();
    } else {
      // 그 외 언어는 firstName + lastName 형식
      formattedName =
        `${stateModel?.profile?.lastName || ''} ${stateModel?.profile?.firstName || ''}`.trim();
    }
    setUserName(formattedName || '');
  }, []);

  return (
    <PageContainer>
      <div className="page-container" style={{ padding: '0 16px' }}>
        <WelcomeContainer>
          <GreetingText>
            {t('welcome')} {userName}님!
            <br />
            {t('signupComplete')}
          </GreetingText>
          <LoginButton
            role="button"
            tabIndex={0}
            onClick={() => {
              navigate('/login');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
              }
            }}
          >
            {t('goToSignIn')}
          </LoginButton>
        </WelcomeContainer>
      </div>
    </PageContainer>
  );
};
const WelcomeContainer = styled.main`
  border-radius: 0;
  display: flex;
  max-width: 588px;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  font-weight: 600;
  margin: auto;
  margin-top: 140px;
  padding-bottom: calc(567px - 300px);

  @media ${(props) => props.theme.media.mobile} {
    justify-content: space-between;
    margin-top: 40px;
    height: calc(100vh - 232px);
    display: flex;
    flex-direction: column;
    padding: 0 0 40px;
  }
`;

const GreetingText = styled.h1`
  width: 100%;
  max-width: 561px;
  font-size: 24px;
  color: var(--Grayscale-80, #2d2d2d);
  letter-spacing: -0.06px;
  line-height: 36px;
  word-break: keep-all;
`;

const LoginButton = styled.div`
  align-self: stretch;
  border-radius: 8px;
  background: var(--4-e-433-c, #4e433c);
  margin-top: 200px;
  min-height: 48px;
  gap: 4px;
  overflow: hidden;
  font-size: 15px;
  color: var(--Grayscale-0, #fff);
  white-space: nowrap;
  text-align: center;
  letter-spacing: -0.04px;
  padding: 16px;
  cursor: pointer;

  &:focus {
    outline: 2px solid #fff;
    outline-offset: 2px;
  }

  @media (max-width: 991px) {
    margin-top: 40px;
    white-space: initial;
  }
`;
export default SignupComplete;
