import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MembershipBadgeProps } from './types';

type MembershipLevel = 'CLASSIC' | 'SILVER' | 'GOLD' | 'PLATINUM';

const MEMBERSHIP_BADGE_ICON: Record<MembershipLevel, string> = {
  CLASSIC: '/images/classic-icon.png',
  SILVER: '/images/silver-icon.png',
  GOLD: '/images/gold-icon.png',
  PLATINUM: '/images/platinum-icon.png',
} as const;

export const MembershipBadge: React.FC<MembershipBadgeProps> = ({
  level,
  stays,
  nights,
  rewardPercentage,
}) => {
  const { t } = useTranslation();

  return (
    <BadgeContainer>
      <BadgeContent>
        <BadgeIcon
          loading="lazy"
          src={MEMBERSHIP_BADGE_ICON[level as MembershipLevel]}
          alt={`${level} membership badge`}
        />
        <BadgeInfo>
          <MembershipLevel>{level}</MembershipLevel>
          {/* <StayInfo>{`투숙 ${stays}회 (투숙일 ${nights}박)`}</StayInfo> */}
          <StayInfo>{t('stayInfo', { stays: stays, nights: nights })}</StayInfo>
        </BadgeInfo>
      </BadgeContent>
      <RewardInfo>
        <RewardValue>
          <RewardNumber>{rewardPercentage}</RewardNumber>
          <RewardUnit>%</RewardUnit>
        </RewardValue>
        <RewardText>{t('reward')}</RewardText>
      </RewardInfo>
    </BadgeContainer>
  );
};

const BadgeContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 16px;
`;

const BadgeContent = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: start;
  min-width: 175px;
  margin: auto 0;
`;

const BadgeIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 48px;
  align-self: stretch;
  margin: auto 0;
`;

const BadgeInfo = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
`;

const MembershipLevel = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  text-align: center;
  letter-spacing: -0.04px;
  align-self: start;
  font:
    700 16px Montserrat,
    sans-serif;
`;

const StayInfo = styled.div`
  color: var(--Grayscale-50, #8e8e8e);
  letter-spacing: -0.03px;
  margin-top: 6px;
  font:
    400 13px Pretendard,
    sans-serif;
`;

const RewardInfo = styled.div`
  align-self: stretch;
  display: flex;
  align-items: baseline;
  gap: 2px;
  color: var(--Grayscale-90, #1d1d1d);
  white-space: nowrap;
  justify-content: start;
  margin: auto 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const RewardValue = styled.div`
  display: flex;
  align-items: baseline;
  gap: 2px;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  text-align: center;
`;

const RewardNumber = styled.span`
  font-size: 28px;
  letter-spacing: -0.07px;
  line-height: 1;
`;

const RewardUnit = styled.span`
  font-size: 16px;
  letter-spacing: -0.04px;
`;

const RewardText = styled.div`
  letter-spacing: -0.04px;
  font:
    400 15px Pretendard,
    sans-serif;
`;
