import axios, { AxiosRequestConfig } from 'axios';
import { isExpired } from 'react-jwt';
import { v4 as uuidv4 } from 'uuid';

import _ from 'lodash';
import { REST_API_URL_V2 } from 'stores/AppStore';

const RefreshToken = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken1'); // 'refreshToken1'이 맞는지 확인 필요

  // 토큰이 없는 경우 처리
  if (!accessToken) {
    console.log('Access token is missing');
    if (config.headers) {
      delete config.headers.Authorization;
    }
    return config;
  }

  try {
    // 토큰 만료 확인
    const expired = isExpired(accessToken);

    if (expired && refreshToken) {
      console.log('Token expired, attempting to refresh');

      try {
        // 토큰 갱신 서버통신
        const response = await axios({
          method: 'post',
          url: `${REST_API_URL_V2}v1/auth/refresh-token`,
          data: {
            accessToken: String(accessToken),
            refreshToken: String(refreshToken),
          },
        });

        // 새 토큰 저장
        localStorage.setItem('accessToken', response.data.accessToken);
        localStorage.setItem('refreshToken', response.data.refreshToken);

        // 헤더에 새 토큰 설정
        if (config.headers) {
          config.headers.Authorization = `Bearer ${response.data.accessToken}`;
        }

        console.log('Token refreshed successfully');
      } catch (error) {
        console.error('Failed to refresh token:', error);
        // 토큰 갱신 실패 시 로컬 스토리지에서 제거
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('refreshToken1');

        if (config.headers) {
          delete config.headers.Authorization;
        }
      }
    } else if (!expired) {
      // 토큰이 유효한 경우 헤더에 설정
      console.log('Token is valid, setting Authorization header');
      if (config.headers) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
  } catch (error) {
    console.error('Error in RefreshToken:', error);
    // 오류 발생 시 헤더에서 Authorization 제거
    if (config.headers) {
      delete config.headers.Authorization;
    }
  }

  return config;
};

// eslint-disable-next-line class-methods-use-this
const getUUID = () => {
  const uuid = localStorage.getItem(`uuid`);
  if (uuid === null) {
    const newUuid = uuidv4();
    localStorage.setItem(`uuid`, newUuid);
    return newUuid;
  }
  return uuid;
};

const refreshErrorHandle = (err: any) => {
  console.error('Refresh token error:', err);
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('refreshToken1');
  return Promise.reject(err);
};

export { RefreshToken, refreshErrorHandle };
