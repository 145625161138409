import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface ReservationHeaderProps {
  reservationNumber: string;
}
export const ReservationHeader: React.FC<ReservationHeaderProps> = ({ reservationNumber }) => {
  const { t } = useTranslation();

  return (
    <HeaderWrapper>
      <Title>
        {/* 예약이
          <br />
          완료되었습니다. */}
        {t('reservation_complete_message')
          .split('\n')
          .map((line, i) => (
            <React.Fragment key={i}>
              {i > 0 && <br />}
              {line}
            </React.Fragment>
          ))}
      </Title>
      <ReservationNumber>
        {t('Reservation Number')} : {reservationNumber}
      </ReservationNumber>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 14px;
`;

const Title = styled.h1`
  text-align: left;
  font-size: 24px;
  color: var(--Grayscale-80, #2d2d2d);
  font-weight: 600;
  letter-spacing: -0.06px;
  line-height: 36px;
  margin: 0;
`;

const ReservationNumber = styled.p`
  color: var(--Grayscale-50, #8e8e8e);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.04px;
`;
