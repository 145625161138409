import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Row, useModelStateContext, InputField, useMediaContext } from 'saladsoft-primitive';
import { useTranslation } from 'react-i18next';
import BTButton from 'components/input/BTButton';
import CountryCode from 'components/commons/CountryCode';
import { SocialSignUpDto } from 'services/data-contracts';
import { validationHelper } from 'libs/helper/validationHelper';
import TermsAndConditions from 'components/commons/TermsAndConditions';
import { useSearchParams } from 'react-router-dom';
import useSocialSignUp from 'hooks/useSocialSignUp';
import { AlertMessage } from './AlertMessage';
import { formatBirthday } from 'libs/helper/utils';

export const SignupForm = () => {
  const [searchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const { handleSignup, loadSocialData } = useSocialSignUp();
  const { isMobile } = useMediaContext();
  const { setState, stateModel } = useModelStateContext<SocialSignUpDto>();

  const [isValid, setIsValid] = useState<boolean>(false);
  const [termsValid, setTermsValid] = useState<boolean>(false);
  const [referralEmailError, setReferralEmailError] = useState<string>('');
  const [birthdayError, setBirthdayError] = useState<string>('');

  // 기존 개별 에러 상태를 통합하여 처리
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    email: '',
    countryCode: '',
    phoneNumber: '',
    birthday: '',
    referralEmail: '',
  });

  // 개별 필드 유효성 검사
  const validateField = (name: string, value: string) => {
    let isValid = false;
    let errorMessage = '';

    switch (name) {
      case 'firstName':
        isValid = validationHelper.firstName(value);
        if (!isValid) {
          errorMessage = value ? t('no_special_chars_spaces') : t('guest_info_required');
        }
        break;

      case 'lastName':
        isValid = validationHelper.lastName(value);
        if (!isValid) {
          errorMessage = value ? t('no_special_chars_spaces') : t('guest_info_required');
        }
        break;

      case 'countryCode':
        isValid = validationHelper.countryCode(value);
        if (!isValid) {
          errorMessage = t('select_country_code');
        }
        break;

      case 'phoneNumber':
        isValid = validationHelper.phoneNumber(value);
        if (!isValid) {
          errorMessage = value ? t('phone_length_error') : t('guest_info_required');
        }
        break;

      case 'birthday':
        isValid = validationHelper.birthday(value);
        if (!isValid && value) {
          errorMessage =
            value.length < 10
              ? t('signup.incompleteBirthdayFormat')
              : t('signup.invalidBirthdayFormat');
        }
        break;

      case 'referralEmail':
        if (value) {
          isValid = validationHelper.referrerEmail(value);
          if (!isValid) {
            errorMessage = t('signup.invalidEmailFormat');
          }
        } else {
          isValid = true; // 선택적 필드
        }
        break;
    }

    setErrors((prev) => ({
      ...prev,
      [name]: errorMessage,
    }));

    return isValid;
  };

  const handleBlur = (name: string, value: string) => {
    validateField(name, value);
  };

  // 전체 필드 유효성 검사
  const validate = () => {
    const firstName = validationHelper.firstName(stateModel?.profile?.firstName);
    const lastName = validationHelper.lastName(stateModel?.profile?.lastName);
    const email = validationHelper.email(stateModel?.username);
    const countryCode = validationHelper.countryCode(stateModel?.profile?.countryCode);
    const phoneNumber = validationHelper.phoneNumber(stateModel?.profile?.phoneNumber);
    const referrerEmail =
      !stateModel?.referrerEmail || validationHelper.referrerEmail(stateModel?.referrerEmail);
    const birthday = validationHelper.birthday(stateModel?.profile?.birthday);

    setIsValid(
      firstName &&
        lastName &&
        email &&
        countryCode &&
        phoneNumber &&
        referrerEmail &&
        birthday &&
        termsValid,
    );
  };

  const handleClickSignup = async () => {
    const result = await handleSignup(stateModel as SocialSignUpDto);
    if (result) {
      setIsValid(true);
    }
  };

  useEffect(() => {
    loadSocialData();
  }, [searchParams]);

  useEffect(() => {
    validate();
  }, [stateModel, termsValid]);

  const formatBirthday = (value: string): string => {
    // 숫자가 아닌 문자 제거
    const numbers = value.replace(/[^\d]/g, '');

    // 최대 8자리까지만 허용
    const trimmed = numbers.substring(0, 8);

    // YYYY-MM-DD 형식으로 포맷팅
    if (trimmed.length > 6) {
      return `${trimmed.substring(0, 4)}-${trimmed.substring(4, 6)}-${trimmed.substring(6)}`;
    } else if (trimmed.length > 4) {
      return `${trimmed.substring(0, 4)}-${trimmed.substring(4)}`;
    } else if (trimmed.length > 0) {
      return trimmed;
    }

    return '';
  };

  const validateBirthday = (birthday: string, originalInput: string) => {
    if (!birthday) {
      setBirthdayError('guest_info_required');
      return;
    }

    // 숫자와 하이픈만 허용하는 정규식 검사
    if (!/^[\d-]*$/.test(originalInput)) {
      setBirthdayError(t('signup.invalidBirthdayFormat'));
      return;
    }

    // YYYY-MM-DD 형식 검사 (정확한 날짜 유효성 검사)
    const dateRegex = /^(\d{4})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

    // 날짜 형식이 맞는지 확인
    if (birthday.length === 10) {
      if (!dateRegex.test(birthday)) {
        setBirthdayError(t('signup.invalidBirthdayFormat'));
        return;
      }

      // 실제 유효한 날짜인지 확인
      const [_, year, month, day] = birthday.match(dateRegex) || [];
      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

      if (
        date.getFullYear() !== parseInt(year) ||
        date.getMonth() !== parseInt(month) - 1 ||
        date.getDate() !== parseInt(day)
      ) {
        setBirthdayError(t('signup.invalidBirthdayValue'));
        return;
      }
    } else if (birthday.length === 8) {
      // YYYYMMDD 형식 검사
      const year = parseInt(birthday.substring(0, 4));
      const month = parseInt(birthday.substring(4, 6));
      const day = parseInt(birthday.substring(6, 8));

      if (
        isNaN(year) ||
        isNaN(month) ||
        isNaN(day) ||
        month < 1 ||
        month > 12 ||
        day < 1 ||
        day > 31
      ) {
        setBirthdayError(t('signup.invalidBirthdayValue'));
        return;
      }

      const date = new Date(year, month - 1, day);
      if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
        setBirthdayError(t('signup.invalidBirthdayValue'));
        return;
      }
    } else {
      setBirthdayError(t('signup.incompleteBirthdayFormat'));
      return;
    }

    // 모든 검사를 통과하면 에러 메시지 제거
    setBirthdayError('');
  };

  const validateReferralEmail = (email: string) => {
    if (!email) {
      setReferralEmailError('');
      return;
    }

    const isValid = validationHelper.referrerEmail(email);
    if (!isValid) {
      setReferralEmailError(t('signup.invalidEmailFormat'));
    } else {
      setReferralEmailError('');
    }
  };

  return (
    <FormContainer role="main">
      <FormTitle style={{ whiteSpace: 'pre-line' }}>{t('signup.title')}</FormTitle>

      <FormSection>
        <InputFieldGroup>
          <FormLabel>
            {t('signup.name')}
            <RequiredMark>*</RequiredMark>
          </FormLabel>
          <Row gap={8} alignItems="flex-start">
            {i18n.language === 'en' ? (
              <>
                <InputField
                  placeholder={t('signup.firstName')}
                  id="firstName"
                  onChange={(value: string) => {
                    setState('profile', { ...stateModel?.profile, firstName: value });
                  }}
                  onBlur={(e) => handleBlur('firstName', e.target.value)}
                  error={!!errors.firstName}
                  helperText={errors.firstName || ''}
                  onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                    const value = e.clipboardData.getData('text');
                    setState('profile', { ...stateModel?.profile, firstName: value });
                  }}
                />
                <InputField
                  placeholder={t('signup.lastName')}
                  id="lastName"
                  onChange={(value: string) => {
                    setState('profile', { ...stateModel?.profile, lastName: value });
                  }}
                  onBlur={(e) => handleBlur('lastName', e.target.value)}
                  error={!!errors.lastName}
                  helperText={errors.lastName || ''}
                  onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                    const value = e.clipboardData.getData('text');
                    setState('profile', { ...stateModel?.profile, lastName: value });
                  }}
                />
              </>
            ) : (
              <>
                <InputField
                  placeholder={t('signup.lastName')}
                  id="lastName"
                  onChange={(value: string) => {
                    setState('profile', { ...stateModel?.profile, lastName: value });
                  }}
                  onBlur={(e) => handleBlur('lastName', e.target.value)}
                  error={!!errors.lastName}
                  helperText={errors.lastName || ''}
                  onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                    const value = e.clipboardData.getData('text');
                    setState('profile', { ...stateModel?.profile, lastName: value });
                  }}
                />
                <InputField
                  placeholder={t('signup.firstName')}
                  id="firstName"
                  onChange={(value: string) => {
                    setState('profile', { ...stateModel?.profile, firstName: value });
                  }}
                  onBlur={(e) => handleBlur('firstName', e.target.value)}
                  error={!!errors.firstName}
                  helperText={errors.firstName || ''}
                  onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                    const value = e.clipboardData.getData('text');
                    setState('profile', { ...stateModel?.profile, firstName: value });
                  }}
                />
              </>
            )}
          </Row>
        </InputFieldGroup>

        <FormLabel>
          {t('signup.email')}
          <RequiredMark>*</RequiredMark>
        </FormLabel>
        <InputField
          placeholder={t('signup.email')}
          type="email"
          id="email"
          disabled={true}
          value={stateModel?.username}
          onChange={(value: string) => {
            setState('username', value);
          }}
          onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
            const value = e.clipboardData.getData('text');
            setState('username', value);
          }}
        />

        <PhoneSection>
          <FormLabel>
            {t('signup.phone')}
            <RequiredMark>*</RequiredMark>
          </FormLabel>
          <Row gap={5} alignItems="flex-start">
            <div style={{ marginTop: '5px' }}>
              <CountryCode
                onChange={(value: string) => {
                  setState('profile', { ...stateModel?.profile, countryCode: value });
                }}
                showOnlyCode={true}
                onBlur={() => handleBlur('countryCode', stateModel?.profile?.countryCode || '')}
                error={errors.countryCode}
              />
            </div>
            <InputField
              id="contact"
              type={isMobile ? 'tel' : 'number'}
              placeholder={t('signup.phoneNumber')}
              onChange={(value: string) => {
                setState('profile', { ...stateModel?.profile, phoneNumber: value });
              }}
              onBlur={(e) => handleBlur('phoneNumber', e.target.value)}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber || ''}
              onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
                const value = e.clipboardData.getData('text');
                setState('profile', { ...stateModel?.profile, phoneNumber: value });
              }}
            />
          </Row>
          <AlertMessage className="alert-message" message={t('signup.phoneNumberAlert')} />
        </PhoneSection>
      </FormSection>

      <AdditionalSection>
        <InputField
          label={t('signup.referralEmail')}
          placeholder={t('signup.referralEmail')}
          type="email"
          id="referral"
          error={!!referralEmailError}
          onChange={(value: string) => {
            setState('referrerEmail', value);
            validateReferralEmail(value);
          }}
          onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
            const value = e.clipboardData.getData('text');
            setState('referrerEmail', value);
            validateReferralEmail(value);
          }}
        />
        {referralEmailError ? (
          <AlertMessage className="alert-message" message={referralEmailError} isError={true} />
        ) : (
          <AlertMessage className="alert-message" message={t('signup.referralEmailAlert')} />
        )}

        <BirthdayContainer>
          <Divider />
          <BirthdayInfo>
            <BirthdayIcon
              src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/4f6d23437e06dab8e81d35b50055e958c648d1ab5f3741f3ff50ca57340624dd?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&"
              alt="생일 아이콘"
            />
            <BirthdayText style={{ whiteSpace: 'pre-line' }}>
              {t('signup.birthdayAlert')}
            </BirthdayText>
          </BirthdayInfo>
          <FormLabel>
            {t('signup.birthdayTitle')}
            <RequiredMark>*</RequiredMark>
          </FormLabel>
          <InputField
            placeholder={t('signup.birthday')}
            id="birthday"
            error={!!birthdayError}
            value={stateModel?.profile?.birthday || ''}
            onChange={(value: string) => {
              const formattedValue = formatBirthday(value);
              setState('profile', { ...stateModel?.profile, birthday: formattedValue });
              validateBirthday(formattedValue, value);
            }}
            // onBlur={(e) => handleBlur('birthday', e.target.value)}
            // helperText={errors.birthday || t('signup.birthdayFormatAlert')}
            onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
              const value = e.clipboardData.getData('text');
              const formattedValue = formatBirthday(value);
              setState('profile', { ...stateModel?.profile, birthday: formattedValue });
              validateBirthday(formattedValue, value);
            }}
          />
          {birthdayError ? (
            <AlertMessage className="alert-message" message={birthdayError} isError={true} />
          ) : stateModel?.profile?.birthday ? null : (
            <AlertMessage className="alert-message" message={t('signup.birthdayFormatAlert')} />
          )}
        </BirthdayContainer>

        <TermsAndConditions
          onTermsChange={(isValid) => {
            setTermsValid(isValid);
          }}
        />
      </AdditionalSection>
      <BTButton
        disabled={!isValid}
        onClick={() => {
          handleClickSignup();
        }}
      >
        {t('signup.signup')}
      </BTButton>
    </FormContainer>
  );
};

const FormContainer = styled.form`
  display: flex;
  max-width: 588px;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  margin: 56px auto;
  margin-bottom: 100px;

  @media ${(props) => props.theme.media.mobile} {
    padding: 0 20px;
    margin: 34px auto;
  }
`;

const FormTitle = styled.h1`
  color: var(--Grayscale-80, #2d2d2d);
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.06px;
`;

const FormSection = styled.section`
  margin-top: 56px;
  width: 100%;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const PhoneSection = styled.div`
  margin-top: 48px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const InputFieldGroup = styled.div`
  margin-bottom: 45px;
`;

const FormLabel = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.04em;
  color: '#1d1d1d';
`;

const RequiredMark = styled.span`
  color: #f35064;
  margin-left: 4px;
`;

const AdditionalSection = styled.section`
  margin-top: 56px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const BirthdayContainer = styled.div`
  margin-top: 48px;
`;

const Divider = styled.hr`
  width: 100%;
  height: 1px;
  background: var(--Grayscale-10, #f0f0f0);
  border: none;
`;

const BirthdayInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 48px 0;
`;

const BirthdayIcon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 100px;
`;

const BirthdayText = styled.p`
  margin-top: 16px;
  font-size: 17px;
  line-height: 26px;
  color: var(--4-e-433-c, #4e433c);
`;
