import { menus } from 'definitions/menus';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useMatch } from 'react-router-dom';
import { Row } from 'saladsoft-primitive';
import styled from 'styled-components';
import useDetectScroll from '@smakss/react-scroll-direction';
import { useUserInfo } from 'providers/UserInfoProvider';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import LanguageSelector from '../LanguageSelector';
import { PCDateRangePickerSimple } from '../searchBox/PCDateRangePickerSimple';
import CountButtonSimple from '../searchBox/CountButtonSimple';
// 라우트 설정이 있는 파일 경로

const Gnb = () => {
  const navigate = useLocaleNavigate();
  const { loginStatus } = useUserInfo();
  const { pathname } = useLocation();
  const { scrollPosition } = useDetectScroll();
  const { t } = useTranslation();

  const [showSearch, setShowSearch] = useState(false);

  // 경로가 일치하는지 확인하는 함수
  const isPathMatching = (currentPath: string, menuPath: string) => {
    // 홈 페이지 특별 처리
    if (menuPath === '/reservation') {
      return (
        currentPath === '/' ||
        currentPath === '/en' ||
        currentPath === '/ja' ||
        currentPath === '/cn'
      );
    }
    // 정확한 경로 일치 확인
    if (currentPath === menuPath) {
      return true;
    }

    // 언어 코드가 포함된 경로 확인 (예: /en/reservation, /ja/reservation 등)
    // 정규식: /^\/(en|ja|cn|ko)?(\/?.*)$/
    // 이 정규식은 경로에서 언어 코드 부분을 제거하고 나머지 경로를 추출합니다
    const pathWithoutLang = currentPath.replace(/^\/(en|ja|cn|ko)/, '');

    // 언어 코드를 제외한 나머지 경로가 메뉴 경로와 일치하는지 확인
    return pathWithoutLang === menuPath;
  };

  useEffect(() => {
    if (
      pathname === '/' ||
      pathname === '/en' ||
      pathname === '/ja' ||
      pathname === '/cn' ||
      pathname === '/ko'
    ) {
      setShowSearch(scrollPosition.top > 750);
    } else {
      const url = window.location.pathname + window.location.search;
      if (url.includes('/search?')) {
        setShowSearch(true);
        return;
      } else {
        setShowSearch(false);
      }
    }
  }, [scrollPosition, pathname]);

  return (
    <>
      <div
        style={{
          padding: '8px 0',
          width: '100%',
          maxWidth: 'var(--max-width)',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Row width="100%" justifyContent="space-between" alignItems="center">
          <Row position="relative" gap={32}>
            <div
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/');
              }}
            >
              <img src={'/images/logo.png'} alt="logo" style={{ width: 200 }} />
            </div>
            <div style={{ display: 'flex', gap: '8px' }}>
              <SimpleSearchContainer show={showSearch}>
                <PCDateRangePickerSimple dateText={<></>} />
              </SimpleSearchContainer>
              <SimpleSearchContainer show={showSearch}>
                <CountButtonSimple count={2} maxCount={12} onChange={() => {}} />
              </SimpleSearchContainer>
            </div>
          </Row>
          <Row gap={40}>
            <>
              {menus.map((menu) => (
                <MenuItem
                  key={menu.path}
                  to={menu.guestPath && !loginStatus ? `${menu.guestPath}` : menu.path}
                  $isActive={isPathMatching(pathname, menu.path)}
                >
                  {t(menu.name)}
                </MenuItem>
              ))}
            </>

            <LanguageSelector />
          </Row>
        </Row>
      </div>
    </>
  );
};

const MenuItem = styled(Link)<{ $isActive: boolean }>`
  margin: 0;
  padding: 0;
  text-decoration: none;
  color: var(--4-e-433-c, #4e433c);
  font-weight: ${(props) => (props.$isActive ? 600 : 400)};
`;

const SimpleSearchContainer = styled.div<{ show: boolean }>`
  position: relative;
  top: ${(props) => (props.show ? 0 : -60)}px;
  height: 44px;
  text-align: center;
  z-index: 2;
  transition: ${(props) => (props.show ? 'top 0.3s ease-in-out' : 'none')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Gnb;
