import React from 'react';
import styled from 'styled-components';
import { Popover } from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, useMediaContext } from 'saladsoft-primitive';
import Subtitle from './Subtitle';
import { CountInner } from './CountInner';
import { useStoreWrapper } from 'stores/StoreHelper';

export const CountButton = ({
  count,
  maxCount,
  style,
  onChange,
}: {
  count: number;
  maxCount: number;
  style?: CSSProperties;
  onChange: (guest: number) => void;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useMediaContext();
  const { uiStore } = useStoreWrapper();

  const [guestCount, setGuestCount] = useState<number>(1);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isMobile) {
      uiStore?.modal.show({
        title: t('setGuestCount'),
        style: {
          width: '100%',
          maxWidth: '100%',
          padding: '0',
          margin: '0 auto',
        },
        children: (
          <CountInner
            onChange={(count: number) => {
              onChange?.(count);
              setGuestCount(count);
            }}
            count={guestCount}
            maxCount={maxCount}
            style={{
              padding: '16px',
              boxShadow: 'none',
              width: '100%',
              height: '100%',
            }}
            className="mobile-count-inner"
            onClose={() => {
              uiStore?.modal.close();
            }}
          />
        ),
      });
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (count) setGuestCount(count);
  }, [count]);

  useEffect(() => {
    // 동적 스타일 태그 생성
    const style = document.createElement('style');
    style.innerHTML = `
      .MuiPaper-root {
       box-shadow:none !important;
       border-radius:20px !important;
       background-color:transparent !important;
      }

      .mobile-count-inner {
        box-shadow:none !important;
        border-radius:20px !important;
      }

      @media ${(props: any) => props.theme.media.mobile} {
        .mobile-count-inner {
          box-shadow:none !important;
          border-radius:20px !important;
          border: 1px solid red !important;
        }
      }
    `;
    document.head.appendChild(style);
    // 컴포넌트 언마운트 시 스타일 태그 제거

    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <>
      <PeopleLayout style={style} selected={anchorEl !== null} onClick={(e: any) => handleClick(e)}>
        <Row width={'100%'} justifyContent="space-between" alignItems="center">
          <Row gap={8} alignItems="center">
            <img src="/images/people.svg" alt="people" />
            <Subtitle type={isMobile ? undefined : 'B'}>{t('People')}</Subtitle>
          </Row>
          <Subtitle type={isMobile ? 'C' : 'A'}>
            {guestCount}
            {t('people2')}
          </Subtitle>
        </Row>
      </PeopleLayout>

      {!isMobile && (
        <Popover
          id={id}
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <CountInner
            onChange={(count: number) => {
              onChange?.(count);
              setGuestCount(count);
            }}
            count={guestCount}
            maxCount={maxCount}
            onClose={handleClose}
          />
        </Popover>
      )}
    </>
  );
};

const PeopleLayout = styled.div<{ selected: boolean }>`
  border: 1px solid red;
  cursor: pointer;
  color: #555;
  display: flex;
  width: 262px;
  height: 80px;
  padding: 16px 32px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: var(--Grayscale-5, #f8f8f8);
  border: ${({ selected }) => (selected ? '1px solid #555' : '1px solid transparent')};
  border-radius: 8px;

  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
    height: 60px;
    padding: 10px 16px;
    margin: 0;
  }
`;
