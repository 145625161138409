import { SignupForm } from 'components/pages/auth/signupForm/SignupForm';
import PageContainer from 'containers/pc/PageContainer';
import React, { useEffect } from 'react';

const EmailSignupPage = () => {
  useEffect(() => {
  }, []);

  return (
    <PageContainer>
      <div className="page-container" style={{}}>
        <SignupForm />
      </div>
    </PageContainer>
  );
};

export default EmailSignupPage;
