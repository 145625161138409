import { makeAutoObservable } from 'mobx';
import { AmenityDto, ListingDto } from 'services/data-contracts';

class ListingStore {
  listing?: ListingDto;

  amenities: AmenityDto[];

  constructor() {
    makeAutoObservable(this);
  }

  setListing(listing: ListingDto) {
    this.listing = listing;
  }

  setAmenities(amenities: AmenityDto[]) {
    this.amenities = amenities;
  }

  unMount() {
    this.listing = undefined;
    this.amenities = [];
  }
}

export default ListingStore;
