import { SettingsMenu } from 'components/pages/my/settingsMenu/SettingsMenu';
import { UserSummary } from 'components/pages/my/userSummary/UserSummary';
import PageContainer from 'containers/pc/PageContainer';
import useToken from 'hooks/useToken';
import { getFullName } from 'libs/helper/utils';
import { useUserInfo } from 'providers/UserInfoProvider';
import React, { useEffect, useState } from 'react';
import Navigation from 'components/commons/mobile/Navigation';
import { useMediaContext } from 'saladsoft-primitive';
import styled from 'styled-components';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import { UserProfile } from 'components/pages/myInfo/userProfile/UserProfile';

const MyPage = () => {
  const navigate = useLocaleNavigate();

  const { isMobile } = useMediaContext();
  const { myAccount, myPoint, myCoupons, loginStatus } = useUserInfo();
  const { validUser } = useToken();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!validUser() && loginStatus === false) {
      navigate('/login');
    } else {
      setIsLoading(true);
    }
  }, [loginStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    console.log('My Info:', myAccount, myPoint, myCoupons);
  }, [myAccount, myPoint, myCoupons]);

  return (
    <PageContainerWrapper>
      <div
        className="page-container"
        style={{ margin: 'auto', height: 'calc(100vh - 272px)', width: '100%', paddingTop: '0px' }}
      >
        <BackgroundWrapper />

        {isLoading && (
          <UserProfileWrapper>
            <UserProfile
              singleUse={true}
              name={getFullName(myAccount?.profile)}
              initial={myAccount?.profile?.lastName?.charAt(0) || ''}
              points={myPoint}
              stats={[]}
              menuItems={[]}
            />
            {/* <UserSummary
              name={getFullName(myAccount?.profile) || ''}
              points={myPoint || 0}
              coupons={myCoupons?.length || 0}
            /> */}
            {/* <SettingsMenu /> */}
          </UserProfileWrapper>
        )}
      </div>
      {isMobile && (
        <NavagationWrapper>
          <Navigation />
        </NavagationWrapper>
      )}
    </PageContainerWrapper>
  );
};

const PageContainerWrapper = styled(PageContainer)`
  .page-container {
    max-width: 100% !important;
    padding-top: 0px !important;
  }
  .page-content-container {
    padding-top: 0px !important;
  }
`;

const NavagationWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: #fff;
  z-index: 1;
`;

const UserProfileWrapper = styled.div`
  width: 100%;
  max-width: 1200px !important;
  margin: auto;
  z-index: 1;
  position: relative;
  /* background-color:#F5F5F5; */
`;

const BackgroundWrapper = styled.div`
  width: 100%;
  height: 300px;
  background-color: #f5f5f5;
  position: absolute;
  /* top: 70px; */
  left: 0;
  z-index: 0;
`;

export default MyPage;
