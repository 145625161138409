import { AuthService } from 'services/AuthService';
import { ListingService } from 'services/ListingService';
import { CommonGuideService } from 'services/CommonGuideService';
import { MyAccountService } from 'services/MyAccountService';
import { ReservationService } from 'services/ReservationService';
import { NonMemberReservationService } from 'services/NonMemberReservationService';
import { PaymentService } from 'services/PaymentService';
import { TermService } from 'services/TermService';
import { MyReservationService } from 'services/MyReservationService';
import { VoucherService } from 'services/VoucherService';
import { PolicyService } from 'services/PolicyService';
import { HttpClient } from '../services/http-client';
import { PromotionService } from 'services/PromotionService';

import { RegionService } from 'services/RegionService';
import { AmenityService } from 'services/AmenityService';
// 각 서비스의 타입을 나열하여 서비스들의 타입 정의
type ServiceInstances = {
  locationService?: Location;
  listingService?: ListingService;
  commonGuideService?: CommonGuideService;
  myAccountService?: MyAccountService;
  reservationService?: ReservationService;
  myReservationService?: MyReservationService;
  nonReservationService?: NonMemberReservationService;
  paymentService?: PaymentService;
  termService?: TermService;
  authService?: AuthService;
  voucherService?: VoucherService;
  // 다른 서비스 클래스를 여기에 추가하세요.
  policyService?: PolicyService;
  promotionService?: PromotionService;
  regionService?: RegionService;
  amenityService?: AmenityService;
};

// 서비스 헬퍼 클래스를 정의
class ServiceHelper {
  private services: ServiceInstances = {};

  private httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
    this.loadServices();
  }

  // 서비스 클래스 목록을 명시적으로 정의
  private loadServices() {
    // 서비스 클래스들을 명시적으로 정의하여 인스턴스화
    this.services = {
      listingService: new ListingService(this.httpClient),
      commonGuideService: new CommonGuideService(this.httpClient),
      myAccountService: new MyAccountService(this.httpClient),
      reservationService: new ReservationService(this.httpClient),
      nonReservationService: new NonMemberReservationService(this.httpClient),
      paymentService: new PaymentService(this.httpClient),
      termService: new TermService(this.httpClient),
      authService: new AuthService(this.httpClient),
      myReservationService: new MyReservationService(this.httpClient),
      voucherService: new VoucherService(this.httpClient),
      policyService: new PolicyService(this.httpClient), // 추가
      promotionService: new PromotionService(this.httpClient),
      regionService: new RegionService(this.httpClient),
      amenityService: new AmenityService(this.httpClient),
    };
  }

  // 타입을 명시한 Proxy 반환
  getServiceProxy(): ServiceInstances {
    return new Proxy(this.services, {
      get: (target: ServiceInstances, prop: keyof ServiceInstances) => {
        if (prop in target) {
          const service = target[prop];
          if (service !== undefined) {
            return service;
          } else {
            throw new Error(`Service '${String(prop)}' has not been initialized.`);
          }
        } else {
          throw new Error(`Service '${String(prop)}' does not exist.`);
        }
      },
    }) as ServiceInstances;
  }
}

// HttpClient 인스턴스를 생성하고 헬퍼 클래스를 초기화
const httpClient = new HttpClient();
const serviceHelper = new ServiceHelper(httpClient);

// 싱글턴으로 내보내기
export default serviceHelper.getServiceProxy();
