import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import { Row } from 'saladsoft-primitive';
import AccordionHeader from './AccordionHeader';
import BTAccordion from 'components/commons/BTAccordion';
import { useUserInfo } from 'providers/UserInfoProvider';
import ServiceHelper from 'stores/ServiceHelper';
import i18n from 'i18n';
import { OptionDto } from 'services/data-contracts';
import OptionCard from './OptionCard';
import { OptionModel } from 'types/CommonTypes';
import SelectedOptionsBox from './SelectedOptionsBox';

const AdditionalOptions = ({
  listingId,
  onChange,
}: {
  listingId: number;
  onChange: (options: OptionModel[]) => void;
}) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<OptionModel[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<OptionModel[]>([]);

  const handleOptionChecked = (option: OptionModel) => {
    if (option.checked) {
      setSelectedOptions((prev) => {
        const existingOption = prev.find((o) => o.id === option.id);
        if (existingOption) {
          return prev.map((o) => (o.id === option.id ? { ...o, qty: option.qty } : o));
        }
        return [...prev, { ...option, qty: 1 }];
      });
    } else {
      setSelectedOptions((prev) => prev.filter((o) => o.id !== option.id));
    }
  };

  const handleQuantityChange = (optionId: number, qty: number) => {
    setSelectedOptions((prev) => prev.map((o) => (o.id === optionId ? { ...o, qty } : o)));

    setOptions((prev) => prev.map((o) => (o.id === optionId ? { ...o, qty } : o)));
  };

  useEffect(() => {
    onChange(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    ServiceHelper.listingService
      ?.getOptionsByListing(listingId, { locale: i18n.language })
      .then((res) => {
        setOptions(res.data);
      });
  }, [listingId]);

  return (
    <div>
      <BTAccordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <AccordionHeader title={t('Additional Options')} index={2} />
        </AccordionSummary>
        <AccordionDetails style={{ margin: '40px 0' }}>
          <Row gap={32} flexDirection="column">
            {options?.map((option) => (
              <OptionCard
                key={option.id}
                option={option}
                onChange={(model) => handleOptionChecked(model)}
                onQuantityChange={handleQuantityChange}
              />
            ))}
          </Row>
          <SelectedOptionsBox
            selectedOptions={selectedOptions}
            onRemove={(optionId) => {
              setSelectedOptions((prev) => prev.filter((o) => o.id !== optionId));
              setOptions((prev) =>
                prev.map((o) => (o.id === optionId ? { ...o, checked: false } : o)),
              );
            }}
            onQuantityChange={handleQuantityChange}
          />
        </AccordionDetails>
      </BTAccordion>
    </div>
  );
};

export default AdditionalOptions;
