import styled from 'styled-components';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ListingDto, ReservationDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

type ReservationStatus = 'CURRENT' | 'UPCOMING' | 'PAST' | 'CANCELLED';

const ReservationCard = ({
  reservation,
  onClick,
}: {
  reservation: ReservationDto;
  onClick?: () => void;
}) => {
  const { t } = useTranslation();
  const [listing, setListing] = useState<ListingDto | null>(null);

  const getStayDuration = (arrivalDate: string, departureDate: string) => {
    const startDate = new Date(arrivalDate);
    const endDate = new Date(departureDate);
    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    return `${startDate.getMonth() + 1}. ${startDate.getDate()} ~ ${endDate.getMonth() + 1}. ${endDate.getDate()} (${diffDays}${t('Nights')} ${diffDays + 1}${t('Days')})`;
  };

  const getDDay = (arrivalDate: string) => {
    const status = reservation.status as ReservationStatus;

    if (status === 'CANCELLED') {
      return t('취소완료');
    }
    // else if (status === 'PAST') {
    //   return t('이용완료');
    // }
    else {
      const startDate = new Date(arrivalDate);
      const today = new Date();
      const diffDays = Math.ceil((startDate.getTime() - today.getTime()) / (1000 * 3600 * 24));
      // return `D-${diffDays}`;

      if (diffDays > 0) {
        return `D-${diffDays}`;
      } else {
        return t('이용완료');
      }
    }
  };

  useEffect(() => {
    console.log('card:', reservation);

    if (reservation.listingId) {
      ServiceHelper.listingService
        ?.getListingById(reservation.listingId, {
          locale: i18n.language,
        })
        .then((response) => {
          setListing(response.data);
        });
    }

    //상태 확인
    // const reservationStatus = ServiceHelper.myReservationService
    //   ?.getReservationsByStatus({
    //     status: 'CURRENT',
    //     locale: i18n.language,
    //   })
    //   .then((res) => console.log(res.data));
  }, [reservation]);

  return (
    <CardContainer>
      <ReservationDate>{moment(reservation.reservationDate).format('YYYY.MM.DD')}</ReservationDate>
      <CardContent>
        <CardHeader>
          <HeaderInfo>
            <DDay status={reservation.status as ReservationStatus}>
              {getDDay(reservation.arrivalDate as string)}
            </DDay>
            <ReservationNumber>
              {t('Reservation Number')} : {reservation.hostawayReservationId}
            </ReservationNumber>
          </HeaderInfo>
          <Divider />
        </CardHeader>
        <CardBody>
          <HotelImage src={listing?.images?.[0]?.url} alt={reservation.listingName} />
          <ReservationDetails>
            <HotelInfo>
              <HotelName>{listing?.name}</HotelName>
              <HotelAddress>{listing?.address}</HotelAddress>
              <StayInfo>
                <StayDuration>
                  <CalendarIcon
                    src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/c040b82a13423f8f2f973d9cd4da30f3193195f55716af4cdae9cd5b45398242?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&"
                    alt=""
                  />
                  <span>
                    {getStayDuration(
                      reservation.arrivalDate as string,
                      reservation.departureDate as string,
                    )}
                  </span>
                </StayDuration>
                <GuestCount>
                  <PersonIcon
                    src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/de68e12d05d3b708f9a67069a55cac35739e74056e6785f1380ef645bce73e7e?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&"
                    alt=""
                  />
                  <span>
                    {reservation.adults}
                    {t('guestsCount')}
                  </span>
                </GuestCount>
              </StayInfo>
            </HotelInfo>
            <PriceInfo>
              <Price>{reservation.basePrice?.toLocaleString()}</Price>
              <Currency>{t('won')}</Currency>
            </PriceInfo>
          </ReservationDetails>
        </CardBody>
        <SearchButton onClick={onClick}>{t('View Details')}</SearchButton>
      </CardContent>
    </CardContainer>
  );
};

const CardContainer = styled.article`
  display: flex;
  flex-direction: column;
  width: calc(50% - 20px);
  min-width: 300px;
  margin-bottom: 32px;

  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

const ReservationDate = styled.time`
  color: var(--Grayscale-70, #555);
  letter-spacing: -0.03px;
  padding: 0 4px;
  font:
    400 13px Pretendard,
    sans-serif;
`;

const CardContent = styled.div`
  border-radius: 12px;
  border: 1px solid var(--Grayscale-20, #e4e4e4);
  background: var(--Grayscale-0, #fff);
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  margin-top: 12px;
  padding: 16px;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  letter-spacing: -0.03px;
`;

const HeaderInfo = styled.div`
  display: flex;
  min-height: 20px;
  align-items: center;
  justify-content: space-between;
`;

const DDay = styled.div<{ status?: ReservationStatus }>`
  gap: 8px;
  border-radius: 4px;
  background: ${(props) => {
    switch (props.status) {
      case 'CANCELLED':
        return '#8e8e8e';
      case 'PAST':
        return '#92897b';
      case 'CURRENT':
        return 'var(--1-f-1-e-1-d, #1f1e1d)';
      case 'UPCOMING':
        return 'var(--1-f-1-e-1-d, #1f1e1d)';
      default:
        return 'var(--1-f-1-e-1-d, #1f1e1d)';
    }
  }};
  color: var(--Grayscale-5, #f8f8f8);
  font:
    700 12px Montserrat,
    sans-serif;
  padding: 3px 6px;
`;

const ReservationNumber = styled.div`
  color: var(--Grayscale-50, #8e8e8e);
  font:
    400 12px Pretendard,
    sans-serif;
`;

const Divider = styled.hr`
  width: 100%;
  border: none;
  height: 1px;
  background: var(--Grayscale-20, #e4e4e4);
  margin-top: 12px;
`;

const CardBody = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
`;

const HotelImage = styled.img`
  aspect-ratio: 0.83;
  width: 110px;
  height: 133px;
  border-radius: 4px;
  object-fit: cover;
`;

const ReservationDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding: 8px 0;
`;

const HotelInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

const HotelName = styled.h3`
  color: var(--Grayscale-90, #1d1d1d);
  font:
    600 17px Pretendard,
    sans-serif;
  letter-spacing: -0.04px;
  margin: 0;
`;

const HotelAddress = styled.address`
  color: var(--Grayscale-50, #8e8e8e);

  letter-spacing: -0.03px;
  margin-top: 5px;
  font-size: 13px;
  font-style: normal;
`;

const StayInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  font:
    400 13px Pretendard,
    sans-serif;
  color: var(--Grayscale-70, #555);
`;

const StayDuration = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const GuestCount = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const CalendarIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const PersonIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const PriceInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 600;
`;

const Price = styled.span`
  font-size: 17px;
  letter-spacing: -0.04px;
`;

const Currency = styled.span`
  font-size: 13px;
  letter-spacing: -0.03px;
`;

const SearchButton = styled.button`
  width: 100%;
  border-radius: 8px;
  background: var(--bg_color, #f7f3ef);
  color: var(--4-e-433-c, #4e433c);
  font:
    500 15px Pretendard,
    sans-serif;
  letter-spacing: -0.04px;
  padding: 12px;
  margin-top: 24px;
  border: none;
  cursor: pointer;

  &:focus-visible {
    outline: 2px solid var(--4-e-433-c, #4e433c);
    outline-offset: 2px;
  }
`;

export default ReservationCard;
