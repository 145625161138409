import React, { useState, ReactNode, useEffect, useRef } from 'react';
import { Context } from './usePageState';

interface PageProviderProps {
  children: ReactNode | ReactNode[];
}

const RootProvider = ({ children }: PageProviderProps) => {
  const [myChannel, setMyChannel] = useState<any | undefined | null>(undefined);
  const [unreadCount, setUnreadCount] = useState<any>({ inquiry: 0 });

  const setChannel = (channel: any | undefined) => {};

  const getMyChannel = async () => {};

  // const getCurrentChannel = async (directChannelName?: string) => {
  //   let targetChannel;
  //   if (myChannel) {
  //     targetChannel = await channelStore.getChannel(
  //       (channelName as string) || (directChannelName as string),
  //       myChannel.id,
  //     );
  //   } else {
  //     targetChannel = await channelStore.getChannel(
  //       (channelName as string) || (directChannelName as string),
  //     );
  //     // setCurrentChannel(targetChannel as ChannelDto);
  //   }
  //   setCurrentChannel(targetChannel as ChannelDto);
  // };

  useEffect(() => {
    getMyChannel();
  }, []);

  return (
    <Context.Provider
      value={{
        unreadCount,
        setUnreadCount,
        myChannel,
        getMyChannel,
        setChannel,
        // reset,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default RootProvider;
