import MobileGnb from 'components/commons/mobile/Gnb';
import Gnb from 'components/commons/pc/Gnb';
import React from 'react';
import { useMediaContext } from 'saladsoft-primitive';
import styled from 'styled-components';
import { HideGnb } from 'components/commons/mobile/HideGnb';
import { useLocation } from 'react-router-dom';

const PageContainer = ({
  header,
  children,
  className,
}: {
  header?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
}) => {
  const { isSmall, isMedium, isLarge, isXLarge, isMobile, isTablet, isDesktop } = useMediaContext();
  const isHideGNB = HideGnb(window.location.pathname);
  const location = useLocation();
  const isMembershipTab = location.pathname.includes('/my-info/membership');

  return (
    <Container $isMembershipTab={isMembershipTab} className={className}>
      {/* 모바일일 때, 특정 경로에서 GNB 숨김 처리 */}
      <GnbContainer>{isMobile ? !isHideGNB && <MobileGnb /> : <Gnb />}</GnbContainer>
      {header && <HeaderContainer>{header}</HeaderContainer>}
      <ContentContainer className="page-content-container">{children}</ContentContainer>
    </Container>
  );
};

const Container = styled.div<{ $isMembershipTab: boolean }>`
  display: flex;
  justify-content: center;
  background: #fff;
  margin: 0 auto;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  @media ${(props) => props.theme.media.mobile} {
    background: ${(props) => (props.$isMembershipTab ? 'var(--Grayscale-5, #f8f8f8)' : '#fff')};
  }
`;

const GnbContainer = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  background: #fff;
  z-index: 2;
  box-sizing: border-box;

  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  // PromotionDetailPage에서 padding-top: 56px 제거 필요
  padding: 56px 0;
  box-sizing: border-box;
  margin-bottom: 120px;
  min-height: calc(100vh - 120px);

  @media (${(props) => props.theme.media.mobile}) {
    padding: 0;
    margin-bottom: 0px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
`;

export default PageContainer;
