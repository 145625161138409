import { useEffect, useRef, useState } from "react";
import { NavigationType, useLocation, useNavigationType } from "react-router-dom";

interface UseScrollRestorationOptions {
    scrollKey?: string;
    scrollDelay?: number;
    scrollThrottleDelay?: number;
}

/**
 * 페이지 이동 시 스크롤 위치를 저장하고 복원하는 커스텀 훅
 * @param options 설정 옵션
 * @returns void
 */
export const useScrollRestoration = (options: UseScrollRestorationOptions = {}) => {
    const {
        scrollKey = "scroll_page",
        scrollDelay = 50,
        scrollThrottleDelay = 100
    } = options;

    const location = useLocation();
    const navigationType = useNavigationType();
    const scrollPosition = useRef(0);
    const prevLocationRef = useRef(location.pathname);

    const [navigationTypes, setNavigationTypes] = useState<'push' | 'pop' | 'init' | null>(null);

    useEffect(() => {

        const referrer = sessionStorage.getItem('referrer');
        if (referrer) {
            setNavigationTypes('init');
            sessionStorage.removeItem('referrer');
            return;
        }
        if (navigationType === 'POP') {
            setNavigationTypes('pop');
            const savedPosition = sessionStorage.getItem(scrollKey);
            if (savedPosition) {
                const position = parseInt(savedPosition, 10);
                setTimeout(() => {
                    window.scrollTo(0, position);
                }, scrollDelay);
            } else {
                window.scrollTo(0, 0);
            }
        } else {
            // console.log('push');
            setNavigationTypes('push');
            window.scrollTo(0, 0);
        }

        // 현재 경로 저장
        prevLocationRef.current = location.pathname;
        // }

        // 스크롤 이벤트 리스너 설정
        let scrollTimer: number | null = null;
        const scrollListener = () => {
            if (scrollTimer !== null) {
                clearTimeout(scrollTimer);
            }
            scrollTimer = window.setTimeout(() => {
                scrollPosition.current = window.scrollY;
            }, scrollThrottleDelay);
        };

        window.addEventListener("scroll", scrollListener);

        // 클린업 함수
        return () => {
            // 페이지를 떠날 때 스크롤 위치 저장
            if (scrollPosition.current > 0) {
                sessionStorage.setItem(scrollKey, String(scrollPosition.current));
            }

            window.removeEventListener("scroll", scrollListener);
            prevLocationRef.current = location.pathname;
        };
    }, [location.pathname, navigationType, scrollKey, scrollDelay, scrollThrottleDelay]);


    useEffect(() => {
        const handleBeforeUnload = () => {
            sessionStorage.setItem('referrer', location.pathname + ' ' + location.search);
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    return { navigationTypes }
}; 