import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
interface MenuItemProps {
  icon: string;
  text: string;
  onClick?: () => void;
}

export const UserMenuItem = ({ icon, text, onClick }: MenuItemProps) => {
  const { t } = useTranslation();

  return (
    <MenuItemContainer onClick={onClick}>
      <MenuItemContent>
        <MenuIcon src={icon} alt="" loading="lazy" />
        <MenuText>{text}</MenuText>
      </MenuItemContent>
      <ChevronIcon
        src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/e359ea65bc82669e3c2d5fa9c1c6884214fec8973c5fd477586704d49e01a34c?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&"
        alt=""
        loading="lazy"
      />
    </MenuItemContainer>
  );
};

const MenuItemContainer = styled.button`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 4px;
  padding: 17px 24px;
  background: transparent;
  border: none;
  cursor: pointer;
`;

const MenuItemContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
`;

const MenuIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
`;

const MenuText = styled.span`
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.04px;
  color: var(--Grayscale-90, #1d1d1d);
`;

const ChevronIcon = styled.img`
  width: 20px;
  height: 20px;
`;
