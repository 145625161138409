import * as React from 'react';
import styled from 'styled-components';
import type { TabProps } from './types';

export const Tab: React.FC<TabProps> = ({ isActive, children, onClick }) => {
  return (
    <TabButton role="tab" aria-selected={isActive} onClick={onClick} $isActive={isActive}>
      {children}
    </TabButton>
  );
};

const TabButton = styled.button<{ $isActive?: boolean }>`
  border: none;
  background: none;

  font:
    600 17px Pretendard,
    sans-serif;

  color: ${(props) =>
    props.$isActive ? 'var(--Grayscale-90, #1d1d1d)' : 'var(--Grayscale-50, #8e8e8e)'};
  border-bottom: ${(props) =>
    props.$isActive ? '2px solid var(--Grayscale-90, #1d1d1d)' : 'transparent'};

  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.06px;
  cursor: pointer;
  height: 42px;
  box-sizing: border-box;

  @media (${(props) => props.theme.media.mobile}) {
    font-size: 17px;
    letter-spacing: -0.042px;
  }
`;
