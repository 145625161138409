import React, { useEffect, useState, useTransition } from 'react';
import PageContainer from 'containers/pc/PageContainer';
import { Link, useSearchParams } from 'react-router-dom';
import { SignupForm } from 'components/pages/auth/signupForm/SignupForm';
import SocialLoginButtons from 'components/login/SocialLoginButtons';
import { SocialSignUpDto } from 'services/data-contracts';

const SignupPage = () => {
  const [searchParams] = useSearchParams();
  const ready = searchParams.get('ready');
  const socialTypeQuery = searchParams.get('socialType');
  const [socialType, setSocialType] = useState<'kakao' | 'google'>();
  const [socialData, setSocialData] = useState<SocialSignUpDto>();

  useEffect(() => {
    if (socialTypeQuery) {
      setSocialType(socialTypeQuery as 'kakao' | 'google');
    }
  }, [socialTypeQuery]);

  useEffect(() => {
    if (ready) {
      const socialData = localStorage.getItem('socialData');

      if (socialData) {
        setSocialData(JSON.parse(socialData!));
      }
    }
  }, [ready]);

  return (
    <PageContainer>
      {typeof socialType === 'undefined' ? (
        <>
          <SocialLoginButtons type="signup" />
        </>
      ) : (
        <SignupForm />
      )}
    </PageContainer>
  );
};

export default SignupPage;
