import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

import useLocaleNavigate from 'hooks/useLocaleNavigate';

const Investor = () => {
  const { t } = useTranslation();
  const navigate = useLocaleNavigate();

  return (
    <BackgroundWrapper>
      <Container>
        <ContentWrapper>
          <TextWrapper>
            <HeadingContainer>
              {/* 한옥 투자자 모집 - 해당 부분은 한글로만 표기 */}
              <MainHeading>한옥 투자자 모집</MainHeading>
              <SubHeading>한옥을 보유하고 계신가요?</SubHeading>
            </HeadingContainer>
            <Description>
              버틀러리 스테이 운영 프로그램에 상담 받아 보세요.
              <br />
              한옥 스테이 운영을 위한 모든 과정을
              <br />
              버틀러리가 안내 드립니다.
            </Description>
          </TextWrapper>
        </ContentWrapper>
        <Image
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate('/investor');
          }}
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/31286e567a788989a09fc0297c839315d57ea59ef3c0ece057c0bbf466dcdc94?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&"
          alt="Investment Recruitment"
        />
      </Container>
    </BackgroundWrapper>
  );
};

const BackgroundWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 340px;
  background: url('/images/investor.png') no-repeat center -470px;

  @media ${(props) => props.theme.media.mobile} {
    height: auto;
    padding: 50px 48px 33px;
  }
`;

const Container = styled.div`
  display: flex;
  max-width: 278px;
  flex-direction: column;
  align-items: center;
  font-family: Pretendard, sans-serif;
  text-align: center;
  justify-content: start;
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const TextWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
`;

const HeadingContainer = styled.div`
  align-self: center;
  display: flex;
  width: 251px;
  max-width: 100%;
  flex-direction: column;
  color: var(--Grayscale-0, #fff);
  justify-content: start;
`;

const MainHeading = styled.div`
  border-radius: 4px;
  background: var(--92897-b, #92897b);
  align-self: center;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.04px;
  padding: 2px 12px;
`;

const SubHeading = styled.div`
  font-size: 22px;
  font-weight: 500;
  letter-spacing: -0.06px;
  margin-top: 10px;
`;

const Description = styled.div`
  margin-top: 8px;
  width: 100%;
  font-size: 13px;
  color: var(--Grayscale-30, #d8d8d8);
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 20px;
`;

const Image = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 48px;
  border-radius: 100px;
  margin-top: 32px;
`;
export default Investor;
