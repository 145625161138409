import useToken from 'hooks/useToken';
import i18n from 'i18n';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { TokenDto, UserInfoDto, UserCouponDto, UserProfileDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import { UserInfoModel } from 'types/CommonTypes';
interface UserInfo {
  // 사용자 정보 타입을 여기에 정의하세요
}

interface UserInfoContextType {
  userInfo: UserProfileDto | null;
  myAccount: UserInfoModel | null;
  loginStatus: boolean;
  myPoint: number;
  myCoupons: UserCouponDto[];
  setUserInfo: (info: UserInfo | null) => void;
  login: (token: TokenDto) => void;
  logout: () => void;
  getLoginStatus: () => void;
}

const UserInfoContext = createContext<UserInfoContextType | undefined>(undefined);

export const UserInfoProvider = ({ children }: { children: ReactNode }) => {
  const { validUser } = useToken();

  const [userInfo, setUserInfo] = useState<UserProfileDto | null>(null);
  const [myAccount, setMyAccount] = useState<UserInfoModel | null>(null);
  const [loginStatus, setLoginStatus] = useState<boolean>(false);
  const [myPoint, setMyPoint] = useState<number>(0);
  const [myCoupons, setMyCoupons] = useState<UserCouponDto[]>([]);

  const login = (token: TokenDto) => {
    setLoginStatus(true);
    localStorage.setItem('accessToken', token.accessToken as string);
    localStorage.setItem('refreshToken1', token.refreshToken as string);
    getUserInfo();
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken1');
    localStorage.removeItem('refreshToken');
    setLoginStatus(false);
    setUserInfo(null);
  };

  const getUserInfo = () => {
    if (validUser()) {
      ServiceHelper.myAccountService?.getMyProfile().then((res) => {
        setUserInfo(res.data);
      });
      ServiceHelper?.myAccountService?.getMyPoint().then((res) => {
        setMyPoint(res.data);
      });
      ServiceHelper?.myAccountService?.getMyAccount().then((res) => {
        if (res && res.data) {
          switch (res.data.membershipLevel) {
            case 'PLATINUM':
              setMyAccount({ ...res.data, discountRate: 9 });
              break;
            case 'GOLD':
              setMyAccount({ ...res.data, discountRate: 5 });
              break;
            case 'SILVER':
              setMyAccount({ ...res.data, discountRate: 3 });
              break;
            case 'CLASSIC':
              setMyAccount({ ...res.data, discountRate: 1 });
              break;
            default:
              setMyAccount(res.data);
              break;
          }
        }
      });
      ServiceHelper?.myAccountService
        ?.getMyCoupons({
          locale: i18n.language,
        })
        .then((res) => {
          setMyCoupons(res.data);
        });
    }
  };
  const getLoginStatus = async () => {
    const status = validUser();
    setLoginStatus(status);

    // 로그인된 상태일 때만 API 호출
    if (status) {
      getUserInfo();
    }
    return setLoginStatus(status);
  };

  useEffect(() => {
    getLoginStatus();
  }, []);

  return (
    <UserInfoContext.Provider
      value={{
        userInfo,
        setUserInfo,
        loginStatus,
        login,
        logout,
        getLoginStatus,
        myAccount,
        myPoint,
        myCoupons,
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
};

export const useUserInfo = () => {
  const context = useContext(UserInfoContext);
  if (context === undefined) {
    throw new Error('useUserInfo must be used within a UserInfoProvider');
  }
  return context;
};
