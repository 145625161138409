// 환불 정책, 멤버십 정책 정보 제공
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { MembershipPolicyDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';

interface PolicyContextType {
  membershipPolicy: MembershipPolicyDto[];
}

const PolicyContext = createContext<PolicyContextType | undefined>(undefined);

export const PolicyProvider = ({ children }: { children: ReactNode }) => {
  const [membershipPolicy, setMembershipPolicy] = useState<MembershipPolicyDto[]>([]);

  const getMembershipPolicy = async () => {
    const response = await ServiceHelper?.policyService?.getMembershipPolicies();
    if (response?.data) {
      setMembershipPolicy(response.data);
    }
  };

  useEffect(() => {
    getMembershipPolicy();
  }, []);

  return <PolicyContext.Provider value={{ membershipPolicy }}>{children}</PolicyContext.Provider>;
};

export const usePolicy = () => {
  const context = useContext(PolicyContext);
  if (context === undefined) {
    throw new Error('usePolicy must be used within a PolicyProvider');
  }
  return context;
};
