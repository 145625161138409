import ChipButton from 'components/input/ChipButton';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { Row } from 'saladsoft-primitive';
import { RegionDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';

const BranchFilter = ({
  onChange,
  value,
}: {
  onChange: (selections: number[]) => void;
  value?: number[];
}) => {
  type RegionModel = RegionDto & { selected: boolean };

  const [regions, setRegions] = useState<RegionModel[]>([]);
  const [selections, setSelections] = useState<Array<{ id: string; selected: boolean }>>([]);

  useEffect(() => {
    ServiceHelper.regionService?.getRegions({ locale: i18n.language }).then((res) => {
      setRegions(res.data.map((region) => ({ ...region, selected: false })));
    });

    return () => {
      setRegions([]);
    };
  }, []);

  return (
    <Row justifyContent="flex-start" gap={10} padding="10px">
      {regions.map((region) => (
        <ChipButton
          key={region.id}
          id={region.id?.toString() || ''}
          label={region.name || ''}
          selected={value?.includes(region.id || 0)}
          onChange={(id, selected) => {
            if (id === '0') {
              setSelections([]);
              setRegions(regions.map((r) => ({ ...r, selected: r.id === 0 })));
              onChange([]);
              return;
            }

            if (id === '100' && selected) {
              const newRegions = regions.map((r) => ({ ...r, selected: r.id === 100 }));
              setRegions(newRegions);
              setSelections([{ id: '100', selected: true }]);
              onChange([100]);
              return;
            }

            const idNumber = Number(id);

            let newSelectedIds = [...(value || [])];

            if (selected) {
              if (newSelectedIds.includes(100)) {
                newSelectedIds = newSelectedIds.filter((item) => item !== 100);
              }
              if (!newSelectedIds.includes(idNumber)) {
                newSelectedIds.push(idNumber);
              }
            } else {
              newSelectedIds = newSelectedIds.filter((item) => item !== idNumber);
            }

            const newSelections = newSelectedIds.map((id) => ({
              id: id.toString(),
              selected: true,
            }));
            setSelections(newSelections);

            const newRegions = regions.map((r) => ({
              ...r,
              selected: newSelectedIds.includes(r.id || 0),
            }));
            setRegions(newRegions);

            onChange(newSelectedIds);
          }}
        />
      ))}
    </Row>
  );
};

export default BranchFilter;
