import MyReservationInfo from 'components/pages/myReservations/reservations/MyReservationInfo';
import PageContainer from 'containers/pc/PageContainer';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Row } from 'saladsoft-primitive';
import ServiceHelper from 'stores/ServiceHelper';
import { NonMemberReservationDto, PaymentDto, ReservationDto } from 'services/data-contracts';
import i18n from 'i18n';
import { format } from 'react-string-format';
import moment from 'moment';

import ReservationSummary from 'components/pages/bookPage/ReservationSummary';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Header } from 'resources/styles/CommonStyles';
import { Checkbox as MuiCheckbox } from '@mui/material';
import BTButton from 'components/input/BTButton';
import { styled as muiStyled } from '@mui/material/styles';
import { useStoreWrapper } from 'stores/StoreHelper';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import useToken from 'hooks/useToken';



const ReservationCancelSuccessPage = () => {
  const { t } = useTranslation();
  const { reservationId } = useParams();
  const navigate = useLocaleNavigate();
  const { validUser } = useToken();

  const [reservationInfo, setReservationInfo] = useState<ReservationDto | null>(null);
  const [paymentInfo, setPaymentInfo] = useState<PaymentDto | null>(null);

  const handleClickToList = () => {
    navigate('/my-reservations');
  };

  useEffect(() => {
    if (reservationId) {
      if (validUser()) {
        ServiceHelper?.myReservationService
          ?.getReservation(Number(reservationId), { locale: i18n.language })
          .then((res) => {
            setReservationInfo(res.data);
          });

        ServiceHelper?.myReservationService?.getPaymentByReservation(Number(reservationId))
          .then((res) => {
            setPaymentInfo(res.data);
          });
      } else {
        const nonMemberReservationDto: NonMemberReservationDto = JSON.parse(sessionStorage.getItem('guestReservationInfo') || '{}');
        ServiceHelper?.nonReservationService?.getReservationForNonMember({ locale: i18n.language }, nonMemberReservationDto)
          .then((res) => {
            setReservationInfo(res.data);
          });

        ServiceHelper?.nonReservationService?.getPaymentForNonMember(nonMemberReservationDto)
          .then((res) => {
            setPaymentInfo(res.data);
          });
      }
    }
  }, [reservationId]);

  useEffect(() => {
    window.scrollTo({
      top: 0,

    });
  }, []);

  return (
    <PageContainer>
      <div className="page-container" style={{ marginBottom: '77px', height: '100vh' }}>
        <Row width="100%" justifyContent="space-between" alignItems="flex-start" gap="48px">
          <div style={{ position: 'sticky', top: -20, width: 384 }}>
            {reservationInfo && <ReservationSummary reservationInfo={reservationInfo} />}
          </div>

          <div>
            <div style={{ marginBottom: 34 }}>
              <Header>{t('reservation.cancelSuccess')}</Header>
              <Notice>
                {t('reservation.cancelSuccessDescription')}
              </Notice>
            </div>


            <Container>
              <ContentWrapper>
                <Title>{t('reservation.cancelInfo')}</Title>
                <InfoSection>
                  <InfoGroup>
                    <InfoRow>
                      <Label>{t('reservation.stayName')}</Label>
                      <Value>{reservationInfo?.listingName}</Value>
                    </InfoRow>
                    <InfoRow>
                      <Label>{t('reservation.stayAmount')}</Label>
                      <Value>{reservationInfo?.totalAmount?.toLocaleString()} {t('won')}</Value>
                    </InfoRow>
                    <InfoRow>
                      <Label>{t('reservation.cancelFee')}</Label>
                      <Value>{reservationInfo?.totalAmount! - paymentInfo?.cancelAmount!} {t('won')}</Value>
                    </InfoRow>
                    <InfoRow>
                      <Label>{t('reservation.paymentMethod')}</Label>
                      <Value>{paymentInfo?.method}</Value>
                    </InfoRow>
                  </InfoGroup>
                  <Divider src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/2af161a56549ca5a1aa9da03e180ab85444b7ddee5b17da58cbcd6da15597d66?placeholderIfAbsent=true" />
                  <RefundSection>
                    <RefundLabel>{t('reservation.refundAmount')}</RefundLabel>
                    <RefundAmount>
                      <RefundValue>{paymentInfo?.cancelAmount?.toLocaleString()}</RefundValue>
                      <RefundUnit>{t('won')}</RefundUnit>
                    </RefundAmount>
                  </RefundSection>
                </InfoSection>
              </ContentWrapper>
              <NoticeSection>
                <NoticeText>{t('reservation.cancelNotice.coupon')}</NoticeText>
                <NoticeText>
                  {t('reservation.cancelNotice.priceChange')}
                </NoticeText>
                <NoticeText>
                  {t('reservation.cancelNotice.refundTime')}
                </NoticeText>
                <NoticeText>
                  {t('reservation.cancelNotice.refundAmountChange')}
                </NoticeText>
              </NoticeSection>
            </Container>
            <div>
              <div style={{ borderBottom: '1px solid #E0E0E0', paddingBottom: 16 }}></div>

              <BTButton style={{ marginTop: 20, width: '100%' }} onClick={() => { handleClickToList() }}>{t('tolist')}</BTButton>
            </div>
          </div>
        </Row>
      </div >
    </PageContainer >
  );
};

const Notice = styled.div`
    display: flex;
    padding: 20px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    background:#EDF1F9;
    border-radius: 12px;
    white-space: pre-wrap;
    font-size: 14px;
    color: #555;
    font-weight: 400;
    margin-top: 80px;
  `;
const Container = styled.article`
  max-width: 792px;
  font-family:
    Pretendard,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
`;

const ContentWrapper = styled.div`
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Title = styled.h1`
  color: #1d1d1d;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.04px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const InfoSection = styled.section`
  border-radius: 0px 0px 12px 12px;
  border-top: 1px solid var(--Grayscale-70, #555);
  background-color: #f8f8f8;
  margin-top: 20px;
  width: 100%;
  padding: 32px 20px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const InfoGroup = styled.div`
  width: 100%;
  font-size: 15px;
  letter-spacing: -0.04px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const InfoRow = styled.div<{ marginTop?: string }>`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 32px;
  justify-content: start;
  flex-wrap: wrap;
  margin-top: ${(props) => props.marginTop || "0"};
  @media (max-width: 991px) {
    max-width: 100%;
  }

  & + & {
    margin-top: 20px;
  }
`;

const Label = styled.span`
  color: #8e8e8e;
  font-weight: 500;
  align-self: stretch;
  margin: auto 0;
`;

const Value = styled.span`
  color: #1d1d1d;
  text-align: right;
  font-weight: 600;
  align-self: stretch;
  margin: auto 0;
  flex: 1;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Divider = styled.img`
  object-fit: contain;
  object-position: center;
  width: 100%;
  stroke-width: 1px;
  stroke: #8e8e8e;
  margin-top: 32px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const RefundSection = styled.div`
  display: flex;
  margin-top: 32px;
  width: 100%;
  align-items: center;
  font-weight: 600;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const RefundLabel = styled.span`
  align-self: stretch;
  margin: auto 0;
  font-size: 15px;
  color: #1d1d1d;
  letter-spacing: -0.04px;
`;

const RefundAmount = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  margin: auto 0;
  align-items: center;
  color: #1c51b6;
  white-space: nowrap;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex: 1;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const RefundValue = styled.span`
  font-size: 19px;
  letter-spacing: -0.05px;
  align-self: stretch;
  margin: auto 0;
`;

const RefundUnit = styled.span`
  font-size: 17px;
  letter-spacing: -0.04px;
  align-self: stretch;
  margin: auto 0;
`;

const NoticeSection = styled.footer`
  margin-top: 24px;
  width: 100%;
  font-size: 12px;
  color: #8e8e8e;
  font-weight: 400;
  letter-spacing: -0.03px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const NoticeText = styled.p`
  margin-top: 12px;
  &:first-child {
    margin-top: 0;
  }
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;


const NewUl = styled.ul`
    list-style: none; 
    font-size: 12px;
    color: #8E8E8E;
    white-space: pre-wrap;
  `;
export default ReservationCancelSuccessPage;
