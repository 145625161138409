import { CouponManagement } from 'components/pages/myInfo/couponManagement/CouponManagement';
import Guide from 'components/pages/myInfo/guide/Guide';
import { MembershipLevel } from 'components/pages/myInfo/membershipLevel/MembershipLevel';
import { MyInfoForm } from 'components/pages/myInfo/myInfoForm/MyInfoForm';
import Notice from 'components/pages/myInfo/notice/Notice';
import Points from 'components/pages/myInfo/points/Points';
import { UserProfile } from 'components/pages/myInfo/userProfile/UserProfile';
import { Wishlist } from 'components/pages/myInfo/wishlist/Wishlist';
import PageContainer from 'containers/pc/PageContainer';
import { getFullName } from 'libs/helper/utils';
import { useUserInfo } from 'providers/UserInfoProvider';
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Row, useMediaContext } from 'saladsoft-primitive';
import useToken from 'hooks/useToken';
import NoticeDetails from 'components/pages/myInfo/notice/NoticeDetails';
import MyInfoTabName from 'components/commons/mobile/MyInfoTabName';
import { useTranslation } from 'react-i18next';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import useLocaleNavigate from 'hooks/useLocaleNavigate';

const MyInfo = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaContext();
  const { myPoint, myAccount, loginStatus } = useUserInfo();
  const { validUser } = useToken();
  const navigate = useLocaleNavigate();
  const [currentTabName, setCurrentTabName] = useState<string | undefined>(undefined);
  const [isAnimating, setIsAnimating] = useState(false);
  const [titleName, setTitleName] = useState<string | undefined>(undefined);
  const prevTabNameRef = useRef<string | undefined>(undefined);

  // 현재 컴포넌트와 다음 컴포넌트를 저장
  const [currentComponent, setCurrentComponent] = useState<React.ReactElement | null>(null);
  const [nextComponent, setNextComponent] = useState<React.ReactElement | null>(null);

  // 애니메이션 방향 (들어오는지, 나가는지)
  const [animationDirection, setAnimationDirection] = useState<'in' | 'out'>('in');

  useEffect(() => {
    if (!validUser() && loginStatus === false) {
      navigate('/login');
    }
  }, [loginStatus]);

  const { tabName, noticeId } = useParams();

  // 탭 이름에 따라 컴포넌트 생성하는 함수
  const getComponentByTabName = (tab: string | undefined): React.ReactElement => {
    switch (tab) {
      case 'form':
        return <MyInfoForm key="form" />;
      case 'coupon':
        return <CouponManagement key="coupon" />;
      case 'wishlist':
        return <Wishlist key="wishlist" />;
      case 'membership':
        return (
          <MembershipLevel
            key="membership"
            currentLevel={myAccount?.membershipLevel || ''}
            userName={getFullName(myAccount?.profile)}
          />
        );
      case 'notice':
        if (noticeId) {
          return (
            <NoticeDetails key={`notice-${noticeId}`} onBack={() => navigate('/my-info/notice')} />
          );
        }
        return <Notice key="notice" />;
      case 'guide':
        return <Guide key="guide" />;
      case 'points':
        return <Points key="points" />;
      default:
        return <MyInfoForm key="default-form" />;
    }
  };

  // 탭 변경 감지 및 애니메이션 처리
  useEffect(() => {
    if (prevTabNameRef.current === undefined) {
      // 첫 로드 시 애니메이션 없이 바로 컴포넌트 설정
      setCurrentTabName(tabName);
      setCurrentComponent(getComponentByTabName(tabName));
      prevTabNameRef.current = tabName;
      return;
    }

    if (tabName !== prevTabNameRef.current) {
      // 다음 컴포넌트 미리 생성
      const newComponent = getComponentByTabName(tabName);
      setNextComponent(newComponent);

      // 현재 컴포넌트 페이드 아웃 시작
      setAnimationDirection('out');
      setIsAnimating(true);

      // 페이드 아웃 완료 후 컴포넌트 교체
      setTimeout(() => {
        setCurrentComponent(newComponent);
        setNextComponent(null);
        setCurrentTabName(tabName);

        // 새 컴포넌트 페이드 인 시작
        setAnimationDirection('in');

        // 페이드 인 완료 후 애니메이션 상태 초기화
        setTimeout(() => {
          setIsAnimating(false);
          prevTabNameRef.current = tabName;
        }, 400);
      }, 400);
    }
  }, [tabName, myAccount, noticeId, navigate]);

  // 타이틀 설정
  useEffect(() => {
    if (tabName === 'membership') {
      setTitleName(t('membershipLevel'));
    } else if (tabName === 'points') {
      setTitleName(t('points'));
    } else if (tabName === 'coupon') {
      setTitleName(t('availableCoupons'));
    } else if (tabName === 'wishlist') {
      setTitleName(t('wishlist'));
    } else if (tabName === 'notice') {
      setTitleName(t('notice'));
    } else if (tabName === 'guide') {
      setTitleName(t('guide'));
    } else if (tabName === 'form') {
      setTitleName(t('myInfo'));
    } else {
      setTitleName(t('myInfo'));
    }
  }, [tabName, t]);

  return (
    <PageContainer>
      <GlobalStyle />
      <div
        className="page-container"
        style={{ margin: 'auto', minHeight: 'calc(100vh - 272px)', marginBottom: '100px' }}
      >
        {isMobile ? <MyInfoTabName title={titleName} /> : <></>}
        <Row alignItems="flex-start" gap={80} padding="0 20px">
          {isMobile ? (
            <></>
          ) : (
            <UserProfile
              name={getFullName(myAccount?.profile)}
              initial={myAccount?.profile?.lastName?.charAt(0) || ''}
              points={myPoint}
              stats={[]}
              menuItems={[]}
            />
          )}
          <ContentContainer>
            <div>{currentComponent}</div>
          </ContentContainer>
        </Row>
      </div>
    </PageContainer>
  );
};

// 페이드 인/아웃 애니메이션
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

// 컨텐츠 컨테이너 (포지셔닝 컨텍스트)
const ContentContainer = styled.div`
  position: relative;
  flex: 1;
  min-height: 500px; /* 최소 높이 설정으로 레이아웃 점프 방지 */
`;

// 애니메이션 효과가 있는 컨텐츠 래퍼
const ContentWrapper = styled.div<{
  isAnimating: boolean;
  direction: 'in' | 'out';
  position: 'absolute' | 'relative';
}>`
  width: 100%;
  position: ${(props) => props.position};
  top: 0;
  left: 0;
  animation: ${(props) => {
      if (!props.isAnimating) return 'none';
      return props.direction === 'in' ? fadeIn : fadeOut;
    }}
    0.4s ease forwards;
`;

const PageTitle = styled.h1`
  background: var(--Grayscale-0, #fff);
  color: var(--Grayscale-90, #1d1d1d);
  letter-spacing: -0.07px;
  font:
    600 28px Pretendard,
    sans-serif;
`;

// 스핀 애니메이션 키프레임 추가
const GlobalStyle = createGlobalStyle`
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

export default MyInfo;
