import { House } from 'types/CommonTypes';

export const menus = [
  {
    name: 'Reservation',
    path: '/',
  },
  {
    name: 'My Reservations',
    path: '/my-reservations',
    guestPath: '/guest-reservation',
  },
  {
    name: 'Promotion',
    path: '/promotion',
  },
  {
    name: 'MY',
    path: '/my-page',
  },
];

export const supportLanguages = [
  { key: 'kr', value: '한국어' },
  { key: 'en', value: 'English' },
  { key: 'zh', value: '中文' },
  { key: 'ja', value: '日本語' },
];

export const houses: House[] = [
  {
    id: 1,
    image: '/images/temp/house_01.jpeg',
    address: 'Seoul, Korea',
    name: 'Hanok',
    price: 220000,
    parking: 1,
  },
  {
    id: 2,
    image: '/images/temp/house_02.jpeg',
    address: 'Seoul, Korea',
    name: 'Hanok',
    price: 220000,
    parking: 1,
  },
  {
    id: 3,
    image: '/images/temp/house_03.jpeg',
    address: 'Seoul, Korea',
    name: 'Hanok',
    price: 220000,
    parking: 0,
  },
  {
    id: 1,
    image: '/images/temp/house_01.jpeg',
    address: 'Seoul, Korea',
    name: 'Hanok',
    price: 220000,
    parking: 1,
  },
  {
    id: 2,
    image: '/images/temp/house_02.jpeg',
    address: 'Seoul, Korea',
    name: 'Hanok',
    price: 220000,
    parking: 1,
  },
  {
    id: 3,
    image: '/images/temp/house_03.jpeg',
    address: 'Seoul, Korea',
    name: 'Hanok',
    price: 220000,
    parking: 0,
  },
  {
    id: 1,
    image: '/images/temp/house_01.jpeg',
    address: 'Seoul, Korea',
    name: 'Hanok',
    price: 220000,
    parking: 1,
  },
  {
    id: 2,
    image: '/images/temp/house_02.jpeg',
    address: 'Seoul, Korea',
    name: 'Hanok',
    price: 220000,
    parking: 1,
  },
  {
    id: 3,
    image: '/images/temp/house_03.jpeg',
    address: 'Seoul, Korea',
    name: 'Hanok',
    price: 220000,
    parking: 0,
  },
];
