import ReservationCard from 'components/pages/myReservations/reservations/ReservationCard';
import PageContainer from 'containers/pc/PageContainer';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import i18n from 'i18n';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReservationDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import styled from 'styled-components';
import { EmptyState } from 'components/commons/EmptyState';
import { useMediaContext } from 'saladsoft-primitive';

type ReservationStatus = 'CURRENT' | 'CANCELED' | 'PAST' | 'UPCOMING';
interface TabItem {
  id: number;
  label: string;
  key: ReservationStatus;
}

const MyReservationPage = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaContext();
  const navigate = useLocaleNavigate();

  const tabs: TabItem[] = [
    { id: 1, label: 'Upcomming', key: 'UPCOMING' },
    { id: 2, label: 'Canceled', key: 'CANCELED' },
    { id: 3, label: 'Past', key: 'PAST' },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [reservations, setReservations] = useState<ReservationDto[]>();

  const onTabChange = (tab: TabItem) => {
    setSelectedTab(tab);
    getReservations(tab.key.toLowerCase() as ReservationStatus);
  };

  const handleReservationClick = (reservationId: number) => {
    navigate(`/my-reservations/${reservationId}`);
  };

  const getReservations = (reservationType: ReservationStatus) => {
    ServiceHelper.myReservationService
      ?.getReservationsByStatus({
        status: reservationType.toUpperCase() as ReservationStatus,
        locale: i18n.language,
      })
      .then((res) => {
        setReservations(typeof res.data === 'undefined' ? [] : res.data);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getReservations('UPCOMING');
  }, []);

  return (
    <PageContainer>
      <div
        className="page-container"
        style={{ marginTop: isMobile ? '24px' : '56px', marginBottom: '200px', padding: '0 24px' }}
      >
        <TabContainer>
          <TabButtonWrapper>
            {tabs.map((tab) => (
              <TabButton
                key={tab.id}
                isSelected={selectedTab.id === tab.id}
                onClick={() => onTabChange(tab)}
                tabIndex={0}
                role="tab"
                aria-selected={selectedTab.id === tab.id}
              >
                {t(`${tab.label}`)}
              </TabButton>
            ))}
          </TabButtonWrapper>
          <TabContentWrapper>
            {reservations && reservations?.length === 0 && (
              <EmptyState message={t(`no-${selectedTab.label}`)} />
            )}
            {reservations?.map((reservation) => (
              <ReservationCard
                key={reservation.id}
                reservation={reservation}
                onClick={() => {
                  handleReservationClick(reservation.id as number);
                }}
              />
            ))}
            {/* <MyReservationInfo /> */}
          </TabContentWrapper>
        </TabContainer>
      </div>
    </PageContainer>
  );
};

const TabContainer = styled.div`
  align-items: start;
  align-self: stretch;
  background: var(--white, #fff);
  display: flex;
  gap: 24px;
  justify-content: start;
  flex-wrap: wrap;
`;

const TabButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  align-self: stretch;
  border-bottom: 1px solid var(--Grayscale-20, #e4e4e4);
  background: var(--white, #fff);
`;

const TabButton = styled.button<{ isSelected: boolean }>`
  min-height: 32px;
  white-space: nowrap;
  font:
    600 24px Pretendard,
    sans-serif;
  color: ${(props) =>
    props.isSelected ? 'var(--Grayscale-90, #1d1d1d)' : 'var(--Grayscale-50, #8e8e8e)'};
  border: none;
  background: none;
  border-bottom: ${(props) =>
    props.isSelected ? '2px solid var(--Grayscale-90, #1d1d1d)' : 'none'};
  cursor: pointer;
  padding: 0 0 8px 0;

  @media ${(props) => props.theme.media.mobile} {
    font-size: 16px;
    letter-spacing: -0.042px;
  }
`;

const TabContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
`;

export default MyReservationPage;
