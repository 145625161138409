import ReservationConfirmation from 'components/pages/reservationComplete/reservations/ReservationConfirmation';
import PageContainer from 'containers/pc/PageContainer';
import React, { useEffect } from 'react';

const ReservationCompletePage = () => {
  return (
    <PageContainer>
      <div className="page-container" style={{ textAlign: 'center', padding: '0 20px' }}>
        <ReservationConfirmation />
      </div>
    </PageContainer>
  );
};

export default ReservationCompletePage;
