import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import i18n from "i18next";
import ko from "./locales/ko.json";
import en from "./locales/en.json";
import ja from "./locales/ja.json";
import cn from "./locales/cn.json";
import moment from "moment";
import "moment/locale/zh-cn"; // 중국어 로케일 불러오기

const resources = {
  ko: { translation: ko },
  en: { translation: en },
  ja: { translation: ja },
  zh: { translation: cn },
};

// moment.js 요일 업데이트 함수
const updateMomentLocale = (lng: string) => {
  if (lng === "zh") {
    moment.updateLocale("zh-cn", {
      weekdays: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
    });
  } else {
    moment.locale(lng);
  }
};

// 초기 moment 설정 적용
updateMomentLocale("zh");

const languageDetector = new LanguageDetector();
const options = {
  order: ["localStorage"],
  lookupLocalStorage: "i18nextLng",
};

languageDetector.init(options);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(backend)
  .init({
    resources,
    detection: options,
    fallbackLng: ["ko"],
    supportedLngs: ["ko", "en", "ja", "zh", "sp"],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

// 🔥 i18n 언어 변경 시 moment 로케일도 업데이트
i18n.on("languageChanged", (lng) => {
  updateMomentLocale(lng);
});

export default i18n;