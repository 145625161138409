import * as React from 'react';
import styled from 'styled-components';
import type { PointHeaderProps } from './types';
import { formatThousandSeparator } from 'libs/helper/utils';
import { useTranslation } from 'react-i18next';

export const PointsHeader: React.FC<PointHeaderProps> = ({ username, points }) => {
  const { t } = useTranslation();

  return (
    <HeaderWrapper>
      <HeaderContent>
        <TitleSection>
          <BrandText>POINT</BrandText>
          {/* <UserText>{username}님의 포인트 내역</UserText> */}
          <UserText>{t('userPointsHistory', { username: username })}</UserText>
        </TitleSection>
        <HeaderIcon
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/42cc02212acf9405cabace6041d6393c60e49ed3cd2bd11b5372ffdc637afcd5?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&"
          alt="포인트 아이콘"
        />
      </HeaderContent>
      <PointsSection>
        <PointsLabel>{t('availablePoints')}</PointsLabel>
        <PointsValue>{formatThousandSeparator(points)}P</PointsValue>
      </PointsSection>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.section`
  border-radius: 12px;
  background: var(--ded-8-d-4, #ded8d4);
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  color: var(--1-f-1-e-1-d, #1f1e1d);
  padding: 24px 20px;
`;

const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  gap: 32px;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const TitleSection = styled.div`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  flex: 1;
`;

const BrandText = styled.h1`
  letter-spacing: -0.05px;
  font:
    700 20px Montserrat,
    sans-serif;
`;

const UserText = styled.p`
  letter-spacing: -0.04px;
  margin-top: 13px;
  font:
    500 15px Pretendard,
    sans-serif;
`;

const HeaderIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  width: 40px;
`;

const PointsSection = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
`;

const PointsLabel = styled.span`
  letter-spacing: -0.03px;
  font:
    400 12px Pretendard,
    sans-serif;
`;

const PointsValue = styled.strong`
  letter-spacing: -0.07px;
  margin-top: 10px;
  font:
    700 30px Montserrat,
    sans-serif;
`;
