import React from 'react';
import styled from 'styled-components';

const BackArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M16 8L7.42 15.8L16 23.6" stroke="#1D1D1D" stroke-width="1.6" strokeLinecap="round" />
  </svg>
);

interface NoticeDetailProps {
  onBack: () => void;
}

// 공지사항 게시글 상세 페이지
const NoticeDetails = ({ onBack }: NoticeDetailProps) => {
  const noticeData = {
    title: '버틀러리 환불 규정 변경',
    date: '2025.04.01',
    content:
      '안녕하세요, butler-lee 입니다.\n더 많은 이용자들이 증가함에 따라 환불규정이 변경되어\n다음과 같이 변경됨을 알려드립니다.\n\n▶ 버틀러리 환불규정 변경 및 시행일  : 2024년 10월 25일\n▶ 버틀러리 환불규정 변경 주요내용\n\n- N일 이내 환불 시, N% 수수료 증가\n\n이전 숙박을 예약하신 분들은 변동없이 진행될 예정이며,\n숙박 예약 사용 시 불편함을 드려서 죄송합니다.\n\n더 나은 서비스를 제공하기 위해 항상 최선을 다하겠습니다.\n\n감사합니다.',
  };

  return (
    <>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/@tabler/icons-webfont@latest/dist/tabler-icons.min.css"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Pretendard:wght@400;600&display=swap"
        rel="stylesheet"
      />
      <NoticeContainer>
        <BackButton onClick={onBack}>
          <BackArrowIcon />
        </BackButton>
        <NoticeHeader>
          <NoticeTitle>{noticeData.title}</NoticeTitle>
          <NoticeDate>{noticeData.date}</NoticeDate>
        </NoticeHeader>
        <NoticeBody>
          {noticeData.content.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </NoticeBody>
      </NoticeContainer>
    </>
  );
};

const NoticeContainer = styled.article`
  width: 100%;
  margin: 0 auto;
  font-family: 'Pretendard', sans-serif;
`;

const NoticeHeader = styled.header`
  padding: 24px 0;
  border-bottom: 1px solid var(--Grayscale-20, #e4e4e4);
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;

  &:hover svg path {
    stroke: #666666;
  }
`;

const NoticeTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: var(--Grayscale-90, #1d1d1d);
  letter-spacing: -0.06px;
  line-height: 1.4;
  margin: 0;
  @media (${(props) => props.theme.media.mobile}) {
    font-size: 19px;
    letter-spacing: -0.047px;
  }
`;

const NoticeDate = styled.time`
  display: block;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: var(--Grayscale-50, #8e8e8e);
  letter-spacing: -0.04px;

  @media (${(props) => props.theme.media.mobile}) {
    font-size: 12px;
    letter-spacing: -0.03px;
  }
`;

const NoticeBody = styled.div`
  padding: 40px 0;
  font-size: 15px;
  line-height: 23px;
  color: var(--Grayscale-90, #1d1d1d);
  letter-spacing: -0.04px;
`;

export default NoticeDetails;
