/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { MembershipPolicyDto, RefundPolicyDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class PolicyService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description Retrieve list of refund policies.
   *
   * @tags PolicyService
   * @name GetRefundPolicies
   * @summary Get Refund Policies
   * @request GET:/v1/policies/refund
   * @secure
   */
  getRefundPolicies = (params: RequestParams = {}) =>
    this.http.request<RefundPolicyDto[], any>({
      path: `/v1/policies/refund`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description Retrieve list of membership policies.
   *
   * @tags PolicyService
   * @name GetMembershipPolicies
   * @summary Get Membership Policies
   * @request GET:/v1/policies/membership
   * @secure
   */
  getMembershipPolicies = (params: RequestParams = {}) =>
    this.http.request<MembershipPolicyDto[], any>({
      path: `/v1/policies/membership`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
}
