import styled from "styled-components";

export const Header = styled.h1`
  color: var(--Grayscale-80, #2d2d2d);
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.06px;
  white-space: pre-wrap;
  margin: 0;

  @media ${(props) => props.theme.media.mobile} {
    font-size: 19px;
    line-height: 150%;
    letter-spacing: -0.047px;
  }
`;
