import useLocaleNavigate from 'hooks/useLocaleNavigate';
import { useUserInfo } from 'providers/UserInfoProvider';
import React from 'react';
import { toast } from 'react-toastify';
import ServiceHelper from 'stores/ServiceHelper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Row } from 'saladsoft-primitive';

interface DeleteAccountModalProps {
  onClose: () => void;
  onConfirm: () => void;
}

interface ButtonProps {
  onClick: () => void;
  children: React.ReactNode;
  variant?: 'primary' | 'secondary';
}

const ModalButton = ({ onClick, children, variant = 'secondary' }: ButtonProps) => (
  <StyledButton onClick={onClick} $variant={variant}>
    {children}
  </StyledButton>
);

// 회원탈퇴 모달
const DeleteAccount = ({ onClose, onConfirm }: DeleteAccountModalProps) => {
  const { t } = useTranslation();
  const { logout } = useUserInfo();
  const navigate = useLocaleNavigate();

  const handleDeleteAccount = async () => {
    try {
      await ServiceHelper?.authService?.withdrawUser();
      logout();

      // 추가
      localStorage.clear();

      onClose();

      setTimeout(() => {
        toast.success(t('deleteAccountSuccess'), {}); // toast 디자인 수정 필요
      }, 1000);

      navigate('/');
    } catch (error) {
      toast.error(t('deleteAccountError'));
      console.error(t('deleteAccountError'), error);
    }
  };

  return (
    <ModalContainer>
      <ModalContent>
        <WarningSection>
          <WarningBox>
            <Row gap={12}>
              <WarningIcon
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/cf69a0c50ca3d4ee1a2798ee9b46305f11746cdcc94a267e155c9febd3624f60?placeholderIfAbsent=true&apiKey=694b79be46254ffd8018f6be6c4bbc2d"
                alt="Warning icon"
              />
              <WarningText>{t('deleteAccountWarning')} </WarningText>
            </Row>
            <AlertMessage>{t('deleteAccountReJoinMessage')}</AlertMessage>
          </WarningBox>
        </WarningSection>
        <ButtonGroup>
          <ModalButton onClick={onClose}>{t('deleteAccountCancel')}</ModalButton>
          <ModalButton onClick={handleDeleteAccount} variant="primary">
            {t('deleteAccount')}
          </ModalButton>
        </ButtonGroup>
      </ModalContent>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ModalContent = styled.div`
  background: var(--Grayscale-0, #fff);
  display: flex;
  width: 640px;
  max-width: 100%;
  flex-direction: column;
  overflow: hidden;
  @media (max-width: 991px) {
    margin-bottom: 10px;
  }
`;

const WarningSection = styled.div`
  background: var(--Grayscale-0, #fff);
  width: 100%;
  padding: 20px 20px 40px;
`;

const WarningBox = styled.div`
  border-radius: 12px;
  background-color: rgba(243, 80, 100, 0.05);
  display: flex;
  flex-direction: column; // temp
  align-items: center;
  /* gap: 12px; */
  padding: 20px 16px;
`;

const WarningIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const WarningText = styled.p`
  font-size: 13px;
  color: var(--Grayscale-70, #555);
  letter-spacing: -0.03px;
  margin: 0;
`;

const AlertMessage = styled.div`
  font-size: 12px;
  color: var(--Primary-400, #f35064);
  letter-spacing: -0.03px;
`;

const ButtonGroup = styled.div`
  background: var(--Grayscale-0, #fff);
  display: flex;
  gap: 24px;
  padding: 12px 16px;
  @media (max-width: 536px) {
    gap: 8px;
  }
`;

const StyledButton = styled.button<{ $variant?: 'primary' | 'secondary' }>`
  border-radius: 8px;
  min-height: 48px;
  font-size: 15px;
  padding: 0 16px;
  flex: 1;
  cursor: pointer;
  border: ${(props) =>
    props.$variant === 'primary' ? 'none' : '1px solid var(--Grayscale-20, #e4e4e4)'};
  background: ${(props) => (props.$variant === 'primary' ? 'var(--4-e-433-c, #4e433c)' : '#fff')};
  color: ${(props) =>
    props.$variant === 'primary' ? 'var(--Grayscale-0, #fff)' : 'var(--Grayscale-70, #555)'};
  font-weight: ${(props) => (props.$variant === 'primary' ? '600' : '500')};
`;

export default DeleteAccount;
