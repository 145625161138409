import moment from 'moment';

import { useRef, useState } from 'react';
import { memoize } from 'lodash';
import { useModelStateContext } from 'saladsoft-primitive';
import { useStoreWrapper } from 'stores/StoreHelper';
import { useParamsHelper } from 'hooks/useParamsHelper';
import ServiceHelper from 'stores/ServiceHelper';
import { CalendarDay } from 'services/data-contracts';

const useDateRagePicker = () => {
  const tempDates = useRef<any[]>([]);
  const { uiStore, listingStore } = useStoreWrapper();
  const { getParams } = useParamsHelper();
  const { guests, startdate, enddate } = getParams();
  const { stateModel, setState } = useModelStateContext<any>();

  const handleClickSerch = () => {
    // uiStore.modal.close();
    // router.push(
    //   `/houses?startdate=${startdate}&enddate=${enddate}&guests=${guests}`,
    // );
  };

  const getCalendarInfo = async (id: number, startdate: Date, duration: number) => {
    const listingId = listingStore?.listing?.id ?? Number(id);
    const dates = getMonthRange(startdate, duration);
    if (dates) {
      if (!Number.isNaN(listingId) && dates[0] !== null && dates[1] !== null) {
        const start = moment(dates[0]).format('YYYY-MM-DD');
        const end = moment(dates[1]).format('YYYY-MM-DD');
        const result = await ServiceHelper.listingService?.getCalendarDaysByListing(listingId, {
          startDate: start,
          endDate: end,
        });

        return result?.data as CalendarDay[];
        // const result = await listingStore.getCalendarInfo(
        //   listingId,
        //   start,
        //   end,
        // );
        // tempDates.current = tempDates.current.concat(result);
        // setState("days", tempDates.current);
      }
    }
  };

  const getMonthRange = (startDate?: Date, duration?: number): string[] | null => {
    if (!startDate) {
      return null;
    }

    // 시작 월의 첫날 구하기
    const startOfMonth = moment(startDate).startOf('month');
    // 종료 월의 말일 구하기 (다다음달의 말일)
    const endOfNextNextMonth = moment(startDate).add(duration, 'months').endOf('month');

    // 날짜를 'YYYY-MM-DD' 형식의 문자열로 포맷팅
    const formattedStartOfMonth = startOfMonth.format('YYYY-MM-DD');
    const formattedEndOfNextNextMonth = endOfNextNextMonth.format('YYYY-MM-DD');

    return [formattedStartOfMonth, formattedEndOfNextNextMonth];
  };

  const checkDateRange = (selectedDate: (Date | null)[]): boolean => {
    // selectedDate 배열의 두 날짜가 모두 정의되어 있는지 확인
    if (selectedDate[0] !== null && selectedDate[1] !== null) {
      const startDate = moment(selectedDate[0]);
      const endDate = moment(selectedDate[1]);

      // 두 날짜 사이의 차이를 계산
      const diffDays = endDate.diff(startDate, 'days');

      // 두 날짜 사이의 차이가 20일 이하인 경우
      if (diffDays <= 20) {
        return false; // 20일을 초과하지 않으면 false 반환
      }
    } else {
      return false;
    }
    return true; // 20일을 초과하면 true 반환
  };

  const shouldDisableDate = memoize((day: Date, startTempDate: Date, minimumStay?: number) => {
    if (!stateModel || !stateModel.days) {
      return false;
    }

    // 시작일이 정의되어 있지 않거나 종료일만 정의된 경우
    // if (startTempDate) {
    //   const checkDay = moment(day);

    //   const startDate = moment(startTempDate);
    //   const twentyDaysAfter = startDate.clone().add(minimumStay, "days");

    //   const t = stateModel.days.findIndex(
    //     (d: CalendarDay) => d.date === checkDay.format("YYYY-MM-DD"),
    //   );

    //   if (t === -1) {
    //     return true;
    //   }
    //   if (checkDay.isSameOrBefore(twentyDaysAfter, "day")) {
    //     return true;
    //   } else if (checkDay.isAfter(twentyDaysAfter, "day")) {
    //     const disableDay = isAnyUnavailableInRange(
    //       startTempDate,
    //       day,
    //       stateModel,
    //     );

    //     if (disableDay === true) {
    //       return true;
    //     }
    //     if (
    //       stateModel.days[t].isAvailable === 1 &&
    //       stateModel.days[t - 1]?.isAvailable === 0
    //     ) {
    //       return true;
    //     }

    //     return stateModel.days[t]?.isAvailable === 0;
    //   }
    // } else {
    //   return getIsAvaliable(day) as boolean;
    // }
  });

  // const isAnyUnavailableInRange = (
  //   startDate: Date,
  //   endDate: Date,
  //   stateModel: { days: CalendarDay[] },
  // ): boolean => {
  //   const start = startDate.getTime();
  //   const end = endDate.getTime();

  //   // Assuming days are sorted by date
  //   const startIndex = binarySearch(stateModel.days, start);
  //   const endIndex = binarySearch(stateModel.days, end);

  //   for (let i = startIndex; i <= endIndex && i < stateModel.days.length; i++) {
  //     const day = stateModel.days[i];
  //     const dayTime = new Date(day?.date ?? "").getTime();
  //     if (dayTime >= start && dayTime <= end && day.isAvailable === 0) {
  //       return true;
  //     }
  //   }

  //   return false;
  // };

  // const binarySearch = (days: CalendarDay[], target: number): number => {
  //   let left = 0;
  //   let right = days.length - 1;

  //   while (left <= right) {
  //     const mid = Math.floor((left + right) / 2);
  //     const midTime = new Date(days[mid].date ?? "").getTime();

  //     if (midTime === target) {
  //       return mid;
  //     } else if (midTime < target) {
  //       left = mid + 1;
  //     } else {
  //       right = mid - 1;
  //     }
  //   }

  //   return left;
  // };

  // const getIsAvaliable = (day: Date) => {
  //   const reserved = stateModel?.days.some((d: CalendarDay) => {
  //     if (!d.date) return false;

  //     const calendarDate = new Date(d.date);
  //     const isSameDate =
  //       calendarDate.getFullYear() === day.getFullYear() &&
  //       calendarDate.getMonth() === day.getMonth() &&
  //       calendarDate.getDate() === day.getDate();

  //     if (isSameDate) {
  //       return d.isAvailable !== 1; // 예약 가능 여부에 따라 반환
  //     }

  //     return false;
  //   });

  //   return reserved;
  // };

  /**
   * @description 일정과 상관없이 선택일로부터 주어진 날짜 이전은 선택금지.
   * @param day
   * @returns
   */
  const shouldDisableMinimum = (beginDate: string, day: Date): boolean => {
    // 시작일이 유효하지 않으면 제한 없음 (false 반환)
    if (!beginDate) {
      return false;
    }

    // 종료일이 유효하지 않으면 제한 없음 (false 반환)
    if (!day) {
      return false;
    }

    const startDate = moment(beginDate);
    const endDate = moment(day);

    // 선택된 날짜가 시작일 이전인 경우 제한 없음 (false 반환)
    if (endDate.isBefore(startDate)) {
      return false;
    }

    // 종료일이 시작일로부터 20일을 초과했는지 확인
    const dayDifference = endDate.diff(startDate, 'days');

    // 20일 이하이면 true 반환 (제한), 20일 초과하면 false 반환 (제한 없음)
    return dayDifference <= 20;
  };

  /**
   * @description DatePicker가 닫힐때 날짜를 설정한다.
   * @param date
   * @returns
   */
  const getCloseDate = (date: [string, string]) => {
    if (date[0] && date[1]) {
      if (moment(date[0]).isSame(date[1], 'day')) {
        return null;
      }

      const temp = {
        startDate: moment(date[0]).format('YYYY-MM-DD'),
        endDate: moment(date[1]).format('YYYY-MM-DD'),
      };

      return temp;
    } else {
      return null;
    }
  };

  return {
    handleClickSerch,
    checkDateRange,
    shouldDisableMinimum,
    shouldDisableDate,
    getCalendarInfo,
    getCloseDate,
  };
};

export { useDateRagePicker };
