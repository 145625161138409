import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

export function LanguageSelector({ onChange }: { onChange: (value: string) => void }) {
  const { t } = useTranslation();
  return (
    <Container>
      <Label htmlFor="language">{t('Language selection')}</Label>
      <SelectContainer>
        <StyledSelect
          id="language"
          aria-label="Select preferred language"
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="KO">KR</option>
          <option value="EN">English</option>
          <option value="JA">日本語</option>
          <option value="ZH">中文</option>
        </StyledSelect>
        <AlertContainer>
          <Icon
            src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/0430d56685ac5bd917780a636aea366379a5a2b159f21224ca4309caab8f18bf?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&"
            alt=""
            role="presentation"
          />
          <AlertText>
            {t('Please select your preferred language for future communications')}
          </AlertText>
        </AlertContainer>
      </SelectContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Label = styled.label`
  font-size: 14px;
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 500;
  letter-spacing: -0.04px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const StyledSelect = styled.select`
  width: 100%;
  min-height: 48px;
  padding: 19px 16px;
  border-radius: 8px;
  background: var(--Grayscale-5, #f8f8f8);
  font-size: 15px;
  border: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L6 6.5L11 1.5' stroke='%23555555' stroke-width='1.5' strokeLinecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 16px center;
  padding-right: 40px;
`;

const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 2px;
`;

const AlertText = styled.span`
  color: var(--sub-color, #1c51b6);
  font-size: 12px;
  letter-spacing: -0.03px;
`;
