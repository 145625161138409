import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { MembershipCardProps } from './types';

export const MembershipCard: React.FC<MembershipCardProps> = ({
  imageSrc,
  level,
  requirement,
  percentage,
}) => {
  const { t } = useTranslation();

  return (
    <CardContainer>
      <CardContent>
        <CardImage loading="lazy" src={imageSrc} alt={`${level} membership level badge`} />
        <CardInfo>
          <LevelText>{level}</LevelText>
          <RequirementText>{requirement}</RequirementText>
        </CardInfo>
      </CardContent>
      <PercentageContainer>
        <PercentageWrapper>
          <PercentageNumber>{percentage}</PercentageNumber>
          <PercentageSymbol>%</PercentageSymbol>
        </PercentageWrapper>
        <PercentageLabel>{t('reward')}</PercentageLabel>
      </PercentageContainer>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: var(--Grayscale-0, #fff);
  display: flex;
  width: 100%;
  gap: 40px;
  overflow: hidden;
  flex-wrap: nowrap;
  padding: 16px;
`;

const CardContent = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: start;
  margin: auto 0;
`;

const CardImage = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 48px;
  border-radius: 8px;
  align-self: stretch;
  margin: auto 0;
`;

const CardInfo = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto 0;
`;

const LevelText = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  text-align: center;
  letter-spacing: -0.04px;
  align-self: start;
  font:
    700 16px Montserrat,
    sans-serif;
`;

const RequirementText = styled.div`
  color: var(--Grayscale-50, #8e8e8e);
  letter-spacing: -0.03px;
  margin-top: 8px;
  font:
    400 13px Pretendard,
    sans-serif;
`;

const PercentageContainer = styled.div`
  align-self: stretch;
  display: flex;
  align-items: end;
  gap: 2px;
  color: var(--Grayscale-90, #1d1d1d);
  white-space: nowrap;
  justify-content: start;
  margin: auto 0;
`;

const PercentageWrapper = styled.div`
  display: flex;
  align-items: baseline;
  gap: 2px;
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  text-align: center;
  justify-content: start;
  line-height: 1;
`;

const PercentageNumber = styled.span`
  letter-spacing: -0.07px;
  font-size: 28px;
  line-height: 1;
  display: flex;
`;

const PercentageSymbol = styled.span`
  letter-spacing: -0.04px;
  font-size: 16px;
  display: flex;
  align-items: baseline;
`;

const PercentageLabel = styled.div`
  letter-spacing: -0.04px;
  display: flex;
  align-items: baseline;
  font:
    400 15px Pretendard,
    sans-serif;
`;
