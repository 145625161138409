import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { styled as muiStyled } from '@mui/material/styles';
import { Row, useModelStateContext } from 'saladsoft-primitive';
import { Slider } from '@mui/material';
import ChipButton from 'components/input/ChipButton';
import { FilterType } from 'types/CommonTypes';
import BTButton from 'components/input/BTButton';
import { useStoreWrapper } from 'stores/StoreHelper';
import PriceRangeChart from 'components/pages/listingPage/PriceRangeChart';
import { observer } from 'mobx-react';
import CaptionText from './CaptionText';
import Subtitle from './Subtitle';
import BranchFilter from './BranchFilter';
import TagFilter from './TagFilter';
import { BarLoader } from 'react-spinners';
import BottomSheetHeader from './BottomSheetHeader';
import { useMediaContext } from 'saladsoft-primitive';
const FilterBox = observer(
  ({
    onApply,
    filterOptions,
  }: {
    onApply: (filterOptions?: FilterType) => void;
    filterOptions?: FilterType;
  }) => {
    const { t } = useTranslation();
    const { filterStore } = useStoreWrapper();
    const { isMobile } = useMediaContext();
    const { setState, stateModel, setMultipleState, unmountModel } =
      useModelStateContext<FilterType>();

    const { uiStore } = useStoreWrapper();

    const [priceValue, setPriceValue] = React.useState<number[]>([0, 0]);
    const [maxPrice, setMaxPrice] = React.useState(0);
    const [minPrice, setMinPrice] = React.useState(0);
    const handleClickCancel = () => {
      uiStore?.universalModal.close();
    };

    const formatPrice = (value: number) => {
      return `${value.toLocaleString()}원`;
    };

    const getSection = (title: string, subtitle: string) => {
      return (
        <Row gap={10} justifyContent="flex-start" margin="24px 0">

          <Subtitle type="C">{title}</Subtitle>
          <CaptionText type="C" style={{ color: '#808080' }}>
            {subtitle}
          </CaptionText>
        </Row>
      );
    };

    useEffect(() => {
      if (filterStore.lowestPrices?.length > 0) {
        const prices = filterStore.lowestPrices
          .map((item) => item.price)
          .filter((price): price is number => price !== undefined);

        const minPrice = prices.length > 0 ? Math.min(...prices) : 0;
        const maxPrice = prices.length > 0 ? Math.max(...prices) : 0;
        setMaxPrice(maxPrice);
        setMinPrice(minPrice);
        const fromPrice = stateModel?.priceOptions?.from || minPrice;
        const toPrice = stateModel?.priceOptions?.to || maxPrice;

        setPriceValue([fromPrice, toPrice]);

        setState('priceOptions', {
          ...stateModel?.priceOptions,
          from: fromPrice,
          to: toPrice,
        });
      }
      return () => {
        unmountModel();
      };
    }, []);

    useEffect(() => {
      if (filterOptions) {
        setMultipleState(filterOptions);
      }
    }, [filterOptions]);

    return (
      <div>
        {isMobile && (
          <BottomSheetHeader onClose={handleClickCancel} title={t('Filter')} />
        )}
        <NotificationContainer>
          <NotificationText>{t('filterHeader')}</NotificationText>
        </NotificationContainer>
        <div style={{ padding: '0 20px' }}>
          <div>{getSection(t('Search Branch'), t('Allow all branches'))}</div>
          <BranchFilter
            value={stateModel?.regions}
            onChange={(selections) => {
              setState(
                'regions',
                selections.map((selection) => selection),
              );
            }}
          />
          <div>
            <hr style={{ border: '1px solid #f0f0f0', marginTop: 20 }} />
            {getSection(t('Price Range'), '')}
            <div style={{ padding: '0 10px' }}>
              <Row justifyContent="center" gap={10} padding="0" position="relative" top={-20}>
                <div style={{ color: '#1D1D1D', fontSize: 14, fontWeight: 600 }}>{priceValue[0].toLocaleString()}{t('won')}</div>
                <div>~</div>
                <div style={{ color: '#1D1D1D', fontSize: 14, fontWeight: 600 }}>{priceValue[1].toLocaleString()}{t('won')}</div>
              </Row>
              <PriceRangeChart lowestPrices={filterStore.lowestPrices} />
              <div style={{ padding: '0px', position: 'relative', top: isMobile ? -23 : -16 }}>
                <SliderContainer
                  min={
                    filterStore.lowestPrices.length > 0
                      ? Math.min(...filterStore.lowestPrices.map((item) => item.price).filter((price): price is number => price !== undefined))
                      : 0
                  }
                  sx={{
                    '& .MuiSlider-track': {
                      height: 2
                    },
                  }}
                  max={maxPrice}
                  step={10000}
                  getAriaLabel={() => 'Price range'}
                  value={priceValue}
                  onChange={(event, newValue) => {
                    const newValueArray = newValue as number[];
                    setPriceValue(newValueArray);

                    const currentFrom = stateModel?.priceOptions?.from;
                    const currentTo = stateModel?.priceOptions?.to;

                    if (currentFrom !== newValueArray[0] || currentTo !== newValueArray[1]) {
                      setState('priceOptions', {
                        ...stateModel?.priceOptions,
                        from: newValueArray[0],
                        to: newValueArray[1],
                      });
                    }
                  }}
                  valueLabelDisplay="auto"
                  valueLabelFormat={formatPrice}
                  getAriaValueText={formatPrice}
                />
              </div>
            </div>

            <Row justifyContent="space-between" gap={10} padding="0" position="relative" top={-20}>
              <div>{minPrice.toLocaleString()}{t('won')}</div>
              <div>{maxPrice.toLocaleString()}{t('won')}</div>
            </Row>
          </div>
          <hr style={{ border: '1px solid #f0f0f0', marginTop: 20 }} />
          <div>
            {getSection(t('hanok recommendation'), t('Allow all branches'))}
            <Row gap={10} justifyContent="flex-start">
              <ChipButton
                onChange={(id, selected) => {
                  setState('recommendations', {
                    ...stateModel?.recommendations,
                    recommended: selected,
                  });
                }}
                id="recommended"
                label={t('Recommended')}
                selected={stateModel?.recommendations?.recommended || false}
              />
              <ChipButton
                onChange={(id, selected) => {
                  setState('recommendations', {
                    ...stateModel?.recommendations,
                    brandNew: selected,
                  });
                }}
                id="new"
                label={t('Newly built hanok')}
                selected={stateModel?.recommendations?.brandNew || false}
              />
            </Row>
          </div>
          <hr style={{ border: '1px solid #f0f0f0', marginTop: 25 }} />

          <div>
            {getSection(t('Filter Options'), '')}
            <TagFilter
              value={stateModel?.options || []}
              onChange={(selections) => {
                setState(
                  'options',
                  selections.map((selection) => selection),
                );
              }}
            />
            {/* 
          <ToggleButtonGroup
            color="primary"
            value={options}
            exclusive
            onChange={(event, value) => setOptions(value)}
            aria-label="Platform"
          >
            <ToggleButton value="none">{t('None')}</ToggleButton>
            <ToggleButton value="bath">{t('Bath Tube')}</ToggleButton>
            <ToggleButton value="foot">{t('Foot bath')}</ToggleButton>
            <ToggleButton value="yard">{t('Yard')}</ToggleButton>
          </ToggleButtonGroup> */}
          </div>
          {/* <div>
          <CheckboxContainer onClick={handleClick} role="checkbox" tabIndex={0}>
            <CheckboxLabel>{t('todays discount')}</CheckboxLabel>
            <CheckboxIndicator checked />
          </CheckboxContainer>
        </div> */}
        </div>
        <div style={{ margin: '16px 0', marginTop: 30 }}>
          <hr style={{ border: '1px solid #f0f0f0' }} />
        </div>
        <Row justifyContent="space-between" gap={10} padding="10px 20px" paddingBottom={20} >
          <BTButton onClick={handleClickCancel} type="light" style={{ color: '#555' }}>
            {t('Cancel')}
          </BTButton>
          <div style={{ flex: 1 }}>
            <BTButton
              style={{ width: '100%' }}
              onClick={() => {
                onApply(stateModel);
                uiStore?.universalModal.close();
              }}
            >
              {t('Apply')}
            </BTButton>
          </div>
        </Row>
      </div>
    );
  },
);

const NotificationContainer = styled.div`
  justify-content: center;
  align-items: center;
  background: var(--Grayscale-5, #f8f8f8);
  display: flex;
  min-height: 40px;
  width: 100%;
  padding: 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const NotificationText = styled.div`
  align-self: stretch;
  min-width: 240px;
  gap: 8px;
  margin: auto 0;
  color: var(--Grayscale-60, #717171);
  letter-spacing: -0.03px;
  font:
    400 13px Pretendard,
    sans-serif;
`;

const CheckboxContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: var(--Grayscale-5, #f8f8f8);
  display: flex;
  margin-top: 20px;
  width: 100%;
  font-size: 15px;
  color: var(--Grayscale-70, #555);
  font-weight: 500;
  padding: 16px;
  cursor: pointer;

  &:focus {
    outline: 2px solid var(--Grayscale-70, #555);
    outline-offset: 2px;
  }

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const CheckboxLabel = styled.div`
  align-self: stretch;
  min-width: 240px;
  min-height: 11px;
  flex: 1;
  margin: auto 0;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

interface CheckboxIndicatorProps {
  checked: boolean;
}

const CheckboxIndicator = styled.div<CheckboxIndicatorProps>`
  border-radius: 100px;
  border: 1px solid var(--Grayscale-50, #8e8e8e);
  background: var(--Grayscale-0, #fff);
  align-self: stretch;
  display: flex;
  width: 16px;
  height: 16px;
  gap: 10px;
  margin: auto 0;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    display: ${(props) => (props.checked ? 'block' : 'none')};
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--Grayscale-50, #8e8e8e);
  }
`;

const SliderContainer = muiStyled(Slider)`
  color: #4e433c;
  & .MuiSlider-thumb {
    height: 24px;
    width: 24px;
    background-color: #fff;
    border: 2px solid currentColor;
    &:focus,
    &:hover,
    &.Mui-active,
    &.Mui-focusVisible {
      box-shadow: inherit;
    }
    &:before {
      display: none;
    }
  }
`;

const SliderContainere = styled(Slider)({
  color: '#4e433c',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&::before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#52af77',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&::before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

export default FilterBox;
