import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Checkbox } from '@mui/material';
import { useStoreWrapper } from 'stores/StoreHelper';
import TermModal from 'components/commons/termAndConditions/TermModal';
import { useMediaContext } from 'saladsoft-primitive';

function AgreementForm({ onCheck }: { onCheck: (checked: boolean) => void }) {
  const { t } = useTranslation();
  const { uiStore } = useStoreWrapper();
  const { isMobile } = useMediaContext();

  // 체크박스 상태 관리
  const [checkboxes, setCheckboxes] = React.useState({
    all: false,
    privacy: false,
    terms: false,
  });

  // 전체 동의 체크박스 핸들러
  const handleAllCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setCheckboxes({
      all: checked,
      privacy: checked,
      terms: checked,
    });
    onCheck(checked);
  };

  const handleShowPrivacyPolicy = (type: 'PRIVACY_POLICY' | 'FACILITY_USAGE_POLICY') => {
    const title = {
      PRIVACY_POLICY: t('Privacy Policy'),
      FACILITY_USAGE_POLICY: t('Facility Usage Policy'),
    };

    if (isMobile) {
      uiStore?.universalModal.show({
        title: title[type],
        children: <TermModal type={type} />,
        modalOption: {
          style: { width: '100%', height: '100%', maxHeight: '80vh' },
        },
        bottomSheetOptions: {
          topPosition: 50,
        },
      });
    } else {
      uiStore?.modal.show({
        title: title[type],
        children: <TermModal type={type} />,
        style: { width: 800, height: '100%', maxHeight: '80vh' },
      });
    }
  };

  // 개별 체크박스 핸들러
  const handleSingleCheck =
    (name: 'privacy' | 'terms') => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      const newCheckboxes = {
        ...checkboxes,
        [name]: checked,
      };

      // 모든 하위 체크박스가 체크되었는지 확인
      const allChecked = newCheckboxes.privacy && newCheckboxes.terms;

      setCheckboxes({
        ...newCheckboxes,
        all: allChecked,
      });

      onCheck(allChecked);
    };

  return (
    <Container>
      <AgreementWrapper>
        <AllAgreementBox>
          <Checkbox
            style={{ paddingRight: '8px' }}
            checked={checkboxes.all}
            onChange={handleAllCheck}
            checkedIcon={
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{
                  background: '#4E433C',
                  borderRadius: '2px',
                }}
              >
                <path
                  d="M4.80078 7.99883L7.70604 10.79883L11.60078 4.79883"
                  stroke="white"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                />
              </svg>
            }
            sx={{
              padding: 0,
              color: '#4e433c',
              '& .MuiSvgIcon-root': {
                width: '16px',
                height: '16px',
                border: '1px solid #8e8e8e',
                borderRadius: '2px',
                background: '#fff',
                '& path': {
                  display: 'none',
                },
              },
            }}
          />
          <TextContainer>
            <Title>{t('agree to all terms')}</Title>
            <Description>{t('agree to all terms description')}</Description>
          </TextContainer>
        </AllAgreementBox>
        <RequiredAgreements>
          <AgreementItem>
            <CheckboxLabel>
              <Checkbox
                checkedIcon={
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      background: '#4E433C',
                      borderRadius: '2px',
                    }}
                  >
                    <path
                      d="M4.80078 7.99883L7.70604 10.79883L11.60078 4.79883"
                      stroke="white"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                    />
                  </svg>
                }
                sx={{
                  padding: 0,
                  color: '#4e433c',
                  '& .MuiSvgIcon-root': {
                    width: '16px',
                    height: '16px',
                    border: '1px solid #8e8e8e',
                    borderRadius: '2px',
                    background: '#fff',
                    '& path': {
                      display: 'none',
                    },
                  },
                }}
                style={{ padding: '0px 8px' }}
                checked={checkboxes.privacy}
                onChange={handleSingleCheck('privacy')}
              />
              <Label>{t('required privacy policy')}</Label>
            </CheckboxLabel>
            <ViewButton
              onClick={() => {
                handleShowPrivacyPolicy('PRIVACY_POLICY');
              }}
            >
              {t('view details')}
            </ViewButton>
          </AgreementItem>
          <AgreementItem>
            <CheckboxLabel>
              <Checkbox
                checkedIcon={
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{
                      background: '#4E433C',
                      borderRadius: '2px',
                    }}
                  >
                    <path
                      d="M4.80078 7.99883L7.70604 10.79883L11.60078 4.79883"
                      stroke="white"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                    />
                  </svg>
                }
                sx={{
                  padding: 0,
                  color: '#4e433c',
                  '& .MuiSvgIcon-root': {
                    width: '16px',
                    height: '16px',
                    border: '1px solid #8e8e8e',
                    borderRadius: '2px',
                    background: '#fff',
                    '& path': {
                      display: 'none',
                    },
                  },
                }}
                style={{ padding: '0px 8px' }}
                checked={checkboxes.terms}
                onChange={handleSingleCheck('terms')}
              />
              <Label>{t('required terms of use')}</Label>
            </CheckboxLabel>
            <ViewButton
              onClick={() => {
                handleShowPrivacyPolicy('FACILITY_USAGE_POLICY');
              }}
            >
              {t('view details')}
            </ViewButton>
          </AgreementItem>
        </RequiredAgreements>
      </AgreementWrapper>
      <div style={{ marginTop: '16px' }} />
    </Container>
  );
}

export default AgreementForm;

const Container = styled.div`
  display: flex;
  max-width: 352px;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  @media ${(props) => props.theme.media.mobile} {
    max-width: 100%;
  }
`;

const AgreementWrapper = styled.div`
  border-radius: 0px 0px 0px 0px;
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const AllAgreementBox = styled.div`
  align-items: center;
  border-radius: 8px;
  background: var(--Grayscale-5, #f8f8f8);
  background-color: var(--Grayscale-5, #f8f8f8);
  display: flex;
  justify-content: start;
  padding: 16px;
`;

const TextContainer = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
`;

const Title = styled.div`
  color: var(--Grayscale-70, #555);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.04px;
`;

const Description = styled.div`
  color: var(--Grayscale-50, #8e8e8e);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.03px;
  margin-top: 2px;
`;

const RequiredAgreements = styled.div`
  display: flex;
  margin: 16px 0;
  width: 100%;
  flex-direction: column;
  font-weight: 400;
  justify-content: start;
  padding: 0;
  gap: 10px;
`;

const AgreementItem = styled.div`
  display: flex;
  min-height: 16px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
`;

const CheckboxLabel = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--Grayscale-90, #1d1d1d);
  letter-spacing: -0.04px;
  justify-content: start;
  margin: auto 0;
`;

const Label = styled.div`
  align-self: stretch;
  margin: auto 0;
`;

const ViewButton = styled.div`
  color: var(--sub-color, var(--Color, #1c51b6));
  font-size: 13px;
  letter-spacing: -0.03px;
  margin-right: 16px;
  cursor: pointer;
`;

const SubmitButton = styled.div`
  align-self: stretch;
  border-radius: 8px;
  background: var(--gray-gray-30, #d8d8d8);
  background-color: var(--gray-gray-30, #d8d8d8);
  margin-top: 48px;
  min-height: 48px;
  width: 100%;
  gap: 4px;
  overflow: hidden;
  font-size: 15px;
  color: var(--Grayscale-0, #fff);
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  letter-spacing: -0.04px;
  padding: 0 16px;
`;
