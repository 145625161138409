import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { InfoRow } from './InfoRow';
import { companyInfo, socialIcons } from './data';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getRouteConfig } from 'libs/helper/utils';
import mainRoutes from 'routes/mainRoutes';
import { useStoreWrapper } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { BusinessDto } from 'services/data-contracts';

export const Footer = observer(() => {
  const { t } = useTranslation();
  const { listingId } = useParams();
  const { useBusinessInfo } = getRouteConfig(mainRoutes);
  const { listingStore } = useStoreWrapper();

  const [businessInfo, setBusinessInfo] = useState<BusinessDto>();

  useEffect(() => {
    if (listingStore?.listing?.id && useBusinessInfo) {
      setBusinessInfo(listingStore?.listing?.business);
    }
  }, [listingStore?.listing?.id]);

  return (
    <FooterContainer role="contentinfo" className="footer">
      <ContentWrapper>
        <LogoSection>
          <Logo
            src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/91ff833e9977a995d6b95006aded2d500e749b1f1d5d788d1e375c4ce4b54ffc?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&"
            alt="Company logo"
            loading="lazy"
          />
        </LogoSection>

        <InfoSection>
          <CompanyDetails>
            <dl>
              {useBusinessInfo && businessInfo ? (
                <>
                  <InfoRow item={{ label: 'companyNameTitle', value: businessInfo.name ?? '' }} />
                  <InfoRow item={{ label: 'representativeTitle', value: businessInfo.ownerName ?? '' }} />
                  <InfoRow item={{ label: 'businessRegistrationNumberTitle', value: businessInfo.registrationNumber ?? '' }} />
                  <InfoRow item={{ label: 'telecomSalesRegistrationNumberTitle', value: businessInfo.commerceRegistrationNumber ?? '' }} />
                  <InfoRow item={{ label: 'addressTitle', value: businessInfo.address ?? '' }} />
                  {businessInfo.phoneNumber && (
                    <InfoRow item={{ label: 'phoneNumberTitle', value: businessInfo.phoneNumber ?? '' }} />
                  )}
                </>
              ) : (
                companyInfo.map((item, index) => (
                  <InfoRow key={index} item={item} />
                ))
              )}
            </dl>
          </CompanyDetails>

          <Disclaimer>{t('footerDisclaimer')}</Disclaimer>
        </InfoSection>

        <Divider />

        <SocialSection>
          {socialIcons.map((icon, index) => (
            <SocialIcon
              onClick={() => {
                window.open(icon.linkTo, '_blank');
              }}
              key={index}
              src={icon.src}
              alt={icon.alt}
              loading="lazy"
            />
          ))}
        </SocialSection>
        <Divider />
        <Copyright>© 2025 Proudlee Inc. ALL RIGHTS RESERVED.</Copyright>
      </ContentWrapper>
    </FooterContainer>
  );
});

const FooterContainer = styled.footer`
  background: var(--4-e-433-c, #4e433c);
  padding: 32px 0 20px 0;
  @media (max-width: 991px) {
    padding: 32px 20px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  max-width: 1200px;
  margin: 0 auto;
  @media ${(props) => props.theme.media.mobile} {
    padding: 0;
    padding-bottom: 100px;
  }
`;

const LogoSection = styled.div`
  display: flex;
  min-height: 32px;
  align-items: center;
`;

const Logo = styled.img`
  width: 154px;
  height: auto;
`;

const InfoSection = styled.div`
  margin: 24px 0;
  font-family: Pretendard, sans-serif;
  letter-spacing: -0.03px;
`;

const CompanyDetails = styled.div`
  font-size: 13px;
`;

const Disclaimer = styled.p`
  color: var(--92897-b, #92897b);
  font-size: 12px;
  line-height: 18px;
  margin-top: 32px;
`;

const Divider = styled.hr`
  position: relative;
  width: 100%;
  border: none;
  border-top: 1px solid var(--Grayscale-0, #fff);
  margin: 16px 0;
  opacity: 0.1;

  @media ${(props) => props.theme.media.mobile} {
    width: 100vw;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const SocialSection = styled.div`
  display: flex;
  gap: 16px;
  padding: 0 20px;

  @media ${(props) => props.theme.media.mobile} {
    padding: 0;
  }
`;

const SocialIcon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 133px;
  cursor: pointer;
`;

const Copyright = styled.p`
  color: var(--92897-b, #92897b);
  font-size: 12px;
  font-family: Pretendard, sans-serif;
  letter-spacing: -0.03px;
  margin-top: 7px;
`;
