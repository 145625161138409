import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useStoreWrapper } from 'stores/StoreHelper';
import { GeolocationType } from 'types/CommonTypes';
import styled from 'styled-components';

declare global {
  interface Window {
    naver: any;
  }
}
const { naver } = window;

const NaverMap = observer(
  ({
    geolocations,
    title,
    mapId,
    style,
    mapStyle,
  }: {
    geolocations: GeolocationType[];
    title: string;
    mapId: string;
    style?: React.CSSProperties;
    mapStyle?: React.CSSProperties;
  }) => {
    const { filterStore } = useStoreWrapper();

    useEffect(() => {
      const initMaps = () => {
        if (!window.naver || !window.naver.maps) {
          return;
        }

        const mapElement = document.getElementById(mapId);
        if (!mapElement) {
          return;
        }

        const centerLat =
          geolocations.reduce((sum, loc) => sum + loc.latitude, 0) / geolocations.length;
        const centerLng =
          geolocations.reduce((sum, loc) => sum + loc.longitude, 0) / geolocations.length;

        const price = filterStore.lowestPrices?.find(
          (price) => price.listingId === geolocations[0].listingId,
        );

        const map = new window.naver.maps.Map(mapId, {
          center: new naver.maps.LatLng(centerLat, centerLng),
          zoom: 15,
          scrollwheel: false,
          scaleControl: false,
          mapTypeControl: false,
          zoomControl: false,
          fullscreenControl: false,
        });

        geolocations.forEach((location, index) => {
          const marker = new naver.maps.Marker({
            position: new naver.maps.LatLng(location.latitude, location.longitude),
            map,
          });

          const mapName = location.listingName ? location.listingName : title;
          const contentString = [
            '<div class="map-outer">',
            // '<span class="map-icon">',
            // '<img src="/images/small_logo.png" class="map-image" />',
            // '</span>',
            '<span class="map-inner">',
            `${mapName}`,
            '</span>',
            '<div class="map-price">',
            `${price?.price || ''}`,
            '</div>',
            '</div>',
          ].join('');

          const infowindow = new naver.maps.InfoWindow({
            content: contentString,
            borderWidth: 0,
            backgroundColor: 'transparent',
          });
          infowindow.open(map, marker);
        });
      };

      if (geolocations.length > 0 && title) {
        initMaps();
      }
    }, [geolocations, title, mapId, filterStore.lowestPrices]);

    return (
      <MapContainer style={style}>
        <div id={mapId} style={{ width: '100%', aspectRatio: '16/9', ...mapStyle }} />
      </MapContainer>
    );
  },
);

const MapContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;
`;

export default NaverMap;
