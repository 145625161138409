import styled from 'styled-components';
import * as React from 'react';
import { TextFieldProps } from './types';

export const TextField: React.FC<TextFieldProps> = ({
  label,
  defaultValue,
  disabled = false,
  hasAlert = false,
  alertMessage,
  id: externalId,
  hideLabel = false,
}) => {
  const internalId = Math.random().toString(36).substr(2, 9);
  const inputId = externalId || internalId;

  return (
    <TextFieldWrapper>
      <Label htmlFor={inputId} hidden={hideLabel}>
        {label}
      </Label>
      <InputContainer>
        <Input
          id={inputId}
          type="text"
          defaultValue={defaultValue}
          disabled={disabled}
          aria-disabled={disabled}
          readOnly
        />
        {hasAlert && alertMessage && (
          <AlertMessage role="alert">
            <AlertIcon
              src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/cbcdb696634287552daa362ff522b6ef443c45b9fb05d1dde8261d50272140a0?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&"
              alt=""
              aria-hidden="true"
            />
            <AlertText>{alertMessage}</AlertText>
          </AlertMessage>
        )}
      </InputContainer>
    </TextFieldWrapper>
  );
};

const TextFieldWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 32px;
`;

const Label = styled.label`
  font-size: 13px;
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 500;
  letter-spacing: -0.03px;
  margin-bottom: 6px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Input = styled.input`
  border-radius: 8px;
  /* border: 1px solid var(--gray-gray-30, #d8d8d8); */
  border: none;
  background: var(--Grayscale-20, #e4e4e4);
  padding: 19px 16px;
  font-size: 15px;
  color: var(--Grayscale-50, #8e8e8e);
  font-weight: 400;
  letter-spacing: -0.04px;
  width: 100%;
  min-height: 48px;

  &:disabled {
    /* background: var(--Grayscale-5, #f8f8f8); */
    border: 1px solid var(--gray-gray-30, #d8d8d8);
    background: var(--Grayscale-20, #e4e4e4);
  }
`;

const AlertMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 6px;
`;

const AlertIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const AlertText = styled.span`
  font-size: 12px;
  color: var(--sub-color, var(--Color, #1c51b6));
  letter-spacing: -0.03px;
`;
