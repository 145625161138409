import styled from 'styled-components';
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import koLocale from 'date-fns/locale/ko';
import enLocale from 'date-fns/locale/en-US';
import moment from 'moment';
import { Popover } from '@mui/material';
import React, { CSSProperties, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, useMediaContext } from 'saladsoft-primitive';
import { useDateRagePicker } from 'hooks/useDateRagePicker';
import BodyText from '../BodyText';
import BTButton from 'components/input/BTButton';
import CaptionText from '../CaptionText';
import Subtitle from '../Subtitle';
import { ConvertStringToShortDate, getUrlParam } from 'libs/helper/utils';
import { DateRangePicker } from './DateRangePicker';
import ClearAllButton from './ClearAllButton';
import { useStoreWrapper } from 'stores/StoreHelper';
import { useParamsHelper } from 'hooks/useParamsHelper';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import { useLocation, useParams } from 'react-router-dom';
import { CountInner } from '../CountInner';

/**
 * @description PC와 모바일 모두를 위한 DateRangePicker Wrapper
 * @param param0 
 * @returns 
 */
export const PCDateRangePicker = ({
  type = 'primary',
  dateText,
  isListingPicker = false,
  useCalendarIcon = true,
  onChange,
  onChangeMonth,
  onClickMobileCalendar,
  style,
}: {
  type?: 'primary' | 'secondary';
  dateText: ReactElement;
  // 상세일때
  isListingPicker?: boolean;

  useCalendarIcon?: boolean;
  onChange?: (date: any, guests: number) => void;
  onChangeMonth?: (date: Date) => void;
  onClickMobileCalendar?: () => void;
  style?: CSSProperties;
}) => {
  const navigate = useLocaleNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { calendarStore } = useStoreWrapper();
  const { getCloseDate } = useDateRagePicker();
  const { listingId } = useParams();
  const { t } = useTranslation();
  const { updateParams } = useParamsHelper();
  const { isMobile } = useMediaContext();

  const [selectedDate, setSelectedDate] = useState<any>([null, null]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [nights, setNights] = useState<number>(0);
  const [fromDate, setFromDate] = useState<string>('날짜 선택');
  const [toDate, setToDate] = useState<string>('날짜 선택');
  const [guests, setGuests] = useState<number>(2);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);

    const temp = getCloseDate(selectedDate);
    if (temp === null) {
      setSelectedDate([null, null]);
    } else {
      onChange?.(temp, guests);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    const startTemp = getUrlParam('startdate');
    const endTemp = getUrlParam('enddate');
    const guestsTemp = getUrlParam('guests');
    if (startTemp && endTemp) {
      setFromDate(startTemp);
      setToDate(endTemp);
      setSelectedDate([startTemp, endTemp]);
    }
    if (guestsTemp) {
      setGuests(Number(guestsTemp));
    }
  }, [location.search]);

  useEffect(() => {
    if (selectedDate[0] && selectedDate[1]) {
      const start = moment(selectedDate[0]);
      const end = moment(selectedDate[1]);
      const nightCount = end.diff(start, 'days');
      setNights(nightCount);
      setFromDate(ConvertStringToShortDate(start.toISOString()));
      setToDate(ConvertStringToShortDate(end.toISOString()));
    } else {
      setNights(0);
      setFromDate(t('Select date'));
      setToDate(t('Select date'));
    }
  }, [selectedDate]);

  useEffect(() => {
    if (calendarStore.bookInfo) {
      setSelectedDate([calendarStore.bookInfo.startDate, calendarStore.bookInfo.endDate]);
    }
  }, [calendarStore.bookInfo]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      document.querySelectorAll('.MuiDayPicker-weekDayLabel').forEach((el) => {
        const dayText = el.textContent?.trim();
        switch (dayText) {
          case '월':
            el.textContent = 'Mon';
            break;
          case '화':
            el.textContent = 'Tue';
            break;
          case '수':
            el.textContent = 'Wed';
            break;
          case '목':
            el.textContent = 'Thu';
            break;
          case '금':
            el.textContent = 'Fri';
            break;
          case '토':
            el.textContent = 'Sat';
            break;
          case '일':
            el.textContent = 'Sun';
            break;
        }
      });
    });

    const targetNode = document.querySelector('.MuiDayPicker-header');
    if (targetNode) {
      observer.observe(targetNode, { childList: true, subtree: true });
    }

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <Row
        {...style}
        gap={10}
        justifyContent="space-evenly"
        alignItems="center"
        width="100%"
        onClick={(e: any) => {
          if (isMobile) {
            onClickMobileCalendar?.();
          } else {
            handleClick(e);
          }
        }}
      >
        <DateBoxContainer
          selected={anchorEl ? true : false}
          style={{ width: '100%', alignItems: 'center', cursor: 'pointer' }}
        >
          <Row gap={20} alignItems="center" height={80}>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <CaptionText type="B">CHECK IN</CaptionText>
              <Subtitle type="B" style={{ color: selectedDate[0] ? '#4E433C' : '#C6C6C6' }}>
                {fromDate}
              </Subtitle>
            </div>
            <NightContainer>
              <BodyText type="B">
                {nights === 0 ? `N${t('Nights')}` : nights + t('Nights')}
              </BodyText>
            </NightContainer>
            <div style={{ width: '100%', textAlign: 'center' }}>
              <CaptionText type="B">CHECK OUT</CaptionText>
              <Subtitle type="B" style={{ color: selectedDate[1] ? '#4E433C' : '#C6C6C6' }}>
                {toDate}
              </Subtitle>
            </div>
          </Row>
        </DateBoxContainer>
      </Row>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DateContainer>
          <DateInnerContainer>
            <DateRangePicker value={selectedDate} onChange={setSelectedDate} />
            <hr
              style={{
                borderTop: '1px solid #F0F0F0',
                margin: '0 -20px',
                width: 'calc(100% + 40px)',
              }}
            />

            <Row justifyContent="space-between">
              <>
                <CountInnerWrapper
                  count={guests}
                  useConfirm={false}
                  maxCount={12}
                  onChange={(guests: number) => {
                    setGuests(guests);
                    onChange?.(selectedDate, guests);
                  }}
                />
                {selectedDate[0] && selectedDate[1] ? (
                  <></>
                  // <ClearAllButton
                  //   onClick={() => {
                  //     searchParams.delete('startdate');
                  //     searchParams.delete('enddate');
                  //     const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
                  //     window.history.pushState({}, '', newUrl);

                  //     setSelectedDate([null, null]);
                  //   }}
                  // >
                  //   {t('Clear all')}
                  // </ClearAllButton>
                ) : (
                  <div style={{ height: 25 }}></div>
                )}
              </>
              <BTButton
                style={{ marginTop: '12px', padding: '0 48px' }}
                onClick={() => {
                  updateParams({ startdate: '', enddate: '' });

                  // setSelectedDate([null, null]);
                  const formattedStartDate = selectedDate[0]
                    ? moment(selectedDate[0]).format('YYYY-MM-DD')
                    : '';
                  const formattedEndDate = selectedDate[1]
                    ? moment(selectedDate[1]).format('YYYY-MM-DD')
                    : '';

                  if (listingId) {
                    navigate(
                      `/listing/${listingId}?startdate=${formattedStartDate}&enddate=${formattedEndDate}&guests=${guests}`,
                    );
                  } else {
                    navigate(
                      `/search?startdate=${formattedStartDate}&enddate=${formattedEndDate}&guests=${guests}`,
                    );
                  }
                  setAnchorEl(null);
                  calendarStore.setCalendarShow(false);
                }}
              >
                {listingId ? t('Apply') : t('Search')}
              </BTButton>
            </Row>
          </DateInnerContainer>
        </DateContainer>
      </Popover >
    </>
  );
};

const DateContainer = styled.div.attrs({
  className: 'MuiPaper-root',
})`
  &.css-3bmhjh-MuiPaper-root-MuiPopover-paper {
    @media ${(props) => props.theme.media.mobile} {
    }
  }
`;

const DateBoxContainer = styled.div<{ selected?: boolean }>`
  width: 100%;
  justify-content: flex-start;
  border: ${(props) => (props.selected ? '1px solid #1D1D1D' : 'none')};
  border-radius: 10px;
`;

const DateInnerContainer = styled.div`
  margin: 20px;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 0px 22.5px 0px rgba(144, 155, 169, 0.25) !important;
  border-radius: 20px;
`;

const NightContainer = styled.div`
  display: flex;
  aspect-ratio: 1/1;
  width: 150px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid var(--Grayscale-20, #e4e4e4);
  background: var(--Grayscale-0, #fff);
`;

const CountInnerWrapper = styled(CountInner)`
  box-shadow: none !important;
  border-bottom: none !important;
  .count-inner-divider {
    display: none;
  }
`;
