"use client";
import * as React from "react";
import { Row, useMediaContext } from "saladsoft-primitive";
import styled from "styled-components";

const Section03 = () => {
  const { isMobile } = useMediaContext();
  return (
    <StayContainer>

      <Row justifyContent="flex-end" gap={20} paddingLeft={isMobile ? '0px' : '60px'} paddingTop={isMobile ? '40px' : '100px'} flexWrap="wrap">
        <div>
          <img src="/images/section_03.png" alt="icon1" style={{ width: '100%' }} />
        </div>
        <div>
          <img src="/images/section_04.png" alt="icon2" style={{ width: '100%' }} />
        </div>
        <div>

        </div>
      </Row>
    </StayContainer>
  );
};

const StayContainer = styled.div`
  max-width: 100%;
  position: relative;
`;

const UnionWrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: right;
  position: absolute;
  top: -100px;
  right: 0;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const PointWrapper = styled.div`
  display: flex;
  width: 98px;
  flex-direction: column;
  align-items: stretch;
  font-family:
    Montserrat,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-size: 24px;
  color: #92897b;
  font-weight: 600;
  letter-spacing: -0.06px;
  justify-content: start;
`;

const PointNumber = styled.div`
  leading-trim: both;
  text-edge: cap;
  align-self: center;
`;

const PointLine = styled.img`
  aspect-ratio: 100;
  object-fit: contain;
  object-position: center;
  width: 100%;
  stroke-width: 1px;
  stroke: #92897b;
  margin-top: 10px;
`;

const StayTitle = styled.div`
  color: #1d1d1d;
  leading-trim: both;
  text-edge: cap;
  font-family:
    Pretendard,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: -0.09px;
  margin-top: 32px;
`;

const StayDescription = styled.div`
  color: #555;
  leading-trim: both;
  text-edge: cap;
  font-family:
    Pretendard,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.05px;
  margin-top: 46px;
`;

export default Section03;
