import React, { useId } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import { Scrollbar, Navigation } from 'swiper/modules';
import { ListingDto } from 'services/data-contracts';
import { useMediaContext } from 'saladsoft-primitive';
// MUI 아이콘 임포트
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import { motion } from 'framer-motion';

const GalleryViewer = ({ listing, useSlider = true, onClose, initialIndex = 0 }:
  { listing?: ListingDto, useSlider?: boolean, onClose: () => void, initialIndex: number }) => {
  const { isMobile } = useMediaContext();
  // 고유 ID 생성 (React 18 이상)
  const uniqueId = useId().replace(/:/g, '');
  const prevBtnId = `gallery-prev-${uniqueId}`;
  const nextBtnId = `gallery-next-${uniqueId}`;

  // 이벤트 전파 중지 함수
  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <Overlay>
      <CloseButton onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}>
        <CloseIcon />
      </CloseButton>
      <MobileWrapper>
        {listing?.images?.map((image, index) => (
          <ImageWrapper
            key={image.url}
            as={motion.div}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <StyledImage src={image.url} alt={`Gallery image ${index}`} />
          </ImageWrapper>
        ))}
      </MobileWrapper>
    </Overlay>
  );
};

const GlobalStyle = createGlobalStyle<{ isMobile: boolean }>`




`;

const MobileWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  padding-top: 60px; // 상단 닫기 버튼 공간 확보
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
`;

const ImageWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  
  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  display: block;
  object-fit: contain;
`;

// MUI 아이콘 스타일링
const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  font-size: 36px;
  color: white;
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  font-size: 36px;
  color: white;
`;

// 네비게이션 버튼 스타일
const NavButton = styled.div<{ position: 'left' | 'right' }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ position }) => position === 'left' ? 'left: 20px;' : 'right: 20px;'}
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10002;
  transition: background-color 0.3s ease;
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  @media (max-width: 768px) {
    width: 40px;
    height: 40px;
    ${({ position }) => position === 'left' ? 'left: 10px;' : 'right: 10px;'}
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// 닫기 버튼 스타일
const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10002;
  transition: background-color 0.3s ease;
  padding: 0;
  
  svg {
    color: white;
    font-size: 24px;
  }
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  @media (max-width: 768px) {
    top: 15px;
    right: 15px;
    width: 36px;
    height: 36px;
    
    svg {
      font-size: 20px;
    }
  }
`;

export default GalleryViewer;
