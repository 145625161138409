import { Typography } from '@mui/material';
import React from 'react';
import { LandmarkDto } from 'services/data-contracts';
import styled from 'styled-components';
import { getSimplifiedKoreanAddress, getSimplifiedEnglishAddress } from 'libs/helper/utils';
import { useTranslation } from 'react-i18next';

const LandmarkCard = ({ landmark, onClick }: { landmark?: LandmarkDto; onClick?: () => void }) => {
  const { t, i18n } = useTranslation();

  const getFormattedAddress = () => {
    if (!landmark?.address) return '';

    if (i18n.language === 'ko') {
      return getSimplifiedKoreanAddress(landmark.address);
    } else {
      return getSimplifiedEnglishAddress(landmark.address);
    }
  };

  return (
    <CardContainer onClick={onClick}>
      <ImageContainer>
        <CardImage src={landmark?.images?.[0]?.url} alt={landmark?.name} />
      </ImageContainer>
      <ContentContainer>
        <TitleStyled>{landmark?.name}</TitleStyled>
        <AddressStyled>{getFormattedAddress()}</AddressStyled>
      </ContentContainer>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 4px;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 140px;
  border-radius: 8px;
  overflow: hidden;
`;

const CardImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TitleStyled = styled(Typography)`
  font-size: 15px !important;
  font-weight: 600 !important;
  color: var(--gray-gray-90, #1d1d1d);
  font-family: Pretendard;
  font-style: normal;
  line-height: 150%;
`;

const AddressStyled = styled(Typography)`
  font-size: 12px !important;
  color: var(--gray-gray-60, #717171);
  font-family: Pretendard;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`;

export default LandmarkCard;
