import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import BodyText from 'components/commons/BodyText';
import Subtitle from 'components/commons/Subtitle';
import BTButton from 'components/input/BTButton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, useMediaContext } from 'saladsoft-primitive';
import styled from 'styled-components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Section from 'components/layouts/Section';
import { useParams } from 'react-router-dom';
import ServiceHelper from 'stores/ServiceHelper';
import i18n from 'i18n';
import { CommonGuideDto, TermDto } from 'services/data-contracts';
import { useStoreWrapper } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import axios from 'axios';

const Amenity = observer(() => {
  const DISPLAY_LIMIT = 8;

  const { t } = useTranslation();
  const { isMobile } = useMediaContext();
  const { listingId } = useParams();
  const { uiStore, listingStore } = useStoreWrapper();

  const [commonGuides, setCommonGuides] = useState<CommonGuideDto[]>();
  const [reservationTerms, setReservationTerms] = useState<TermDto>();
  const [checkinTerms, setCheckinTerms] = useState<TermDto>();
  const [usageTerms, setUsageTerms] = useState<TermDto>();

  const AmennityList = () => {
    return (
      <div style={{ padding: 20 }}>
        <AmenityWrapper>
          {listingStore?.amenities?.map((item) => (
            <AmenityItem key={item.id}>{item?.name}</AmenityItem>
          ))}
        </AmenityWrapper>
      </div>
    );
  };

  const handleShowAll = () => {
    if (isMobile) {
      uiStore?.modal.show({
        title: t('ammenity'),
        children: <AmennityList />,
        style: { width: '100%', height: '100%', maxHeight: '100%' },
      });
    } else {
      uiStore?.modal.show({
        style: { maxWidth: 900 },
        title: t('ammenity'),
        children: <AmennityList />,
      });
    }
  };

  const TitleSection = () => {
    return (
      <Row gap={5} justifyContent="flex-start">
        <Subtitle type="B">{t('ammenity')}</Subtitle>
        <BodyText type="A" style={{ color: '#8E8E8E' }}>
          {listingStore?.amenities?.length}
        </BodyText>
      </Row>
    );
  };

  useEffect(() => {
    if (listingId) {
      ServiceHelper.listingService
        ?.getAmenitiesByListing(Number(listingId), { locale: i18n.language })
        .then((res) => {
          listingStore.setAmenities(res.data);
        });
    }

    ServiceHelper.commonGuideService?.getAllGuides({ locale: i18n.language }).then((res) => {
      setCommonGuides(res.data);
    });

    ServiceHelper.termService
      ?.getTermByCategory('RESERVATION', { locale: i18n.language })
      .then((res) => {
        setReservationTerms(res.data);
      });

    ServiceHelper.termService
      ?.getTermByCategory('CHECKIN', { locale: i18n.language })
      .then((res) => {
        setCheckinTerms(res.data);
      });

    ServiceHelper.termService?.getTermByCategory('USAGE', { locale: i18n.language }).then((res) => {
      setUsageTerms(res.data);
    });

    // fetchReservations();
  }, [listingId]);

  return (
    <Section title={<TitleSection />}>
      <AmenityWrapper>
        {listingStore?.amenities
          ?.slice(0, DISPLAY_LIMIT)
          .map((item) => <AmenityItem key={item.id}>{item?.name}</AmenityItem>)}
      </AmenityWrapper>
      <BTButton
        onClick={() => {
          handleShowAll();
        }}
        type="outline"
        style={{ width: '100%', border: '1px solid #e4e4e4' }}
        contentStyle={{ justifyContent: 'flex-start', width: '100%' }}
      >
        <Row gap={5} justifyContent="space-between" width="100%">
          <BodyText type="A" style={{ color: '#8E8E8E' }}>
            {t('showAll')} ({listingStore?.amenities?.length})
          </BodyText>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="8"
              viewBox="0 0 20 8"
              fill="none"
            >
              <path
                d="M1 3.5H0.5V4.5H1V3.5ZM19.3536 4.35355C19.5488 4.15829 19.5488 3.84171 19.3536 3.64645L16.1716 0.464466C15.9763 0.269204 15.6597 0.269204 15.4645 0.464466C15.2692 0.659728 15.2692 0.976311 15.4645 1.17157L18.2929 4L15.4645 6.82843C15.2692 7.02369 15.2692 7.34027 15.4645 7.53553C15.6597 7.7308 15.9763 7.7308 16.1716 7.53553L19.3536 4.35355ZM1 4.5H19V3.5H1V4.5Z"
                fill="#92897B"
              />
            </svg>
          </div>
        </Row>
      </BTButton>
      <AccordionList>
        <Accordion defaultExpanded>
          <AccordionSummaryWrapper
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            {t('Reservation Information')}
          </AccordionSummaryWrapper>
          <AccordionDetailsWrapper>
            <div dangerouslySetInnerHTML={{ __html: reservationTerms?.content || '' }} />
          </AccordionDetailsWrapper>
        </Accordion>
        <Accordion>
          <AccordionSummaryWrapper
            expandIcon={<ExpandMoreIcon sx={{ width: '24px', height: '24px' }} />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            {t('Check-in Information')}
          </AccordionSummaryWrapper>
          <AccordionDetailsWrapper>
            <div dangerouslySetInnerHTML={{ __html: checkinTerms?.content || '' }} />
          </AccordionDetailsWrapper>
        </Accordion>
        <Accordion>
          <AccordionSummaryWrapper
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            {t('Usage Guidelines')}
          </AccordionSummaryWrapper>
          <AccordionDetailsWrapper>
            <div
              dangerouslySetInnerHTML={{ __html: usageTerms?.content || '' }}
              style={{ display: 'block' }}
            />
          </AccordionDetailsWrapper>
        </Accordion>
      </AccordionList>
    </Section>
  );
});

const AmenityWrapper = styled.div`
  margin-bottom: 28px;
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(4, 1fr);

  @media ${(props) => props.theme.media.mobile} {
    grid-template-columns: repeat(2, 1fr);
    /* margin: 20px 0; */
    gap: 8px;
  }
`;

const AmenityItem = styled.div`
  padding: 12px 16px;
  border-radius: 8px;
  text-align: left;
  color: var(--gray-gray-90, #1d1d1d);
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 19.5px */

  @media ${(props) => props.theme.media.mobile} {
    padding: 8px 12px;
  }
`;

const AccordionList = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.media.mobile} {
    margin-left: -20px;
    margin-right: -20px;
  }
`;

const AccordionDetailsWrapper = styled(AccordionDetails)`
  background-color: #f8f8f8;
  color: #717171;
  padding: 32px 40px !important;
  font-size: 14px;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  @media ${(props) => props.theme.media.mobile} {
    /* padding: 40px !important; */
  }

  li {
    margin-bottom: 10px !important;
  }

  /* ul {
    padding-left: 0;

    li {
      color: #717171;
      font-family: Pretendard;
      font-size: 14px;
      line-height: 150%;
      margin-bottom: 8px;
      list-style-type: none;
      padding-left: 5px;
      position: relative;
      display: flex;
      gap: 4px;

      &:before {
        content: '•';
        color: #717171;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  } */
`;

const AccordionSummaryWrapper = styled(AccordionSummary)`
  font-size: 15px !important;
  font-weight: 600 !important;
`;

export default Amenity;
