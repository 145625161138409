"use client";
import * as React from "react";
import { Row } from "saladsoft-primitive";
import styled from "styled-components";

const Section06 = () => {
  return (
    <StayContainer>
      <Row gap={50} width="100%" flexWrap="wrap">
        <div>
          <img src="/images/icon_04.png" alt="icon_04" />
          <Title>전문적인 한옥 관리</Title>
          <Description>
            담당팀을 통한 전문적인 한옥 유지보수
          </Description>
          <img src="/images/section_10.png" alt="section_10" style={{ width: '100%' }} />
        </div>
        <div>
          <img src="/images/icon_05.png" alt="icon_05" />
          <Title>한옥별 맞춤형 공간 기획</Title>
          <Description>
            한옥별 특색을 고려한 공간 및 기획 설계
          </Description>
          <img src="/images/section_11.png" alt="section_11" style={{ width: '100%' }} />
        </div>

      </Row>
    </StayContainer>
  );
};

const StayContainer = styled.div`
  max-width: 449px;
  margin: 0 auto;
  padding:100px 20px;
`;

const Title = styled.div`
 color: var(--Grayscale-0, #FFF);

/* 32 semibold */
font-family: Pretendard;
font-size: 32px;
font-style: normal;
font-weight: 600;
line-height: 150%; /* 48px */
letter-spacing: -0.08px;
@media ${(props) => props.theme.media.mobile} {
  font-size: 24px;
}
`;

const Description = styled.div`
 color: var(--Grayscale-10, #F0F0F0);
leading-trim: both;
text-edge: cap;
font-family: Pretendard;
font-size: 22px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 33px */
letter-spacing: -0.055px;
margin-top: 5px;
margin-bottom: 30px;
@media ${(props) => props.theme.media.mobile} {
  font-size: 18px;
}
`;


export default Section06;
