import React from 'react';
import styled from 'styled-components';
import { OptionModel } from 'types/CommonTypes';
import { CountButtonSmall } from 'components/commons/CountButtonSmall';

interface SelectedOptionsBoxProps {
  selectedOptions: OptionModel[];
  onRemove: (optionId: number) => void;
  onQuantityChange: (optionId: number, qty: number) => void;
}

const SelectedOptionsBox = ({
  selectedOptions,
  onRemove,
  onQuantityChange,
}: SelectedOptionsBoxProps) => {
  if (selectedOptions.length === 0) return null;

  return (
    <Container>
      <OptionList>
        {selectedOptions.map((option) => (
          <OptionItem key={option.id}>
            <DeleteButton onClick={() => onRemove(option.id!)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
              >
                <path
                  d="M10.6289 1.11719L1.11581 10.6306"
                  stroke="#1D1D1D"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                />
                <path
                  d="M1.11719 1.11719L10.6303 10.6306"
                  stroke="#1D1D1D"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                />
              </svg>
            </DeleteButton>
            <OptionName>{option.name}</OptionName>
            <PriceControlWrapper>
              <OptionPrice>
                <PriceNumber>
                  {((option?.price || 0) * (option.qty || 1)).toLocaleString()}
                </PriceNumber>
                <PriceUnit>원</PriceUnit>
              </OptionPrice>
              {option.useQty && (
                <StyledCountButtonSmall
                  count={option.qty}
                  maxCount={10}
                  useZero={false}
                  onChange={(qty) => {
                    onQuantityChange(option.id!, qty);
                  }}
                />
              )}
            </PriceControlWrapper>
          </OptionItem>
        ))}
      </OptionList>
    </Container>
  );
};

export default SelectedOptionsBox;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 32px;
`;

const OptionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const OptionItem = styled.ul`
  display: flex;
  flex-direction: column;
  height: 74px;
  padding: 16px;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #edf1f9;
  position: relative;
`;

const DeleteButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 9.514px;
  height: 9.513px;
  flex-shrink: 0;
  border: none;
  background: none;
  cursor: pointer;
`;

const OptionName = styled.div`
  color: var(--Grayscale-70, #555);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.035px;
`;

const PriceControlWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const OptionPrice = styled.div`
  display: flex;
  color: var(--sub-color, #1c51b6);
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.032px;
`;

const PriceNumber = styled.p`
  color: var(--sub-color, #1c51b6);
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.032px;
`;

const PriceUnit = styled.p`
  color: var(--sub-color, #1c51b6);
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.032px;
`;

const StyledCountButtonSmall = styled(CountButtonSmall)`
  background: transparent;

  .MuiButtonBase-root.MuiIconButton-root {
    width: 20px !important;
    height: 20px !important;
    padding: 0 !important;

    svg {
      width: 16px !important;
      height: 16px !important;
    }
  }

  div[style*='text-align: center'] {
    min-width: 28px !important;

    div {
      font-family: Pretendard !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 150% !important;
      letter-spacing: -0.035px !important;
    }
  }

  & > div > div {
    gap: 0 !important;
  }
`;
