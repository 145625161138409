import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
interface NavigationItemProps {
  icon: string;
  label: string;
  onClick?: () => void;
}

type TabProps = {
  selected?: number;
  onChange?: (id: number) => void;
};

const NavigationItem: React.FC<NavigationItemProps> = ({ icon, label, onClick }) => (
  <NavItemWrapper onClick={onClick}>
    <NavIcon loading="lazy" src={icon} alt={`${label} icon`} />
    <NavLabel>{label}</NavLabel>
  </NavItemWrapper>
);

const Divider = () => (
  <DividerLine
    loading="lazy"
    src="https://cdn.builder.io/api/v1/image/assets/TEMP/0b034e9a555de394500867f65628f162dae8e583fe742fd189014b818aa28f5b?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
    alt=""
  />
);

const MobileTab = ({ selected, onChange }: TabProps) => {
  const { t } = useTranslation();
  const navigationItems: NavigationItemProps[] = [
    {
      icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/7fbbdb95028cba9f8535dda84ff6500c20c9a0b7dc790c1cb4beff75476174cb?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25',
      label: t('facilities'),
    },
    {
      icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/596dd7e5359869bed5f8350b3b8c7c7eb56e2a1b0a12f415b57ff908a69ce90e?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25',
      label: t('plan'),
    },
    {
      icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/dce064a162574b942cfade5514c48815ad3c0b99fa872ebe4630f11f8c541420?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25',
      label: t('location'),
    },
    {
      icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/1017a8bcd7f7e5d26fb22acc7044468103c865c50214d55eb73bba89bbb84bfd?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25',
      label: t('attraction'),
    },
  ];
  return (
    <NavigationContainer>
      <NavigationWrapper>
        {navigationItems.map((item, index) => (
          <React.Fragment key={item.label}>
            <NavigationItem {...item} onClick={() => onChange?.(index + 1)} />
            {index < navigationItems.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </NavigationWrapper>
    </NavigationContainer>
  );
};

const NavigationContainer = styled.nav`
  background-color: rgba(248, 248, 248, 1);
  display: flex;
  flex-direction: column;
  color: #1d1d1d;
  white-space: nowrap;
  letter-spacing: -0.03px;
  justify-content: center;
  padding: 28px 16px;
  width: 100%;
  font:
    400 13px Pretendard,
    sans-serif;
`;

const NavigationWrapper = styled.div`
  display: flex;
  min-height: 45px;
  width: 100%;
  align-items: center;
  gap: 18px;
  justify-content: space-between;
  padding: 0 16px;
`;

const NavItemWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 50px;
  margin: auto 0;
`;

const NavIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 32px;
`;

const NavLabel = styled.span`
  leading-trim: both;
  text-edge: cap;
  margin-top: 4px;
`;

const DividerLine = styled.img`
  aspect-ratio: 0.03;
  object-fit: contain;
  object-position: center;
  width: 1px;
  align-self: stretch;
  margin: auto 0;
`;

export default MobileTab;
