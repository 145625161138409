import React, { useEffect, useRef, useState } from 'react';
import { Row, useMediaContext } from 'saladsoft-primitive';
import { createGlobalStyle } from 'styled-components';

import ListingList from 'components/commons/ListingList';
import PageContainer from 'containers/pc/PageContainer';
import styled from 'styled-components';
import useDetectScroll from '@smakss/react-scroll-direction';
import Navigation from 'components/commons/mobile/Navigation';
import ServiceHelper from 'stores/ServiceHelper';
import i18n from 'i18n';
import { getUrlParam } from 'libs/helper/utils';
import { useStoreWrapper } from 'stores/StoreHelper';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { PCDateRangePickerSimple } from 'components/commons/searchBox/PCDateRangePickerSimple';
import CountButtonSimple from 'components/commons/searchBox/CountButtonSimple';
import { ListingDto } from 'services/data-contracts';
import { BulkPriceRequest } from 'services/data-contracts';
import { useScrollRestoration } from 'hooks/useScrollRestoration';
const GlobalStyle = createGlobalStyle`
  .PageContainer__ContentContainer-sc-sfnan6-2.iieURQ.page-content-container {
    max-width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
  }
  
  [class^="SearchPage__ListingWrapper"] {
        > div {
        padding-bottom: 20px !important;
       @media (max-width: 768px) {
        padding-bottom: 0px !important;
      }
    }
  }
`;

const SearchPage = observer(() => {
  const location = useLocation();
  const { isMobile } = useMediaContext();
  const { scrollPosition } = useDetectScroll();
  const { filterStore, calendarStore } = useStoreWrapper();
  const { navigationTypes } = useScrollRestoration();

  const startDate = getUrlParam('startdate');
  const endDate = getUrlParam('enddate');
  const guests = getUrlParam('guests');

  const [showSearch, setShowSearch] = useState(false);
  // const [isLoading, setIsLoading] = useState(true);

  const fetchListings = async () => {
    sessionStorage.removeItem('scroll_page');
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    const guestNumber = guests ? parseInt(guests, 10) : 2;
    filterStore.setLoading(true);

    await ServiceHelper.listingService
      ?.getListings({
        startDate: startDate ?? undefined,
        endDate: endDate ?? undefined,
        guestNumber,
        locale: i18n.language,
      })
      .then((res) => {
        filterStore.setListings(res.data);
        filterStore.setFilteredListings(res.data);
        fetchLowestPrices(res.data, startDate ?? undefined, endDate ?? undefined, guestNumber);
        // setIsLoading(false);
      });
  };

  const fetchLowestPrices = (
    listings: ListingDto[],
    startDate?: string,
    endDate?: string,
    guestNumber?: number,
  ) => {
    const ids = listings.map((listing) => listing.id);

    const bulkPriceRequest: BulkPriceRequest = {
      listingIds: ids as number[],
      startDate: startDate ?? '',
      endDate: endDate ?? '',
      adults: guestNumber,
    };
    ServiceHelper.listingService?.getBulkPrices(bulkPriceRequest).then((res) => {
      filterStore.setLowestPrices(res.data);
    });
  };

  useEffect(() => {
    setShowSearch(scrollPosition.top > 750);
  }, [scrollPosition]);



  useEffect(() => {
    const filters = getUrlParam('filters');
    if (filters !== null) {
      return;
    }
    const forceFetch = sessionStorage.getItem('forceFetch');
    sessionStorage.removeItem('forceFetch');
    if (forceFetch === null) {
      fetchListings();
      return;
    }

    if (navigationTypes === 'pop') {
      return;
    }

    if (navigationTypes === 'init') {
      fetchListings();
    } else {
      // setIsInitialLoading(false);
    }
    return () => {
      sessionStorage.setItem('forceFetch', location.pathname);
    };
  }, [location.search, navigationTypes]);

  useEffect(() => {
    return () => {
      calendarStore.unMount();
    };
  }, [location.search, filterStore.filteredListings]);

  useEffect(() => {
    return () => {
      sessionStorage.setItem('forceFetch', 'true');
    };
  }, []);

  return (
    <PageContainer>
      <GlobalStyle />
      <PageContentOverride />
      <div style={{ position: 'relative' }}>
        {isMobile && (
          <div
            style={{
              position: 'sticky',
              top: 52,
              zIndex: 1,
              background: '#fff',
              // padding: '20px 0',
            }}
          >
            <Row padding="0 16px" gap={8}>
              <PCDateRangePickerSimple dateText={<></>} />
              <div
                style={{ minWidth: 120 }}
                onClick={() => {
                  calendarStore.setMobileCalendarShow(true, false, true);
                }}
              >
                <CountButtonSimple
                  count={Number(guests) || 2}
                  maxCount={12}
                  onChange={() => { }}
                  disabled={true}
                />
              </div>
            </Row>
          </div>
        )}
        <ListingWrapper
          className="page-container"
          style={{ zIndex: 0, position: 'relative', padding: 0 }}
        >
          {filterStore.isLoading && (
            <NoContents>
              <img style={{ width: 100 }} src="/images/butlerlee-loading.gif" alt="loading" />
            </NoContents>
          )}
          <ListingList listings={filterStore.filteredListings} />
        </ListingWrapper>
      </div>
      {isMobile && (
        <NavagationWrapper>
          <Navigation />
        </NavagationWrapper>
      )}
    </PageContainer>
  );
});

const NavagationWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: #fff;
  z-index: 1;
`;

const ListingWrapper = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    /* padding: 16px; */
  }
`;

const NoContents = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  text-align: center;
`;

const PageContentOverride = styled.div`
  /* page-content-container 클래스를 오버라이드하는 스타일 */
  .page-content-container {
    /* 여기에 원하는 스타일 속성을 추가하세요 */
    padding: 0 !important;
    margin: 0;
  }
`;

export default SearchPage;
