import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import BTAccordion from 'components/commons/BTAccordion';
import AccordionHeader from './AccordionHeader';
import { DiscountSection } from './points/discount/DiscountSection';

const Agreements = ({ onCheck }: { onCheck: (checked: boolean) => void }) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = React.useState(false);
  const [termChecks, setTermChecks] = React.useState<boolean[]>(new Array(5).fill(false));
  const [isExpanded, setIsExpanded] = React.useState(false);

  const termsData = [
    {
      title: t('guests'),
      description: t('guestsDescription'),
      isRequired: true,
    },
    {
      title: t('smoking'),
      description: t('smokingDescription'),
      isRequired: true,
    },
    {
      title: t('replacement'),
      description: t('replacementDescription'),
      isRequired: true,
    },
    {
      title: t('photography'),
      description: t('photographyDescription'),
      isRequired: true,
    },
    {
      title: t('refund'),
      description: t('refundDescription'),
      isRequired: true,
    },
  ];
  const handleChange = () => {
    setIsChecked(!isChecked);
    setTermChecks(new Array(5).fill(!isChecked));
    onCheck(!isChecked);
  };

  const handleTermCheck = (index: number) => {
    const newTermChecks = [...termChecks];
    newTermChecks[index] = !newTermChecks[index];
    setTermChecks(newTermChecks);

    const allChecked = newTermChecks.every((check) => check);
    setIsChecked(allChecked);
    onCheck(allChecked);
  };

  return (
    <div>
      <StyledContainer>
        <HeaderContainer>
          <AccordionHeader title={t('Agreements')} index={3} required />
        </HeaderContainer>
        <ContentContainer style={{ padding: '24px 0 40px' }}>
          <CardContainer role="region" aria-label="Agreement Section">
            <Checkbox
              size="small"
              id="agreementCheckbox"
              checked={isChecked}
              onChange={handleChange}
              aria-label={t('agreement')}
              checkedIcon={
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{
                    background: '#4E433C',
                    borderRadius: '2px',
                  }}
                >
                  <path
                    d="M4.80078 7.99883L7.70604 10.79883L11.60078 4.79883"
                    stroke="white"
                    strokeWidth="1.2"
                    strokeLinecap="round"
                  />
                </svg>
              }
              sx={{
                padding: 0,
                color: '#4e433c',
                '& .MuiSvgIcon-root': {
                  width: '16px',
                  height: '16px',
                  border: '1px solid #8e8e8e',
                  borderRadius: '2px',
                  background: '#fff',
                  '& path': {
                    display: 'none',
                  },
                },
              }}
            />
            <ContentWrapper>
              <Title>{t('agreement')}</Title>
              <Description>{t('agreementDescription')}</Description>
            </ContentWrapper>
            <ExpandButton onClick={() => setIsExpanded(!isExpanded)}>
              <ExpandIcon
                isExpanded={isExpanded}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M5 7.5L10 13L15 7.5"
                  stroke="#555555"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                />
              </ExpandIcon>
            </ExpandButton>
          </CardContainer>
          {isExpanded && (
            <div style={{ marginTop: 16, marginLeft: 10 }}>
              <Container>
                {termsData.map((term, index) => (
                  <TermWrapper key={index}>
                    <FormControlLabel
                      sx={{
                        '& .MuiFormControlLabel-label': {
                          fontFamily: 'Pretendard',
                          fontSize: '14px',
                          color: '#1D1D1D',
                          fontStyle: 'normal',
                          fontWeight: 400,
                          lineHeight: '150%',
                          letterSpacing: '-0.04px',
                        },
                        gap: '3px',
                      }}
                      control={
                        <Checkbox
                          style={{ padding: 3 }}
                          checked={termChecks[index]}
                          onChange={() => handleTermCheck(index)}
                          checkedIcon={
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{
                                background: '#4E433C',
                                borderRadius: '2px',
                              }}
                            >
                              <path
                                d="M4.80078 7.99883L7.70604 10.79883L11.60078 4.79883"
                                stroke="white"
                                strokeWidth="1.2"
                                strokeLinecap="round"
                              />
                            </svg>
                          }
                          sx={{
                            padding: 0,
                            color: '#4e433c',
                            '& .MuiSvgIcon-root': {
                              width: '16px',
                              height: '16px',
                              border: '1px solid #8e8e8e',
                              borderRadius: '2px',
                              background: '#fff',
                              '& path': {
                                display: 'none',
                              },
                            },
                          }}
                        />
                      }
                      label={term.title}
                    />
                    <TermDescription>{term.description}</TermDescription>
                  </TermWrapper>
                ))}
              </Container>
            </div>
          )}
        </ContentContainer>
      </StyledContainer>
    </div>
  );
};

const StyledContainer = styled.div`
  box-shadow: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
`;

const HeaderContainer = styled.div`
  padding: 0;
`;

const ContentContainer = styled.div`
  padding: 0;
`;

const CardContainer = styled.section`
  align-items: center;
  border-radius: 8px;
  background: var(--Grayscale-5, #f8f8f8);
  display: flex;
  gap: 10px;
  padding: 16px;
`;

const TitleContainer = styled.div`
  border-radius: 0px 0px 0px 0px;
  display: flex;
  max-width: 100%;
  gap: 4px;
  font-weight: 600;
`;

const Title = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 15px;
  letter-spacing: -0.037px;
  font-family: Pretendard;
  font-weight: 600;
  line-height: 150%;
  font-style: normal;
`;

const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  width: 12px;
  height: 12px;
  border-radius: 2px;
  border: 1px solid var(--Grayscale-50, #8e8e8e);
  background: var(--Grayscale-0, #fff);
  margin: auto 0;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:checked {
    background: #4e433c;
    border-color: #4e433c;

    &::after {
      content: '';
      display: block;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 1.2px 1.2px 0;
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
  position: relative;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 240px;
  margin: auto 0;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

// const Title = styled.h2`
//   color: var(--Grayscale-70, #555);
//   font-family: Pretendard, sans-serif;
//   font-size: 15px;
//   font-weight: 600;
//   letter-spacing: -0.04px;
//   margin: 0;

//   @media (max-width: 991px) {
//     max-width: 100%;
//   }
// `;

const Description = styled.p`
  color: var(--Grayscale-50, #8e8e8e);
  font-family: Pretendard, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.03px;
  margin: 0;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ExpandButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const ExpandIcon = styled.svg<{ isExpanded: boolean }>`
  aspect-ratio: 1;
  object-fit: contain;
  width: 20px;
  margin: auto 0;
  transition: transform 0.2s ease-in-out;
  transform: ${(props) => (props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  font-weight: 400;
  padding: 0 14px;

  @media (max-width: 991px) {
    width: 100%;
  }
`;

const TermWrapper = styled.div`
  margin-bottom: 6px;
`;

const TermDescription = styled.p`
  color: var(--Grayscale-50, #8e8e8e);
  font-family: Pretendard, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.03px;
  margin: 3px 14px;
`;

export default Agreements;
