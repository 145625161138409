import Section from 'components/layouts/Section';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BulkPriceRequest, ListingDto, ListingLowestPriceDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Scrollbar } from 'swiper/modules';
import RecommendListingItem from 'components/commons/RecommendListingItem';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import useToken from 'hooks/useToken';

const RecommendListings = ({ listingId }: { listingId?: string }) => {
  const { t } = useTranslation();
  const navigate = useLocaleNavigate();
  const { validUser } = useToken();

  const [listings, setListings] = useState<ListingDto[]>([]);
  const [favoriteListings, setFavoriteListings] = useState<ListingDto[]>([]);
  const [lowestPrices, setLowestPrices] = useState<ListingLowestPriceDto[]>([]);

  const fetchNearBy = (listingId: number) => {
    ServiceHelper.listingService
      ?.getRelatedListings(listingId, {
        locale: i18n.language,
      })
      .then((respont) => {
        if (respont) {
          setListings(respont.data);
          const ids = respont.data?.map((listing) => listing.id);
          const bulkPriceRequest: BulkPriceRequest = {
            listingIds: ids as number[],
            startDate: '',
            endDate: '',
            adults: 2,
          };

          ServiceHelper.listingService?.getBulkPrices(bulkPriceRequest).then((res) => {
            setLowestPrices(res.data);
          });
        }
      });
  };

  const fetchFavoriteListings = () => {
    if (validUser()) {
      ServiceHelper.myAccountService?.getFavoriteListings({ locale: i18n.language }).then((res) => {
        setFavoriteListings(res.data);

      });
    }
  };

  const handleClick = (listing?: ListingDto) => {
    navigate(`/listing/${listing?.id}`);
  };

  useEffect(() => {
    if (listingId) {
      const listingIdNumber = parseInt(listingId, 10);
      if (listingIdNumber) {
        fetchNearBy(listingIdNumber);
      }
    }
  }, [listingId]);

  useEffect(() => {
    if (listings.length > 0) {
      fetchFavoriteListings();
    }
  }, [listings]);

  return (
    <>
      {listings && listings.length > 0 && (
        <Section title={t('RecommendListings')}>
          <Swiper
            spaceBetween={10}
            slidesPerView="auto"
            scrollbar={{
              hide: true,
            }}
          >
            {listings.map((listing) => (
              <SwiperSlide key={listing.id} style={{ width: 'auto' }}>
                <div
                  style={{
                    width: 300,
                    overflow: 'hidden',
                  }}
                >
                  <RecommendListingItem
                    house={listing}
                    isFavorite={favoriteListings?.some((favorite) => favorite.id === listing.id)}
                    onClick={() => {
                      handleClick(listing);
                    }}
                    lowestPrice={lowestPrices?.find((price) => price.listingId === listing.id)?.price || 0}
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </Section>
      )}
    </>
  );
};

export default RecommendListings;
