import * as React from 'react';
import styled from 'styled-components';
import { AlertMessageProps } from './types';

export const AlertMessage: React.FC<AlertMessageProps> = ({ message, className, isError }) => {
  return (
    <AlertContainer className={className} isError={isError}>
      {message}
    </AlertContainer>
  );
};

const AlertContainer = styled.div<{ isError?: boolean }>`
  color: ${(props) => (props.isError ? 'red' : '#1C51B6')};
  font-size: 12px;
  letter-spacing: -0.03px;
  line-height: 18px;
  margin-top: 14px;
  white-space: pre-line;
  @media ${(props) => props.theme.media.mobile} {
    margin-top: 10px;
  }
`;
