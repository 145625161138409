import * as React from 'react';
import styled from 'styled-components';

interface SectionHeaderProps {
  title: string;
}

export function SectionHeader({ title }: SectionHeaderProps) {
  return (
    <Header>
      <Title>{title}</Title>
    </Header>
  );
}

const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
`;

const Title = styled.h2`
  font-size: 14px;
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 500;
  letter-spacing: -0.04px;
`;
