import React, { useState } from 'react';
import PageContainer from 'containers/pc/PageContainer';

import useLocaleNavigate from 'hooks/useLocaleNavigate';
import SocialLoginButtons from 'components/login/SocialLoginButtons';

const LoginPage = () => {
  const navigate = useLocaleNavigate();




  return (
    <PageContainer>
      <SocialLoginButtons type="login" />
      <></>
    </PageContainer>
  );
};

export default LoginPage;
