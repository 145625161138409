import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUserInfo } from 'providers/UserInfoProvider';
import { InputField, Row, useMediaContext, useModelStateContext } from 'saladsoft-primitive';
import useToken from 'hooks/useToken';
import { UserProfileDto } from 'services/data-contracts';
import CountryCode from 'components/commons/CountryCode';
import { FormHelperText, Link } from '@mui/material';
import { validationHelper } from 'libs/helper/validationHelper';
import ServiceHelper from 'stores/ServiceHelper';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { TextField } from './TextField';
import { formatBirthday } from 'libs/helper/utils';

export const MyInfoForm = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaContext();
  const { validUser } = useToken();
  const { loginStatus, myAccount, setUserInfo } = useUserInfo();

  const { setState, stateModel } = useModelStateContext<UserProfileDto>();

  const [isEditing, setIsEditing] = useState(false);
  const [isValid, setIsValid] = useState<boolean>(false);

  // 실시간 유효성 상태용 state
  const [isFirstNameValid, setIsFirstNameValid] = useState<boolean | null>(null);
  const [isLastNameValid, setIsLastNameValid] = useState<boolean | null>(null);
  const [isPhoneValid, setIsPhoneValid] = useState<boolean | null>(null);
  const [isCountryCodeValid, setIsCountryCodeValid] = useState<boolean | null>(null);
  const [isBirthdayValid, setIsBirthdayValid] = useState<boolean | null>(null);

  // 각 필드별 에러 메시지 state
  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    countryCode: '',
    phoneNumber: '',
    birthday: '',
  });

  // 전체 유효성 검사
  const validate = () => {
    const firstName = validationHelper.firstName(
      stateModel?.firstName || myAccount?.profile?.firstName,
    );
    const lastName = validationHelper.lastName(
      stateModel?.lastName || myAccount?.profile?.lastName,
    );
    const countryCode = validationHelper.countryCode(
      stateModel?.countryCode || myAccount?.profile?.countryCode,
    );
    const phoneNumber = validationHelper.phoneNumber(
      stateModel?.phoneNumber || myAccount?.profile?.phoneNumber,
    );
    const birthday = validationHelper.birthday(
      stateModel?.birthday || myAccount?.profile?.birthday,
    );

    setIsValid(firstName && lastName && countryCode && phoneNumber && birthday);
  };

  // 개별 필드 유효성 검사 및 에러 메시지 설정
  const validateField = (name: string, value: string) => {
    let isValid = false;
    let errorMessage = '';

    switch (name) {
      case 'firstName':
        isValid = validationHelper.firstName(value);
        if (!isValid) {
          errorMessage = value ? t('no_special_chars_spaces') : t('guest_info_required');
        }
        setIsFirstNameValid(isValid);
        break;

      case 'lastName':
        isValid = validationHelper.lastName(value);
        if (!isValid) {
          errorMessage = value ? t('no_special_chars_spaces') : t('guest_info_required');
        }
        setIsLastNameValid(isValid);
        break;

      case 'countryCode':
        isValid = validationHelper.countryCode(value);
        if (!isValid) {
          errorMessage = t('select_country_code');
        }
        setIsCountryCodeValid(isValid);
        break;

      case 'phoneNumber':
        isValid = validationHelper.phoneNumber(value);
        if (!isValid) {
          errorMessage = value ? t('phone_length_error') : t('guest_info_required');
        }
        setIsPhoneValid(isValid);
        break;

      case 'birthday':
        isValid = validationHelper.birthday(value);
        if (!isValid && value) {
          errorMessage = t('invalidBirthday');
        }
        setIsBirthdayValid(isValid);
        break;
    }

    setErrors((prev) => ({
      ...prev,
      [name]: errorMessage,
    }));

    return isValid;
  };

  // 로그인 확인 및 초기 데이터 로딩
  useEffect(() => {
    if (validUser() && loginStatus) {
      if (myAccount?.profile) {
        setState('firstName', myAccount?.profile?.firstName);
        setState('lastName', myAccount?.profile?.lastName);
        setState('countryCode', myAccount?.profile?.countryCode);
        setState('phoneNumber', myAccount?.profile?.phoneNumber);
        setState('birthday', myAccount?.profile?.birthday);
      }
    }
  }, [loginStatus, myAccount]);

  useEffect(() => {
    if (isEditing) {
      validate();
    }
  }, [stateModel, isEditing]);

  // 저장 버튼 클릭하면
  const handleSaveClick = async () => {
    if (!isValid) {
      toast.error(t('invalidValue')); // 유효하지 않은 값이 있습니다.
      return;
    }

    // 수정된 값이 없으면 기존 값 사용
    const updateProfile = {
      ...stateModel,
      firstName: stateModel?.firstName || myAccount?.profile?.firstName,
      lastName: stateModel?.lastName || myAccount?.profile?.lastName,
      countryCode: stateModel?.countryCode || myAccount?.profile?.countryCode,
      phoneNumber: stateModel?.phoneNumber || myAccount?.profile?.phoneNumber,
      birthday: stateModel?.birthday || myAccount?.profile?.birthday,
    };

    // 회원정보 수정 API 호출
    const response = await ServiceHelper.myAccountService?.saveMyProfile(
      updateProfile as UserProfileDto,
    );
    if (response?.status === 200) {
      setUserInfo({
        ...myAccount,
        profile: updateProfile,
      });
      setIsEditing(false);
      toast.success(t('saveSuccess'));
    }
  };

  // 포커스 아웃 시 유효성 검사
  const handleBlur = (name: string, value: string) => {
    const isValid = validateField(name, value);
    if (!isValid) {
      setIsEditing(true);
    }
  };

  // 회원정보 수정 취소
  const handleReset = () => {
    if (isEditing) {
      // 수정 취소 시 초기값으로 복원
      setState('firstName', myAccount?.profile?.firstName);
      setState('lastName', myAccount?.profile?.lastName);
      setState('countryCode', myAccount?.profile?.countryCode);
      setState('phoneNumber', myAccount?.profile?.phoneNumber);
      setState('birthday', myAccount?.profile?.birthday);

      // 에러 상태 초기화
      setErrors({
        firstName: '',
        lastName: '',
        countryCode: '',
        phoneNumber: '',
        birthday: '',
      });
    }
    setIsEditing(!isEditing);
  };

  return (
    <FormContainer role="main" aria-labelledby="profile-title">
      <StyledLink onClick={handleReset}>
        {isEditing ? t('cancelEditProfile') : t('editProfile')}
      </StyledLink>
      <FormContent>
        <FormSection aria-label="기본 정보">
          <Label>{t('name')}</Label>
          <Row gap={8} marginBottom={32} alignItems="flex-start">
            <InputField
              value={stateModel?.firstName || ''}
              id="firstName-field"
              disabled={!isEditing}
              onChange={(value: string) => setState('firstName', value)}
              onBlur={(e) => handleBlur('firstName', e.target.value)}
              error={isEditing && !!errors.firstName}
              helperText={(isEditing && errors.firstName) || ' '}
            />
            <InputField
              value={stateModel?.lastName || ''}
              id="lastName-field"
              disabled={!isEditing}
              onChange={(value: string) => setState('lastName', value)}
              onBlur={(e) => handleBlur('lastName', e.target.value)}
              error={isEditing && !!errors.lastName}
              helperText={(isEditing && errors.lastName) || ' '}
            />
          </Row>

          <TextField
            label={t('email')}
            defaultValue={myAccount?.username || ''}
            id="email-field"
            disabled
          />

          <Label>{t('phoneNumber')}</Label>
          <Row gap={8} marginBottom={32} alignItems="flex-start">
            <div style={{ marginTop: '5px' }}>
              <CountryCode
                onChange={(value: string) => setState('countryCode', value)}
                value={stateModel?.countryCode || ''}
                showOnlyCode
                disabled={!isEditing}
                onBlur={() => handleBlur('countryCode', stateModel?.countryCode || '')}
                error={errors.countryCode || ''}
              />
            </div>
            <InputField
              value={stateModel?.phoneNumber || ''}
              disabled={!isEditing}
              onChange={(value: string) => setState('phoneNumber', value)}
              onBlur={(e) => handleBlur('phoneNumber', e.target.value)}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber || ' '}
            />
          </Row>

          <Label>{t('birthday')}</Label>
          <InputField
            value={stateModel?.birthday || ''}
            // 생년월일 1회만 수정 가능한 코드
            disabled={!isEditing || !myAccount?.profile?.editableBirthday}
            // disabled={!isEditing} // 테스트용 (여러 번 수정 가능)
            id="birthdate-field"
            onChange={(value: string) => {
              const formattedDate = formatBirthday(value);
              setState('birthday', formattedDate);
            }}
            onBlur={(e) => handleBlur('birthday', e.target.value)}
            error={isEditing && !!errors.birthday}
            helperText={isEditing && errors.birthday ? errors.birthday : t('birthdayHelperText')}
            FormHelperTextProps={{
              component: styledHelperText,
              sx: {
                color:
                  isEditing && errors.birthday
                    ? 'var(--error-color, #d32f2f) !important'
                    : !myAccount?.profile?.editableBirthday
                      ? 'var(--Grayscale-60, #808080) !important'
                      : 'var(--sub-color, #1c51b6) !important',
              },
            }}
          />
          {isEditing && (
            <EditProfileButton
              onClick={() => {
                if (isEditing) {
                  handleSaveClick();
                }
              }}
            >
              {t('save')}
            </EditProfileButton>
          )}
        </FormSection>
      </FormContent>
    </FormContainer>
  );
};

const FormContainer = styled.main`
  background-color: #fff;
  display: flex;
  max-width: 825px;
  flex-direction: column;
  overflow: hidden;
  font-family: Pretendard, sans-serif;
  width: 100%;
  @media ${(props) => props.theme.media.mobile} {
    padding: 0;
  }
`;

const FormTitle = styled.h1`
  font-size: 28px;
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 600;
  letter-spacing: -0.07px;
  margin: 0;
`;

const StyledLink = styled(Link)`
  color: var(--Grayscale-90, #808080);
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
`;

const EditProfileButton = styled.button`
  display: inline-block;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0px 16px;
  gap: 4px;
  border-radius: 8px;
  background: var(--4-e-433-c, #4e433c);
  color: var(--Grayscale-0, #fff);
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  margin-left: auto;
  cursor: pointer;
  margin-top: 30px;
`;

const FormContent = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100%;
  gap: 24px;
  flex-wrap: wrap;
`;

const FormSection = styled.section`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  flex: 1;
  flex-basis: 0%;
`;

const Label = styled.label`
  font-size: 13px;
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 500;
  letter-spacing: -0.03px;
  margin-bottom: 6px;
`;

const styledHelperText = styled(FormHelperText)({
  '&.MuiFormHelperText-root': {
    marginTop: '6px',
    fontSize: '12px',
    letterSpacing: '-0.03px',
    color: 'var(--sub-color, #1c51b6) !important',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    '&::before': {
      content: '""',
      width: '16px',
      height: '16px',
      backgroundImage:
        'url("https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/cbcdb696634287552daa362ff522b6ef443c45b9fb05d1dde8261d50272140a0?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&")',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
  },
  '&.Mui-error': {
    color: 'var(--error-color, #d32f2f) !important',
    '&::before': {
      display: 'none', // 에러일 때는 아이콘 숨김
    },
  },
  '&.Mui-disabled': {
    color: 'var(--sub-color, #1c51b6) !important',
  },
  '&.MuiFormHelperText-contained': {
    margin: 0,
    marginTop: '6px',
  },
});
