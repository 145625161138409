import React, { useEffect } from 'react';
import ScrollTop from 'libs/commons/ScrollTop';
import LayoutWrapper from 'LayoutWrapper';
import StateProvider from 'providers/StateProvider';
import { Slide, ToastContainer } from 'react-toastify';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Drawer from 'components/commons/Drawer';
import NotFoundPage from 'pages/NotFoundPage';
import 'react-toastify/dist/ReactToastify.css';
import CssBaseline from '@mui/material/CssBaseline';
import {
  BottomSheet,
  Confirm,
  Modal,
  ModelStateProvider,
  UniversalModal,
} from 'saladsoft-primitive';
import 'resources/css/Common.css';
import GlobalStyle from 'resources/styles/globalStyle';
import MobileCalendarPortal from 'components/commons/searchBox/MobileCalendarPortal';
import useExitClearSession from 'hooks/useExit';

const App = () => {
  useExitClearSession();

  return (
    <>
      <CssBaseline />
      <GlobalStyle />
      <BrowserRouter>
        {/* <ScrollRestoration /> */}
        <ModelStateProvider>
          <StateProvider>
            <ScrollTop />
            <Drawer />
            <UniversalModal />
            <MobileCalendarPortal />
            <Modal />
            <Confirm />
            <BottomSheet />
            <Routes>
              <Route path="/*" element={<LayoutWrapper />} />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <ToastContainer
              position="bottom-center"
              autoClose={1000}
              style={{ width: '100%', maxWidth: '500px' }}
              hideProgressBar
              newestOnTop
              closeOnClick
              transition={Slide}
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          </StateProvider>
        </ModelStateProvider>
      </BrowserRouter>
    </>
  );
};

export default App;
