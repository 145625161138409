import * as React from 'react';
import styled from 'styled-components';
import { ReservationTab } from './ReservationTab';

export const ReservationList = () => {
  const [activeTab, setActiveTab] = React.useState<string>('진행중인 예약');

  return (
    <Container>
      <TabContainer>
        <ReservationTab
          label="진행중인 예약"
          isActive={activeTab === '진행중인 예약'}
          onClick={() => setActiveTab('진행중인 예약')}
        />
        <ReservationTab
          label="취소내역"
          isActive={activeTab === '취소내역'}
          onClick={() => setActiveTab('취소내역')}
        />
        <ReservationTab
          label="지난예약"
          isActive={activeTab === '지난예약'}
          onClick={() => setActiveTab('지난예약')}
        />
      </TabContainer>
      <ReservationsContainer>
        <main>
          {/* {mockReservations.map((reservation, index) => (
            <ReservationCard onClick={() => navigate(`/my-reservations/${reservation.reservationNumber}`)} key={`${reservation.reservationNumber}-${index}`} {...reservation} />
          ))} */}
        </main>
      </ReservationsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TabContainer = styled.nav`
  display: flex;
  gap: 24px;
  border-bottom: 1px solid var(--Grayscale-20, #e4e4e4);
  background: var(--white, #fff);
`;

const ReservationsContainer = styled.div`
  display: flex;
  min-height: 1000px;
  width: 100%;
  padding-top: 32px;
  flex-direction: column;

  main {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px 24px;
  }
`;
