import React from 'react';
import { Row } from 'saladsoft-primitive';
import styled, { css } from 'styled-components';

import BodyText from 'components/commons/BodyText';
import { ScaleLoader } from 'react-spinners';

export type ButtonType = 'primary' | 'outline' | 'ghost' | 'light';
export type ButtonSize = 'normal' | 'small';

interface Props {
  children: React.ReactNode;
  type?: ButtonType;
  size?: ButtonSize;
  contentStyle?: React.CSSProperties;
  disabled?: boolean;
  busy?: boolean;
  style?: React.CSSProperties;
  onClick?: () => void;
  className?: string;
}

export default function BTButton({
  children,
  type = 'primary',
  size = 'normal',
  disabled,
  busy,
  style,
  onClick,
  contentStyle,
  className,
  ...props
}: Props) {
  return (
    <StyledButton
      type={type}
      className={className}
      size={size}
      disabled={disabled}
      onClick={() => {
        if (onClick && !disabled && !busy) {
          onClick();
        }
      }}
      {...props}
      style={style}
    >
      <Row gap={10} {...contentStyle}>
        <BodyText type="A" style={{ userSelect: 'none', width: '100%' }}>
          {busy ? <ScaleLoader width={2} height={20} color="#fff" /> : children}
        </BodyText>
      </Row>
    </StyledButton>
  );
}

const primaryStyles = css`
  background: var(--primary-color);
  color: white;

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%), #4e433c;
  }

  &:active {
    background: var(--1-f-1-e-1-d, #1f1e1d);
  }
`;

const primaryDisabledStyles = css`
  background: var(--disabled-color);
  color: white;

  &:hover {
    opacity: 1;
  }

  &:active {
    transform: none;
    opacity: 1;
  }
`;

const outlineStyles = css`
  display: inline-flex;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--4-e-433-c, #4e433c);
  background: var(--Grayscale-0, #fff);

  &:hover {
    background: #f8f6f4;
  }

  &:active {
    background: #eeebe8;
  }
`;

const outlineDisabledStyles = css`
  border-radius: 8px;
  border: 1px solid var(--Grayscale-20, #e4e4e4);
  background: var(--Grayscale-10, #f0f0f0);
  color: #c6c6c6;

  &:hover,
  &:active {
  }
`;

const ghostStyles = css`
  display: inline-flex;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;

  border-radius: 8px;
  border: 1px solid var(--Grayscale-20, #e4e4e4);
  background: #fff;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  &:active {
    transform: scale(0.98);
    background: rgba(0, 0, 0, 0.08);
  }
`;

const ghostDisabledStyles = css`
  border-radius: 8px;
  border: 1px solid var(--Grayscale-20, #e4e4e4);
  background: var(--Grayscale-10, #f0f0f0);
`;

const lightStyles = css`
  background: transparent;
  color: var(--point-color);

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  &:active {
    transform: scale(0.98);
    background: rgba(0, 0, 0, 0.08);
  }
`;

const getButtonStyles = ({
  type,
  disabled,
  size,
}: {
  type: ButtonType;
  disabled?: boolean;
  size?: ButtonSize;
}) => {
  switch (type) {
    case 'primary':
      return disabled ? primaryDisabledStyles : primaryStyles;
    case 'outline':
      return disabled ? outlineDisabledStyles : outlineStyles;
    case 'ghost':
      return disabled ? ghostDisabledStyles : ghostStyles;
    case 'light':
      return lightStyles;
    default:
      return null;
  }
};

const StyledButton = styled.div<{ type: ButtonType; disabled?: boolean; size?: ButtonSize }>`
  justify-content: center;
  display: inline-flex;
  align-items: center;
  padding: 0 16px;
  height: ${({ size }) => (size === 'small' ? '40px' : '48px')};
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  ${({ type, disabled, size }) => getButtonStyles({ type, disabled, size })}
`;
