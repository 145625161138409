import { toast } from 'react-toastify';

declare global {
  interface Window {
    naver: any;
  }
}
const { naver } = window;

export const getGeolocation = async (address: string) => {
  return new Promise((resolve, reject) => {
    naver.maps.Service.geocode(
      {
        address,
      },
      (status: any, response: any) => {
        if (status !== naver.maps.Service.Status.OK) {
          toast.error('지도 취득에 실패했습니다.');
          reject(new Error('Geocoding failed'));
          return;
        }
        const { items } = response.result;
        if (items.length === 0) {
          resolve(null);
          return;
        }
        resolve({
          x: Number(items[0].point.x),
          y: Number(items[0].point.y),
        });
      },
    );
  });
};
