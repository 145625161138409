import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUserInfo } from 'providers/UserInfoProvider';
import { getFullName } from 'libs/helper/utils';
import ServiceHelper from 'stores/ServiceHelper';
import { MembershipPolicyDto } from 'services/data-contracts';
import { MembershipBadge } from './MembershipBadge';
import { MembershipProgress } from './MembershipProgress';
import { useStoreWrapper } from 'stores/StoreHelper';
import { Button, useMediaContext } from 'saladsoft-primitive';
import { useTranslation } from 'react-i18next';

// 내 정보(투숙 횟수, 숙박 일수) 가져오기
export const MembershipStatus = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaContext();
  const { myAccount } = useUserInfo();
  const { uiStore } = useStoreWrapper();
  const [membershipPolicy, setMembershipPolicy] = useState<MembershipPolicyDto[]>([]);
  const [myAward, setMyAward] = useState<MembershipPolicyDto | null>(null);

  useEffect(() => {
    const membershipPolicy = async () => {
      const response = await ServiceHelper?.policyService?.getMembershipPolicies();
      if (response?.data) {
        setMembershipPolicy(response.data);
        const myAward =
          response.data.find((policy) => policy.level === myAccount?.membershipLevel) || null;
        setMyAward(myAward);
      }
    };
    membershipPolicy();
  }, []);

  return (
    <StatusContainer>
      {isMobile && (
        <ModalHeader>
          <HeaderTitle>{t('membershipLevel')}</HeaderTitle>
          <CloseIcon onClick={() => uiStore?.universalModal.close()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M18.1226 6.37793L6.37756 18.1229"
                stroke="#1D1D1D"
                stroke-width="1.6"
                stroke-linecap="round"
              />
              <path
                d="M6.37744 6.37793L18.1224 18.1229"
                stroke="#1D1D1D"
                stroke-width="1.6"
                stroke-linecap="round"
              />
            </svg>
          </CloseIcon>
        </ModalHeader>
      )}

      <StatusCard>
        <WelcomeText>
          {t('membershipLevelFull', {
            name: getFullName(myAccount?.profile),
            level: myAccount?.membershipLevel,
          })}
        </WelcomeText>
        <Divider
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/69dc00cde91cef6d90eff48168d73d7be2e65f2dd34c3fb1d386f764d8b46699?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&"
          alt=""
          aria-hidden="true"
        />
        <MembershipBadge
          level={myAccount?.membershipLevel || ''}
          stays={myAward?.stayCount || 0}
          nights={myAward?.stayNightCount || 0}
          rewardPercentage={myAward?.pointRate || 0}
        />
      </StatusCard>
      <MembershipProgress currentLevel={myAccount?.membershipLevel || ''} stays={0} nights={0} />
      {/* {isMobile && isMobile && <CloseButton>{t('close')}</CloseButton>} */}
      {isMobile && (
        <CloseButton onClick={() => uiStore?.universalModal.close()}>{t('close')}</CloseButton>
      )}
    </StatusContainer>
  );
};

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 8px;
  width: 100%;
`;

const HeaderTitle = styled.h1`
  color: var(--Grayscale-90, #1d1d1d);
  font-family: Pretendard;
  font-size: 19px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.047px;
`;

const CloseIcon = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled(Button)`
  display: flex;
  width: 100%;
  height: 48px;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #43433c !important;
  margin-top: 32px;
  cursor: pointer;

  color: var(--Grayscale-0, #fff);
  text-align: center;
  font:
    600 15px/150% Pretendard,
    sans-serif;
  letter-spacing: -0.037px;
`;

const StatusContainer = styled.main`
  display: flex;
  max-width: 640px;
  flex-direction: column;
  padding: 8px 16px 40px;
`;

const StatusCard = styled.section`
  border-radius: 8px;
  background: var(--Grayscale-5, #f8f8f8);
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  padding: 20px 16px 16px;
`;

const WelcomeText = styled.h1`
  color: var(--Grayscale-90, #1d1d1d);
  margin-bottom: 16px;
  letter-spacing: -0.04px;
  font:
    500 15px Pretendard,
    sans-serif;
`;

const MemberName = styled.span`
  color: var(--Grayscale-90, #1d1d1d);
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.037px;
`;

const MemberLevel = styled.span`
  color: var(--Grayscale-90, #1d1d1d);
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.037px;
`;

const Divider = styled.img`
  /* aspect-ratio: 333.33; */
  object-fit: contain;
  object-position: center;
  width: 100%;
  stroke-width: 1.2px;
  stroke: var(--Grayscale-20, #e4e4e4);
  /* margin-top: 16px; */
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
