import styled from 'styled-components';
import { CompanyInfoItem } from './types';
import React, { useTransition } from 'react';
import { useTranslation } from 'react-i18next';
interface InfoRowProps {
  item: CompanyInfoItem;
}

export const InfoRow = ({ item }: InfoRowProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Label>{t(item.label)}</Label>
      <Value>{t(item.value)}</Value>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 8px;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 5px;
  &:first-child {
    margin-top: 0;
  }
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Label = styled.dt`
  color: var(--92897-b, #92897b);
  font-size: 13px;
`;

const Value = styled.dd`
  color: #c1bab6;
  flex: 1;
  font-size: 13px;
  margin: 0;
`;
