import React, { useState } from 'react';
import { Row } from 'saladsoft-primitive';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Popover } from '@mui/material';
import { useStoreWrapper } from 'stores/StoreHelper';
import BodyText from './BodyText';

interface PromotionFilterPorps {
  onSort: (sort: string[]) => void;
  isMobile?: boolean;
}

const PromotionFilter = ({ onSort, isMobile }: PromotionFilterPorps) => {
  const { t } = useTranslation();
  const { uiStore } = useStoreWrapper();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [sortType, setSortType] = useState<'newest' | 'oldest'>('newest');

  const handleClickSort = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isMobile) {
      uiStore?.universalModal.show({
        title: t('sort'),
        modalOption: {
          style: {
            width: 600,
            height: '100%',
            maxHeight: '80vh',
          },
        },
        children: (
          <ModalContainer>
            <ModalContent>
              <ModalSortBoxStyle>
                <HeaderContainer>
                  <HeaderTitle>{t('sort')}</HeaderTitle>
                  <CloseButton
                    onClick={() => {
                      uiStore?.universalModal.close();
                    }}
                  >
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/2e3a6d5039433b65ac03f38e1df15128101fd433cd338d57b88009bb05536059?placeholderIfAbsent=true&apiKey=694b79be46254ffd8018f6be6c4bbc2d"
                      alt="Close"
                    />
                  </CloseButton>
                </HeaderContainer>
                <div
                  className={`sort-option ${sortType === 'newest' ? 'active' : ''}`}
                  onClick={() => {
                    setSortType('newest');
                    const sortParam = ['createdAt,desc'];
                    onSort(sortParam);
                    uiStore?.universalModal.close();
                  }}
                >
                  <BodyText type="F">{t('newest')}</BodyText>
                </div>
                <div
                  className={`sort-option ${sortType === 'oldest' ? 'active' : ''}`}
                  onClick={() => {
                    setSortType('oldest');
                    const sortParam = ['createdAt,asc'];
                    onSort(sortParam);
                    uiStore?.universalModal.close();
                  }}
                >
                  <BodyText type="F">{t('oldest')}</BodyText>
                </div>
              </ModalSortBoxStyle>
            </ModalContent>
          </ModalContainer>
        ),
      });
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleSort = (type: 'newest' | 'oldest') => {
    setSortType(type);
    const sortParam = type === 'newest' ? ['createdAt,desc'] : ['createdAt,asc'];
    onSort(sortParam);
    handleClosePopover();
  };

  const open = Boolean(anchorEl);
  const displaySortText = sortType === 'newest' ? t('newest') : t('oldest');

  return (
    <FilterContainer>
      <Row gap={4} onClick={handleClickSort}>
        <img src={`/images/sort_newest.svg`} alt="sort" />
        <BodyText type="F" style={{ color: '#1D1D1D' }}>
          {displaySortText}
        </BodyText>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
          <path
            d="M2.4375 3.9375L6.1875 8.0625L9.9375 3.9375"
            stroke="#1D1D1D"
            strokeWidth="1.1"
            strokeLinecap="round"
          />
        </svg>
      </Row>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '& .MuiPaper-root': {
            boxShadow: 'none',
            overflow: 'visible',
          },
        }}
      >
        <SortBoxStyle>
          <div
            className={`sort-option ${sortType === 'newest' ? 'active' : ''}`}
            onClick={() => handleSort('newest')}
          >
            <BodyText type="F">{t('newest')}</BodyText>
          </div>
          <div
            className={`sort-option ${sortType === 'oldest' ? 'active' : ''}`}
            onClick={() => handleSort('oldest')}
          >
            <BodyText type="F">{t('oldest')}</BodyText>
          </div>
        </SortBoxStyle>
      </Popover>
    </FilterContainer>
  );
};

const FilterContainer = styled.div`
  cursor: pointer;
`;

const SortBoxStyle = styled.div`
  display: inline-flex;
  flex-direction: column;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15) !important;
  background-color: #fff;

  border-radius: 12px;
  padding: 8px 0;
  min-width: 160px;

  .sort-option {
    padding: 16px 20px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: #f8f8f8;
    }

    &.active {
      font-weight: 600;

      &::after {
        content: '';
        width: 24px;
        height: 24px;
        background-image: url('/images/filter-checked.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalContent = styled.div`
  padding-bottom: 64px;
`;

const HeaderContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px;
`;

const HeaderTitle = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  font-family: Pretendard;
  font-size: 19px;
  font-weight: 600;
  line-height: 150%;
  text-align: center;
  font-style: normal;
  letter-spacing: -0.047px;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  &:focus {
    outline: none;
  }
  img {
    width: 24px;
    height: 24px;
  }
`;

const ModalSortBoxStyle = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 8px 0;
  border-radius: 12px 12px 0px 0px;

  .sort-option {
    padding: 16px 20px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.active {
      font-weight: 600;

      &::after {
        content: '';
        width: 24px;
        height: 24px;
        background-image: url('/images/filter-checked.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
`;

export default PromotionFilter;
