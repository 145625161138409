import BookPage from 'pages/BookPage';
import DetailPage from 'pages/DetailPage';
import EmailSignupPage from 'pages/EmailSignupPage';
import GuestReservationPage from 'pages/GuestReservationPage';
import HomePage from 'pages/HomePage';
import LoginPage from 'pages/LoginPage';
import MyInfo from 'pages/MyInfo';
import MyPage from 'pages/MyPage';
import MyReservationsPage from 'pages/MyReservationsPage';
import PaymentSuccessPage from 'pages/PaymentSuccessPage';
import PromotionPage from 'pages/PromotionPage';
import ReservationCompletePage from 'pages/ReservationCompletePage';
import ReservationInfoPage from 'pages/ReservationInfo';
import SearchPage from 'pages/SearchPage';
import SignupComplete from 'pages/SignupComplete';
import { RoutePropInterface } from 'types/CommonTypes';
import SignupPage from 'pages/SignupPage';
import SocialRedirect from 'components/pages/auth/redirect/SocialRedirect';
import PromotionDetailPage from 'pages/PromotionDetailPage';
import FailPage from 'pages/FailPage';
import SearchHanokPage from 'pages/SearchHanokPage';
import InvestorPage from 'pages/InvestorPage';
import ReservationCancelPage from 'pages/ReservationCancel';
import ReservationCancelSuccessPage from 'pages/ReservationCancelSuccessPage';

const mainRoutes: RoutePropInterface[] = [
  {
    path: '/:locale?/',
    name: '',
    element: HomePage,
    isPublic: true,
    useNav: true,
    showTab: true
  },
  {
    path: '/:locale?/search',
    name: 'search',
    element: SearchPage,
    isPublic: true,
    useNav: true,
    showTab: true
  },
  {
    path: '/:locale?/searchhanok',
    name: 'searchhanok',
    element: SearchHanokPage,
    isPublic: true,
    useNav: true,
  },
  {
    path: '/:locale?/listing/:listingId',
    name: '',
    element: DetailPage,
    isPublic: true,
    useNav: true,
    showTab: false,
    useBusinessInfo: true,
  },
  {
    path: '/:locale?/book/:listingId',
    name: '',
    element: BookPage,
    isPublic: true,
    useNav: true,
    showGnb: false,
    useBusinessInfo: true,
  },
  {
    path: '/:locale?/reservation',
    name: 'reservation',
    element: HomePage,
    isPublic: true,
    useNav: true,
  },
  {
    path: '/:locale?/guest-reservation',
    name: 'guestReservation',
    element: GuestReservationPage,
    isPublic: true,
    useNav: true,
    showGnb: false,
  },
  {
    path: '/:locale?/my-reservations',
    name: 'my-reservations',
    element: MyReservationsPage,
    isPublic: true,
    useNav: true,
    showTab: false,
  },
  {
    path: '/:locale?/my-reservations/:reservationId',
    name: 'my-reservations',
    element: ReservationInfoPage,
    isPublic: true,
    useNav: true,
    showGnb: false,
    showTab: false,
  },
  {
    path: '/:locale?/my-reservations/:reservationId/cancel',
    name: 'my-reservations',
    element: ReservationCancelPage,
    isPublic: true,
    useNav: true,
  },
  {
    path: '/:locale?/my-reservations/:reservationId/cancel/success',
    name: 'my-reservations',
    element: ReservationCancelSuccessPage,
    isPublic: true,
    useNav: true,
  },
  {
    path: '/:locale?/promotion',
    name: 'promotion',
    element: PromotionPage,
    isPublic: true,
    useNav: true,
    showTab: false,
  },
  {
    path: '/:locale?/promotion/:promotionId',
    name: 'promotion-detail',
    element: PromotionDetailPage,
    isPublic: true,
    useNav: true,
    showTab: false,
  },
  {
    path: '/:locale?/my-page',
    name: 'my-page',
    element: MyPage,
    isPublic: true,
    useNav: true,
    showTab: true,
  },
  {
    path: '/:locale?/my-info',
    name: 'my-info',
    element: MyInfo,
    isPublic: true,
    useNav: true,
  },
  {
    path: '/:locale?/my-info/:tabName',
    name: 'my-info',
    element: MyInfo,
    isPublic: true,
    useNav: true,
    showGnb: false,

  },
  {
    path: '/:locale?/my-info/:tabName/:noticeId',
    name: 'my-info',
    element: MyInfo,
    isPublic: true,
    useNav: true,
  },
  {
    path: '/:locale?/payment-success',
    name: 'payment-success',
    element: PaymentSuccessPage,
    isPublic: true,
    useNav: true,
  },
  {
    path: '/:locale?/reservation-complete',
    name: 'reservation-complete',
    element: ReservationCompletePage,
    isPublic: true,
    useNav: true,
  },
  {
    path: '/:locale?/email-signup',
    name: 'email-signup',
    element: EmailSignupPage,
    isPublic: true,
    useNav: true,
  },
  {
    path: '/:locale?/signup-complete',
    name: 'signup-complete',
    element: SignupComplete,
    isPublic: true,
    useNav: true,
  },
  {
    path: '/:locale?/login',
    name: 'login',
    element: LoginPage,
    isPublic: true,
    useNav: true,
    showGnb: false,
    showTab: false,
  },
  {
    path: '/:locale?/signup',
    name: 'signup',
    element: SignupPage,
    isPublic: true,
    useNav: true,
  },
  {
    path: '/:locale?/auth/login/kakao',
    name: 'redirect-social',
    element: SocialRedirect,
    isPublic: true,
    useNav: true,
  },
  {
    path: '/:locale?/auth/login/google',
    name: 'redirect-social',
    element: SocialRedirect,
    isPublic: true,
    useNav: true,
  },
  // 테스트
  {
    path: '/:locale?/fail',
    name: 'fail',
    element: FailPage,
    isPublic: true,
    useNav: true,
  },
  {
    path: '/:locale?/investor',
    name: 'investor',
    element: InvestorPage,
    isPublic: true,
    useNav: true,
  },

];

export default mainRoutes;
