import * as React from 'react';
import styled from 'styled-components';
import { useEffect } from 'react';
import { MembershipProgressProps } from './types';
import { usePolicy } from 'providers/PolicyProvider';
import { useUserInfo } from 'providers/UserInfoProvider';
import { LevelIndicator } from '../../membershipLevel/LevelIndicator';
import { useTranslation } from 'react-i18next';
export const MembershipProgress: React.FC<MembershipProgressProps> = ({
  currentLevel,
  stays,
  nights,
}) => {
  const { t } = useTranslation();
  const { membershipPolicy } = usePolicy();
  const { myAccount } = useUserInfo();


  // 다음 등급에 해당하는 멤버십 정책 정보 가져오기
  const getNextLevelPolicy = () => {
    const levels = ['CLASSIC', 'SILVER', 'GOLD', 'PLATINUM'];
    const currentIndex = levels.indexOf(currentLevel);

    if (currentLevel === 'PLATINUM') {
      return null;
    }

    const nextLevel = levels[currentIndex + 1];
    return membershipPolicy.find((policy) => policy.level === nextLevel);
  };

  const nextLevelPolicy = getNextLevelPolicy();
  const currentLevelPolicy = membershipPolicy.find((policy) => policy.level === currentLevel);

  return (
    <Container>
      <LevelIndicator currentLevel={currentLevel} />
      <ProgressNote>
        {currentLevel === 'PLATINUM' ? (
          <>
            *{' '}
            {t('platinumMaintenance', {
              stayCount: currentLevelPolicy?.stayCount,
              nightCount: currentLevelPolicy?.stayNightCount,
            })}
          </>
        ) : (
          <>
            *{' '}
            {t('nextLevelAchievement', {
              stayCount: nextLevelPolicy?.stayCount,
              nightCount: nextLevelPolicy?.stayNightCount,
              level: nextLevelPolicy?.level,
            })}
          </>
        )}
      </ProgressNote>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin-top: 32px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ProgressNote = styled.div`
  color: var(--Grayscale-60, #717171);
  letter-spacing: -0.03px;
  margin-top: 32px;
  font:
    400 12px Pretendard,
    sans-serif;
`;

const GoldText = styled.span`
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  color: rgba(113, 113, 113, 1);
`;
