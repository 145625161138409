import * as React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { CouponDto, ListingPriceDto, OptionDto, VoucherDto } from 'services/data-contracts';
import { NumberFormat, Row } from 'saladsoft-primitive';
import { useEffect, useState } from 'react';
import { format } from 'react-string-format';
import { useUserInfo } from 'providers/UserInfoProvider';
import { OptionModel } from 'types/CommonTypes';
import ServiceHelper from 'stores/ServiceHelper';

function PriceDetail({
  priceInfo,
  point,
  voucher,
  coupon,
  changePrice,
  options,
}: {
  priceInfo?: ListingPriceDto;
  point?: number;
  voucher?: VoucherDto;
  coupon?: CouponDto;
  changePrice: (price: number) => void;
  options: OptionModel[];
}) {
  const { t } = useTranslation();
  const { loginStatus } = useUserInfo();

  const [pricePerNight, setPricePerNight] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const [voucherAmount, setVoucherAmount] = useState<number>(0);
  const [couponAmount, setCouponAmount] = useState<number>(0);
  const [pointAmount, setPointAmount] = useState<number>(0);

  const [additionalOptionsAmount, setAdditionalOptionsAmount] = useState<number>(0);
  const [totalDiscount, setTotalDiscount] = useState<number>(0);
  const [isDiscountOpen, setIsDiscountOpen] = useState<boolean>(false);
  const [rewardPoints, setRewardPoints] = useState<number>(0);

  const formatPrice = (price: number) => {
    return price.toLocaleString('ko-KR');
  };

  // 1박 가격 계산
  const FormatPricePerNight = () => {
    if (priceInfo?.price && priceInfo?.nights && priceInfo.nights > 0) {
      const pricePerNight = Math.floor(priceInfo.price / priceInfo.nights);
      return pricePerNight.toLocaleString('ko-KR');
    }
    return '0';
  };

  useEffect(() => {
    setPricePerNight(priceInfo?.price ?? 0 / (priceInfo?.nights ?? 1));
    setTotalPrice(priceInfo?.totalPrice ?? 0);
  }, [priceInfo]);

  useEffect(() => {
    let voucherDiscount = 0;
    let couponDiscount = 0;
    let pointDiscount = point || 0;

    if (voucher) {
      if (voucher.discountType === 'FIXED') {
        voucherDiscount = voucher.price ?? 0;
      } else if (voucher.discountType === 'PERCENT') {
        voucherDiscount =
          (((priceInfo?.totalPrice ?? 0) + (additionalOptionsAmount ?? 0)) * (voucher.price ?? 0)) /
          100;
      }
    }

    if (coupon) {
      if (coupon.discountType === 'FIXED') {
        couponDiscount = coupon.price ?? 0;
      } else if (coupon.discountType === 'PERCENT') {
        couponDiscount = ((priceInfo?.totalPrice ?? 0) * (coupon.price ?? 0)) / 100;
      }
    }

    setVoucherAmount(voucherDiscount);
    setCouponAmount(couponDiscount);
    setPointAmount(pointDiscount);

    // 총 할인 금액 계산 및 적용
    const totalDiscount = pointDiscount + voucherDiscount + couponDiscount;
    setTotalDiscount(totalDiscount);
    setTotalPrice((priceInfo?.totalPrice ?? 0) - totalDiscount + additionalOptionsAmount);
  }, [point, voucher, coupon, priceInfo?.totalPrice, additionalOptionsAmount]);

  useEffect(() => {
    changePrice(totalPrice);

    // 예상 적립 포인트 (회원적립)
    if (loginStatus && totalPrice > 0) {
      ServiceHelper?.myReservationService
        ?.getCalculateRewardPoints({
          totalAmount: totalPrice,
        })
        .then((res) => {
          setRewardPoints(res.data);
        });
    }
  }, [totalPrice, loginStatus]);

  useEffect(() => {
    setAdditionalOptionsAmount(
      options.reduce((acc, option) => acc + (option?.price ?? 0) * (option?.qty ?? 0), 0),
    );
  }, [options]);

  const ChevronIcon = ({ isOpen }: { isOpen: boolean }) => (
    <StyledSvgIcon
      isOpen={isOpen}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path d="M5 7.5L10 13L15 7.5" stroke="#555555" strokeWidth="1.3" strokeLinecap="round" />
    </StyledSvgIcon>
  );

  const getDDay = (arrivalDate: string) => {
    const startDate = new Date(arrivalDate);
    const today = new Date();
    const diffDays = Math.ceil((startDate.getTime() - today.getTime()) / (1000 * 3600 * 24));
    return `D-${diffDays}`;
  };

  return (
    <Container>
      <PriceList>
        <PriceItem>
          {/* <Label>{format(t('Nightly Rate'), `₩${formatPrice(pricePerNight)}`)}</Label> */}
          <Label>{format(t('Nightly Rate'), `₩${FormatPricePerNight()}`)}</Label>
          <Amount>
            <NumberFormat
              style={{ justifyContent: 'flex-end' }}
              value={priceInfo?.price ?? 0}
              prefix="₩"
            />
          </Amount>
        </PriceItem>
        <PriceItem>
          <Label>{t('Additional Guest Fee')}</Label>
          <Amount>
            <NumberFormat
              style={{ justifyContent: 'flex-end' }}
              value={priceInfo?.extraPersonPrice ?? 0}
              prefix="₩"
            />
          </Amount>
        </PriceItem>
        <PriceItem>
          <Label>{t('Room Maintenance Fee')}</Label>
          <Amount>
            <NumberFormat
              style={{ justifyContent: 'flex-end' }}
              value={priceInfo?.cleaningFee ?? 0}
              prefix="₩"
            />
          </Amount>
        </PriceItem>
        <PriceItem>
          <Label>{t('Additional Options')}</Label>
          <Amount>
            <NumberFormat
              style={{ justifyContent: 'flex-end' }}
              value={additionalOptionsAmount ?? 0}
              prefix="₩"
            />
          </Amount>
        </PriceItem>
        {options?.length > 0 && (
          <Divider>
            {options?.map((option) => (
              <Row justifyContent="space-between">
                <div>
                  {option?.name!.length > 20 ? `${option?.name!.slice(0, 20)}...` : option.name}
                </div>
                <div>{option?.qty ?? 1}</div>
              </Row>
            ))}
          </Divider>
        )}
        <DiscountSection>
          <PriceItem onClick={() => setIsDiscountOpen(!isDiscountOpen)}>
            <Label>{t('Total Discount')}</Label>
            <DiscountAmount>
              <Label>₩{formatPrice(totalDiscount)}</Label>
              <ChevronIcon isOpen={isDiscountOpen} />
            </DiscountAmount>
          </PriceItem>
          <DiscountDetails isOpen={isDiscountOpen}>
            {/* 포인트 사용 */}
            <DiscountItem>
              <SubLabel>{t('Point Used')}</SubLabel>
              <SubAmount>₩{formatPrice(pointAmount)}</SubAmount>
            </DiscountItem>
            <DiscountItem>
              <SubLabel>{t('Voucher Used')}</SubLabel>
              <SubAmount>₩{formatPrice(voucherAmount)}</SubAmount>
            </DiscountItem>
            <DiscountItem>
              <SubLabel>{t('Coupon Used')}</SubLabel>
              <SubAmount>₩{formatPrice(couponAmount)}</SubAmount>
            </DiscountItem>
          </DiscountDetails>
          {loginStatus && (
            <PriceItem>
              <Label>{t('Member Reward Points')}</Label>
              <DiscountAmount>
                <SubAmount>{formatPrice(rewardPoints)}P</SubAmount>
                <Label>{t('to be earned')}</Label>
              </DiscountAmount>
            </PriceItem>
          )}
        </DiscountSection>
        {/* {loginStatus && (
          <PriceItem>
            <Label>{t('Member Points')}</Label>
            <PointAmount>{t('20,000P to be earned')}</PointAmount>
          </PriceItem>
        )} */}
      </PriceList>
      <DashHr />
      <TotalSection>
        <TotalLabel>
          <Title>{t('Total Payment')}</Title>
          <VatText>{t('*VAT Included')}</VatText>
        </TotalLabel>
        <TotalAmount>
          <Price>
            <NumberFormat value={totalPrice} />
          </Price>
          <Currency>{t('won')}</Currency>
        </TotalAmount>
      </TotalSection>
    </Container>
  );
}
export default PriceDetail;

// 공통 스타일
const flexRow = css`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 32px;
`;

const labelStyle = css`
  font-weight: 500;
  align-self: stretch;
  margin: auto 0;
  color: var(--Grayscale-70, #555);
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.037px;
`;

const amountStyle = css`
  text-align: right;
  align-self: stretch;
  flex: 1;
  margin: auto 0;
`;

// 스타일 컴포넌트들
const Container = styled.div`
  align-self: stretch;
  border-radius: 0px 0px 12px 12px;
  border-top: 1px solid var(--Grayscale-70, #555);
  background: var(--Grayscale-5, #f8f8f8);
  background-color: var(--Grayscale-5, #f8f8f8);
  display: flex;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  justify-content: start;
  padding: 32px 20px;
`;

const PriceList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 15px;
  letter-spacing: -0.04px;
  justify-content: start;
`;

const PriceItem = styled.div`
  ${flexRow}
  justify-content: space-between;
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
`;

const Label = styled.div`
  ${labelStyle}
`;

const Amount = styled.div`
  ${amountStyle}
  font-weight: 700;
`;

const Divider = styled.div`
  border-radius: 8px;
  display: flex;
  margin-top: 8px;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  justify-content: start;
  gap: 3px;
  color: var(--Grayscale-50, #8e8e8e);
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.032px;
`;

const DiscountSection = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  font-size: 14px;
  justify-content: start;
`;

const DiscountAmount = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 3px;
`;

const StyledSvgIcon = styled.svg<{ isOpen: boolean }>`
  transition: transform 0.3s ease;
  transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  cursor: pointer;
`;

// const DiscountIcon = styled.img`
//   aspect-ratio: 1;
//   object-fit: contain;
//   object-position: center;
//   width: 20px;
//   align-self: stretch;
//   margin: auto 0;
// `;

const DiscountDetails = styled.div<{ isOpen: boolean }>`
  padding: 15px;
  display: flex;
  margin-top: 8px;
  width: 100%;
  flex-direction: column;
  font-size: 14px;
  justify-content: start;
  background: #f0f0f0;
  gap: 5px;
  border-radius: 10px;
  overflow: hidden;
  max-height: ${(props) => (props.isOpen ? '100%' : '0')};
  padding: ${(props) => (props.isOpen ? '15px' : '0')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
`;

const DiscountItem = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 32px;
  justify-content: start;
`;

const SubLabel = styled.div`
  ${labelStyle}
  color: var(--Grayscale-50, #8e8e8e);
`;

const SubAmount = styled.div`
  ${amountStyle}
`;

const PointAmount = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  text-align: right;
  font-weight: 400;
  align-self: stretch;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
`;

const TotalSection = styled.div`
  display: flex;
  margin-top: 32px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const TotalLabel = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: start;
  margin: auto 0;
`;

const Title = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.04px;
  align-self: stretch;
  margin: auto 0;
`;

const VatText = styled.div`
  color: var(--Grayscale-50, #8e8e8e);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.03px;
  align-self: stretch;
  margin: auto 0;
`;

const TotalAmount = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  color: var(--sub-color, var(--Color, #1c51b6));
  font-weight: 600;
  white-space: nowrap;
  justify-content: end;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
`;

const Price = styled.div`
  font-size: 24px;
  letter-spacing: -0.06px;
  align-self: stretch;
  margin: auto 0;
`;

const Currency = styled.div`
  font-size: 17px;
  letter-spacing: -0.04px;
  align-self: stretch;
  margin: auto 0;
`;

const DashHr = styled.div`
  border-bottom: 1px dashed var(--Grayscale-70, #555);
  margin-top: 20px;
`;
