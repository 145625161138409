import ReservationConfirmation from 'components/pages/reservationComplete/reservations/ReservationConfirmation';
import PageContainer from 'containers/pc/PageContainer';
import React, { useEffect, useState } from 'react';
import { getUrlParam } from 'libs/helper/utils';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import BTButton from 'components/input/BTButton';
import styled from 'styled-components';
import Subtitle from 'components/commons/Subtitle';
import BodyText from 'components/commons/BodyText';
import { getErrorMessage } from 'libs/helper/errorCodeMapper';

const ReservationCompletePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const code = getUrlParam('code');
  const [errorCode, setErrorCode] = useState('');

  // useEffect(() => {
  //   if (code) {
  //     switch (code) {
  //       case 'er_0001':
  //         setErrorCode(t('ER_0001'));
  //         break;
  //       default:
  //         setErrorCode(code);
  //     }
  //   }
  // }, []);

  useEffect(() => {
    if (code) {
      setErrorCode(getErrorMessage(code, t));
    }
  }, [code, t]);

  return (
    <PageContainer>
      <FailPageWrapper className="page-container">
        <TextContainer>
          <Subtitle type="A" style={{ fontSize: '24px' }}>
            {t('reservationFailed')}
          </Subtitle>
          <BodyText type="B" style={{ color: 'var(--Grayscale-50, #8E8E8E)' }}>
            {t('cancellationReason')}: {errorCode}
          </BodyText>
        </TextContainer>
        <ButtonWrapper>
          <BTButton
            style={{ width: '100%' }}
            onClick={() => {
              navigate('/');
            }}
          >
            {t('goHome')}
          </BTButton>
        </ButtonWrapper>
      </FailPageWrapper>
    </PageContainer>
  );
};

const FailPageWrapper = styled.div`
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 16px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  text-align: left;
`;

const ButtonWrapper = styled.div`
  width: 200px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  @media ${(props: any) => props.theme.media.mobile} {
    width: 100%;
  }
`;

export default ReservationCompletePage;
