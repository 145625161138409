export const HIDDEN_GNB_PATH = [
  '/my-info',
  '/my-info/points',
  '/my-info/guide',
  '/my-info/wishlist',
  '/my-info/notice',
  '/my-info/coupon',
  '/my-info/membership',
  '/book',
  '/ongoing-reservation-info', // 임시 경로 (예약 및 결제 완료)
] as const;

export const HideGnb = (pathname: string): boolean => {
  if (pathname.startsWith('/my-reservations/') && pathname !== '/my-reservations/') {
    return true;
  }
  return HIDDEN_GNB_PATH.some((path) => pathname.startsWith(path));
};
