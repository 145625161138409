import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ListingDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import { EmptyState } from 'components/commons/EmptyState';
import { AccommodationCard } from './components/AccommodationCard';
import useToken from 'hooks/useToken';
import { useNavigate } from 'react-router-dom';
import { useUserInfo } from 'providers/UserInfoProvider';
import { useTranslation } from 'react-i18next';
import { useMediaContext } from 'saladsoft-primitive';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import { useLayoutEffect } from 'react';

export const Wishlist = () => {
  const [wishList, setWishList] = useState<ListingDto[]>([]);
  const { loginStatus } = useUserInfo();
  const { validUser } = useToken();
  const navigate = useLocaleNavigate();
  const { i18n } = useTranslation();
  const { isMobile } = useMediaContext();
  const { t } = useTranslation();
  // 위시 리스트 조회
  const fetchWishList = async () => {
    try {
      const response = await ServiceHelper.myAccountService?.getFavoriteListings({
        locale: i18n.language,
      });
      if (response?.status === 200) {
        setWishList(response.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (!validUser() && loginStatus === false) {
      navigate('/login');
    } else {
      fetchWishList();
    }
  }, [loginStatus]);

  useLayoutEffect(() => {
    const footer = document.querySelector('.footer') as HTMLElement;
    if (footer) {
      footer.style.display = 'none';
    }
  }, []);

  return (
    <PageWrapper>
      {!isMobile && <PageTitle>{t('wishlist')}</PageTitle>}
      <PageContent>
        {wishList && wishList.length > 0 ? (
          <AccommodationList>
            {wishList.map((listing) => (
              <AccommodationCard
                favoriteItem={listing}
                key={listing.id}
                onFavoriteChange={fetchWishList}
              />
            ))}
          </AccommodationList>
        ) : (
          <EmptyState message={t('no wishlist')} />
        )}
      </PageContent>
    </PageWrapper>
  );
};

const PageWrapper = styled.main`
  background-color: var(--Grayscale-0, #fff);
  display: flex;
  max-width: 825px;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
`;

const PageTitle = styled.h1`
  font-size: 28px;
  background: var(--Grayscale-0, #fff);
  min-height: 48px;
  color: var(--Grayscale-90, #1d1d1d);
  letter-spacing: -0.07px;
  font:
    600 28px Pretendard,
    sans-serif;
`;

const PageContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const AccommodationList = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
