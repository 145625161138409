import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import BTButton from 'components/input/BTButton';
import { Row, useMediaContext } from 'saladsoft-primitive';
import { ListingDto, PromotionDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';
import { getUrlParam } from 'libs/helper/utils';
import ServiceHelper from 'stores/ServiceHelper';
import i18n from 'i18n';
import useLocaleNavigate from 'hooks/useLocaleNavigate';

import { observer } from 'mobx-react-lite';
import { useStoreWrapper } from 'stores/StoreHelper';
import PromotionItem from 'components/pages/listingPage/PromotionItem';

const PromotionList = observer(
  ({
    listings,
    hasMore,
    length,
  }: {
    listings?: ListingDto[];
    hasMore?: boolean;
    length?: number;
  }) => {
    const navigate = useLocaleNavigate();

    const [promotions, setPromotions] = useState<PromotionDto[]>([]);
    const fetchPromotions = () => {
      ServiceHelper.promotionService?.getPromotions({ locale: i18n.language }).then((res) => {
        setPromotions(res.data);
      });
    };

    useEffect(() => {
      fetchPromotions();
    }, []);

    const handleClickPromotion = (promotion: PromotionDto) => {
      navigate(`/promotion/${promotion.id}`);
    };

    return (
      <ListContainer>
        <ListWrapper className="page-container" style={{ zIndex: 0, position: 'relative', padding: '0 20px' }}>
          <Title1>EVENT &</Title1>
          <Title2>PROMOTION</Title2>
          <Row gap={32} justifyContent="flex-start" alignItems="flex-start" flexWrap="wrap">
            {promotions?.slice(0, length).map((promotion) => (
              <PromotionItem
                key={promotion.id}
                promotion={promotion}
                onClick={() => {
                  handleClickPromotion(promotion);
                }}
              />
            ))}
          </Row>
        </ListWrapper>
      </ListContainer>
    );
  },
);

const ListContainer = styled.div`
  padding: 32px;
  margin-top: 32px;
  background-color: #f8f8f8;
  position: relative;
  z-index: 0;

  @media ${(props) => props.theme.media.mobile} {
    padding: 16px 0;
  }
`;

const Title1 = styled.div`
  color: var(--1-f-1-e-1-d, #1f1e1d);
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 60px */
  letter-spacing: -0.1px;
`;

const Title2 = styled.div`
  position: relative;
  top: -10px;
  color: var(--92897-b, #92897b);
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 60px */
  letter-spacing: -0.1px;
  margin-bottom: 32px;
`;

const ListWrapper = styled.div`
  margin-top: 32px;
`;

export default PromotionList;
