import ChipButton from 'components/input/ChipButton';
import React, { useEffect, useState } from 'react';
import { Row } from 'saladsoft-primitive';
import ServiceHelper from 'stores/ServiceHelper';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { AmenityDto } from 'services/data-contracts';

const TagFilter = ({
  onChange,
  value,
}: {
  onChange: (selections: number[]) => void;
  value: number[];
}) => {
  interface AmenityModel extends AmenityDto {
    selected: boolean;
  }

  const [amenities, setAmenities] = useState<AmenityModel[]>([]);

  const [selections, setSelections] = useState<Array<{ id: string; selected: boolean }>>([]);

  const fetchOptions = () => {
    ServiceHelper?.amenityService?.getAmenitiesByFilter({ locale: i18n.language }).then((res) => {
      setAmenities(res.data?.map((r) => ({ ...r, selected: false })));
    });
  };

  useEffect(() => {
    fetchOptions();
  }, []);

  return (
    <Row justifyContent="flex-start" gap={10} flexWrap="wrap">
      {amenities?.map((amenity) => (
        <ChipButton
          key={amenity.id}
          id={amenity.id?.toString() || ''}
          label={amenity.name || ''}
          selected={value?.includes(amenity.id || 0)}
          onChange={(id, selected) => {
            if (id === '0') {
              setSelections([]);
              setAmenities(amenities.map((r) => ({ ...r, selected: r.id === 0 })));
              onChange([]);
              return;
            }

            if (id === '100' && selected) {
              const newAmenities = amenities.map((r) => ({ ...r, selected: r.id === 100 }));
              setAmenities(newAmenities);
              setSelections([{ id: '100', selected: true }]);
              onChange([100]);
              return;
            }

            const idNumber = Number(id);

            let newSelectedIds = [...(value || [])];

            if (selected) {
              if (newSelectedIds.includes(100)) {
                newSelectedIds = newSelectedIds.filter((item) => item !== 100);
              }
              if (!newSelectedIds.includes(idNumber)) {
                newSelectedIds.push(idNumber);
              }
            } else {
              newSelectedIds = newSelectedIds.filter((item) => item !== idNumber);
            }

            const newSelections = newSelectedIds.map((id) => ({
              id: id.toString(),
              selected: true,
            }));
            setSelections(newSelections);

            const newAmenities = amenities.map((r) => ({
              ...r,
              selected: newSelectedIds.includes(r.id || 0),
            }));
            setAmenities(newAmenities);

            onChange(newSelectedIds);
          }}
        />
      ))}
    </Row>
  );
};

export default TagFilter;
