import React, { useRef } from 'react';
import { motion, useInView } from 'framer-motion';

interface AnimateOnScrollProps {
    children: React.ReactNode;
    direction?: 'up' | 'down' | 'left' | 'right';
    delay?: number;
    duration?: number;
    amount?: number;
    once?: boolean;
    className?: string;
    style?: React.CSSProperties;
}

const AnimateOnScroll: React.FC<AnimateOnScrollProps> = ({
    children,
    direction = 'up',
    delay = 0,
    duration = 0.7,
    amount = 0.3,
    once = true,
    className,
    style,
}) => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once, amount });

    // 방향에 따른 초기 상태 설정
    const getInitialPosition = () => {
        switch (direction) {
            case 'up':
                return { opacity: 0, y: 50 };
            case 'down':
                return { opacity: 0, y: -50 };
            case 'left':
                return { opacity: 0, x: -100 };
            case 'right':
                return { opacity: 0, x: 100 };
            default:
                return { opacity: 0, y: 50 };
        }
    };

    // 방향에 따른 최종 상태 설정
    const getFinalPosition = () => {
        switch (direction) {
            case 'up':
            case 'down':
                return { opacity: 1, y: 0 };
            case 'left':
            case 'right':
                return { opacity: 1, x: 0 };
            default:
                return { opacity: 1, y: 0 };
        }
    };

    return (
        <motion.div
            ref={ref}
            className={className}
            style={style}
            initial={getInitialPosition()}
            animate={isInView ? getFinalPosition() : getInitialPosition()}
            transition={{
                duration,
                delay,
                type: "spring",
                stiffness: 50,
                damping: 15
            }}
        >
            {children}
        </motion.div>
    );
};

export default AnimateOnScroll; 