import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import BTButton from 'components/input/BTButton';
import { useTranslation } from 'react-i18next';
import { ListingDto } from 'services/data-contracts';
import ListingImageSwiper from './ListingImageSwiper';
import { useStoreWrapper } from 'stores/StoreHelper';
import { useMediaContext } from 'saladsoft-primitive';

const Detail = ({
  listing,
  onClickImage,
}: {
  listing?: ListingDto;
  onClickImage?: (index: number) => void;
}) => {
  const { t, i18n } = useTranslation();
  const { uiStore } = useStoreWrapper();
  const { isMobile } = useMediaContext();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  // const handleImageClick = (index: number) => {
  //   if (!isMobile) {
  //     openImageModal(index);
  //   }
  // };

  // const openImageModal = (index: number) => {
  //   uiStore?.modal.show({
  //     title: listing?.title || '',
  //     children: (
  //       <ModalImageContainer>
  //         <ListingImageSwiper listing={listing} />
  //       </ModalImageContainer>
  //     ),
  //     style: {
  //       width: '90vw',
  //       maxWidth: '1200px',
  //       height: '90vh',
  //       maxHeight: '90vh',
  //       overflow: 'hidden',
  //     },
  //   });
  // };

  return (
    <ListingSection>
      <GlobalStyle />
      <DetailWrapper>
        <DetailContents>
          <DetailLogo>
            <Detail2>BUTLER.LEE</Detail2>
          </DetailLogo>
          <Div121
            dangerouslySetInnerHTML={{
              __html: listing?.title?.replace(/\\r\\n/g, '<br/>') || '',
            }}
          />
        </DetailContents>
      </DetailWrapper>

      <div>
        <ListingImageSwiper images={listing?.images} onClick={onClickImage} useThumbnail={false} />
      </div>

      <div style={{ padding: '0 20px' }}>
        <div style={{ position: 'relative' }}>
          <ContentWrapper
            isOpen={isOpen}
            dangerouslySetInnerHTML={{ __html: listing?.desc || '' }}
          />
          {!isOpen && <Overlay />}
        </div>
        <BTButton
          type="outline"
          style={{ width: '100%', background: '#fff' }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? t('showLess') : t('showMore')}
        </BTButton>
      </div>
    </ListingSection>
  );
};

const ListingSection = styled.div``;

const GlobalStyle = createGlobalStyle`
  .swiper-scrollbar {
    opacity: 1 !important;
    bottom: 40px !important;
    background:rgba(255, 255, 255, 0.3) !important;
    height: 2px !important;
    width: 70% !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }
  .swiper-scrollbar-drag{
    background: #fff;
  }
`;

const DetailWrapper = styled.div`
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: var(--gray-gray-5, #f8f8f8);
  display: flex;
  gap: 10px;
  overflow: hidden;
  padding: 64px 20px;

  @media ${(props) => props.theme.media.mobile} {
    padding: 40px 20px;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    width: 100vw;
  }
`;

const DetailContents = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  flex-direction: column;
  margin: auto 0;
`;

const DetailLogo = styled.div`
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--92897-b, #92897b);
  border-bottom: 1px solid var(--92897-b, #92897b);
  align-self: center;
  display: flex;
  gap: 10px;
  color: var(--92897-b, #92897b);
  white-space: nowrap;
  padding: 0 4px;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Detail2 = styled.div`
  margin: auto 0;
  padding: 3px 0;
  color: var(--92897-b, #92897b);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;

  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div121 = styled.div`
  text-align: center;
  margin-top: 32px;
  color: var(--gray-gray-90, #1d1d1d);
  text-align: center;
  font-family: Pretendard;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  white-space: pre-line;
  letter-spacing: -0.055px;
  overflow-wrap: break-word;
  padding: 0 20px;
  font:
    400 22px / 33px Pretendard,
    sans-serif;

  @media ${(props) => props.theme.media.mobile} {
  }
`;

const ContentWrapper = styled.div<{ isOpen: boolean }>`
  margin: 62px 0;
  white-space: pre-line;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '200px')};
  overflow: hidden;
  @media ${(props) => props.theme.media.mobile} {
    margin: 55px 0 40px;
  }
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 90%);
`;

const ModalImageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export default Detail;
