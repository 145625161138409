"use client";
import * as React from "react";
import styled from "styled-components";

const HanokStaySection = () => {
  return (
    <StayContainer>
      <ContentWrapper>
        <PointWrapper>
          <PointNumber>Point 01</PointNumber>
          <PointLine
            src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/108811236426305ded130e5aa71d8b9a3f2e9889e2fb0fddb22aa1f8a04e7745?placeholderIfAbsent=true"
            alt=""
          />
        </PointWrapper>
        <StayTitle>도심 속 유일한 합법, 독채스테이</StayTitle>
      </ContentWrapper>
      <StayDescription>
        낡은 한옥을 한옥주와 함께 리모델링하고
        <br />
        도심 속 유일한 합법, 독채 스테이로 한옥체험업
        <br />
        허가를 취득합니다.
      </StayDescription>
    </StayContainer>
  );
};

const StayContainer = styled.div`
  max-width: 449px;
  padding: 0 20px;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const PointWrapper = styled.div`
  display: flex;
  width: 98px;
  flex-direction: column;
  align-items: stretch;
  font-family:
    Montserrat,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-size: 24px;
  color: #92897b;
  font-weight: 600;
  letter-spacing: -0.06px;
  justify-content: start;
`;

const PointNumber = styled.div`
  leading-trim: both;
  text-edge: cap;
  align-self: center;
`;

const PointLine = styled.img`
  aspect-ratio: 100;
  object-fit: contain;
  object-position: center;
  width: 100%;
  stroke-width: 1px;
  stroke: #92897b;
  margin-top: 10px;
`;

const StayTitle = styled.div`
  color: #1d1d1d;
  leading-trim: both;
  text-edge: cap;
  font-family:
    Pretendard,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: -0.09px;
  margin-top: 32px;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 24px;
  }
`;

const StayDescription = styled.div`
  color: #555;
  leading-trim: both;
  text-edge: cap;
  font-family:
    Pretendard,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.05px;
  margin-top: 46px;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 16px;
  }
`;

export default HanokStaySection;
