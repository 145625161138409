import React from 'react';
import { Row } from 'saladsoft-primitive';
import styled from 'styled-components';
import { TagDto } from 'services/data-contracts';

const TagList = ({
  tags,
  onClick,
}: {
  tags: TagDto[];
  onClick: (e: React.MouseEvent, tag: TagDto) => void;
}) => {
  return (
    <Row gap={3}>
      {tags.map((tag) => (
        <TagItem key={tag.id} onClick={(e) => onClick(e, tag)}>
          #{tag.name}
        </TagItem>
      ))}
    </Row>
  );
};

const TagItem = styled.div`
  display: flex;
  padding: 3px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 2px;
  background: var(--background_color, #f7f3ef);
  color: var(--92897-b, #92897b);
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.032px;
`;

export default TagList;
