import { styled } from '@mui/material';
import Section from 'components/layouts/Section';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LandmarkDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Scrollbar } from 'swiper/modules';
import { useStoreWrapper } from 'stores/StoreHelper';
import LandmarkCard from './LandmarkCard';
import LandmarkModal from './LandmarkModal';
import AllLandmarkModal from './AllLandmarkModal';
import { useMediaContext } from 'saladsoft-primitive';
const NearBy = ({ listingId }: { listingId?: string }) => {
  const { t } = useTranslation();
  const { uiStore } = useStoreWrapper();
  const { isMobile } = useMediaContext();
  const [landmarks, setLandmarks] = useState<LandmarkDto[]>([]);

  const fetchNearBy = (listingId: number) => {
    ServiceHelper.listingService
      ?.getLandmarksByListing(listingId, {
        locale: i18n.language,
      })
      .then((respont) => {
        if (respont) {
          setLandmarks(respont.data);
        }
      });
  };

  const handleClick = (landmark?: LandmarkDto) => {
    uiStore?.universalModal.show({
      title: landmark?.name,
      modalOption: { style: { width: 600, height: '100%', maxHeight: '80vh' } },
      children: <LandmarkModal landmark={landmark} />,
    });
  };

  const handleShowAllLandmark = () => {
    const mobileStyle = {
      width: '100%',
      height: '100%',
      maxHeight: '100%',
    };
    const pcStyle = {
      width: 600,
      height: '100%',
      maxHeight: '80vh',
    };
    uiStore?.modal.show({
      title: t('NearBy'),
      children: <AllLandmarkModal landmarks={landmarks} />,
      style: {
        ...(isMobile ? mobileStyle : pcStyle),
      },
    });
  };

  useEffect(() => {
    if (listingId) {
      const listingIdNumber = parseInt(listingId, 10);
      if (listingIdNumber) {
        fetchNearBy(listingIdNumber);
      }
    }
  }, [listingId]);

  return (
    <Section title={t('NearBy')} rightAction={<></>}>
      <Swiper
        spaceBetween={10}
        slidesPerView="auto"
        scrollbar={{
          hide: true,
        }}
        modules={[Scrollbar]}
      >
        {landmarks?.map((landmark) => (
          <SwiperSlide key={landmark.id} style={{ width: 'auto' }}>
            <div
              style={{
                width: 140,
                overflow: 'hidden',
                cursor: 'pointer',
              }}
            >
              <LandmarkCard
                landmark={landmark}
                onClick={() => {
                  handleClick(landmark);
                }}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {landmarks.length === 0 && (
        <div style={{ textAlign: 'center', padding: 20 }}>
          <div>{t('NoLandmarks')}</div>
        </div>
      )}
    </Section>
  );
};

export default NearBy;
