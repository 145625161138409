import * as React from 'react';
import styled from 'styled-components';
import { ParkingInfoProps } from '../types';
import { useTranslation } from 'react-i18next';

export const ParkingInfo: React.FC<ParkingInfoProps> = ({ hasParking }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <ParkingIcon
        src={
          hasParking
            ? 'https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/39630f1d12e854f12a44a13798bc70be17af9003884275bda337f8600ae6fcb3?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&'
            : 'https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/8bb4ea7326c543e151417fd400ca4203509820de9fde1b1ddc48270d48df1625?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&'
        }
        alt={hasParking ? t('parkingAvailable') : t('parkingUnavailable')}
      />
      <ParkingText>{hasParking ? t('parkingAvailable') : t('parkingUnavailable')}</ParkingText>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const ParkingIcon = styled.img`
  width: 16px;
  height: 16px;
`;

const ParkingText = styled.span`
  color: var(--Grayscale-70, #555);
  font-size: 13px;
  letter-spacing: -0.03px;
`;
