import BTButton from 'components/input/BTButton';
import i18n from 'i18n';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, useMediaContext } from 'saladsoft-primitive';
import { TermDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import { useStoreWrapper } from 'stores/StoreHelper';
import styled from 'styled-components';

const TermModal = ({
  type,
}: {
  type:
    | 'PRIVACY_POLICY'
    | 'FACILITY_USAGE_POLICY'
    | 'THIRD_PARTY_PRIVACY_POLICY'
    | 'MARKETING_AGREEMENT'
    | 'RESERVATION'
    | 'CHECKIN'
    | 'USAGE';
}) => {
  const { t } = useTranslation();
  const { uiStore } = useStoreWrapper();
  const { isMobile } = useMediaContext();
  const [term, setTerm] = useState<TermDto | null>(null);

  useEffect(() => {
    ServiceHelper?.termService
      ?.getTermByCategory(type, {
        locale: i18n.language,
      })
      .then((res) => {
        setTerm(res.data);
      });
  }, []);

  return (
    <Container>
      <div dangerouslySetInnerHTML={{ __html: term?.content || '' }}></div>
      <Row width="100%" justifyContent="flex-end" padding="20px 0">
        <BTButton
          style={{ width: 200 }}
          onClick={() => {
            isMobile ? uiStore?.universalModal.close() : uiStore?.modal.close();
          }}
        >
          {t('close')}
        </BTButton>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  padding: 20px 40px;
`;

export default TermModal;
