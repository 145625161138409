import { TFunction } from 'i18next';

// 에러 코드를 번역된 메시지로 ㅂ변환
export const getErrorMessage = (code: string, t: TFunction): string => {
    const errorCode = code?.toUpperCase() || '';

    // ER_로 시작하지 않는 백엔드 에러코드인 경우 ER_1000 반환
    if (!errorCode.startsWith('ER_')) {
        return t('ER_1000');
    }

    // 유효한 에러 코드인지 확인 (ER_XXXX 형식)
    if (!(/^ER_\d{4}$/.test(errorCode))) {
        return t('ER_1000');
    }

    // t 함수로 번역된 메시지 반환 (없으면 ER_1000)
    return t(errorCode, { defaultValue: t('ER_1000') });
};
