import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { supportLanguages } from 'definitions/menus';

function useLocaleNavigate() {
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  // 초기 언어 경로 설정을 위한 useEffect
  useEffect(() => {
    const pathname = location.pathname;

    // 이미 언어 경로가 있는지 확인
    const hasLanguagePrefix = supportLanguages.some(
      (lang) => pathname.startsWith(`/${lang.key}/`) || pathname === `/${lang.key}`
    );

    // 언어 경로가 없고, 초기 렌더링인 경우에만 리다이렉트
    if (!hasLanguagePrefix) {
      const language = supportLanguages.find((language) => language.key === currentLanguage);
      if (language) {
        const fullLocation = pathname + location.search;
        const newPath =
          fullLocation === '/' ? `/${language.key}` : `/${language.key}${fullLocation}`;

        // 현재 경로와 새 경로가 다른 경우에만 navigate 실행
        if (pathname !== newPath) {
          navigate(newPath, { replace: true });
        }
      }
    }
  }, [currentLanguage]); // location 의존성 제거, 언어 변경 시에만 실행

  // 언어를 고려한 navigate 함수 반환
  const localeNavigate = useCallback(
    (to: string, options?: any) => {
      // 이미 언어 접두사가 있는지 확인
      const hasLanguagePrefix = supportLanguages.some(
        (lang) => to.startsWith(`/${lang.key}/`) || to === `/${lang.key}`
      );

      // 언어 접두사가 없으면 현재 언어 접두사 추가
      if (!hasLanguagePrefix && !to.startsWith('/')) {
        const language = supportLanguages.find((language) => language.key === currentLanguage);
        if (language) {
          to = `/${language.key}/${to}`;
        }
      } else if (!hasLanguagePrefix && to.startsWith('/')) {
        const language = supportLanguages.find((language) => language.key === currentLanguage);
        if (language) {
          to = to === '/' ? `/${language.key}` : `/${language.key}${to}`;
        }
      }

      return navigate(to, options);
    },
    [navigate, currentLanguage]
  );

  // history.back 기능 추가
  const goBack = useCallback(() => {
    window.history.back();
  }, []);

  // 확장된 navigate 객체 반환 (기존 함수 + goBack 메서드)
  return Object.assign(localeNavigate, { goBack });
}

export default useLocaleNavigate;
