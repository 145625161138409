import React from 'react';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BookInfo, Dates } from 'types/CommonTypes';
import { CountButton } from '../CountButton';
import { useParamsHelper } from 'hooks/useParamsHelper';
import { PCDateRangePicker } from './PCDateRangePicker';
import styled from 'styled-components';
import BTButton from 'components/input/BTButton';
import CalendarModal from 'components/pages/listingPage/CalendarModal';
import { ListingDto } from 'services/data-contracts';
import { useStoreWrapper } from 'stores/StoreHelper';
import { getUrlParam } from 'libs/helper/utils';
import { createPortal } from 'react-dom';
import { observer } from 'mobx-react';

export const MobileSearchBox = observer(
  ({
    onChange,
    onSearch,
    dateText,
    useCalendarIcon = true,
  }: {
    dateText: ReactElement;
    useCalendarIcon?: boolean;
    onChange: (bookInfo: BookInfo) => void;
    onSearch?: (bookInfo: BookInfo) => void;
  }) => {
    const guestsRef = useRef<number>(2);
    const datesRef = useRef<Dates>({});

    const { t } = useTranslation();
    const { calendarStore } = useStoreWrapper();
    const { getParams } = useParamsHelper();
    const { guests } = getParams();

    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [bookInfo, setBookInfo] = useState<BookInfo | undefined>(undefined);
    const [listing, setListing] = useState<ListingDto | undefined>(undefined);

    useEffect(() => {
      const { startdate, enddate } = getParams();
      if (startdate && enddate) {
        datesRef.current = {
          startDate: startdate as string,
          endDate: enddate as string,
        };
      }
    }, [getParams]);

    useEffect(() => {
      if (calendarStore.mobileCalendarShow) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }, [calendarStore.mobileCalendarShow]);

    return (
      <>
        <Layout>
          <DateLayout>
            <InnverBox>
              <PCDateRangePicker
                onClickMobileCalendar={(listing?: ListingDto) => {
                  setListing(listing);
                  calendarStore.setMobileCalendarShow(true, false, false);
                }}
                onChange={(selectedDate: Dates) => {
                  if (datesRef.current && selectedDate.startDate && selectedDate.endDate) {
                    datesRef.current = selectedDate;
                  }
                }}
                useCalendarIcon={useCalendarIcon}
                dateText={dateText}
              />
            </InnverBox>
          </DateLayout>
          <div style={{ padding: 22, paddingTop: 0, width: '100%' }}>
            <CountButton
              count={guests ? Number(guests) : guestsRef.current}
              maxCount={12}
              onChange={(guests: number) => {
                const searchParams = new URLSearchParams(window.location.search);
                searchParams.set('guests', guests.toString());
                const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
                window.history.pushState({}, '', newUrl);
                guestsRef.current = guests;
              }}
            />
          </div>
          <SearchButton
            onClick={() => {
              const startDate = getUrlParam('startdate');
              const endDate = getUrlParam('enddate');
              const guests = getUrlParam('guests');

              if (calendarStore.bookInfo) {
                onSearch?.({
                  startDate: calendarStore.bookInfo.startDate,
                  endDate: calendarStore.bookInfo.endDate,
                  guests: guests ? Number(guests) : guestsRef.current,
                });
              } else {
                onSearch?.({
                  startDate: startDate || datesRef.current.startDate || '',
                  endDate: endDate || datesRef.current.endDate || '',
                  guests: guests ? Number(guests) : guestsRef.current,
                });
              }
            }}
          >
            {t('Search')}
          </SearchButton>
        </Layout>
        {/* {
        createPortal(
          <CalendarModalWrapper show={isCalendarModalOpen} >
            {isCalendarModalOpen && (
              <CalendarModal
                minGuests={listing?.minGuest}
                maxGuests={listing?.maxGuest}
                onSearch={(bookInfo: BookInfo) => {
                  setIsCalendarModalOpen(false);
                  onSearch?.(bookInfo);
                }}
                onChange={(bookInfo: BookInfo) => {
                  onChange(bookInfo);
                }}
                onClose={() => setIsCalendarModalOpen(false)}
              />
            )}
          </CalendarModalWrapper >,
          document.body
        )} */}
      </>
    );
  },
);

const SearchButton = styled(BTButton)`
  height: 48px;
  width: 100%;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
`;

const Layout = styled.div`
  margin: auto;
  gap: 10px;
  width: 100%;
  max-width: 1007px;
  height: 100%;
  background: #fff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
`;

const DateLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  height: 80px;
  border-radius: 8px;
  margin-top: 24px;
  /* margin: 24px;
  margin-bottom: 0; */
  width: 100%;
`;

const InnverBox = styled.div`
  background: var(--Grayscale-5, #f8f8f8);
  width: calc(100% - 42px);
  border-radius: 8px;
`;
