import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import { Row } from 'saladsoft-primitive';
import AccordionHeader from './AccordionHeader';
import { ContactForm } from './guestInfo/contactForm/ContactForm';
import BTAccordion from 'components/commons/BTAccordion';
import { ReservationDto, UserInfoDto } from 'services/data-contracts';

interface GuestInfoProps {
  onChange: (data?: ReservationDto) => void;
  userInfo?: UserInfoDto;
}

const GuestInfo = ({ onChange, userInfo }: GuestInfoProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <StyledContainer>
        <HeaderContainer>
          <AccordionHeader title={t('Guest Information')} index={1} required={true} />
        </HeaderContainer>
        <ContentContainer style={{ marginBottom: 30 }}>
          <ContactForm
            userInfo={userInfo}
            onChange={(data) => {
              onChange(data);
            }}
          />
        </ContentContainer>
      </StyledContainer>
    </div>
  );
};

const StyledContainer = styled.div`
  box-shadow: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
`;

const HeaderContainer = styled.div`
  padding: 0;
`;

const ContentContainer = styled.div`
  padding: 0;
`;

export default GuestInfo;
