import { styled } from '@mui/material';
import Section from 'components/layouts/Section';
import React from 'react';
import { useTranslation } from 'react-i18next';
const Plan = ({ url }: { url: string }) => {
  const { t } = useTranslation();
  return (
    <>
      {url && (
        <Section title={t('Plan')}>
          <img src={url} alt="plan" style={{ width: '100%' }} />
        </Section>
      )}
    </>
  );
};

export default Plan;
