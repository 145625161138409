import * as React from 'react';
import styled from 'styled-components';
import { PromotionDto } from 'services/data-contracts';

type PromotionItemProps = {
  promotion: PromotionDto;
  onClick: () => void;
  isLast: boolean;
};

const PromotionItem: React.FC<PromotionItemProps> = ({ promotion, onClick, isLast }) => {
  const stripHtml = (html: string) => {
    return html
      .replace(/<[^>]*>/g, '')
      .replace(/&nbsp;/g, ' ')
      .trim();
  };

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  console.log(promotion);

  return (
    <LoungeContainer isLast={isLast}>
      <ImageWrapper onClick={onClick}>
        <LoungeImage loading="lazy" src={promotion?.images?.[0]?.url} alt="북촌 라운지 전경" />
        <ImageOverlay />
      </ImageWrapper>
      <ContentContainer>
        <InfoSection>
          <Title onClick={onClick}>{promotion.title}</Title>
          <Description>{truncateText(stripHtml(promotion.desc || ''), 200)}</Description>
        </InfoSection>
        <LocationSection>
          <OpeningDate>{promotion.period}</OpeningDate>
          {promotion.address?.trim() !== '' && (
            <AddressWrapper>
              <LocationIconWrapper>
                <LocationIconImage src="/images/location-icon.png" alt="위치 아이콘" />
              </LocationIconWrapper>
              <Address>{promotion.address}</Address>
            </AddressWrapper>
          )}
        </LocationSection>
      </ContentContainer>
    </LoungeContainer>
  );
};

const LoungeContainer = styled.div<{ isLast: boolean }>`
  display: flex;
  gap: 20px;
  justify-content: start;
  flex-wrap: nowrap;
  padding: 24px 0;

  border-bottom: ${(props) => (props.isLast ? 'none' : '1px solid var(--Grayscale-20, #e4e4e4)')};
  @media ${(props) => props.theme.media.mobile} {
    gap: 16px;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  align-self: start;
  position: relative;
  aspect-ratio: 1.428;
  min-width: 240px;
  height: 180px;
  overflow: hidden;
  justify-content: start;
  width: 257px;
  cursor: pointer;

  @media ${(props) => props.theme.media.mobile} {
    width: 134px;
    height: 100px;
    min-width: 134px;
  }
`;

const LoungeImage = styled.img`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const ImageOverlay = styled.div`
  position: relative;
  display: flex;
  min-height: 180px;
  max-width: 100%;
  width: 1280px;
`;

const ContentContainer = styled.div`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  justify-content: space-between;
  flex: 1;
  flex-basis: 0%;
  @media ${(props) => props.theme.media.mobile} {
    min-width: 134px;
  }
`;

const InfoSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Title = styled.h2`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 19px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: -0.05px;
  margin: 0;
  @media ${(props) => props.theme.media.mobile} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Description = styled.div`
  color: var(--Grayscale-70, #555);
  text-overflow: ellipsis;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-top: 10px;
  letter-spacing: -0.04px;

  @media ${(props) => props.theme.media.mobile} {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
`;

const LocationSection = styled.div`
  display: flex;
  margin-top: 23px;
  width: 100%;
  flex-direction: column;
  font-size: 12px;
  color: var(--Grayscale-50, #8e8e8e);
  font-weight: 400;
  letter-spacing: -0.03px;
  justify-content: start;
  @media ${(props) => props.theme.media.mobile} {
    margin-top: 12px;
  }
`;

const OpeningDate = styled.span`
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const OpeningHours = styled.span`
  margin-top: 12px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const AddressWrapper = styled.div`
  display: flex;
  margin-top: 4px;
  width: 100%;
  align-items: start;
  gap: 4px;
  justify-content: start;
  flex-wrap: wrap;
  @media ${(props) => props.theme.media.mobile} {
    margin-top: 3px;
  }
`;

const LocationIconWrapper = styled.div`
  display: flex;
  width: 12px;
  height: 12px;
  align-items: center;
  justify-content: center;
`;

const LocationIconImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Address = styled.span`
  flex: 1;
  flex-basis: 0%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default PromotionItem;
