import MyReservationInfo from 'components/pages/myReservations/reservations/MyReservationInfo';
import PageContainer from 'containers/pc/PageContainer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'saladsoft-primitive';
import ServiceHelper from 'stores/ServiceHelper';
import { PaymentDto, ReservationDto } from 'services/data-contracts';
import i18n from 'i18n';

const ReservationInfoPage = () => {
  const { reservationId } = useParams();
  const [reservationInfo, setReservationInfo] = useState<ReservationDto | null>(null);
  const [paymentInfo, setPaymentInfo] = useState<PaymentDto>();

  useEffect(() => {
    if (reservationId) {
      ServiceHelper?.myReservationService
        ?.getReservation(Number(reservationId), { locale: i18n.language })
        .then((res) => {
          setReservationInfo(res.data);
        });

      ServiceHelper.myReservationService?.getPaymentByReservation(Number(reservationId))
        .then((res) => {
          setPaymentInfo(res.data);
        });
    }

  }, [reservationId]);


  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <PageContainer>
      <div className="page-container" style={{ marginBottom: '77px' }}>
        <Row width="100%" justifyContent="space-between" alignItems="flex-start">
          <div style={{ position: 'sticky', top: -20 }}>
            {reservationInfo && <MyReservationInfo reservationInfo={reservationInfo} paymentInfo={paymentInfo} />}
          </div>
        </Row>
      </div>
    </PageContainer>
  );
};

export default ReservationInfoPage;
