import React from 'react';
import { Row, useMediaContext } from 'saladsoft-primitive';
import { AdminListingImageDto } from 'services/data-contracts';
import styled from 'styled-components';

const DetailPhotoBox = ({ images, onClick }: { images?: AdminListingImageDto[], onClick: (index: number) => void }) => {
  const { isMobile } = useMediaContext();

  const nonPlanImages = images?.filter((image) => !image.isPlan || []);

  return (
    <Container style={{ cursor: 'pointer' }}>
      <Row gap={4} height="100%">
        <MainImageWrapper onClick={() => onClick(0)}>
          <MainImage src={nonPlanImages?.[0]?.url} alt="메인 이미지" />
        </MainImageWrapper>
        <>
          {!isMobile && (
            <SubImagesWrapper>
              <SubImageGrid>
                <Image src={nonPlanImages?.[1]?.thumbnailUrl} alt="no-image" onClick={() => onClick(1)} />
                <Image src={nonPlanImages?.[2]?.thumbnailUrl} alt="no-image" onClick={() => onClick(2)} />
                <Image src={nonPlanImages?.[3]?.thumbnailUrl} alt="no-image" onClick={() => onClick(3)} />
                <Image src={nonPlanImages?.[4]?.thumbnailUrl} alt="no-image" onClick={() => onClick(4)} />
              </SubImageGrid>
            </SubImagesWrapper>
          )}
        </>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  height: 477px;
  overflow: hidden;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  @media ${(props) => props.theme.media.mobile} {
    height: 258px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
`;

const MainImageWrapper = styled.div`
  flex: 0.6;
  height: 100%;
  @media ${(props) => props.theme.media.mobile} {
    flex: 1;
  }
`;

const MainImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SubImagesWrapper = styled.div`
  flex: 0.4;
  /* height: fit-content; */
  height: 100%;
`;

const SubImageGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 4px;
  height: 100%;
`;

const Image = styled.img`
  width: 100%;
  /* aspect-ratio: 1; */
  object-fit: cover;
  height: 100%;
`;

export default DetailPhotoBox;
