import React, { useState } from 'react';
import styled from 'styled-components';
import { Tab } from '../points/Tab';
import { EmptyState } from 'components/commons/EmptyState';
import { NoticeList } from './NoticeList';
import NoticeDetails from './NoticeDetails';
import { useMediaContext } from 'saladsoft-primitive';
import MyInfoTabName from 'components/commons/mobile/MyInfoTabName';
import { useTranslation } from 'react-i18next';
// 공지사항, 이벤트 내역 가져오기 (현재 데이터 없음. 임시 코드)

const Notice = () => {
  const { t } = useTranslation();
  const { isMobile } = useMediaContext();

  const [activeTab, setActiveTab] = useState<'all' | 'notice' | 'event'>('all');
  const [noticeData, setNoticeData] = useState([]);
  const [eventData, setEventData] = useState([]);

  const [showDetail, setShowDetail] = useState(false);

  const allData = [...noticeData, ...eventData];


  return (
    <PageWrapper>
      <PageContent>
        <TabSection>
          <TabList role="tablist">
            <Tab isActive={activeTab === 'all'} onClick={() => setActiveTab('all')}>
              {t('all')}
            </Tab>
            <Tab isActive={activeTab === 'notice'} onClick={() => setActiveTab('notice')}>
              {t('notice')}
            </Tab>
            <Tab isActive={activeTab == 'event'} onClick={() => setActiveTab('event')}>
              {t('event')}
            </Tab>
          </TabList>
          <TabContent role="tabpanel">
            {!showDetail ? (
              <>
                {activeTab === 'all' &&
                  (allData.length === 0 ? <EmptyState message={t('noContent')} /> : <NoticeList />)}
                {activeTab === 'notice' &&
                  (noticeData.length !== 0 ? (
                    <EmptyState message={t('noNotice')} />
                  ) : (
                    <NoticeList />
                  ))}
                {activeTab === 'event' &&
                  (eventData.length === 0 ? <EmptyState message={t('noEvent')} /> : <NoticeList />)}
              </>
            ) : (
              <NoticeDetails onBack={() => setShowDetail(false)} />
            )}
          </TabContent>
        </TabSection>
      </PageContent>
    </PageWrapper>
  );
};

export default Notice;

const PageWrapper = styled.main`
  background-color: var(--Grayscale-0, #fff);
  display: flex;
  max-width: 825px;
  width: 100%;
  flex-direction: column;
`;


const PageContent = styled.div`
  display: flex;
  margin-top: 35px;
  width: 100%;
  flex-direction: column;

  @media (${(props) => props.theme.media.mobile}) {
    margin-top: 0;
  }
`;

const TabSection = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  letter-spacing: -0.04px;
  gap: 14px;

  @media (${(props) => props.theme.media.mobile}) {
    margin-top: 0;
  }
`;

const TabList = styled.div`
  border-bottom: 1px solid var(--Grayscale-20, #e4e4e4);
  background: var(--white, #fff);
  display: flex;
  gap: 24px;

  @media (${(props) => props.theme.media.mobile}) {
    gap: 16px;
  }
`;

const TabContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
