import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ListingDto } from 'services/data-contracts';
import { useTranslation } from 'react-i18next';

import NaverMapMuilti from 'components/maps/NaverMapMuilti';
import { GeolocationType } from 'types/CommonTypes';

const MapList = ({ listings, hasMore }: { listings?: ListingDto[]; hasMore?: boolean }) => {
  const { t } = useTranslation();
  const [geolocations, setGeolocations] = useState<GeolocationType[]>([]);

  const getGeolocations = () => {
    const geolocations = listings?.map((listing) => ({
      listingName: listing.name,
      listingId: listing.id,
      latitude: listing.latitude,
      longitude: listing.longitude,
    }));
    setGeolocations(geolocations as GeolocationType[]);
  };

  useEffect(() => {
    getGeolocations();
  }, [listings]);

  return (
    <>
      <ListingContainer>
        <>
          <NaverMapMuilti title="test" mapId="mapList" geolocations={geolocations} />
        </>
        {listings?.length === 0 && <NoContents>{t('No contents')}</NoContents>}
      </ListingContainer>
    </>
  );
};

const ListingContainer = styled.div`
  width: 100%;

  @media ${(props) => props.theme.media.mobile} {
    gap: 16px;
    margin-top: 0px;
  }

  @media ${(props) => props.theme.media.tablet} {
    flex-wrap: wrap;
    gap: 20px;
  }
`;

const SearchFilterWrapper = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    position: relative;
    top: -40px;
  }
`;

const NoContents = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: pre-line;
  text-align: center;
`;

export default MapList;
