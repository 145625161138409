'use client';

import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ListingDto } from 'services/data-contracts';

const MapOption = ({
  icon,
  label,
  onClick,
}: {
  icon: string;
  label: string;
  onClick?: () => void;
}) => (
  <MapOptionWrapper onClick={onClick}>
    <MapIcon src={icon} alt={`${label} icon`} />
    <MapLabel>{label}</MapLabel>
  </MapOptionWrapper>
);

const Divider = () => (
  <DividerLine
    src="https://cdn.builder.io/api/v1/image/assets/TEMP/dc2b3d7aef9c1500c576f18166e73d0bc0791abf05617ea6aa840c11ddf87d10?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
    alt=""
  />
);

function MapProviderSelector({ listing }: { listing?: ListingDto }) {
  const { t } = useTranslation();

  // 리스팅 주소 가져오기
  const address = listing?.location || '';
  const latitude = listing?.latitude || 0;
  const longitude = listing?.longitude || 0;

  const openGoogleMaps = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.indexOf('android') > -1;
    const isIOS = /iphone|ipad|ipod/.test(userAgent);

    // 주소 또는 좌표 기반 URL 생성
    const mapUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;
    const coordUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
    const finalUrl = address ? mapUrl : coordUrl;

    if (isAndroid) {
      window.location.href = `geo:${latitude},${longitude}?q=${encodeURIComponent(address)}`;
    } else if (isIOS) {
      window.location.href = `maps://?q=${encodeURIComponent(address)}`;
    } else {
      window.open(finalUrl, '_blank');
    }
  };

  const openNaverMaps = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.indexOf('android') > -1;
    const isIOS = /iphone|ipad|ipod/.test(userAgent);
    // 네이버 지도 URL 생성
    const webUrl = `https://map.naver.com/v5/search/${encodeURIComponent(address)}`;

    if (isAndroid) {
      window.location.href = `nmap://search?query=${encodeURIComponent(address)}&appname=com.example.myapp`;
    } else if (isIOS) {
      window.location.href = `nmap://search?query=${encodeURIComponent(address)}&appname=com.example.myapp`;
    } else {
      window.open(webUrl, '_blank');
    }
  };

  const openKakaoMaps = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isAndroid = userAgent.indexOf('android') > -1;
    const isIOS = /iphone|ipad|ipod/.test(userAgent);

    // 카카오맵 URL 생성
    const webUrl = `https://map.kakao.com/?q=${encodeURIComponent(address)}`;

    if (isAndroid) {
      window.location.href = `kakaomap://search?q=${encodeURIComponent(address)}`;
    } else if (isIOS) {
      window.location.href = `kakaomap://search?q=${encodeURIComponent(address)}`;
    } else {
      window.open(webUrl, '_blank');
    }
  };

  return (
    <MapSelectorContainer>
      <MapOptionsWrapper>
        <MapOption
          icon="https://cdn.builder.io/api/v1/image/assets/TEMP/27a1875e8c0d83a4047848ce9dccffaa859b9e354a5a5cafcafe94cf066db77d?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
          label={t('googleMap')}
          onClick={openGoogleMaps}
        />
        <Divider />
        <MapOption
          icon="https://cdn.builder.io/api/v1/image/assets/TEMP/8bcf8d30020a5afa8014c7bbb6abd3283b0ce995064526b0fdcdf4c7344ca657?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
          label={t('naverMap')}
          onClick={openNaverMaps}
        />
        <Divider />
        <MapOption
          icon="https://cdn.builder.io/api/v1/image/assets/TEMP/b28f88e5c21d04b9d50bbe98740c608ce8294550cb76a1239d1c5a9c620e56ae?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
          label={t('kakaoMap')}
          onClick={openKakaoMaps}
        />
      </MapOptionsWrapper>
    </MapSelectorContainer>
  );
}

const MapSelectorContainer = styled.div`
  border-radius: 8px;
  background-color: rgba(248, 248, 248, 1);
  display: flex;
  padding: 20px;
  margin: 20px;
  align-items: center;
  gap: 4px;
  overflow: hidden;

  font-size: 13px;
  color: #555;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  letter-spacing: -0.03px;
  justify-content: center;
`;

const MapOptionsWrapper = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  width: 343px;
  align-items: center;
  gap: 24px;
  justify-content: center;
`;

const MapOptionWrapper = styled.div`
  align-self: stretch;
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: inherit;
  font: inherit;

  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline: 2px solid #0066ff;
    border-radius: 4px;
  }
`;

const MapIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 48px;
`;

const MapLabel = styled.span`
  margin-top: 12px;
`;

const DividerLine = styled.img`
  aspect-ratio: 0.02;
  object-fit: contain;
  object-position: center;
  width: 1px;
  stroke-width: 1px;
  stroke: #e4e4e4;
  align-self: stretch;
  margin-top: auto;
  margin-bottom: auto;
  flex-shrink: 0;
`;

export default MapProviderSelector;
