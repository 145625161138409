import React from 'react';
import styled from 'styled-components';
import { UserCouponDto } from 'services/data-contracts';
import { NumberFormat, Row, useMediaContext } from 'saladsoft-primitive';
import { useTranslation } from 'react-i18next';
import CheckIcon from '@mui/icons-material/Check';
import { useStoreWrapper } from 'stores/StoreHelper';
import BTButton from 'components/input/BTButton';
import { formatYearOnly, formatThousandSeparator, getDday } from 'libs/helper/utils';
import i18n from 'i18n';
import { useEffect } from 'react';

export const CouponCard = ({
  coupon,
  checked,
  onClick,
  selectable = true,
}: {
  coupon?: UserCouponDto;
  checked?: boolean;
  onClick?: () => void;
  selectable?: boolean;
}) => {
  const { t } = useTranslation();
  const { uiStore } = useStoreWrapper();
  const { isMobile } = useMediaContext();

  useEffect(() => {
    console.log(coupon);
  }, []);

  return (
    <CardContainer checked={checked} onClick={onClick}>
      <CardHeader>
        <DayLabel>{getDday(coupon?.coupon?.expireDate)}</DayLabel>
        <StoreTitle>
          <p>
            {coupon?.listings && coupon.listings.length > 0
              ? coupon.listings.length === 1
                ? coupon.listings[0].name
                : `${coupon.listings[0].name} ${t('외')} ${coupon.listings.length - 1}`
              : ''}
          </p>
          {coupon?.coupon?.listings && coupon.coupon.listings.length > 1 && (
            <StoreIcon
              src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/197852973175884fcc757670fd3ef1fd450fdb2e14910a10b855baa1d8c1d419?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&"
              loading="lazy"
              onClick={() => {
                const modalContent = (
                  <ModalContent>
                    {isMobile && (
                      <HeaderContainer>
                        <HeaderTitle>
                          {coupon?.coupon?.name} ({formatYearOnly(coupon?.coupon?.expireDate)}
                          {t('year')}_{formatThousandSeparator(coupon?.coupon?.price ?? 0)}
                          {t('won')})
                        </HeaderTitle>
                        <CloseButton
                          onClick={() => {
                            uiStore?.universalModal.close();
                          }}
                        >
                          <img
                            loading="lazy"
                            src="https://cdn.builder.io/api/v1/image/assets/TEMP/2e3a6d5039433b65ac03f38e1df15128101fd433cd338d57b88009bb05536059?placeholderIfAbsent=true&apiKey=694b79be46254ffd8018f6be6c4bbc2d"
                            alt="Close"
                          />
                        </CloseButton>
                      </HeaderContainer>
                    )}
                    <ListingContainer>
                      {coupon?.listings?.map((listing, index) => (
                        <ListingItem key={`${listing.name}-${index}`}>{listing.name}</ListingItem>
                      ))}
                    </ListingContainer>
                    <BTButton
                      type="primary"
                      onClick={() =>
                        isMobile ? uiStore?.universalModal.close() : uiStore?.modal.close()
                      }
                    >
                      {t('close')}
                    </BTButton>
                  </ModalContent>
                );

                const modalTitle = `${coupon?.coupon?.name} (${formatYearOnly(coupon?.coupon?.expireDate)}${t('year')}_${formatThousandSeparator(coupon?.coupon?.price ?? 0)}${t('won')})`;

                if (isMobile) {
                  uiStore?.universalModal.show({
                    children: modalContent,
                  });
                } else {
                  uiStore?.modal.show({
                    children: modalContent,
                    title: modalTitle,
                    style: { width: '50%' },
                  });
                }
              }}
            />
          )}
        </StoreTitle>
      </CardHeader>
      <CardContent>
        <CouponInfo>
          <Row width="100%" justifyContent="space-between">
            <div>
              <AmountText>
                <NumberFormat value={coupon?.coupon?.price ?? 0} surffix={t('won')} />
              </AmountText>
              <DescriptionText>{coupon?.coupon?.name}</DescriptionText>
            </div>
            <>
              {selectable === true && (
                <div>
                  <CheckedIcon checked={checked}>
                    <CheckIcon style={{ color: checked ? '#fff' : '#C6C6C6' }} />
                  </CheckedIcon>
                </div>
              )}
            </>
          </Row>
        </CouponInfo>
        <PeriodInfo>
          <PeriodRow>
            <PeriodLabel>{t('coupon.usagePeriod')}</PeriodLabel>
            {/* <PeriodValue>{coupon?.coupon?.expireDate}까지 사용가능</PeriodValue> */}
            <PeriodWrapper>
              {i18n.language === 'ko' ? (
                <>
                  <ExpireDate>{coupon?.coupon?.expireDate}</ExpireDate>
                  <PeriodText>{t('coupon.validUntil')}</PeriodText>
                </>
              ) : (
                <>
                  <PeriodText>{t('coupon.validUntil')}</PeriodText>
                  <ExpireDate>{coupon?.coupon?.expireDate}</ExpireDate>
                </>
              )}
            </PeriodWrapper>
          </PeriodRow>
          <PeriodRow>
            <PeriodLabel>{t('coupon.reservationPeriod')}</PeriodLabel>
            <PeriodValue>
              {coupon?.coupon?.stayStartDate === null ? (
                t('coupon.noLimit')
              ) : (
                <>
                  {coupon?.coupon?.stayStartDate}~{coupon?.coupon?.stayEndDate}
                </>
              )}
            </PeriodValue>
          </PeriodRow>
        </PeriodInfo>
      </CardContent>
    </CardContainer>
  );
};

const CardContainer = styled.article<{ checked?: boolean }>`
  border-radius: 12px;
  border: ${({ checked }) =>
    checked ? '2px solid #4E433C' : '1px solid var(--Grayscale-20, #e4e4e4)'};
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const CheckedIcon = styled.div<{ checked?: boolean }>`
  width: 32px;
  height: 32px;
  background: ${({ checked }) => (checked ? '#4E433C' : '#fff')};
  border: ${({ checked }) => (checked ? 'none' : '1px solid #C6C6C6')};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: var(--Grayscale-10, #f0f0f0);
  border-radius: 12px 12px 0 0;
`;

const DayLabel = styled.div`
  padding: 3px 6px;
  border-radius: 4px;
  background: var(--92897-b, #92897b);
  color: var(--Grayscale-5, #f8f8f8);
  font-weight: 700;
  font-size: 12px;
  font-family: Montserrat, sans-serif;
`;

const StoreTitle = styled.h3`
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--Grayscale-70, #555);
  font-size: 12px;
  font-weight: 400;
`;

const StoreIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const CardContent = styled.div`
  padding: 10px 16px 24px;
`;

const CouponInfo = styled.div`
  margin-bottom: 32px;
`;

const AmountText = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: var(--Grayscale-90, #1d1d1d);
`;

const DescriptionText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: var(--Grayscale-90, #1d1d1d);
`;

const PeriodInfo = styled.div`
  font-size: 12px;
`;

const PeriodRow = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 6px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const PeriodLabel = styled.div`
  width: 80px;
  color: var(--Grayscale-70, #555);
  font-weight: 500;
`;

const PeriodValue = styled.div`
  color: var(--Grayscale-70, #555);
  font-weight: 400;
`;

const PeriodWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const ExpireDate = styled.span`
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 600;
  letter-spacing: -0.04px;
`;

const PeriodText = styled.span`
  color: var(--Grayscale-70, #555);
  font-weight: 400;
  letter-spacing: -0.04px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 12px;
`;

const HeaderContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
`;

const HeaderTitle = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  font-family: Pretendard;
  font-size: 19px;
  font-weight: 600;
  line-height: 150%;
  text-align: center;
  font-style: normal;
  letter-spacing: -0.047px;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  &:focus {
    outline: none;
  }
  img {
    width: 24px;
    height: 24px;
  }
`;

const ListingContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding: 8px 0 40px;
`;

const ListingItem = styled.li`
  list-style: none;
  color: var(--Grayscale-90, #1d1d1d);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.035px;
`;
