import * as React from 'react';
import styled from 'styled-components';
import type { EmptyStateProps } from './types';

export const EmptyState: React.FC<EmptyStateProps> = ({
  message,
  iconSrc = 'https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/11025f901c8bf1e455190d6731493ef6332b354b4435a23d9ce25e26a4b8a48f?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&',
}) => {
  return (
    <EmptyStateWrapper>
      <EmptyStateIcon src={iconSrc} alt="" role="presentation" loading="lazy" />
      <EmptyStateText>{message}</EmptyStateText>
    </EmptyStateWrapper>
  );
};

const EmptyStateWrapper = styled.div`
  display: flex;
  /* height: calc(100vh - 300px); */
  width: 100%;
  margin: 0 auto;
  min-height: 400px;
  flex-direction: column;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  color: var(--Grayscale-50, #8e8e8e);
`;

const EmptyStateIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  width: 48px;
`;

const EmptyStateText = styled.p`
  margin-top: 16px;
  font:
    400 15px Pretendard,
    sans-serif;
  text-align: center;
`;
