import BTButton from 'components/input/BTButton';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'saladsoft-primitive';
import { LandmarkDto } from 'services/data-contracts';
import styled from 'styled-components';

import { toast } from 'react-toastify';
import { getGeolocation } from 'libs/helper/mapHelper';
import { GeolocationType } from 'types/CommonTypes';
import NaverMap from 'components/maps/NaverMap';
import { useStoreWrapper } from 'stores/StoreHelper';

const LandmarkModal = ({ landmark, onClick }: { landmark?: LandmarkDto; onClick?: () => void }) => {
  const { t } = useTranslation();
  const { uiStore } = useStoreWrapper();
  const [geolocation, setGeolocation] = useState<GeolocationType | null>(null);

  const getGeolocationInfo = async () => {
    if (!landmark?.address) return;
    const geoData = await getGeolocation(landmark?.address || '');
    if (geoData && typeof geoData === 'object' && 'x' in geoData && 'y' in geoData) {
      setGeolocation({ latitude: geoData.y as number, longitude: geoData.x as number });
    }
  };

  useEffect(() => {
    getGeolocationInfo();
  }, [landmark?.address]);

  return (
    <div onClick={onClick} style={{ padding: '16px' }}>
      <img
        src={landmark?.images?.[0]?.url}
        alt={landmark?.name}
        style={{
          width: '100%',
          height: 'auto', // 적절한 높이 설정
          objectFit: 'cover',
          borderRadius: '8px',
        }}
      />
      <TitleStyled>{landmark?.name}</TitleStyled>
      <AddressStyled>
        <Row width="100%" justifyContent="space-between">
          <div>{landmark?.address}</div>
          <BTButton type="outline" onClick={() => { }} style={{ height: 30 }}>
            <CopyAddressButton
              onClick={() => {
                navigator.clipboard.writeText(landmark?.address || '');
                toast.success(t('copyAddress'));
              }}
            >
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/a5ba37ebd0b68134335abb0539612a8557848dc3f45500ad6165def3c6ba46fd?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
                alt=""
              />
              <div>{t('copyClipboard')}</div>
            </CopyAddressButton>
          </BTButton>
        </Row>
      </AddressStyled>
      <DescStyled dangerouslySetInnerHTML={{ __html: landmark?.desc || '' }} />
      {geolocation && (
        <NaverMap geolocations={[geolocation]} title={landmark?.name || ''} mapId="landmarkMap" />
      )}
      <ButtonStyled>
        <BTButton
          type="outline"
          onClick={() => {
            uiStore?.universalModal.close();
          }}
        >
          {t('Close')}
        </BTButton>
      </ButtonStyled>
    </div>
  );
};

const CopyAddressButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--sub-color, #1c51b6);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.03px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

const CopyText = styled.span`
  text-decoration: underline;
`;

const TitleStyled = styled.div`
  font-size: 15px;
  font-weight: 600;
  margin-top: 16px;
`;

const AddressStyled = styled.div`
  font-size: 13px;
  color: #888;
`;

const DescStyled = styled.div`
  font-size: 13px;
  color: #888;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ButtonStyled = styled.div`
  margin-top: 20px;
`;

export default LandmarkModal;
