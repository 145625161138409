import * as React from 'react';
import styled from 'styled-components';

interface ReservationDetailsProps {
  checkInDate: string;
  checkOutDate: string;
  numberOfGuests: number;
  totalAmount: number;
}

export const ReservationDetails: React.FC<ReservationDetailsProps> = ({
  checkInDate,
  checkOutDate,
  numberOfGuests,
  totalAmount,
}) => (
  <DetailsContainer>
    <DetailRow>
      <DetailLabel>일정</DetailLabel>
      <DetailValue>
        {checkInDate} ~ {checkOutDate} (1박)
      </DetailValue>
    </DetailRow>
    <DetailRow>
      <DetailLabel>인원</DetailLabel>
      <DetailValue>{numberOfGuests}인</DetailValue>
    </DetailRow>
    <Divider />
    <TotalRow>
      <TotalLabel>총 결제 금액</TotalLabel>
      <TotalAmount>
        <Amount>{totalAmount.toLocaleString()}</Amount>
        <Currency>원</Currency>
      </TotalAmount>
    </TotalRow>
  </DetailsContainer>
);

const DetailsContainer = styled.div`
  border-radius: 0 0 12px 12px;
  border-top: 1px solid var(--Grayscale-20, #e4e4e4);
  background: var(--Grayscale-5, #f8f8f8);
  margin-top: 20px;
  padding: 20px 16px;
`;

const DetailRow = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  font-size: 14px;
  letter-spacing: -0.04px;
  &:not(:first-child) {
    margin-top: 10px;
  }
`;

const DetailLabel = styled.span`
  color: var(--Grayscale-50, #8e8e8e);
  font-weight: 500;
`;

const DetailValue = styled.span`
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 400;
  flex: 1;
  text-align: left;
`;

const Divider = styled.hr`
  border: none;
  height: 1px;
  background: var(--Grayscale-20, #e4e4e4);
  margin: 20px 0;
`;

const TotalRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 600;
`;

const TotalLabel = styled.span`
  font-size: 15px;
  letter-spacing: -0.04px;
`;

const TotalAmount = styled.div`
  display: flex;
  align-items: center;
`;

const Amount = styled.span`
  font-size: 19px;
  letter-spacing: -0.05px;
`;

const Currency = styled.span`
  font-size: 17px;
  letter-spacing: -0.04px;
  /* margin-left: 4px; */
`;
