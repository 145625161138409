import { ButtonProps, IconButton } from '@mui/material';
import SocialLoginButtons from 'components/login/SocialLoginButtons';
import MyReservationForNoneMember from 'components/pages/myReservations/reservations/MyReservationForNoneMember';
import MyReservationInfo from 'components/pages/myReservations/reservations/MyReservationInfo';
import { ReservationList } from 'components/pages/myReservations/reservations/ReservationList';
import PageContainer from 'containers/pc/PageContainer';
import i18n from 'i18n';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NonMemberReservationDto, UserDto, UserProfileDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import styled from 'styled-components';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import { useMediaContext } from 'saladsoft-primitive';
import useToken from 'hooks/useToken';
import { toast } from 'react-toastify';
const GuestReservationPage = () => {
  const { t } = useTranslation();
  const { validUser } = useToken();
  const { isMobile } = useMediaContext();
  const navigate = useLocaleNavigate();
  const [myInfo, setMyInfo] = useState<UserProfileDto>();
  const [guestReservationInfo, setGuestReservationInfo] = useState<NonMemberReservationDto>();
  const [noneMemberModel, setNoneMemberModel] = useState<NonMemberReservationDto>();

  const onNonMemberSearch = (stateModel: NonMemberReservationDto) => {
    ServiceHelper?.nonReservationService
      ?.getReservationForNonMember({ locale: i18n.language }, stateModel)
      .then((res) => {
        if (res.status === 200) {
          setGuestReservationInfo(res.data);
          setNoneMemberModel(stateModel);
          sessionStorage.setItem('guestReservationInfo', JSON.stringify(stateModel));

        } else {
          const newRes = res as any;
          const error = newRes.response.data.errorCode;

          switch (error) {
            case 'RESERVATION.INVALID_GUEST_PHONE':
              toast.error(t('reservation.noneMember.invalidGuestPhone'), {
                position: 'top-right',
              });
              break;
            case 'RESERVATION.NOT_FOUND':
              toast.error(t('reservation.noneMember.reservationNotFound'), {
                position: 'top-right',
              });
              break;
          }


        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (validUser()) {
      ServiceHelper?.myAccountService?.getMyProfile().then((res) => {
        setMyInfo(res.data);
      });
    }
  }, []);

  return (
    <PageContainer>
      {isMobile && (
        <CloseWrapper>
          <IconButton
            onClick={() => {
              navigate.goBack();
            }}
          >
            <CloseIcon />
          </IconButton>
        </CloseWrapper>
      )}
      {/* 비회원 정보표시 */}
      {guestReservationInfo ? (
        <div style={{ marginTop: '56px' }}>
          <MyReservationInfo reservationInfo={guestReservationInfo} model={noneMemberModel} />
        </div>
      ) : (
        <>
          <HeaderSection>
            <NavigationSection>
              <SocialLoginButtons
                type="login"
                style={{ height: 'unset', width: '100%', marginTop: 50 }}
                forGuestReservation={true}
              />
            </NavigationSection>
          </HeaderSection>
          <div className="page-container" style={{ padding: '0 16px' }}>
            {myInfo ? (
              <ReservationList />
            ) : (
              <MyReservationForNoneMember
                onSearch={(stateModel: NonMemberReservationDto) => {
                  onNonMemberSearch(stateModel);
                }}
              />
            )}
          </div>
        </>
      )}
    </PageContainer>
  );
};

const Button: React.FC<ButtonProps> = ({ children, variant = 'primary' }) => (
  <StyledButton className={`button-${variant}`} role="button" tabIndex={0}>
    {children}
  </StyledButton>
);

const HeaderSection = styled.header`
  align-self: center;
  display: flex;
  width: 100%;
  margin: auto;
  flex-direction: column;
  max-width: 640px;
  text-align: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Logo = styled.img`
  aspect-ratio: 5.18;
  object-fit: contain;
  width: 207px;
  align-self: center;
  max-width: 100%;
`;

const NavigationSection = styled.nav`
  display: flex;
  margin-top: 64px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const AuthSection = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: var(--Grayscale-50, #8e8e8e);
  font-weight: 400;
  justify-content: center;
`;

const Divider = styled.div`
  height: 1px;
  background: var(--Grayscale-20, #e4e4e4);
  flex: 1;
`;

const AuthText = styled.span`
  white-space: nowrap;
`;

const ButtonGroup = styled.div`
  display: flex;
  margin-top: 32px;
  width: 100%;
  flex-direction: column;
  gap: 12px;
`;

const StyledButton = styled.button`
  width: 100%;
  border-radius: 8px;
  min-height: 48px;
  padding: 0 16px;
  font-size: 15px;
  font-weight: 600;
  border: none;
  cursor: pointer;

  &.button-primary {
    background-color: rgba(255, 232, 18, 1);
    color: var(--Grayscale-0, #fff);
  }

  &.button-secondary {
    background-color: var(--gray-gray-0, #fff);
    border: 1px solid var(--Grayscale-20, #e4e4e4);
    color: var(--Grayscale-0, #fff);
  }

  &.button-disabled {
    background-color: var(--Grayscale-30, #d8d8d8);
    color: var(--Grayscale-0, #fff);
  }
`;

const CloseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  position: fixed;
  top: 15x;
`;

export default GuestReservationPage;
