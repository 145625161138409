import styled from '@mui/material/styles/styled';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'saladsoft-primitive';

type TabProps = {
  selected?: number;
  onChange?: (id: number) => void;
};

const Tab = ({ selected, onChange }: TabProps) => {
  const { t } = useTranslation();
  const Tabs = [
    { id: 0, name: t('details') },
    { id: 1, name: t('facilities') },
    { id: 2, name: t('plan') },
    { id: 3, name: t('location') },
    { id: 4, name: t('attraction') },
  ];

  const [selectedTab, setSelectedTab] = useState(selected ?? 0);

  // useEffect(() => {
  //   setSelectedTab(selected ?? 0);
  // }, [selected]);

  return (
    <Row gap={20} justifyContent="flex-start" borderBottom="1px solid #E4E4E4">
      {Tabs.map((tab) => (
        <TabItem
          selected={selectedTab === tab.id}
          onClick={() => {
            setSelectedTab(tab.id);
            onChange?.(tab.id);
          }}
          key={tab.id}
        >
          {tab.name}
        </TabItem>
      ))}
    </Row>
  );
};

const TabItem = styled('div') <{ selected: boolean }>`
  cursor: pointer;
  color: ${({ selected }) => (selected ? '#000' : '#ccc')};
  border-bottom: ${({ selected }) => (selected ? '2px solid #1d1d1d' : 'none')};
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 17px;
`;

export default Tab;
