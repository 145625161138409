import * as React from 'react';
import styled from 'styled-components';
import { PromotionDto } from 'services/data-contracts';
import { formatDate } from 'libs/helper/utils';

type PromotionItemProps = {
  promotion: PromotionDto;
  onClick: () => void;
};

const PromotionItem: React.FC<PromotionItemProps> = ({ promotion, onClick }) => {

  const stripHtml = (html: string) => {
    return html
      .replace(/<[^>]*>/g, '')
      .replace(/&nbsp;/g, ' ')
      .trim();
  };

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
  };
  return (
    <PromotionItemWrapper>
      <ImageWrapper image={promotion?.images?.[0]?.url} onClick={onClick} />
      <ContentContainer>
        <InfoSection>
          <Title onClick={onClick}>{promotion.title}</Title>
          <Description>{truncateText(stripHtml(promotion.desc || ''), 200)}</Description>
        </InfoSection>
        <LocationSection>
          <OpeningDate>{promotion.period}</OpeningDate>
          {promotion.address?.trim() !== '' && (
            <AddressWrapper>
              <LocationIcon />
              <Address>{promotion.address}</Address>
            </AddressWrapper>
          )}
        </LocationSection>
      </ContentContainer>
    </PromotionItemWrapper>
  );
};

const PromotionItemWrapper = styled.div`
  z-index: 0;
  position: relative;
  flex: 0 0 calc(36.55% - 60px);

  @media ${(props) => props.theme.media.tablet} {
    flex: 0 0 calc(50% - 20px);
  }

  @media ${(props) => props.theme.media.mobile} {
    flex: 0 0 100%;
  }
`;

const ImageWrapper = styled.div<{ image?: string }>`
  position: relative;
  cursor: pointer;
  display: flex;
  width: 100%;
  padding-bottom: 66.67%;
  justify-content: flex-end;
  align-items: center;
  border-radius: 8px;
  background: url(${(props) => props.image}) lightgray 50% / cover no-repeat;
  margin-bottom: 24px;
`;

const ContentContainer = styled.div`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  justify-content: space-between;
  flex: 1;
  flex-basis: 0%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const InfoSection = styled.div`
  display: flex;
  width: 100%;
  gap: 6px;
  flex-direction: column;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Title = styled.h2`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 19px;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: -0.05px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Description = styled.div`
  color: var(--Grayscale-70, #555);
  text-overflow: ellipsis;
  white-space: pre-wrap;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.04px;
  overflow: hidden;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const LocationSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 12px;
  gap: 4px;
  color: var(--Grayscale-50, #8e8e8e);
  font-weight: 400;
  letter-spacing: -0.03px;
  justify-content: start;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const OpeningDate = styled.span`
  margin: 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const AddressWrapper = styled.div`
  display: flex;
  margin: 0;
  width: 100%;
  align-items: start;
  gap: 4px;
  justify-content: start;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const LocationIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('/images/location-icon.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 2px;
  width: 12px;
  height: 12px;
`;

const Address = styled.span`
  flex: 1;
  flex-basis: 0%;
  margin: 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default PromotionItem;
