import CountryCode from 'components/commons/CountryCode';
import BTButton from 'components/input/BTButton';
import { FormField } from 'components/pages/bookPage/guestInfo/contactForm/FormField';
import { validationHelper } from 'libs/helper/validationHelper';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, useModelStateContext } from 'saladsoft-primitive';
import { NonMemberReservationDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import styled from 'styled-components';

interface TextFieldProps {
  placeholder: string;
}

interface ButtonProps {
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'disabled';
}

const TextField: React.FC<TextFieldProps> = ({ placeholder }) => (
  <TextFieldContainer>
    <label className="visually-hidden" htmlFor={`input-${placeholder}`}>
      {placeholder}
    </label>
    <input
      type="text"
      id={`input-${placeholder}`}
      placeholder={placeholder}
      aria-label={placeholder}
    />
  </TextFieldContainer>
);

const Button: React.FC<ButtonProps> = ({ children, variant = 'primary' }) => (
  <StyledButton className={`button-${variant}`} role="button" tabIndex={0}>
    {children}
  </StyledButton>
);

const MyReservationForNoneMember = ({
  onSearch,
}: {
  onSearch: (stateModel: NonMemberReservationDto) => void;
}) => {
  const { t } = useTranslation();
  const { setState, stateModel: state, unmountModel } = useModelStateContext<NonMemberReservationDto>();

  const [countryCodeError, setCountryCodeError] = useState<string>('');
  const [isCountryCodeValid, setIsCountryCodeValid] = useState<boolean | null>(null);
  const [phoneError, setPhoneError] = useState<string>('');
  const [isPhoneValid, setIsPhoneValid] = useState<boolean | null>(null);
  const [isReservationValid, setIsReservationValid] = useState<boolean | null>(null);
  const [reservationError, setReservationError] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(false);

  const searchReservation = () => {
    if (state) {
      onSearch(state);
    }
  };

  const validate = () => {
    const countryCode = validationHelper.required(state?.guestCountryCode);
    const phoneNumber = validationHelper.tel(state?.guestPhoneNumber);
    const reservationId = validationHelper.number(String(state?.hostawayReservationId));
    setIsValid(countryCode && phoneNumber && reservationId);
  };

  useEffect(() => {
    if (state) {
      validate();
    }
  }, [state]);

  useEffect(() => {
    return () => {
      unmountModel();
    };
  }, []);

  return (
    <PageContainer>
      <MainSection>
        <ReservationHeader>
          <Divider />
          <ReservationText>{t('guest.non_member_reservation_lookup')}</ReservationText>
          <Divider />
        </ReservationHeader>
        <Row gap={5} alignItems="flex-start">
          <CountryCode
            onChange={(value: string) => {
              setState('guestCountryCode', value);
              // onChange에서는 에러 메시지를 초기화만 하고
              // 실제 유효성 검사는 하지 않음
              if (value) {
                setCountryCodeError('');
                setIsCountryCodeValid(true);
              }
            }}
            onBlur={() => {
              // blur 이벤트에서 유효성 검사
              if (!state?.guestCountryCode) {
                setCountryCodeError(t('guest.please_select_country_code'));
                setIsCountryCodeValid(false);
              }
            }}
            error={countryCodeError}
            isValid={isCountryCodeValid}
          />
          <FormField
            id="contact"
            type="tel"
            placeholder={t('guest.numbers_only_please')}
            error={phoneError}
            isValid={isPhoneValid}
            onChange={(e: any) => {
              const value = e.target.value;
              setState('guestPhoneNumber', value);
              // 실시간 유효성 체크
              setIsPhoneValid(value.length >= 8 && value.length <= 11 && /^\d*$/.test(value));
            }}
            onBlur={(e: any) => {
              const value = e.target.value;

              // 전화번호 검사
              if (!/^\d*$/.test(value)) {
                setPhoneError(t('guest.only_numbers_allowed'));
              } else if (value.length < 8 || value.length > 11) {
                setPhoneError(t('guest.phone_number_must_be_8_to_11_digits'));
              } else {
                setPhoneError('');
              }

              // 국가 코드 검사
              if (!state?.guestCountryCode) {
                setCountryCodeError(t('guest.please_select_country_code'));
                setIsCountryCodeValid(false);
              }
            }}
          />
        </Row>
        <div>
          <FormField
            label={t('guest.reservation_id')}
            required={true}
            id="firstName"
            type="number"
            error={reservationError}
            isValid={isReservationValid}
            onChange={(e: any) => {
              const value = e.target.value;
              setState('hostawayReservationId', value);
              setIsReservationValid(validationHelper.number(value));
            }}
            onBlur={(e: any) => {
              const value = e.target.value;
              if (!validationHelper.number(value)) {
                setReservationError(t('guest.only_numbers_allowed'));
              } else {
                setReservationError('');
              }
            }}
          />
        </div>
        <div>
          <BTButton disabled={!isValid} style={{ width: '100%' }} onClick={searchReservation}>
            {t('guest.search_reservation')}
          </BTButton>
        </div>
      </MainSection>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  max-width: 640px;
  margin: auto;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  letter-spacing: -0.04px;
`;

const StyledButton = styled.button`
  width: 100%;
  border-radius: 8px;
  min-height: 48px;
  padding: 0 16px;
  font-size: 15px;
  font-weight: 600;
  border: none;
  cursor: pointer;

  &.button-primary {
    background-color: rgba(255, 232, 18, 1);
    color: var(--Grayscale-0, #fff);
  }

  &.button-secondary {
    background-color: var(--gray-gray-0, #fff);
    border: 1px solid var(--Grayscale-20, #e4e4e4);
    color: var(--Grayscale-0, #fff);
  }

  &.button-disabled {
    background-color: var(--Grayscale-30, #d8d8d8);
    color: var(--Grayscale-0, #fff);
  }
`;

const MainSection = styled.main`
  display: flex;
  margin-top: 100px;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const ReservationHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  color: var(--Grayscale-50, #8e8e8e);
  text-align: center;
  justify-content: center;
  margin-bottom: 40px;
`;

const ReservationText = styled.span`
  white-space: nowrap;
`;

const TextFieldContainer = styled.div`
  border-radius: 8px;
  background: var(--Grayscale-5, #f8f8f8);
  min-height: 48px;
  width: 100%;

  input {
    width: 100%;
    height: 100%;
    min-height: 48px;
    padding: 0 16px;
    border: none;
    background: transparent;
    font-size: 15px;
    color: var(--Grayscale-90, #1d1d1d);

    &::placeholder {
      color: var(--Grayscale-50, #8e8e8e);
    }
  }
`;

const Divider = styled.div`
  height: 1px;
  background: var(--Grayscale-20, #e4e4e4);
  flex: 1;
`;

export default MyReservationForNoneMember;
