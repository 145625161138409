import * as React from 'react';
import styled from 'styled-components';
import { BookInfo, Dates } from 'types/CommonTypes';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { differenceInDays, format } from 'date-fns';
import { enUS, ko, ja, zhCN } from 'date-fns/locale';
import i18n from 'i18n';
import { useStoreWrapper } from 'stores/StoreHelper';
import { PCDateRangePicker } from 'components/commons/searchBox/PCDateRangePicker';
import { CountButton } from 'components/commons/CountButton';
import { useTranslation } from 'react-i18next';
import BTButton from 'components/input/BTButton';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker';
import koLocale from 'date-fns/locale/ko';
import enLocale from 'date-fns/locale/en-US';
import jaLocale from 'date-fns/locale/ja';
import cnLocale from 'date-fns/locale/zh-CN';

import { CountInner } from 'components/commons/CountInner';
import { Row } from 'saladsoft-primitive';
import { convertStringToDate, getUrlParam } from 'libs/helper/utils';
import { DateRangePicker } from 'components/commons/searchBox/DateRangePicker';
import { IconButton } from '@mui/material';
import { createPortal } from 'react-dom';
import BottomSheetHeader from 'components/commons/BottomSheetHeader';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import ListingStore from 'stores/ListingStore';

const CalendarModal = ({
  minGuests,
  maxGuests,
  isListingPicker = false,
  useImmediateSearch = false,
  onClose,
  onChange,
  onSearch,
}: {
  minGuests?: number;
  maxGuests?: number;
  isListingPicker?: boolean;
  useImmediateSearch: boolean;
  onClose: () => void;
  onChange: (bookInfo: BookInfo) => void;
  onSearch?: (bookInfo: BookInfo) => void;
}) => {
  const guestsRef = useRef<number>(2);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const guests = getUrlParam('guests');
  const startDate = getUrlParam('startdate');
  const endDate = getUrlParam('enddate');

  const { listingStore } = useStoreWrapper();
  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState<any>([null, null]);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState<boolean>(false);

  const navigate = useLocaleNavigate();

  const onChangeCount = (count: number) => {
    guestsRef.current = count;
  };

  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      if (start < end) {
        setSelectedDate([start, end]);
      } else {
        setSelectedDate([null, null]);
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    // 동적 스타일 태그 생성
    const style = document.createElement('style');
    style.innerHTML = `
      .MuiPaper-root {
       box-shadow:none !important;
       border-radius:0px !important;
      }
      .css-1xhj18k {
        flex-direction:column !important;
       };

      .css-1tape97 {
        border-right:0px solid transparent !important;
      };
    `;

    document.head.appendChild(style);
    // 컴포넌트 언마운트 시 스타일 태그 제거
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  return (
    <>
      <CardContainer>
        <BottomSheetHeader onClose={onClose} title={t('calendar2')} />
        <ScheduleBox>
          <Row
            justifyContent="space-evenly"
            width="100%"
            background="#fff"
            padding="20px 8px"
            borderRadius="10px"
          >
            <div style={{ textAlign: 'center' }}>
              <CheckInText>CHECK IN</CheckInText>
              {selectedDate[0] && selectedDate[1] ? (
                <DateText>{`${convertStringToDate(selectedDate[0])}`} </DateText>
              ) : (
                <NoDateText>{t('please select date')}</NoDateText>
              )}
            </div>
            <div style={{ textAlign: 'center' }}>
              <CheckInText>CHECK OUT</CheckInText>
              {selectedDate[0] && selectedDate[1] ? (
                <DateText>{`${convertStringToDate(selectedDate[1])}`} </DateText>
              ) : (
                <NoDateText>{t('please select date')}</NoDateText>
              )}
            </div>
          </Row>
        </ScheduleBox>
        <div>
          <DateRangePicker
            isListingPicker={isListingPicker}
            value={selectedDate}
            onChange={(date: any) => {
              if (date[0] && date[1]) {
                setSelectedDate(date);
                onChange({
                  startDate: format(date[0], 'yyyy-MM-dd'),
                  endDate: format(date[1], 'yyyy-MM-dd'),
                  guests: guestsRef.current,
                });
              }
            }}
          />
          {/* <LocalizationProvider
          dateAdapter={AdapterDateFns}
          locale={i18n.language === 'ko' ? koLocale : enLocale}
        >
          <StaticDateRangePicker
            disablePast={true}
            disableMaskedInput={true}
            displayStaticWrapperAs="desktop"
            calendars={2}
            // key={pickerKey}
            shouldDisableDate={(day: Date) => {
              // return shouldDisableDate(day, selectedTempDate[0], minimumStay) as boolean;
              return false;
            }}
            onMonthChange={(date: Date) => {
              // onChangeMonth?.(date);
            }}
            startText={t('checkIn')}
            value={selectedDate}
            onChange={(date: any, e: any) => {
              setSelectedDate(date);
              // handleClickDate(date, e);
            }}
            renderInput={(startProps: any, endProps: any) => <></>}
          />
        </LocalizationProvider> */}
        </div>
        <div style={{ paddingTop: 0, width: '100%', marginBottom: 90, padding: '0 20px' }}>
          <CountInnerWrapper
            onChange={onChangeCount}
            useConfirm={false}
            count={Number(guests) || minGuests || 2}
            maxCount={isListingPicker ? maxGuests : 12}
          />
          <hr />
        </div>
        <ButtonWrapper>
          <Row gap={10} width="100%" justifyContent="space-between" padding="10px">
            <div style={{ flex: 0.3 }}>
              <BTButton
                type="outline"
                style={{ border: 0, width: '100%' }}
                onClick={() => {
                  onClose();
                  // uiStore?.modal.close();
                }}
              >
                {t('Cancel')}
              </BTButton>
            </div>
            <div style={{ flex: 0.7 }}>
              <SearchButton
                style={{ width: '100%' }}
                onClick={() => {
                  const [start, end] = selectedDate;
                  if (start && end) {
                    searchParams.set('startdate', format(start, 'yyyy-MM-dd'));
                    searchParams.set('enddate', format(end, 'yyyy-MM-dd'));
                    searchParams.set('guests', guestsRef.current.toString());
                    // calendarStore.setMobileCalendarShow(false);
                    /**
                     * Search가 있는경우, 메인 페이지를 제외한 다른 모든 페이지
                     * Search가 없는 경우, 메인페이지는 별도의 "검색" 버튼이 있으므로 그 버튼을 이용해야함.
                     */
                    onSearch?.({
                      startDate: format(start, 'yyyy-MM-dd'),
                      endDate: format(end, 'yyyy-MM-dd'),
                      guests: guestsRef.current,
                    });
                    if (useImmediateSearch) {
                      if (isListingPicker) {
                        navigate(
                          `/listing/${listingStore?.listing?.id}?${searchParams.toString()}`,
                          { replace: true },
                        );
                      } else {
                        navigate(`/search?${searchParams.toString()}`, { replace: true });
                      }
                    }
                  }
                }}
              >
                {t('Confirm')}
              </SearchButton>
            </div>
          </Row>
        </ButtonWrapper>
      </CardContainer>

      {createPortal(
        <CalendarModalWrapper show={isCalendarModalOpen}>
          {isCalendarModalOpen && (
            <CalendarModal
              useImmediateSearch={useImmediateSearch}
              minGuests={2}
              maxGuests={12}
              onSearch={(bookInfo: BookInfo) => {
                setIsCalendarModalOpen(false);
                onSearch?.(bookInfo);
              }}
              onChange={(bookInfo: BookInfo) => {
                onChange(bookInfo);
              }}
              onClose={() => setIsCalendarModalOpen(false)}
            />
          )}
        </CalendarModalWrapper>,
        document.body,
      )}
    </>
  );
};

const CardContainer = styled.div`
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  display: flex;
  max-width: 100%;
  width: 100%;
  /* overflow: hidden; */
  font-family: Pretendard, sans-serif;
  padding: 12px 0;
  padding-top: 0;
  height: 100vh;
  /* height: 100%;
  max-height: 100vh; */
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
`;

const CountInnerWrapper = styled(CountInner)`
  width: 100%;
  box-shadow: none !important;
  padding: 0 20px;
  padding-bottom: 16px;
  margin: 0;
`;

const SearchButton = styled(BTButton)`
  height: 48px;
  width: 100%;
`;

const ScheduleBox = styled.div`
  width: 100%;

  background: #f8f8f8;
  padding: 24px 20px;
`;

const CheckInText = styled.div`
  color: var(--Grayscale-50, #8e8e8e);
  text-align: center;

  /* 12 medium */
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  letter-spacing: -0.03px;
`;

const DateText = styled.div`
  color: var(--4-e-433-c, #4e433c);
  text-align: center;
  /* 15 semibold */
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 22.5px */
  letter-spacing: -0.037px;
`;

const NoDateText = styled.div`
  color: var(--Grayscale-40, #c6c6c6);
  text-align: center;
  /* 15 semibold */
  font-family: Pretendard;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 22.5px */
  letter-spacing: -0.037px;
`;

const CalendarModalWrapper = styled.div<{ show: boolean }>`
  height: 100vh;
  width: 100%;
  overflow: auto;
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2147483647;
  background-color: #fff;
  isolation: isolate;
`;

export default CalendarModal;
