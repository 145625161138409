import React, { useEffect } from 'react';
import { ListingLowestPriceDto } from 'services/data-contracts';

interface PriceRangeChartProps {
  lowestPrices: ListingLowestPriceDto[];
}

const PriceRangeChart: React.FC<PriceRangeChartProps> = ({
  lowestPrices,
}: {
  lowestPrices: { id: number; price: number }[];
}) => {
  // 가격 범위 계산
  const prices = lowestPrices.map((item) => item.price);
  const maxPrice = Math.max(...prices);
  const totalBars = 10; // 총 바의 개수

  // 구간 크기 계산
  const interval = maxPrice > 0 ? maxPrice / (totalBars - 1) : 0;

  // 각 구간별 레코드 수 계산
  const priceRangeCounts = Array.from({ length: totalBars }, (_, index) => {
    const rangeStart = index * interval;
    const rangeEnd = (index + 1) * interval;

    if (index === 0) {
      // 0원 구간
      return {
        price: 0,
        count: prices.filter((price) => price === 0).length,
      };
    } else if (index === totalBars - 1) {
      // 마지막 구간 (최대값)
      return {
        price: maxPrice,
        count: prices.filter((price) => price >= rangeStart && price <= maxPrice).length,
      };
    } else {
      // 중간 구간
      return {
        price: rangeStart,
        count: prices.filter((price) => price > rangeStart && price <= rangeEnd).length,
      };
    }
  });

  // 최대 레코드 수 계산
  const maxCount = Math.max(...priceRangeCounts.map((item) => item.count));

  return (
    <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
      <br />
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          height: '150px',
          borderBottom: '2px solid #4e433c',
        }}
      >
        {priceRangeCounts.map((item, index) => (
          <div
            key={index}
            style={{
              flex: 1,
              margin: '0 4px',
              textAlign: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              minHeight: '200px',
            }}
          >
            <div style={{ flex: 1 }} />
            <div
              style={{
                height: `${(item.count / maxCount) * 140}px`,
                background: item.count > 0 ? '#92897B' : '#f5f5f5',
                borderRadius: '4px 4px 0 0',
                transition: 'height 0.3s ease',
                opacity: 0.5,
                width: '5px',
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PriceRangeChart;
