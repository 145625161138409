import React, { useRef, CSSProperties, ReactElement, useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import StaticDateRangePicker from '@mui/lab/StaticDateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import i18n from 'i18n';
import { format } from 'date-fns';
import koLocale from 'date-fns/locale/ko';
import enLocale from 'date-fns/locale/en-US';
import jpLocale from 'date-fns/locale/ja';
import cnLocale from 'date-fns/locale/zh-CN';
import { useDateRagePicker } from 'hooks/useDateRagePicker';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import { DateRangePickerDay, DateRangePickerDayProps, PickersDayProps } from '@mui/lab';
import { CalendarDay } from 'services/data-contracts';
import { NumberFormat, Row } from 'saladsoft-primitive';
import { toast } from 'react-toastify';
import { useStoreWrapper } from 'stores/StoreHelper';
export const DateRangePicker = ({
  isListingPicker = true,
  value,
  onChange,
  style,
}: {
  isListingPicker?: boolean;
  value: any;
  onChange?: (date: any) => void;
  style?: CSSProperties;
}) => {
  const { shouldDisableDate: hookDisableDate, getCalendarInfo } = useDateRagePicker();
  const { listingId } = useParams();
  const { listingStore } = useStoreWrapper();

  const [calendarInfo, setCalendarInfo] = useState<CalendarDay[]>([]);

  const [selectedDate, setSelectedDate] = useState<any>([null, null]);
  const [isLoading, setIsLoading] = useState(false);

  const { t, i18n } = useTranslation();

  const [key, setKey] = useState(0);

  const handleOnChangeMonth = async (date: Date) => {
    if (isListingPicker) {
      const calendars = (await getCalendarInfo(Number(listingId), date, 1)) as CalendarDay[];
      setIsLoading(false);
      setCalendarInfo(calendars);
    }
  };

  const renderDayInPicker = (
    date: Date,
    dateRangePickerDayProps: DateRangePickerDayProps<Date>,
  ) => {
    const t = calendarInfo?.filter((dateCalendar: any) => {
      return dateCalendar.date === moment(date).format('YYYY-MM-DD');
    });

    const isPastDate = moment(date).isBefore(moment(), 'day');
    const isAvailable = !isPastDate && t && t[0]?.isAvailable === 1;

    return (
      <>
        <RenderDayStyle
          onClick={(e) => {
            e.currentTarget.classList.add('selectedBtn');
          }}
        >
          <>
            <DateRangePickerDay
              {...dateRangePickerDayProps}
              style={{
                fontSize: 16,
                fontWeight: isAvailable ? 'bold' : 'normal',
              }}
            />
          </>
          <>
            {!isPastDate &&
              !dateRangePickerDayProps.outsideCurrentMonth &&
              t &&
              t[0]?.isAvailable === 1 ? (
              <b style={{ letterSpacing: 0.1 }}>
                <>
                  <NumberFormat
                    style={{ fontSize: 9, fontWeight: 500 }}
                    value={t[0].price || 0}
                    prefix=""
                  />
                </>
              </b>
            ) : (
              <b style={{ letterSpacing: 0.1, height: 10 }} />
            )}
          </>
        </RenderDayStyle>
      </>
    );
  };

  useEffect(() => {
    if (value) {
      setSelectedDate(value);
    }
  }, [value]);

  useEffect(() => {
    // 동적 스타일 태그 생성
    const style = document.createElement('style');
    style.innerHTML = `
      .MuiPaper-root {
       box-shadow:none !important;
       border-radius:20px !important;
       background-color:transparent !important
      }

      .MuiDateRangePickerDay-day.Mui-selected:hover {
        background-color: #92897B !important;
      }

      .MuiDateRangePickerDay-day.Mui-selected {
        background-color: #92897B !important;
      }

      .MuiDateRangePickerDay-day.Mui-selected {
        background-color: #92897B !important;
      }

      .MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight.MuiDateRangePickerDay-rangeIntervalDayHighlightStart {
        background: linear-gradient(to right, transparent 50%, rgba(146, 137, 123, 0.2) 50%) !important;     
        }

      .MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight.MuiDateRangePickerDay-rangeIntervalDayHighlightEnd {
        background: linear-gradient(to left, transparent 50%, rgba(146, 137, 123, 0.2) 50%) !important;


      }

      .MuiDateRangePickerDay-root {
        width:44px !important;
        height:44px !important;
        text-align:center;
      }

    .MuiDateRangePickerDay-day {
        width:40px !important;
        height:40px !important;
    }

      .MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight {
        background:rgba(146, 137, 123, 0.2)!important;
        border-radius:0px ;
      }

      .MuiTypography-subtitle1 {
        color: var(--gray-gray-90, #1d1d1d) !important;
        font-weight: 600 !important;
        font-size: 19px !important;
        line-height: 150% !important;
      }

      .PrivatePickersSlideTransition-root {
        min-height:345px !important;
    

        & [class*="css-mvmu1"] {
          
        }
      }

        .MuiPickerStaticWrapper-root {
          & div[class*="css-1snvurg"] {
            @media (max-width: 768px) {
              width: 100%;
            }
          }

          & div[class*="css-1xhj18k"] {
            gap: 40px;
            margin: 20px 0px !important;

            @media (max-width: 768px) {
              gap: 0px !important;
              margin: 10px 0px !important;
            }
          }

          & div[class*="css-1tape97"] {
            @media (max-width: 768px) {
              padding: 0 16px;
            }
          }

          & div[class*="css-f7iyql"] {
            padding: 16px;

            @media (max-width: 768px) {
              padding: 8px;
            }
          }
        }
      }

      .css-170k9md {
        min-height: 400px !important;
      }

      .MuiPaper-root-MuiPopover-paper {
        background-color: transparent !important;
        overflow: hidden;
        border-radius: 20px;
      }

      .MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight {
      }

      .MuiDateRangePickerDay-day.Mui-selected {
        background-color: #92897B !important;
        color: #fff !important;
      }

      .css-1tape97 {
        border-right:none !important;
        width:50% !important;

        @media screen and (max-width: 768px) {
          width: 100% !important;
        }
      }
    .css-1w13o7u-MuiTypography-root {
      width:40px !important
    }
    `;
    document.head.appendChild(style);
    // 컴포넌트 언마운트 시 스타일 태그 제거

    if (isListingPicker) {
      setIsLoading(true);
      handleOnChangeMonth(new Date());
    }
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  const checkDisableDate = (day: Date) => {

    // isListingPicker가 false거나 listingId가 없으면 모든 날짜 선택 가능
    if (!listingStore.listing?.id) {
      return false;
    }

    // 과거 날짜 체크
    const isPastDate = moment(day).isBefore(moment(), 'day');
    if (isPastDate) return true;

    const currentDateStr = moment(day).format('YYYY-MM-DD');
    const currentDateInfo = calendarInfo?.find((info) => info.date === currentDateStr);

    // 체크인/체크아웃 날짜가 모두 선택된 경우 - isAvailable이 1인 날짜만 선택 가능
    if (selectedDate[0] && selectedDate[1]) {
      return !currentDateInfo?.isAvailable;
    }

    // 체크인 날짜가 없는 경우 - isAvailable이 1인 날짜만 선택 가능
    if (!selectedDate[0]) {
      return !currentDateInfo?.isAvailable;
    }

    // 체크인 날짜가 있는 경우
    const startDate = moment(selectedDate[0]);
    const currentDate = moment(day);

    // 체크인 날짜 이전은 선택 불가
    if (!currentDate.isAfter(startDate)) {
      return true;
    }

    // 체크인 다음날부터 연속된 날짜 체크
    const checkDate = moment(startDate);

    while (checkDate.isBefore(currentDate)) {
      checkDate.add(1, 'days');
      const dateStr = checkDate.format('YYYY-MM-DD');
      const dateInfo = calendarInfo?.find((info) => info.date === dateStr);
      const prevDateStr = checkDate.clone().subtract(1, 'days').format('YYYY-MM-DD');
      const prevDateInfo = calendarInfo?.find((info) => info.date === prevDateStr);

      if (dateStr === currentDateStr) {
        // 체크아웃 가능한 조건:
        // Case 1: 선택일(18일=1) -> 19일(0) 체크아웃 가능, 이후 모든 날짜 불가능
        // Case 2: 선택일(18일=1) -> 19일(1) 가능, 20일(0) 가능, 21일 이후 불가능
        if (!dateInfo?.isAvailable) {
          // 현재 날짜가 0인 경우
          // 이전 날짜가 1이고, 체크인 날짜와 현재 날짜 사이에 0인 날짜가 없는 경우에만 체크아웃 가능
          const hasUnavailableDateBefore = calendarInfo?.some((info) => {
            const infoDate = moment(info.date);
            return (
              infoDate.isAfter(startDate) && infoDate.isBefore(moment(dateStr)) && !info.isAvailable
            );
          });
          return !(prevDateInfo?.isAvailable === 1 && !hasUnavailableDateBefore);
        } else {
          // 현재 날짜가 1인 경우
          // 이전 날짜가 0이거나 이미 0인 날짜가 있었다면 체크아웃 불가능
          const hasUnavailableDate = calendarInfo?.some((info) => {
            const infoDate = moment(info.date);
            return (
              infoDate.isAfter(startDate) && infoDate.isBefore(moment(dateStr)) && !info.isAvailable
            );
          });
          return hasUnavailableDate || prevDateInfo?.isAvailable === 0;
        }
      }

      // 중간 날짜들의 연속성 체크는 유지
      if (!dateInfo?.isAvailable && !prevDateInfo?.isAvailable) {
        return true;
      }
    }

    return true;
  };

  const isAvailableDate = (day: Date) => {
    const currentDateStr = format(day, 'yyyy-MM-dd');
    const currentDateInfo = calendarInfo?.find((info) => info.date === currentDateStr);
    return currentDateInfo?.price && currentDateInfo.isAvailable === 1;
  };

  return (
    <>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={i18n.language === 'ko' ? koLocale : enLocale}
      >
        <StaticDateRangePicker
          key={key}
          disablePast
          disableMaskedInput
          displayStaticWrapperAs="desktop"
          calendars={2}
          renderDay={renderDayInPicker}
          defaultCalendarMonth={value?.[0] ? new Date(value[0]) : new Date()}
          shouldDisableDate={checkDisableDate}
          onMonthChange={(date: Date) => {
            handleOnChangeMonth(date);
          }}
          startText={t('checkIn')}
          value={selectedDate}
          onChange={(date: any, e: any) => {
            // listingId가 없으면 바로 날짜 설정
            if (!listingId) {
              setSelectedDate(date);
              onChange?.(date);
              return;
            }

            // 체크인/체크아웃이 모두 선택된 상태에서 새로운 날짜 선택
            if (selectedDate[0] && selectedDate[1] && date[0]) {
              // 과거 날짜 체크
              const isPastDate = moment(date[0]).isBefore(moment(), 'day');
              if (isPastDate) {
                toast.warning(t('checkInDateNotAvailable'));
                setSelectedDate([null, null]);
                onChange?.([null, null]);
                setKey((prev) => prev + 1);
                return;
              }

              const isAvailable = isAvailableDate(date[0]);
              if (!isAvailable) {
                toast.warning(t('checkInDateNotAvailable'));
                setSelectedDate([null, null]);
                onChange?.([null, null]);
                setKey((prev) => prev + 1);
              } else {
                // 새로운 체크인 날짜만 설정하고 체크아웃은 null로 초기화
                setSelectedDate([date[0], null]);
                onChange?.([date[0], null]);
              }
              return;
            }

            // 체크인 날짜가 있고 체크아웃 날짜를 선택하는 경우
            if (selectedDate[0] && date[1]) {
              const startDate = moment(selectedDate[0]);
              const endDate = moment(date[1]);

              // 시작일부터 선택한 날짜까지의 연속된 체크아웃 가능 여부 확인
              let lastAvailableDate = moment(startDate);
              const checkDate = moment(startDate).add(1, 'days');

              while (checkDate.isSameOrBefore(endDate)) {
                const dateStr = checkDate.format('YYYY-MM-DD');
                const dateInfo = calendarInfo?.find((info) => info.date === dateStr);
                const prevDateInfo = calendarInfo?.find(
                  (info) =>
                    info.date === checkDate.clone().subtract(1, 'days').format('YYYY-MM-DD'),
                );

                if (dateInfo?.isAvailable || prevDateInfo?.isAvailable) {
                  lastAvailableDate = checkDate.clone();
                } else {
                  break;
                }
                checkDate.add(1, 'days');
              }

              // 선택한 날짜가 마지막 체크아웃 가능 날짜 이후라면 선택 불가
              if (endDate.isAfter(lastAvailableDate)) {
                toast.warning(t('unavailableDatesInRange'));
                setSelectedDate([selectedDate[0], null]);
                onChange?.([selectedDate[0], null]);
                return;
              }

              // 체크아웃 날짜로 설정
              const newDate = [selectedDate[0], date[1]];
              setSelectedDate(newDate);
              onChange?.(newDate);
              return;
            }

            // 새로운 체크인 날짜 선택
            setSelectedDate([date[0], null]);
            onChange?.([date[0], null]);
          }}
          renderInput={(startProps: any, endProps: any) => <></>}
        />
      </LocalizationProvider>
      <Overlay isLoading={isLoading}>
        <Row alignItems="center" justifyContent="center" height="100%">
          <img src={'/images/butlerlee-loading.gif'} style={{ width: 100 }} alt="loading" />
        </Row>
      </Overlay>
    </>
  );
};

const RenderDayStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Overlay = styled.div<{ isLoading: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  opacity: ${({ isLoading }) => (isLoading ? 1 : 0)};
  pointer-events: ${({ isLoading }) => (isLoading ? 'auto' : 'none')};
`;