import Section from 'components/layouts/Section';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

function CancellationPolicy({
  style,
  useText = true,
}: {
  style?: React.CSSProperties;
  useText?: boolean;
}) {
  const { t } = useTranslation();

  // 취소 정책 데이터 배열
  const cancellationPolicyData = [
    { days: '10 days before', refund: '100% refund' },
    { days: '9 days before', refund: '90% refund' },
    { days: '8 days before', refund: '80% refund' },
    { days: '7 days before', refund: '70% refund' },
    { days: '6 days before', refund: '60% refund' },
    { days: '5 days before', refund: '50% refund' },
    { days: '4 days before', refund: '40% refund' },
    { days: '3 days before', refund: 'No change or refund' },
  ];

  return (
    <Section title={t('cancellationPolicy')} style={{ cursor: 'default', marginTop: 10, ...style }}>
      <PolicyContainer>
        <PolicyTable>
          <tbody>
            {cancellationPolicyData.map((policy, index) => (
              <TableRow key={index} isLastRow={index === cancellationPolicyData.length - 1}>
                <DaysCell isLastRow={index === cancellationPolicyData.length - 1}>
                  {t(policy.days)}
                </DaysCell>
                <RefundCell isLastRow={index === cancellationPolicyData.length - 1}>
                  {t(policy.refund)}
                </RefundCell>
              </TableRow>
            ))}
          </tbody>
        </PolicyTable>
        {useText && (
          <DisclaimerText>
            {t(
              'The confirmation of the reservation means that you have read and agreed to all of the above.',
            )}
          </DisclaimerText>
        )}
      </PolicyContainer>
    </Section>
  );
}

const PolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 12px;
  width: 100%;
`;

const PolicyTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid rgba(228, 228, 228, 1);
  table-layout: fixed;
`;

const TableRow = styled.tr<{ isLastRow: boolean }>`
  width: 100%;
`;

const DaysCell = styled.td<{ isLastRow: boolean }>`
  background-color: rgba(248, 248, 248, 1);
  padding: 8px 16px;
  border: 1px solid rgba(240, 240, 240, 1);
  color: var(--gray-gray-60, #717171);
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* width: 106px; */
  width: 115px;

  ${(props) =>
    props.isLastRow &&
    `
    background: rgba(222, 216, 212, 0.60);
    color: var(--gray-gray-90, #1D1D1D);
  `}
`;

const RefundCell = styled.td<{ isLastRow: boolean }>`
  background-color: rgba(255, 255, 255, 1);
  padding: 8px 16px;
  border: 1px solid rgba(240, 240, 240, 1);
  color: var(--gray-gray-70, #555);
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;

  ${(props) =>
    props.isLastRow &&
    `
    background: rgba(222, 216, 212, 0.20);
    color: var(--gray-gray-90, #1D1D1D);
  `}
`;

const DisclaimerText = styled.div`
  color: var(--Grayscale-50, #8e8e8e);
  font-weight: 400;
  letter-spacing: -0.03px;
  margin-top: 16px;
  &::before {
    content: '* ';
    color: inherit;
    font: inherit;
  }
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

export default CancellationPolicy;
