/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PaymentDto,
  ReservationDto,
  ReservationRequestDto,
  TosspaymentRequestDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MyReservationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * @description 상태별 예약 목록을 조회합니다.
   *
   * @tags MyReservationService
   * @name GetReservationsByStatus
   * @summary 예약 목록
   * @request GET:/v1/me/reservations
   * @secure
   */
  getReservationsByStatus = (
    query: {
      status?: 'CURRENT' | 'UPCOMING' | 'PAST' | 'CANCELED';
      locale: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ReservationDto[], any>({
      path: `/v1/me/reservations`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyReservationService
   * @name CreateReservation
   * @summary 예약 생성
   * @request POST:/v1/me/reservations
   * @secure
   */
  createReservation = (data: ReservationRequestDto, params: RequestParams = {}) =>
    this.http.request<TosspaymentRequestDto, any>({
      path: `/v1/me/reservations`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyReservationService
   * @name CreateFreeReservation
   * @summary 무료 예약 생성
   * @request POST:/v1/me/reservations/free
   * @secure
   */
  createFreeReservation = (data: ReservationRequestDto, params: RequestParams = {}) =>
    this.http.request<PaymentDto, any>({
      path: `/v1/me/reservations/free`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyReservationService
   * @name GetReservation
   * @summary 예약 정보
   * @request GET:/v1/me/reservations/{reservationId}
   * @secure
   */
  getReservation = (
    reservationId: number,
    query: {
      locale: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ReservationDto, any>({
      path: `/v1/me/reservations/${reservationId}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyReservationService
   * @name GetRefundPrice
   * @summary 예약 취소 시 환불금액
   * @request GET:/v1/me/reservations/{reservationId}/refund-price
   * @secure
   */
  getRefundPrice = (reservationId: number, params: RequestParams = {}) =>
    this.http.request<number, any>({
      path: `/v1/me/reservations/${reservationId}/refund-price`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyReservationService
   * @name GetPaymentByReservation
   * @summary 결제 정보
   * @request GET:/v1/me/reservations/{reservationId}/payment
   * @secure
   */
  getPaymentByReservation = (reservationId: number, params: RequestParams = {}) =>
    this.http.request<PaymentDto, any>({
      path: `/v1/me/reservations/${reservationId}/payment`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyReservationService
   * @name GetTotalStayNights
   * @summary Get Total Stay Nights
   * @request GET:/v1/me/reservations/total-stay-nights
   * @secure
   */
  getTotalStayNights = (params: RequestParams = {}) =>
    this.http.request<number, any>({
      path: `/v1/me/reservations/total-stay-nights`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyReservationService
   * @name GetCalculateRewardPoints
   * @summary 예상 적립 포인트
   * @request GET:/v1/me/reservations/calculate-reward-points
   * @secure
   */
  getCalculateRewardPoints = (
    query: {
      /** @format float */
      totalAmount: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<number, any>({
      path: `/v1/me/reservations/calculate-reward-points`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 예약을 취소하고 관련된 결제도 처리합니다.
   *
   * @tags MyReservationService
   * @name CancelReservation
   * @summary 예약 취소
   * @request DELETE:/v1/me/reservations/{reservationId}/cancel
   * @secure
   */
  cancelReservation = (reservationId: number, params: RequestParams = {}) =>
    this.http.request<ReservationDto, any>({
      path: `/v1/me/reservations/${reservationId}/cancel`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
}
