import * as React from 'react';
import styled from 'styled-components';
import { CapacityInfoProps } from '../types';
import { useTranslation } from 'react-i18next';

export const CapacityInfo: React.FC<CapacityInfoProps> = ({ standardCapacity, maxCapacity }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <StandardCapacity>{t('StandardGuests', { 0: standardCapacity })}</StandardCapacity>
      <Separator>/</Separator>
      <MaxCapacity>{t('MaxGuests', { 0: maxCapacity })}</MaxCapacity>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const StandardCapacity = styled.span`
  color: var(--Color, #1c51b6);
  font-size: 13px;
`;

const Separator = styled.span`
  color: var(--Grayscale-70, #555);
  font-size: 13px;
`;

const MaxCapacity = styled.span`
  color: var(--Grayscale-70, #555);
  font-size: 13px;
  letter-spacing: -0.03px;
`;
