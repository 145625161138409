import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface MembershipCardProps {
  memberName: string;
  membershipLevel: string;
  profileImage: string;
}

const ClassicCard: React.FC<MembershipCardProps> = ({
  memberName,
  membershipLevel,
  profileImage,
}) => {
  const { t } = useTranslation();

  return (
    <CardContainer role="article" aria-label={`${memberName}'s membership status`}>
      <ProfileImage loading="lazy" src={profileImage.toLocaleLowerCase()} alt={`${memberName}'s profile`} />
      <MembershipInfo>
        <MemberName>{t('membershipLevelPrefix', { name: memberName })}</MemberName>
        <StatusWrapper>
          <MembershipLevel>{membershipLevel}</MembershipLevel>
          <StatusText>{t('membershipLevelSuffix')}</StatusText>
        </StatusWrapper>
      </MembershipInfo>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  justify-content: center;
  align-self: stretch;
  background: var(--1-f-1-e-1-d, #1f1e1d);
  display: flex;
  flex-direction: column;
  font-size: 19px;
  text-align: center;
  letter-spacing: -0.05px;
  padding: 46px 20px;
  width: 100%;
  margin: 40px 0;

  @media ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
  }
`;

const ProfileImage = styled.img`
  aspect-ratio: 1.53;
  object-fit: contain;
  object-position: center;
  width: 320px;
  border-radius: 12px;
  align-self: center;
  max-width: 100%;
  @media ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

const MembershipInfo = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const MemberName = styled.div`
  color: var(--Grayscale-0, #fff);
  font-family: Pretendard, sans-serif;
  font-weight: 600;
`;

const StatusWrapper = styled.div`
  display: flex;
  /* margin-top: 12px; */
  align-items: start;
  gap: 2px;
  justify-content: start;
`;

const MembershipLevel = styled.div`
  color: var(--92897-b, #92897b);
  font-family: Montserrat, sans-serif;
  font-weight: 700;
`;

const StatusText = styled.div`
  color: var(--Grayscale-0, #fff);
  font-family: Pretendard, sans-serif;
  font-weight: 600;
`;

export default ClassicCard;
