/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  EmailLoginDto,
  LoginResponseDto,
  SocialLoginRequestDto,
  SocialSignUpDto,
  TokenDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class AuthService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags AuthService
   * @name WithdrawUser
   * @summary Withdraw User
   * @request POST:/v1/auth/withdraw
   * @secure
   */
  withdrawUser = (params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v1/auth/withdraw`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthService
   * @name SignUpWithKakao
   * @summary Sign Up with Kakao
   * @request POST:/v1/auth/signup/kakao
   * @secure
   */
  signUpWithKakao = (data: SocialSignUpDto, params: RequestParams = {}) =>
    this.http.request<TokenDto, any>({
      path: `/v1/auth/signup/kakao`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthService
   * @name SignUpWithGoogle
   * @summary Sign Up with Google
   * @request POST:/v1/auth/signup/google
   * @secure
   */
  signUpWithGoogle = (data: SocialSignUpDto, params: RequestParams = {}) =>
    this.http.request<TokenDto, any>({
      path: `/v1/auth/signup/google`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthService
   * @name RefreshToken
   * @summary Refresh Token
   * @request POST:/v1/auth/refresh-token
   * @secure
   */
  refreshToken = (data: TokenDto, params: RequestParams = {}) =>
    this.http.request<TokenDto, any>({
      path: `/v1/auth/refresh-token`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthService
   * @name TestLogin
   * @summary Test Login
   * @request POST:/v1/auth/login/test-login
   * @secure
   */
  testLogin = (data: EmailLoginDto, params: RequestParams = {}) =>
    this.http.request<TokenDto, any>({
      path: `/v1/auth/login/test-login`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthService
   * @name KakaoLogin
   * @summary Kakao easy login
   * @request POST:/v1/auth/login/kakao
   * @secure
   */
  kakaoLogin = (data: SocialLoginRequestDto, params: RequestParams = {}) =>
    this.http.request<LoginResponseDto, any>({
      path: `/v1/auth/login/kakao`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthService
   * @name GoogleLogin
   * @summary Google easy login
   * @request POST:/v1/auth/login/google
   * @secure
   */
  googleLogin = (data: SocialLoginRequestDto, params: RequestParams = {}) =>
    this.http.request<LoginResponseDto, any>({
      path: `/v1/auth/login/google`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags AuthService
   * @name LoginWithEmail
   * @summary Email Login
   * @request POST:/v1/auth/login/email
   * @secure
   */
  loginWithEmail = (data: EmailLoginDto, params: RequestParams = {}) =>
    this.http.request<TokenDto, any>({
      path: `/v1/auth/login/email`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
