"use client";
import * as React from "react";
import styled from "styled-components";

const HanokValue = () => {
    return (
        <>
            <link
                rel="stylesheet"
                href="https://cdn.jsdelivr.net/npm/@tabler/icons-webfont@latest/dist/tabler-icons.min.css"
            />
            <link
                href="https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Pretendard:wght@400;600;700&display=swap"
                rel="stylesheet"
            />
            <StyledContainer>
                <StyledHeader>
                    <ValueLabel>Our Value</ValueLabel>
                    <ValueTitle>
                        <span>버틀러리는 서·북촌에</span>
                        <StyledBold>소재한 한옥의 가치에 주목합니다</StyledBold>
                    </ValueTitle>
                </StyledHeader>
                <StyledMainImage>
                    <StyledImg
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/cf9ce5618f2cb865a440c20477732afa8bd7948d"
                        alt="Traditional Korean hanok interior with wooden window frame and cushioned seating"
                    />
                </StyledMainImage>
                <StyledFeatures>
                    <StyledFeature>
                        <StyledIconContainer>
                            <div>
                                <img src="/images/icon1.svg" alt="icon_1" />
                            </div>
                        </StyledIconContainer>
                        <StyledFeatureText>
                            <span>각종 규제로</span>
                            <StyledBold>한옥만 건축가능 2층 이상 건축 불가</StyledBold>
                        </StyledFeatureText>
                    </StyledFeature>
                    <StyledFeature>
                        <StyledIconContainer>
                            <div>
                                <img src="/images/icon1.svg" alt="icon_2" />
                            </div>
                        </StyledIconContainer>
                        <StyledFeatureText>
                            <span>대부분 주거 목적</span>
                            <StyledBold>활용없이 방치되는 한옥</StyledBold>
                        </StyledFeatureText>
                    </StyledFeature>
                    <StyledFeature>
                        <StyledIconContainer>
                            <div>
                                <img src="/images/icon1.svg" alt="icon_3" />
                            </div>
                        </StyledIconContainer>
                        <StyledFeatureText>
                            <span>저평가된 한옥</span>
                            <StyledBold>입지대비 저렴한 매입가/임대료</StyledBold>
                        </StyledFeatureText>
                    </StyledFeature>
                </StyledFeatures>
            </StyledContainer>
        </>
    );
};

const StyledContainer = styled.main`
  max-width: 1168px;
  margin: 0 auto;
  padding: 0 20px;
  @media (max-width: 991px) {
    padding: 0 16px;
  }
`;

const StyledHeader = styled.header`
  margin-bottom: 60px;
`;

const ValueLabel = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #92897b;
  letter-spacing: -0.0025em;
  padding-bottom: 8px;
  border-bottom: 1px solid #92897b;
  width: 82px;
  margin-bottom: 24px;
`;

const ValueTitle = styled.h1`
  font-family: "Pretendard", sans-serif;
  font-size: 32px;
  line-height: 150%;
  color: #1d1d1d;
  letter-spacing: -0.08px;
  font-weight: normal;
  @media (max-width: 991px) {
    font-size: 28px;
  }
  @media (max-width: 640px) {
    font-size: 24px;
  }
`;

const StyledBold = styled.span`
  font-weight: 700;
  display: block;
`;

const StyledMainImage = styled.figure`
  width: 100%;
  height: 773px;
  margin: 0 0 72px 0;
  @media (max-width: 640px) {
    height: 400px;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledFeatures = styled.section`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 24px;
  }
`;

const StyledFeature = styled.article`
  flex: 1;
  position: relative;
  border-bottom: 1px solid #8E8E8E;
  padding-bottom: 20px;
`;

const StyledIconContainer = styled.div`
  width: 20px;
  height: 20px;
  margin-bottom: 16px;
`;

const StyledFeatureText = styled.p`
  font-family: "Pretendard", sans-serif;
  font-size: 24px;
  line-height: 150%;
  color: #1f1e1d;
  letter-spacing: -0.06px;
  margin: 0;

  span {
    display: block;
  }

  @media (max-width: 640px) {
    font-size: 20px;
  }
`;

export default HanokValue;
