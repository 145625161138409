import * as React from 'react';
import { Accordion } from '@mui/material';

const BTAccordion = ({
  defaultExpanded,
  disabled,
  children,
}: {
  defaultExpanded: boolean;
  disabled?: boolean;
  children: NonNullable<React.ReactNode>;
}) => {
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      sx={{
        boxShadow: 'none',
        '&.MuiPaper-root': { boxShadow: 'none' },
      }}
    >
      {children}
    </Accordion>
  );
};

export default BTAccordion;
