import { CompanyInfoItem, SocialIcon } from './types';

export const companyInfo: CompanyInfoItem[] = [
  { label: 'companyNameTitle', value: 'companyName' },
  { label: 'representativeTitle', value: 'representative' },
  { label: 'businessRegistrationNumberTitle', value: 'businessRegistrationNumber' },

  { label: 'telecomSalesRegistrationNumberTitle', value: 'telecomSalesRegistrationNumber' },
  { label: 'addressTitle', value: 'address' },
];

export const socialIcons: SocialIcon[] = [

  {
    src: 'https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/9c7170e4c597e869da85824b0660d09a4a01e7e372dd4eb393b2c29037f05a67?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&',
    alt: 'Social media icon 2',
    linkTo: 'https://www.instagram.com/butlerlee.hanok/',
  },
  {
    src: 'https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/6038ac2156e207de203dd4512eaf10a3c73dee73d112eb33352f62dd6bbe9849?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&',
    alt: 'Social media icon 3',
    linkTo: 'https://www.youtube.com/@butlerlee9556/',
  },
];
