/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  NonMemberReservationDto,
  PaymentDto,
  ReservationDto,
  TosspaymentRequestDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class NonMemberReservationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags NonMemberReservationService
   * @name CreateReservationForNonMember
   * @summary 비회원 예약 생성
   * @request POST:/v1/non-member/reservation
   * @secure
   */
  createReservationForNonMember = (data: ReservationDto, params: RequestParams = {}) =>
    this.http.request<TosspaymentRequestDto, any>({
      path: `/v1/non-member/reservation`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags NonMemberReservationService
   * @name GetRefundPriceForNonMember
   * @summary 비회원 예약 취소 시 환불 금액
   * @request POST:/v1/non-member/reservation/refund-price
   * @secure
   */
  getRefundPriceForNonMember = (data: NonMemberReservationDto, params: RequestParams = {}) =>
    this.http.request<number, any>({
      path: `/v1/non-member/reservation/refund-price`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags NonMemberReservationService
   * @name GetPaymentForNonMember
   * @summary 비회원 예약 결제 정보 조회
   * @request POST:/v1/non-member/reservation/payment/inquiry
   * @secure
   */
  getPaymentForNonMember = (data: NonMemberReservationDto, params: RequestParams = {}) =>
    this.http.request<PaymentDto, any>({
      path: `/v1/non-member/reservation/payment/inquiry`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 예약 번호와 게스트 전화번호를 이용하여 비회원 예약 정보를 조회합니다.
   *
   * @tags NonMemberReservationService
   * @name GetReservationForNonMember
   * @summary 비회원 예약 조회
   * @request POST:/v1/non-member/reservation/inquiry
   * @secure
   */
  getReservationForNonMember = (
    query: {
      locale: string;
    },
    data: NonMemberReservationDto,
    params: RequestParams = {},
  ) =>
    this.http.request<ReservationDto, any>({
      path: `/v1/non-member/reservation/inquiry`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * @description 예약을 취소하고 관련된 결제도 처리합니다.
   *
   * @tags NonMemberReservationService
   * @name CancelReservationForNonMember
   * @summary 비회원 예약 취소
   * @request DELETE:/v1/non-member/reservation/cancel
   * @secure
   */
  cancelReservationForNonMember = (data: NonMemberReservationDto, params: RequestParams = {}) =>
    this.http.request<ReservationDto, any>({
      path: `/v1/non-member/reservation/cancel`,
      method: 'DELETE',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
}
