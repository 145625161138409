import * as React from 'react';
import styled from 'styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const PaymentMethods = ({ onChange }: { onChange: (isForeignCard: boolean) => void }) => {
  const { t } = useTranslation();
  const [isForeignCard, setIsForeignCard] = useState(false);

  return (
    <Container>
      <PaymentMethodWrapper>
        <PaymentSection>
          <TitleContainer>
            <Title>{t('payment method')}</Title>
            <RequiredMark>*</RequiredMark>
          </TitleContainer>
          <TabContainer>
            <PaymentTab
              active={!isForeignCard}
              onClick={() => {
                setIsForeignCard(false);
                onChange(false);
              }}
            >
              {t('credit card')}
            </PaymentTab>
            <ForeignCardTab
              className={isForeignCard ? 'active' : ''}
              onClick={() => {
                setIsForeignCard(true);
                onChange(true);
              }}
              active={isForeignCard}
            >
              {t('foreign card')}
            </ForeignCardTab>
          </TabContainer>
        </PaymentSection>
      </PaymentMethodWrapper>
    </Container>
  );
};

export default PaymentMethods;

const Container = styled.div`
  display: flex;
  max-width: 792px;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  justify-content: center;
  padding: 1px 0;
`;

const PaymentMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  @media ${(props) => props.theme.media.mobile} {
  }
`;

const PaymentSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
`;

const TitleContainer = styled.div`
  border-radius: 0px 0px 0px 0px;
  display: flex;
  max-width: 100%;
  gap: 4px;
  font-weight: 600;
`;

const Title = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 17px;
  letter-spacing: -0.04px;
`;

const RequiredMark = styled.div`
  color: var(--error, #f35064);
  font-size: 19px;
  letter-spacing: -0.05px;
  flex-grow: 1;
  width: 267px;
  flex-basis: auto;
`;

const TabContainer = styled.div`
  display: flex;
  margin-top: 20px;
  min-height: 48px;
  width: 100%;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.04px;
  justify-content: start;
  flex-wrap: nowrap;ㅜ
`;

const PaymentTab = styled.div<{ active: boolean }>`
  cursor: pointer;
  align-self: stretch;
  flex: 1;
  border-radius: 8px;
  border: ${({ active }) =>
    active ? '1px solid var(--92897-b, #92897b)' : '1px solid var(--Grayscale-20, #e4e4e4)'};
  background-color: ${({ active }) => (active ? '#F7F3EF' : '#FFFFFF')};
  min-width: 240px;
  min-height: 48px;
  gap: 10px;
  color: var(--4-e-433-c, #4e433c);
  white-space: nowrap;
  margin: auto 0;
  padding: 14px 0;
  @media ${(props) => props.theme.media.mobile} {
    /* min-width: 165px; */
    min-width: 0;
  }
`;

const ForeignCardTab = styled.div<{ active: boolean }>`
  cursor: pointer;
  align-self: stretch;
  flex: 1;
  border-radius: 8px;
  border: ${({ active }) =>
    active ? '1px solid var(--92897-b, #92897b)' : '1px solid var(--Grayscale-20, #e4e4e4)'};
  min-width: 240px;
  min-height: 48px;
  gap: 10px;
  color: var(--Grayscale-70, #555);
  margin: auto 0;
  padding: 14px 0;
  background-color: ${({ active }) => (active ? '#F7F3EF' : '#FFFFFF')};
  @media ${(props) => props.theme.media.mobile} {
    /* min-width: 165px; */
    min-width: 0;
  }
`;
