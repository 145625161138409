import { convertStringToDate } from 'libs/helper/utils';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberFormat, useMediaContext } from 'saladsoft-primitive';
import { ListingDto, ListingPriceDto, ReservationDto } from 'services/data-contracts';
import styled from 'styled-components';
import { format } from 'react-string-format';
import ParkingComponent from 'components/commons/ParkingComponent';
import { Divider } from '@mui/material';

function BasicInfoContainer({
  bookInfo,
  priceInfo,
  listing,
}: {
  bookInfo?: ReservationDto;
  priceInfo?: ListingPriceDto;
  listing?: ListingDto;
}) {
  const { isMobile } = useMediaContext();
  const { t } = useTranslation();
  const [stayDuration, setStayDuration] = useState<string>('');
  const [nightsOnly, setNightsOnly] = useState<string>('');
  const [nightsAndDays, setNightsAndDays] = useState<string>('');

  useEffect(() => {
    if (bookInfo) {
      const startDate = new Date(bookInfo?.arrivalDate ?? '');
      const endDate = new Date(bookInfo?.departureDate ?? '');
      const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      // 숙박 일수
      const nights = bookInfo?.nights ?? diffDays;
      const days = nights + 1;

      const nightsAndDays = format(t('nightsAndDaysFormat'), nights, days);
      setNightsAndDays(nightsAndDays);

      const formattedStartDate = convertStringToDate(bookInfo?.arrivalDate ?? '');
      const formattedEndDate = convertStringToDate(bookInfo?.departureDate ?? '');

      setStayDuration(
        `${formattedStartDate} ~ ${formattedEndDate} (${format(t('stayDurationFormat'), diffDays)})`,
      );
    }
  }, [bookInfo]);

  return (
    <BasicInfo>
      <ListingInfo>
        <Img loading="lazy" src={listing?.images?.[0]?.thumbnailUrl} />
        <Div>
          <Div2>
            <Div3>
              <Div4>{listing?.name}</Div4>
              <Div5>{listing?.address}</Div5>
            </Div3>
          </Div2>
          <Div6>
            <Div7>
              <Div8>{format(t('StandardGuests'), listing?.minGuest ?? 0)}</Div8>
              <Div9>/</Div9>
              <Div10>{format(t('maxGuest'), listing?.maxGuest ?? 0)}</Div10>
            </Div7>
            <Img2
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/0c403801ac5e000543d603679d6fa54f9edfa91182cb19a270236bbe969d7fe6?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
            />
            <Div11>
              <Div12>
                <ParkingComponent house={listing as ListingDto} />
              </Div12>
            </Div11>
          </Div6>
        </Div>
      </ListingInfo>
      <BookInfoStyle>
        <Div13>
          <Div14>
            <Div15>{t('date')}</Div15>
            <Div16>
              <Div17>{stayDuration}</Div17>
            </Div16>
          </Div14>
          <Div18>
            <Div19>{t('guest')}</Div19>
            <Div20>{format(t('guestsFormat'), bookInfo?.adults ?? 2)}</Div20>
          </Div18>
          <Divider style={{ margin: '16px 0' }} />
          <Div21>
            <Div22>{t('product_price')}</Div22>
            <Div23>
              <StayInfoContainer>
                <StayInfoItem>{nightsAndDays}</StayInfoItem>
                <StayInfoDivider>/</StayInfoDivider>
                <StayInfoItem>{format(t('guestsFormat'), bookInfo?.adults ?? 2)}</StayInfoItem>
              </StayInfoContainer>
              <Div25>
                <Div26>
                  <NumberFormat value={priceInfo?.totalPrice ?? 0} />
                </Div26>
                <Div27>{t('won')}</Div27>
              </Div25>
            </Div23>
          </Div21>
        </Div13>
      </BookInfoStyle>
    </BasicInfo>
  );
}

const BasicInfo = styled.div`
  display: flex;
  max-width: 792px;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
`;

const ListingInfo = styled.div`
  display: flex;
  gap: 16px;
  justify-content: start;
  flex-wrap: nowrap;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 100px;
  border-radius: 4px;
  align-self: start;
`;

const Div = styled.div`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  flex-basis: 0%;
  @media ${(props) => props.theme.media.mobile} {
  }
`;

const Div2 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
`;

const Div3 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  @media ${(props) => props.theme.media.mobile} {
  }
`;

const Div4 = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.04px;
`;

const Div5 = styled.div`
  color: var(--Grayscale-50, #8e8e8e);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.03px;
`;

const Div6 = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  color: var(--Grayscale-70, #555);
  font-weight: 400;
  justify-content: start;
  flex-wrap: wrap;
  @media ${(props) => props.theme.media.mobile} {
    margin-top: 12px;
  }
`;

const Div7 = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: start;
  margin: auto 0;
`;

const Div8 = styled.div`
  color: var(--Color, #1c51b6);
  align-self: stretch;
  margin: auto 0;
`;

const Div9 = styled.div`
  align-self: stretch;
  margin: auto 0;
`;

const Div10 = styled.div`
  letter-spacing: -0.03px;
  align-self: stretch;
  margin: auto 0;
`;

const Img2 = styled.img`
  aspect-ratio: 0.08;
  object-fit: contain;
  object-position: center;
  width: 1px;
  stroke-width: 1px;
  stroke: var(--Grayscale-20, #e4e4e4);
  align-self: stretch;
  margin: auto 0;
`;

const Div11 = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 2px;
  white-space: nowrap;
  letter-spacing: -0.03px;
  justify-content: start;
  margin: auto 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Img3 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 16px;
  align-self: stretch;
  margin: auto 0;
`;

const Div12 = styled.div`
  align-self: stretch;
  margin: auto 0;
`;

const BookInfoStyle = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
`;

const Div13 = styled.div`
  border-radius: 0px 0px 12px 12px;
  background-color: rgba(251, 249, 245, 1);
  border-color: rgba(193, 186, 182, 1);
  border-top-width: 1px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  padding: 20px 16px;
  border-top: 1px solid #c1bab6;

  @media ${(props) => props.theme.media.mobile} {
  }
`;

const Div14 = styled.div`
  display: flex;
  min-height: 10px;
  width: 100%;
  align-items: center;
  gap: 32px;
  font-size: 14px;
  letter-spacing: -0.04px;
  justify-content: start;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
    align-items: center;
  }
`;

const Div15 = styled.div`
  color: var(--Grayscale-50, #8e8e8e);
  font-weight: 500;
  align-self: stretch;
  margin: auto 0;
`;

const Div16 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 400;
  text-align: center;
  justify-content: center;
  margin: auto 0;
`;

const Div17 = styled.div`
  width: 100%;
`;

const Div18 = styled.div`
  display: flex;
  margin-top: 10px;
  min-height: 10px;
  width: 100%;
  align-items: center;
  gap: 32px;
  font-size: 14px;
  white-space: nowrap;
  letter-spacing: -0.04px;
  justify-content: flex-start;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
    white-space: initial;
  }
`;

const Div19 = styled.div`
  color: var(--Grayscale-50, #8e8e8e);
  font-weight: 500;
  align-self: stretch;
  margin: auto 0;
`;

const Div20 = styled.div`
  align-self: stretch;
  gap: 8px;
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 400;
  text-align: center;
  margin: auto 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div21 = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: 991px) {
    max-width: 100%;
    align-items: center;
  }
`;

const Div22 = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.04px;
`;

const Div23 = styled.div`
  display: flex;
  min-width: 240px;
  min-height: 34px;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  width: 242px;
`;

const Div24 = styled.div`
  color: var(--Grayscale-70, #555);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.03px;
`;

const StayInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StayInfoItem = styled.div`
  color: var(--Grayscale-70, #555);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.03px;
`;

const StayInfoDivider = styled.div`
  color: var(--Grayscale-70, #555);
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.03px;
  margin: 0 4px;
`;

const Div25 = styled.div`
  display: flex;
  align-items: center;
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 600;
  white-space: nowrap;
  justify-content: start;
  @media (max-width: 991px) {
    white-space: initial;
    margin-top: 0;
  }
`;

const Div26 = styled.div`
  font-size: 19px;
  letter-spacing: -0.05px;
  align-self: stretch;
  margin: auto 0;
`;

const Div27 = styled.div`
  font-size: 13px;
  letter-spacing: -0.03px;
  align-self: stretch;
  margin: auto 0;
`;

export default BasicInfoContainer;
