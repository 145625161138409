import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styled from 'styled-components';
import { Row } from 'saladsoft-primitive';

interface AccordionHeaderProps {
  title: string;
  index: number;
  required?: boolean;
}

const AccordionHeader = ({ title, index, required = false }: AccordionHeaderProps) => {
  const { t } = useTranslation();

  return (
    <TitleContainer>
      <Row justifyContent="flex-start" alignItems="center" gap="8px">
        <Disc>{index}</Disc>
        <Title>{title}</Title>
      </Row>
      {required && <RequiredMark>*</RequiredMark>}
    </TitleContainer>
  );
};

const TitleContainer = styled.div`
  display: flex;
  gap: 4px;
  font-weight: 600;
`;

const AccordionList = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Disc = styled.div`
  background: #1f1e1d;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.03px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 17px;
  letter-spacing: -0.04px;
`;

const RequiredMark = styled.div`
  color: var(--error, #f35064);
  font-size: 19px;
  letter-spacing: -0.05px;
  flex-grow: 1;
  flex-basis: auto;
`;

export default AccordionHeader;
