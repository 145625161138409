import PromotionItem from 'components/pages/promotions/Item';
import PageContainer from 'containers/pc/PageContainer';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import ServiceHelper from 'stores/ServiceHelper';
import i18n from 'i18n';
import { PromotionDto } from 'services/data-contracts';
import { Row, useMediaContext } from 'saladsoft-primitive';
import PromotionFilter from 'components/commons/PromotionFilter';
import { useStoreWrapper } from 'stores/StoreHelper';

type PromotionStatus = 'ONGOING' | 'ENDED';
interface TabItem {
  id: number;
  label: string;
  key: PromotionStatus;
}

const PromotionPage = () => {
  const { t } = useTranslation();
  const navigate = useLocaleNavigate();
  const { isMobile } = useMediaContext();
  const { uiStore } = useStoreWrapper();

  const tabs: TabItem[] = [
    { id: 1, label: 'Ongoing', key: 'ONGOING' },
    { id: 2, label: 'Ended', key: 'ENDED' },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [promotions, setPromotions] = useState<PromotionDto[]>([]);

  const onTabChange = (tab: TabItem) => {
    setSelectedTab(tab);
    fetchPromotions(tab.key);
  };

  const fetchPromotions = (promotionType: PromotionStatus) => {
    ServiceHelper.promotionService
      ?.getPromotions({
        locale: i18n.language,
      })
      .then((res) => {
        if (promotionType === 'ONGOING') {
          const filteredPromotions = res.data?.filter((promotion) => !promotion.isExpire);
          setPromotions(filteredPromotions);
        } else {
          const filteredPromotions = res.data?.filter((promotion) => promotion.isExpire);
          setPromotions(filteredPromotions);
        }
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchPromotions(selectedTab.key);
  }, []);

  const getPromotionSummaryText = (tab: TabItem, count: number) => {
    return tab.key === 'ONGOING' ? (
      <span>
        {t('reservation.ongoingPromotion')} <span style={{ fontWeight: 600 }}>{count}</span>
        {t('reservation.promotionCount')}
      </span>
    ) : (
      <span>
        {t('reservation.endedPromotion')} <span style={{ fontWeight: 600 }}>{count}</span>
        {t('reservation.promotionCount')}
      </span>
    );
  };

  return (
    <PageContainer>
      <div
        className="page-container"
        style={{ marginTop: isMobile ? 20 : '56px', minHeight: 'calc(100vh - 120px)', padding: '0 20px' }}
      >
        <TabContainer>
          <TabButtonWrapper>
            {tabs.map((tab) => (
              <TabButton
                key={tab.id}
                isSelected={selectedTab.id === tab.id}
                onClick={() => onTabChange(tab)}
                tabIndex={0}
                role="tab"
                aria-selected={selectedTab.id === tab.id}
              >
                {t(`${tab.label}`)}
              </TabButton>
            ))}
          </TabButtonWrapper>
          <TabContentWrapper>
            <PromotionSummary>
              {getPromotionSummaryText(selectedTab, promotions?.length || 0)}
              <PromotionFilter
                onSort={() => {
                  fetchPromotions(selectedTab.key);
                }}
                isMobile={isMobile}
              />
            </PromotionSummary>
            {promotions && promotions?.length === 0 && (
              <Row height="calc(100% - 200px)" justifyContent="center" alignItems="center">
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div>
                    <img src="/images/no-contents.svg" alt="" />
                  </div>
                  <div style={{ color: '#8E8E8E', fontSize: '15px', fontWeight: '400' }}>
                    {t(`no-${selectedTab.label}`)}
                  </div>
                </div>
              </Row>
            )}
            {promotions?.map((promotion, index) => (
              <PromotionItem
                key={promotion.id}
                promotion={promotion}
                onClick={() => {
                  navigate(`/promotion/${promotion.id}`);
                }}
                isLast={index === promotions.length - 1}
              />
            ))}
            {/* <MyReservationInfo /> */}
          </TabContentWrapper>
        </TabContainer>
      </div>
    </PageContainer>
  );
};

const TabContainer = styled.div`
  align-items: start;
  align-self: stretch;
  background: var(--white, #fff);
  display: flex;
  height: 100vh;
  justify-content: start;
  flex-wrap: wrap;
`;

const TabButtonWrapper = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  justify-content: start;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--Grayscale-20, #e4e4e4);
  background: var(--white, #fff);
`;

const TabButton = styled.button<{ isSelected: boolean }>`
  min-height: 32px;
  white-space: nowrap;
  font:
    600 24px Pretendard,
    sans-serif;
  color: ${(props) =>
    props.isSelected ? 'var(--Grayscale-90, #1d1d1d)' : 'var(--Grayscale-50, #8e8e8e)'};
  border: none;
  background: none;
  border-bottom: ${(props) =>
    props.isSelected ? '2px solid var(--Grayscale-90, #1d1d1d)' : 'none'};
  cursor: pointer;
  padding: 0 0 8px 0;

  @media ${(props) => props.theme.media.mobile} {
    font-size: 17px;
    letter-spacing: -0.042px;
  }
`;

const TabContentWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const PromotionSummary = styled.div`
  display: flex;
  padding: 16px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  font:
    400 15px Pretendard,
    sans-serif;
  color: var(--Grayscale-50, #8e8e8e);
`;

export default PromotionPage;
