import React, { useEffect, useRef, useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Row } from 'saladsoft-primitive';
import styled from 'styled-components';

const ChipButton = ({
  id,
  label,
  selected,
  onChange,
}: {
  id: string;
  label: string;
  selected?: boolean;
  onChange: (id: string, selected: boolean) => void;
}) => {
  const idRef = useRef(id);

  const [checked, setChecked] = useState(selected);

  const handleChange = () => {
    setChecked(!checked);
    onChange(idRef.current, !checked);
  };

  useEffect(() => {
    setChecked(selected);
  }, [selected]);

  return (
    <ChipButtonWrapper onClick={handleChange} checked={checked || false}>
      <Row gap={10}>
        <div style={{ color: checked ? '#4E433C' : '#555' }}>{label}</div>
        <CheckIcon style={{ width: 20, height: 20, color: checked ? '#4E433C' : '#C6C6C6' }} />
      </Row>
    </ChipButtonWrapper>
  );
};

const ChipButtonWrapper = styled.div<{ checked?: boolean }>`
  border-radius: 100px;
  border: 1px solid var(--Grayscale-20, #e4e4e4);
  padding: 8px 12px;
  cursor: pointer;
  background-color: ${({ checked }) => (checked ? '#F7F3EF' : 'transparent')};
  > div:first-child {
    font-size: 14px;
    font-weight: 600;
    color: ${({ checked }) => (checked ? '#fff' : '#C6C6C6')};
  }
`;

export default ChipButton;
