import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
      font-family: 'Pretendard', sans-serif;
      letter-spacing: -0.055px;
      font-style: normal;
    }
    
  :root {
    --primary-color: #4E433C;
    --secondary-color: #92897B;
    --tertiary-color: #DED8D4;
    --quaternary-color: #1F1E1D;
    --disabled-color: #D8D8D8;
    --point-color: #920000;
    --padding: 10px;
    --margin: 20px;
    --gnb-height:85px;
    --border-radius:8px;
    --border-color:#ebebeb;
    --background:#F3F4F1;
    --max-width:1200px;
  }

  // 모바일 BottomSheet 스타일 초기화
  [data-rsbs-header] {
    display: none !important;
  }
   
   [data-rsbs-content] > div {
    margin-top: 0 !important;
   }

   .Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: #4E433C !important; /* 원하는 성공 메시지 배경색 */
  }
`;

export default GlobalStyle;
