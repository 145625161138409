import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  ReservationRequestDto,
  UserCouponDto,
  UserDto,
  UserInfoDto,
  VoucherDto,
} from 'services/data-contracts';
import { useUserInfo } from 'providers/UserInfoProvider';
import { useEffect, useState } from 'react';
import BTAccordion from 'components/commons/BTAccordion';
import { AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionHeader from '../../AccordionHeader';
import CheckedCoupon from './CheckedCoupon';
import { SectionHeader } from './SectionHeader';
import { SearchField } from './SearchField';

interface DiscountSectionProps {
  memberInfo?: UserDto;
  onSearch: (type: string, value: string | undefined) => void;
  userInfo?: UserInfoDto;
  voucher?: VoucherDto;
  coupon?: UserCouponDto;
  onCloseDiscount?: (type: 'voucher' | 'coupon') => void;
}

export function DiscountSection({
  memberInfo,
  onSearch,
  voucher,
  coupon,
  onCloseDiscount,
}: DiscountSectionProps) {
  const { t } = useTranslation();
  const { loginStatus, myPoint } = useUserInfo();
  const [pointError, setPointError] = useState<string>('');
  const [pointInput, setPointInput] = useState<string>('');

  const [validVoucher, setValidVoucher] = useState(true);
  const [validCoupon, setValidCoupon] = useState(true);

  const getVoucherPrice = (voucher: VoucherDto) => {
    if (voucher.discountType === 'FIXED') {
      return voucher.price?.toString() ?? '';
    } else {
      return `${voucher.price}%`;
    }
  };

  const getCouponPrice = (coupon: UserCouponDto) => {
    if (coupon.coupon?.discountType === 'FIXED') {
      return `${coupon.coupon?.price?.toLocaleString()} ${t('won')}`;
    } else {
      return `${coupon.coupon?.price}%`;
    }
  };

  const validatePoint = (value: string) => {
    const inputPoint = Number(value);
    if (isNaN(inputPoint) || inputPoint < 0) {
      setPointError(t('pleaseEnterValidNumber'));
      return false;
    }
    if (inputPoint > myPoint) {
      setPointError(t('enteredPointExceedsBalance'));
      onSearch('points', '0');
      return false;
    }
    setPointError('');
    return true;
  };

  const handlePointChange = (value: string) => {
    setPointInput(value);
    const isValid = validatePoint(value);
    if (isValid) {
      onSearch('points', value);
    }
  };

  const handlePointSearch = (value: string) => {
    if (validatePoint(value)) {
      onSearch('points', value);
    }
  };

  useEffect(() => {
    // voucher가 선택된 경우 coupon 사용 불가
    if (voucher) {
      setValidCoupon(false);
    }
    // coupon이 선택된 경우 voucher 사용 불가
    else if (coupon) {
      setValidVoucher(false);
    }
    // 둘 다 선택되지 않은 경우 모두 사용 가능
    else {
      setValidVoucher(true);
      setValidCoupon(true);
    }
  }, [voucher, coupon]);

  return (
    <div>
      <StyledContainer>
        <HeaderContainer>
          <AccordionHeader title={t('payment')} index={4} />
        </HeaderContainer>
        <ContentContainer>
          <Container role="main" aria-label={t('discountSection')}>
            {memberInfo && (
              <SectionContainer>
                <SectionHeader title={t('points')} />
                <SearchField
                  isValid
                  buttonText={t('apply')}
                  onBlur={(value: string) => {
                    onSearch('points', value);
                  }}
                  showBalance
                  balance={myPoint.toLocaleString()}
                  totalPoints={myPoint}
                  usedPoints={Number(pointInput) || 0}
                  onSearch={handlePointSearch}
                  onChangeValue={handlePointChange}
                  value={pointInput}
                  sectionId="points"
                  errorMessage={pointError}
                />
              </SectionContainer>
            )}

            <SectionContainer>
              <SectionHeader title={t('voucher')} />
              <SearchField
                buttonText={t('apply')}
                showBalance={false}
                isValid={validVoucher}
                value={voucher ? voucher?.code : ''}
                onSearch={(value) => onSearch('voucher', value)}
                sectionId="voucher"
                placeholder={t('voucherPlaceholder')}
              />
            </SectionContainer>
            {voucher && (
              <div style={{ marginTop: 16, maxWidth: 343 }}>
                <CheckedCoupon
                  title={voucher.category?.name as string}
                  price={getVoucherPrice(voucher)}
                  onClose={() => {
                    onCloseDiscount?.('voucher');
                  }}
                />
              </div>
            )}

            <SectionContainer>
              <SectionHeader title={t('coupon')} />
              {loginStatus ? (
                <SearchButton
                  isValid={validCoupon}
                  onClick={() => {
                    if (!voucher) {
                      onSearch('coupon', '');
                    }
                  }}
                  aria-label={`${t('coupon')} 검색하기`}
                >
                  {t('search')}
                </SearchButton>
              ) : (
                <></>
              )}
            </SectionContainer>

            {coupon && (
              <div style={{ marginTop: 16, maxWidth: 343 }}>
                <CheckedCoupon
                  title={coupon.coupon?.name as string}
                  price={getCouponPrice(coupon)}
                  onClose={() => {
                    onCloseDiscount?.('coupon');
                  }}
                />
              </div>
            )}

            {pointInput && Number(pointInput) > 0 && (
              <div style={{ marginTop: 16, maxWidth: 343 }}>
                <CheckedCoupon
                  title={t('Point Used')}
                  price={`${Number(pointInput).toLocaleString()} P`}
                  onClose={() => {
                    setPointInput('');
                    onSearch('points', '0');
                  }}
                />
              </div>
            )}
          </Container>
        </ContentContainer>
      </StyledContainer>
    </div>
  );
}

const StyledContainer = styled.div`
  box-shadow: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
`;

const HeaderContainer = styled.div`
  padding: 0;
`;

const ContentContainer = styled.div`
  padding: 0;
`;

const Container = styled.main`
  margin: 24px 0 40px 0;
  display: flex;
  max-width: 343px;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  @media ${(props) => props.theme.media.mobile} {
    max-width: 100%;
  }
`;

const SectionContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 30px;
  &:first-child {
    margin-top: 0;
  }
`;

const SearchButton = styled.button<{ isValid: boolean }>`
  border-radius: 8px;
  border: 1px solid var(--Grayscale-20, #e4e4e4);
  background-color: #fff;
  margin-top: 12px;
  min-height: 48px;
  width: 100%;
  font-size: 15px;
  color: ${(props) => (props.isValid ? 'var(--Grayscale-70, #555)' : 'var(--Grayscale-30, #ccc)')};
  text-align: center;
  padding: 0 16px;
  cursor: pointer;
  opacity: ${(props) => (props.isValid ? 1 : 0.5)};
  &:focus-visible {
    outline: 2px solid var(--Color, #1c51b6);
    outline-offset: 2px;
  }

  &:hover {
    background-color: var(--Grayscale-5, #f8f8f8);
  }
`;
