import * as React from 'react';
import styled from 'styled-components';
// import { BedTypeDto } from 'services/data-contracts';
import { ListingBedDto } from 'services/data-contracts';
import { useEffect, useState } from 'react';
import ServiceHelper from 'stores/ServiceHelper';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { useStoreWrapper } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { format } from 'react-string-format';

const CheckPoint = observer(() => {
  const { t } = useTranslation();
  const { listingStore } = useStoreWrapper();
  const [bedTypes, setBedTypes] = useState<ListingBedDto[]>([]);

  useEffect(() => {
    if (listingStore?.listing) {
      ServiceHelper.listingService
        ?.getBedTypesByListing(listingStore?.listing?.id as number, {
          locale: i18n.language,
        })
        .then((res) => {
          setBedTypes(res.data);
        });
    }
  }, [listingStore?.listing]);

  return (
    <Div>
      <Div2>
        <Div3>
          <Img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/aa8922d54543d3e578a72beff0d4bad1e2e8ac709bd7ad7791be015a2af4b378?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
          />
          <Checkpoint>CHECK POINT</Checkpoint>
        </Div3>
        <Div4>
          <Div5>
            <Div6>
              <Div7>
                <Img2
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/a79ba8cdba6900dd943da1964f6ed69e7f5a7345db3ab36a88c45a7e4abd5dc6?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
                />
              </Div7>
              <Div8>
                <Div9>{format(t('baseGuest'), listingStore?.listing?.minGuest || 2)}</Div9>
                <Div10>/</Div10>
                <Div11>{format(t('maxGuest'), listingStore?.listing?.maxGuest || 6)}</Div11>
              </Div8>
            </Div6>
            <Div12>
              <Div13>
                <Img3
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/70c0b1fbc3272ae3893f88a36a203462b3688b4e4a6bb9c7f72fc25c3090658b?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
                />
              </Div13>
              {bedTypes?.map((bedType) => (
                <Div14 key={bedType.id}>
                  <BedTypeName>{bedType.name}</BedTypeName>
                  <BedTypeQuantity>{bedType.quantity}</BedTypeQuantity>
                </Div14>
              ))}
            </Div12>
            <Div15>
              <Div16>
                <Img4
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/c9cced955848ccde1884089d4d1d70f00c5147d86c7ec44d93abd970620463f3?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
                />
              </Div16>
              <Div17>
                <Div18>
                  <Div19>
                    {listingStore?.listing?.hasParking
                      ? t('parkingAvailable')
                      : t('parkingUnavailable')}
                  </Div19>
                  {!listingStore?.listing?.hasParking && (
                    <Img5
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/6b4f48b730976596ed563c5066197f6e31cdf3997408fc17020e78a59a5f2393?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
                    />
                  )}
                </Div18>
              </Div17>
            </Div15>
          </Div5>
          <Img6
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/fe982f486e480c9298516dd04158efa082bd22bec73aaa56adf3e8e28581955f?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
          />
          <Div20>
            {/* <Div21>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/5b7f61eb062e18aa3aab7884ece74a11b2dd2e96d04869b5196598be0c286902?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
              />
              <Div22>야외 욕조</Div22>
            </Div21> */}
            <Div23>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/5b7f61eb062e18aa3aab7884ece74a11b2dd2e96d04869b5196598be0c286902?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25"
              />
              <Otttv>{t('ottAvailable')}</Otttv>
            </Div23>
          </Div20>
          {/* <Div24>{t('WinterNotice')}</Div24> */}
        </Div4>
      </Div2>
    </Div>
  );
});

const Div = styled.div`
  border-radius: 0px 0px 12px 12px;
  background-color: rgba(251, 249, 245, 1);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-color: rgba(193, 186, 182, 1);
  border-top-width: 2px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
  justify-content: start;
  padding: 28px 0;
  border-top: 2px solid #c1bab6;
  background: #fbf9f5;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
`;

const Div2 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  padding: 0 24px;
`;

const Div3 = styled.div`
  align-items: center;
  border-radius: 2px;
  background: var(--92897-b, #92897b);
  background-color: var(--92897-b, #92897b);
  align-self: start;
  display: flex;
  gap: 4px;
  color: rgba(248, 248, 248, 1);
  text-align: center;
  justify-content: start;
  padding: 4px 8px;
  font:
    700 12px Montserrat,
    sans-serif;
`;

const Img = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 12px;
  align-self: stretch;
  margin: auto 0;
`;

const Checkpoint = styled.div`
  align-self: stretch;
  margin: auto 0;
  font-family: Montserrat;
  color: #f8f8f8;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
`;

const Div4 = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
`;

const Div5 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
`;

const Div6 = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
  justify-content: start;
`;

const Div7 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 28px;
  margin: auto 0;
`;

const Img2 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 28px;
`;

const Div8 = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 4px;
  justify-content: start;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
  font:
    400 15px Pretendard,
    sans-serif;
`;

const Div9 = styled.div`
  color: #1d1d1d;
  font-weight: 600;
  letter-spacing: -0.04px;
  align-self: stretch;
  margin: auto 0;
`;

const Div10 = styled.div`
  color: #d8d8d8;
  leading-trim: both;
  text-edge: cap;
  font-size: 13px;
  align-self: stretch;
  margin: auto 0;
`;

const Div11 = styled.div`
  color: #8e8e8e;
  leading-trim: both;
  text-edge: cap;
  align-self: stretch;
  width: 68px;
  margin: auto 0;
`;

const Div12 = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
  align-items: center;
  gap: 12px;
  justify-content: start;
`;

const Div13 = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 7px;
  justify-content: start;
  width: 28px;
  margin: auto 0;
`;

const Img3 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 28px;
  align-self: stretch;
  margin: auto 0;
`;

const Div14 = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  color: #1d1d1d;
  letter-spacing: -0.04px;
  margin: auto 0;
  font:
    600 15px Pretendard,
    sans-serif;
`;

const BedTypeName = styled.div`
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.04px;
  margin-right: 5px;
`;

const BedTypeQuantity = styled.div``;

const Div15 = styled.div`
  display: flex;
  margin-top: 10px;
  width: 100%;
  align-items: center;
  gap: 12px;
  justify-content: start;
`;

const Div16 = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: start;
  width: 28px;
  margin: auto 0;
`;

const Img4 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 28px;
  align-self: stretch;
  margin: auto 0;
`;

const Div17 = styled.div`
  align-self: stretch;
  display: flex;
  min-width: 240px;
  width: 336px;
  flex-direction: column;
  color: #1d1d1d;
  letter-spacing: -0.04px;
  justify-content: center;
  margin: auto 0;
  font:
    600 15px Pretendard,
    sans-serif;
`;

const Div18 = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 4px;
  justify-content: start;
`;

const Div19 = styled.div`
  align-self: stretch;
  margin: auto 0;
`;

const Img5 = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 18px;
  align-self: stretch;
  margin: auto 0;
`;

const Img6 = styled.img`
  object-fit: contain;
  object-position: center;
  width: 100%;
  margin-top: 24px;
`;

const Div20 = styled.div`
  display: flex;
  margin-top: 14px;
  width: 100%;
  flex-direction: column;
  color: rgba(85, 85, 85, 1);
  justify-content: start;
  font:
    500 14px Pretendard,
    sans-serif;
`;

const Div21 = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 4px;
  justify-content: start;
`;

const Div22 = styled.div`
  align-self: stretch;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
`;

const Div23 = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
  align-items: center;
  gap: 4px;
  justify-content: start;
`;

const Otttv = styled.div`
  align-self: stretch;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
`;

const Div24 = styled.div`
  color: #8e8e8e;
  margin-top: 24px;
  padding-bottom: 24px;
  font:
    400 12px/18px Pretendard,
    sans-serif;

  &::before {
    content: '*';
    color: inherit;
    font: inherit;
    margin-right: 3px;
  }
`;

export default CheckPoint;
