import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useStoreWrapper } from 'stores/StoreHelper';
import { useUserInfo } from 'providers/UserInfoProvider';
import ServiceHelper from 'stores/ServiceHelper';
import { useTranslation } from 'react-i18next';
import { getFullName } from 'libs/helper/utils';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import { useMediaContext } from 'saladsoft-primitive';
import { UserMenuItem } from './UserMenuItem';
import { UserStatsItem } from './UserStatsItem';
import { UserProfileProps } from './types';
import { MembershipStatus } from '../modal/membershipStatus/MembershipStatus';
import DeleteAccount from '../modal/account/DeleteAccont';

// defaultStats, defaultMenuItems 내부로 이동 후, 쿠폰 조회 추가
export const UserProfile = ({
  name,
  initial,
  points,
  // stats = defaultStats,
  // menuItems = defaultMenuItems,
  stats,
  menuItems,
  singleUse,
}: UserProfileProps) => {
  const { t, i18n } = useTranslation();
  const { isMobile } = useMediaContext();
  const navigate = useLocaleNavigate();
  const { uiStore } = useStoreWrapper();
  const { logout, myCoupons } = useUserInfo();
  const { myAccount } = useUserInfo();

  const defaultStats = [
    {
      icon: 'https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/d4f0d6642ae3d9bee5ec5c1cb6f34b17844cec0a4295469626a20fbe3f356b93?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&',
      label: t('membershipLevel'),
      component: <MembershipStatus />,
    },
    {
      icon: 'https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/e5f838e9f96097dcae67315035a681d9639db3d39535737283e613b007c48acc?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&',
      label: t('availableCoupons'),
      value: myCoupons?.length.toString() || '0',
      highlight: true,
      to: '/my-info/coupon',
    },
    {
      icon: 'https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/29c007a6e90f0f2078fbc9c0b5381e2249f47600f34e95ff877899c131894137?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&',
      label: t('wishlist'),
      to: '/my-info/wishlist',
    },
  ];

  const defaultMenuItems = [
    {
      icon: 'https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/7828bb4f5e0abaa51003e94d023d9a34919e5d0c12681eaf8158479040ff803f?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&',
      text: t('membershipSystem'),
      to: '/my-info/membership',
    },
    {
      icon: 'https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/572524785b3975089784eb009dbbfd713973776c3f8c2d094cba7319289ab2a5?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&',
      text: t('notice'),
      to: '/my-info/notice',
    },
    {
      icon: 'https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/5e292694d5f8fa0c0dfb8a1e0592cd41382d09d3d710d140171f6cfd59969786?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&',
      text: t('guide'),
      to: '/my-info/guide',
    },
  ];

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  return (
    <ProfileContainer singleUse={singleUse}>
      <ProfileHeader>
        <UserInfo>
          <UserAvatar>{initial}</UserAvatar>
          <UserName
            onClick={() => {
              navigate('/my-info');
            }}
          >
            {i18n.language === 'ko' ? (
              <>
                {getFullName(myAccount?.profile)}
                <span>님</span>
              </>
            ) : (
              <>Hello, {myAccount?.profile?.firstName}</>
            )}
            <ChevronIcon
              src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/e359ea65bc82669e3c2d5fa9c1c6884214fec8973c5fd477586704d49e01a34c?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&"
              alt=""
              loading="lazy"
            />
          </UserName>
          <LogoutButton onClick={handleLogout}>{t('logout')}</LogoutButton>
        </UserInfo>

        <PointsCard>
          <PointsLabel
            onClick={() => {
              navigate('/my-info/points');
            }}
          >
            <PointsIcon
              src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/41dc7f6c360defdca415837405cd7ff19e5fa82d9b22552c11711750a52437f4?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&"
              alt=""
              loading="lazy"
            />
            <span>{t('availablePoints')}</span>
          </PointsLabel>
          <PointsValue>
            {points?.toLocaleString()}P
            <ChevronIcon
              src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/e359ea65bc82669e3c2d5fa9c1c6884214fec8973c5fd477586704d49e01a34c?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&"
              alt=""
              loading="lazy"
            />
          </PointsValue>
        </PointsCard>

        <StatsContainer>
          {defaultStats.map((stat, index) => (
            <UserStatsItem
              key={index}
              {...stat}
              onClick={() => {
                if (index > 0) {
                  navigate(stat?.to as string);
                } else {
                  // MembershipStatus(index 0)인 경우에만 모바일 조건 확인
                  if (isMobile) {
                    uiStore?.universalModal.show({
                      title: stat.label,
                      children: stat.component,
                      modalOption: { style: { width: 600, height: '100%', maxHeight: '80vh' } },
                    });
                  } else {
                    uiStore?.modal.show({
                      style: {
                        width: 'auto',
                      },
                      title: stat.label,
                      children: stat.component,
                    });
                  }
                }
              }}
            />
          ))}
        </StatsContainer>
      </ProfileHeader>

      <MenuContainer>
        {defaultMenuItems.map((item, index) => (
          <UserMenuItem
            key={index}
            {...item}
            onClick={() => {
              navigate(item?.to as string);
            }}
          />
        ))}
      </MenuContainer>

      <DeleteAccountWrapper>
        <DeleteAccountText
          onClick={() => {
            uiStore?.modal.show({
              style: {
                width: 'auto',
              },
              title: t('deleteAccountConfirm'),
              children: (
                // onConfiirm(탈퇴하기) 처리 필요
                <DeleteAccount
                  onClose={() => {
                    uiStore?.modal.close();
                  }}
                  onConfirm={() => {
                    // 여기서는 아무 동작도 하지 않음 (실제 탈퇴 처리는 DeleteAccount 컴포넌트 내부 버튼에서 처리)
                    return Promise.resolve();
                  }}
                />
              ),
            });
          }}
        >
          {t('deleteAccount')}
        </DeleteAccountText>
      </DeleteAccountWrapper>
    </ProfileContainer>
  );
};

const ProfileContainer = styled.section<{ singleUse?: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: ${(props) => (props.singleUse ? 'none' : '1px solid var(--Grayscale-20, #e4e4e4)')};
  max-width: 640px;
  width: 100%;
  padding-bottom: 48px;
  font-family: Pretendard, sans-serif;
  min-height: 700px;
  max-width: ${(props) => (props.singleUse ? '640px' : '375px')};
  margin: ${(props) => (props.singleUse ? 'auto' : 'unset')};

  @media ${(props) => props.theme.media.mobile} {
    border-radius: 0;
    max-width: 100%;
    min-height: unset;
    padding-bottom: 0;
  }
`;

const ProfileHeader = styled.div`
  /* background-color: rgba(245, 245, 245, 1); */
  border-radius: 12px 12px 0 0;
  padding-bottom: 32px;
  @media ${(props) => props.theme.media.mobile} {
    border-radius: 0;
  }
`;

const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 32px 24px 20px;
`;

const UserAvatar = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: rgba(190, 190, 190, 1);
  color: #fff;
  font-size: 25px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserName = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 19px;
  font-weight: 600;
  letter-spacing: -0.05px;
  color: var(--Grayscale-90, #1d1d1d);
`;

const LogoutButton = styled.button`
  color: var(--Grayscale-90, #666666);
  font-size: 14px;
  cursor: pointer;
  padding: 8px 10px;
  border-radius: 6px;
  border: 1px solid var(--Grayscale-20, #d9d9d9);
  background-color: white;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  margin-left: auto;

  &:hover {
    background-color: var(--Grayscale-20, #f5f5f5);
    border-color: var(--Grayscale-40, #999999);
    color: var(--Grayscale-90, #333333);
    transform: translateY() (-1px);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
`;

const ChevronIcon = styled.img`
  width: 20px;
  height: 20px;
`;

const PointsCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 24px;
  padding: 16px;
  border-radius: 8px;
  background: var(--1-f-1-e-1-d, #1f1e1d);
  color: #fff;
`;

const PointsLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  cursor: pointer;
`;

const PointsIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const PointsValue = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 20px;
  font-weight: 700;
`;

const StatsContainer = styled.div`
  display: flex;
  gap: 9px;
  margin: 20px 24px 0;
`;

const MenuContainer = styled.nav`
  margin-top: 16px;
`;

const DeleteAccountWrapper = styled.div`
  margin: 16px 0;
`;

const DeleteAccountText = styled.span`
  color: var(--Grayscale-90, #808080);
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
  margin: 16px 24px;
  display: inline;
`;
