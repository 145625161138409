import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { LoginResponseDto, SocialLoginRequestDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import PageContainer from 'containers/pc/PageContainer';
import { useStoreWrapper } from 'stores/StoreHelper';
import { useTranslation } from 'react-i18next';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import styled from 'styled-components';
import { useUserInfo } from 'providers/UserInfoProvider';
import { AxiosResponse } from 'axios';

const SocialRedirect = () => {
  const navigate = useLocaleNavigate();
  const { t } = useTranslation();
  const { uiStore } = useStoreWrapper();
  const { login } = useUserInfo();
  const [searchParams] = useSearchParams();

  const code = searchParams.get('code'); // 소셜 로그인 액세스 토큰 발급에 사용되는 파라미터
  const scope = searchParams.get('scope'); // 구글 OAuth에서 사용되는 파라미터
  const state = searchParams.get('state'); // 로그인/회원가입 프로세스 구분(signup, login)

  // 회원가입 확인창
  const handleConfirmSignup = (
    socialData: SocialLoginRequestDto,
    socialType: 'kakao' | 'google',
  ) => {
    uiStore?.confirm.show({
      title: t('signup.signupRequired'),
      okText: t('signup.confirm'),
      closeText: t('signup.cancel'),
      onConfirmed: () => {
        localStorage.setItem('socialData', JSON.stringify(socialData));
        navigate(`/signup?ready=true&socialType=${socialType}`);
      },
      onCancel: () => {
        navigate('/login');
      },
    });
  };

  const getBookInfo = () => {
    const bookInfo = localStorage.getItem('bookInfo');
    if (bookInfo) {
      localStorage.removeItem('bookInfo');
      return JSON.parse(bookInfo);
    }
    return null;
  };

  // // 카카오 로그인 (로직 통합 필요)
  // // LoginStatus: REGISTERED, EMAIL_REGISTERED, SIGNUP_REQUIRED, ERROR
  // const handleKakaoLogin = (data: SocialLoginRequestDto) => {
  //   ServiceHelper.authService
  //     ?.kakaoLogin(data)
  //     .then(async (response) => {
  //       // 이미 다른 소셜 계정으로 가입된 이메일인 경우
  //       if (response.status === 409) {
  //         toast.error(t('alreadyRegisteredUser'));
  //         navigate('/login');
  //         return;
  //       }

  //       // 401 Unauthorized: 토큰 만료, 유효하지 않은 토큰, 토큰 없음, 인증 실패(잘못된 인증 정보, 권한 없음)
  //       if (response.status === 401) {
  //         const refreshToken = localStorage.getItem('refreshToken1');
  //         const accessToken = localStorage.getItem('accessToken');
  //         // 이미 로그인된 상태에서 토큰이 만료된 경우
  //         if (refreshToken && accessToken) {
  //           try {
  //             const newTokens = await ServiceHelper.authService?.refreshToken({ refreshToken });
  //             if (newTokens) {
  //               localStorage.setItem('accessToken', newTokens.data.accessToken || '');
  //               localStorage.setItem('refreshToken1', newTokens.data.refreshToken || '');
  //               handleKakaoLogin(data);
  //               return;
  //             }
  //           } catch (error: any) {
  //             localStorage.removeItem('accessToken');
  //             localStorage.removeItem('refreshToken1');
  //             toast.error(t('loginExpired'));
  //           }
  //         }
  //         // 토큰이 없거나 토큰 갱신에 실패한 경우
  //         navigate('/login');
  //         return;
  //       }

  //       // 미가입 사용자인 경우, 회원가입 확인 팝업 표시
  //       if (response.status === 404 && state !== 'signup') {
  //         const newResponse = response as any;
  //         const socialData = newResponse.response.data.data;
  //         handleConfirmSignup(socialData, 'kakao');
  //         return;
  //       }

  //       // 회원가입 프로세스인 경우
  //       if (state === 'signup') {
  //         const newResponse = response as any;
  //         // 이미 가입된 회원인 경우, 로그인 처리
  //         if (newResponse?.data?.loginStatus === 'REGISTERED') {
  //           localStorage.setItem('accessToken', newResponse.accessToken || '');
  //           localStorage.setItem('refreshToken1', newResponse.refreshToken || '');
  //           toast.success(t('loginSuccess'));
  //           setTimeout(() => {
  //             navigate('/');
  //           }, 3000);
  //           return;
  //         }
  //         // 미가입 사용자인 경우, 회원가입 페이지로 이동
  //         const socialData = newResponse.response.data.data;
  //         localStorage.setItem('socialData', JSON.stringify(socialData));
  //         navigate(`/signup?ready=true&socialType=kakao`);
  //         return;
  //       }

  //       // 일반 로그인 프로세스인 경우
  //       if (response.data) {
  //         const newResponse = response.data as any;
  //         if (newResponse.loginStatus === 'REGISTERED') {
  //           localStorage.setItem('accessToken', newResponse.accessToken || '');
  //           localStorage.setItem('refreshToken1', newResponse.refreshToken || '');
  //           login(newResponse);
  //           toast.success(t('loginSuccess'));

  //           const bookInfo = getBookInfo();
  //           if (bookInfo) {
  //             navigate(
  //               `/book/${bookInfo.listingId}?startdate=${bookInfo.startDate}&enddate=${bookInfo.endDate}&guests=${bookInfo.guests}`,
  //             );
  //           } else {
  //             navigate('/');
  //           }
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('카카오 로그인 에러:', error);
  //       toast.error(t('kakaoLoginFailed'));
  //       navigate('/login');
  //     });
  // };

  // // 구글 로그인
  // const handleGoogleLogin = (data: SocialLoginRequestDto) => {
  //   const googleData = {
  //     ...data,
  //     scope: scope || '',
  //   };

  //   console.log('구글 로그인 요청 데이터:', googleData);

  //   ServiceHelper.authService
  //     ?.googleLogin(data)
  //     .then(async (response) => {
  //       // 이미 다른 소셜 계정으로 가입된 이메일인 경우
  //       if (response.status === 409) {
  //         toast.error(t('alreadyRegisteredUser'));
  //         navigate('/login');
  //         return;
  //       }

  //       // 401 Unauthorized: 토큰 만료, 유효하지 않은 토큰, 토큰 없음, 인증 실패(잘못된 인증 정보, 권한 없음)
  //       if (response.status === 401) {
  //         const refreshToken = localStorage.getItem('refreshToken1');
  //         const accessToken = localStorage.getItem('accessToken');
  //         // 이미 로그인된 상태에서 토큰이 만료된 경우
  //         if (refreshToken && accessToken) {
  //           try {
  //             const newTokens = await ServiceHelper.authService?.refreshToken({ refreshToken });
  //             if (newTokens) {
  //               localStorage.setItem('accessToken', newTokens.data.accessToken || '');
  //               localStorage.setItem('refreshToken1', newTokens.data.refreshToken || '');
  //               handleGoogleLogin(data);
  //               return;
  //             }
  //           } catch (error: any) {
  //             localStorage.removeItem('accessToken');
  //             localStorage.removeItem('refreshToken1');
  //             toast.error(t('loginExpired'));
  //           }
  //         }
  //         // 토큰이 없거나 토큰 갱신에 실패한 경우
  //         navigate('/login');
  //         return;
  //       }

  //       // 미가입 사용자인 경우, 회원가입 확인 팝업 표시
  //       if (response.status === 404 && state !== 'signup') {
  //         const newResponse = response as any;
  //         const socialData = newResponse.response.data.data;
  //         handleConfirmSignup(socialData, 'google');
  //         return;
  //       }

  //       // 회원가입 프로세스인 경우
  //       if (state === 'signup') {
  //         const newResponse = response as any;
  //         // 이미 가입된 회원인 경우, 로그인 처리
  //         if (newResponse?.data?.loginStatus === 'REGISTERED') {
  //           localStorage.setItem('accessToken', newResponse.accessToken || '');
  //           localStorage.setItem('refreshToken1', newResponse.refreshToken || '');
  //           toast.success(t('loginSuccess'));
  //           setTimeout(() => {
  //             navigate('/');
  //           }, 3000);
  //           return;
  //         }
  //         // 미가입 사용자인 경우, 회원가입 페이지로 이동
  //         const socialData = newResponse.response.data.data;
  //         localStorage.setItem('socialData', JSON.stringify(socialData));
  //         navigate(`/signup?ready=true&socialType=google`);
  //         return;
  //       }

  //       // 일반 로그인 프로세스인 경우
  //       if (response.data) {
  //         const newResponse = response.data as any;
  //         if (newResponse.loginStatus === 'REGISTERED') {
  //           localStorage.setItem('accessToken', newResponse.accessToken || '');
  //           localStorage.setItem('refreshToken1', newResponse.refreshToken || '');
  //           login(newResponse);
  //           toast.success(t('loginSuccess'));
  //           navigate('/');
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('구글 로그인 에러:', error);
  //       toast.error(t('googleLoginFailed'));
  //       navigate('/login');
  //     });
  // };

  const handleLogin = async (response: AxiosResponse<LoginResponseDto, any>, socialType: 'kakao' | 'google') => {
    if (response.status === 409) {
      toast.error(t('alreadyRegisteredUser'));
      navigate('/login');
      return;
    }

    // 401 Unauthorized: 토큰 만료, 유효하지 않은 토큰, 토큰 없음, 인증 실패(잘못된 인증 정보, 권한 없음)
    if (response.status === 401) {
      const refreshToken = localStorage.getItem('refreshToken1');
      const accessToken = localStorage.getItem('accessToken');
      // 이미 로그인된 상태에서 토큰이 만료된 경우
      if (refreshToken && accessToken) {
        try {
          const newTokens = await ServiceHelper.authService?.refreshToken({ refreshToken });
          if (newTokens) {
            localStorage.setItem('accessToken', newTokens.data.accessToken || '');
            localStorage.setItem('refreshToken1', newTokens.data.refreshToken || '');

            return;
          }
        } catch (error: any) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken1');
          toast.error(t('loginExpired'));
        }
      }
      // 토큰이 없거나 토큰 갱신에 실패한 경우
      navigate('/login');
      return;
    }

    // 미가입 사용자인 경우, 회원가입 확인 팝업 표시
    if (response.status === 404 && state !== 'signup') {
      const newResponse = response as any;
      const socialData = newResponse.response.data.data;
      handleConfirmSignup(socialData, socialType);
      return;
    }

    if (response.status === 400) {
      toast.error(t('loginNotCompletedTryAgain'));
      return;
    }

    // 회원가입 프로세스인 경우
    if (state === 'signup') {
      const newResponse = response as any;
      // 이미 가입된 회원인 경우, 로그인 처리
      if (newResponse?.data?.loginStatus === 'REGISTERED') {
        localStorage.setItem('accessToken', newResponse.accessToken || '');
        localStorage.setItem('refreshToken1', newResponse.refreshToken || '');
        toast.success(t('loginSuccess'));
        setTimeout(() => {
          navigate('/');
        }, 3000);
        return;
      }


      // 미가입 사용자인 경우, 회원가입 페이지로 이동
      const socialData = newResponse.response.data.data;
      localStorage.setItem('socialData', JSON.stringify(socialData));
      navigate(`/signup?ready=true&socialType=${socialType}`);
      return;
    }

    // 일반 로그인 프로세스인 경우
    if (response.data) {
      const newResponse = response.data as any;
      if (newResponse.loginStatus === 'REGISTERED') {
        localStorage.setItem('accessToken', newResponse.accessToken || '');
        localStorage.setItem('refreshToken1', newResponse.refreshToken || '');
        login(newResponse);
        toast.success(t('loginSuccess'));

        const bookInfo = getBookInfo();
        if (bookInfo) {
          navigate(
            `/book/${bookInfo.listingId}?startdate=${bookInfo.startDate}&enddate=${bookInfo.endDate}&guests=${bookInfo.guests}`,
          );
        } else {
          navigate('/');
        }
      }
    }
  };

  // 소셜 로그인 토큰 발급
  const getSocialToken = async () => {
    if (code) {
      const socialData: SocialLoginRequestDto = {
        code: code || '',
      };

      const socialType = scope ? 'google' : 'kakao';

      try {
        let response;
        if (scope) {
          response = await ServiceHelper.authService?.googleLogin(socialData);
        } else {
          response = await ServiceHelper.authService?.kakaoLogin(socialData);
        }

        if (response) {
          handleLogin(response as unknown as AxiosResponse<LoginResponseDto, any>, socialType);
        } else {
          toast.error(t('loginNotCompletedTryAgain'));
          navigate('/login');
        }
      } catch (error) {
        toast.error(t('loginNotCompletedTryAgain'));
        navigate('/login');
      }
    } else {
      toast.error(t('loginNotCompletedTryAgain'));
      navigate('/login');
    }
  };

  useEffect(() => {
    if (code) {
      getSocialToken();
    }
  }, [code]);

  return (
    <PageContainer>
      <NoContents>
        <img style={{ width: 100 }} src="/images/butlerlee-loading.gif" alt="loading" />
      </NoContents>
    </PageContainer>
  );
};

export default SocialRedirect;

const NoContents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 272px);
  white-space: pre-line;
  width: 100%;
`;
