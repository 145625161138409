import { isExpired, decodeToken } from 'react-jwt';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { enUS, ko, ja, zhCN } from 'date-fns/locale';
import i18n from 'i18n';
import { format } from 'date-fns';
import { RoutePropInterface } from 'types/CommonTypes';
/**
 * @description null이나 undefined가 아닌지 체크
 * @param value
 * @returns
 */
export const isValid = (value: any) => {
  const t = !!(typeof value !== 'undefined' && value !== null);
  return t;
};

export const isEmpty = (value: any) => {
  return !!(typeof value !== 'undefined' && value !== null && value !== '');
};

export const validAll = (values: Array<any>) => {
  let isValid = true;
  values.forEach((value: any) => {
    if (typeof value === 'undefined' || value === null) {
      isValid = false;
    }
  });

  return isValid;
};

export const formatDateTime = (dateString?: string) => {
  if (!dateString) return '';
  return format(new Date(dateString), 'yyyy.MM.dd HH:mm');
};

export const formatDate = (dateString?: string) => {
  if (!dateString) return '';
  return format(new Date(dateString), 'yyyy.MM.dd');
};

// 년도만 표시 (예: 25년)
export const formatYearOnly = (dateString?: string) => {
  if (!dateString) return '';
  return format(new Date(dateString), 'yy');
};

// 전체 이름 표시
export const getFullName = (profile?: { firstName?: string; lastName?: string }): string => {
  if (!profile) return '';

  const firstName = profile.firstName || '';
  const lastName = profile.lastName || '';

  if (!lastName && firstName) return '';

  const isKoreanName = /^[가-힣\s]*$/.test(`${lastName}${firstName}`);
  return isKoreanName ? `${lastName}${firstName}` : `${firstName} ${lastName}`.trim();
};

export const cloneDeep = (value: any) => {
  return typeof value === 'object' ? JSON.parse(JSON.stringify(value)) : value;
};

export const hasAnyValue = (value: any, key: Array<string>) => { };

export const getYoutubeUrl = (value: string) => {
  if (value === null) {
    return '';
  }
  if (value.length < 10) {
    return '';
  } else if (value.length === 11) {
    return value;
  } else {
    const url = new URL(value);
    return value.substring(value.length - 11);
  }
};

export const setDefaultValue = (value: any, defaultValue: unknown) => {
  if (typeof value === 'undefined' || value === null) {
    return defaultValue;
  }
  return value;
};

export const isJsonObject = (value?: string) => {
  try {
    JSON.parse(value!);
  } catch (e) {
    return false;
  }
  return true;
};
// export { isValid, isEmpty, cloneDeep };

export const getUserEmail = () => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    const t = !isExpired(accessToken);
    const userInfo = decodeToken(accessToken) as any;
    if (t && userInfo) {
      return userInfo?.sub;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const formatString = (format: string, ...args: any[]): string => {
  let formatted = format;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < args.length; i++) {
    const regexp = new RegExp(`\\{${i}\\}`, 'gi');
    formatted = formatted.replace(regexp, args[i]);
  }
  return formatted;
};

export const getBrightnessType = (hexColor: string): 'dark' | 'bright' | 'normal' => {
  if (!isValidHex(hexColor)) {
    return 'normal';
  }
  // HEX 코드를 RGB로 변환
  const r = parseInt(hexColor.substring(1, 3), 16);
  const g = parseInt(hexColor.substring(3, 5), 16);
  const b = parseInt(hexColor.substring(5, 7), 16);

  const brightness = Math.round((r * 299 + g * 587 + b * 114) / 1000);

  if (brightness > 245) {
    return 'bright';
  } else if (brightness < 125) {
    return 'dark';
  }

  return 'normal';
};

function isValidHex(hex: string) {
  const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

  if (hexRegex.test(hex)) {
    return true;
  } else {
    return false;
  }
}

export const isOver24Hours = (oldDate: Date): boolean => {
  const now: Date = new Date();
  const elapsed: number = now.getTime() - oldDate.getTime();
  const hours24: number = 24 * 60 * 60 * 1000;
  return elapsed > hours24;
};

export const cutoffString = (str?: string, length?: number) => {
  if (typeof str !== 'undefined' && typeof length !== 'undefined') {
    if (str.length <= length) {
      return str;
    }
  } else {
    return str;
  }

  const chars = [...str];
  return `${chars.slice(0, length).join('')}..`;
};

export const copyToClipboard = (message?: string) => {
  if (message === undefined) return;
  navigator.clipboard
    .writeText(message)
    .then(() => {
      toast('클립보드에 복사되었습니다.');
    })
    .catch((err) => {
      console.error('복사 실패:', err);
    });
};

export const getFlexPosition = (position?: string) => {
  switch (position) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    default:
      return 'center';
  }
};

export const getDeviceToken = () => {
  const deviceToken = localStorage.getItem(`deviceToken`) || '';
  if (deviceToken === '') {
    const newDeviceToken = uuidv4();
    localStorage.setItem(`deviceToken`, newDeviceToken);
    return newDeviceToken;
  }
  return deviceToken;
};

export const rgbToHex = ({ r, g, b }: { r: number; g: number; b: number }) => {
  const toHex = (c: number) => {
    const hex = c.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };

  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

// export const hexToRgba = (hex: string) => {
//   const regex = /^#([0-9A-F]{3}){1,2}([0-9A-F]{2})?$/i;
//   if (!regex.test(hex)) {
//     return { r: 0, g: 0, b: 0, a: 1 };
//   }

//   // HEX 코드의 길이에 따라 처리
//   let r;
//   let g;
//   let b;
//   let a = 1; // 알파 기본값은 1
//   if (hex.length === 4) {
//     // 3자리 HEX 코드
//     r = parseInt(hex[1] + hex[1], 16) || 0;
//     g = parseInt(hex[2] + hex[2], 16) || 0;
//     b = parseInt(hex[3] + hex[3], 16 || 0);
//   } else if (hex.length === 7 || hex.length === 9) {
//     // 6자리 또는 8자리 HEX 코드
//     r = parseInt(hex.slice(1, 3), 16) || 0;
//     g = parseInt(hex.slice(3, 5), 16) || 0;
//     b = parseInt(hex.slice(5, 7), 16) || 0;
//     if (hex.length === 9) {
//       // 알파 값 처리
//       a = parseInt(hex.slice(7, 9), 16) / 255 || 1;
//     }
//   }
//   return { r: r || 0, g: g || 0, b: b || 0, a: a || 1 };
//   // return `rgba(${r}, ${g}, ${b}, ${a})`;
// };

export const checkColorFormat = (color: string) => {
  const hexRegex = /^#([0-9A-F]{3}){1,2}([0-9A-F]{2})?$/i;
  const rgbaRegex = /^rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(,\s*(0|1|0?\.\d+))?\)$/;

  if (hexRegex.test(color)) {
    return 'HEX';
  } else if (rgbaRegex.test(color)) {
    return 'RGBA';
  } else {
    return null;
  }
};

export const parseRgba = (rgbaString?: string) => {
  const rgbaRegex = /^rgba?\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})(,\s*(0|1|0?\.\d+))?\)$/;
  const match = rgbaString?.match(rgbaRegex);

  if (match) {
    const r = parseInt(match[1], 10);
    const g = parseInt(match[2], 10);
    const b = parseInt(match[3], 10);
    const a = match[5] !== undefined ? parseFloat(match[5]) : 1;

    return { r, g, b, a };
  } else {
    return { r: 0, g: 0, b: 0, a: 1 };
  }
};

export const isGuid = (guid: string) => {
  const guidPattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return guidPattern.test(guid);
};

export const convertStringToDate = (date: string) => {
  // 유효하지 않은 날짜 체크
  if (!date || isNaN(new Date(date).getTime())) {
    return '';
  }

  const localeMap = {
    ko,
    en: enUS,
    jp: ja,
    ja: ja, // 일본어 (i18에서는 'ja'로 설정됨) 
    cn: zhCN,
    zh: zhCN, // 중국어 (i18에서는 'zh'로 설정됨)
  };

  const locale = localeMap[i18n.language as keyof typeof localeMap] || ko;

  const customZhCN = {
    ...zhCN,
    localize: {
      ...zhCN.localize,
      day: (n: number) => ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"][n],
    },
  };

  if (i18n.language === 'zh' || i18n.language === 'cn') {
    return format(new Date(date), 'yyyy.MM.dd (EEEE)', { locale: customZhCN as Locale });
  }

  return format(new Date(date), 'yyyy.MM.dd (EEE)', {
    locale,
  });
};

// MM.dd / EEE 형식
export const ConvertStringToShortDate = (date: string) => {
  if (!date || isNaN(new Date(date).getTime())) {
    return '';
  }

  const localeMap = {
    ko,
    en: enUS,
    jp: ja,
    ja: ja, // 일본어 (i18에서는 'ja'로 설정됨) 
    cn: zhCN,
    zh: zhCN, // 중국어 (i18에서는 'zh'로 설정됨)
  };

  const locale = localeMap[i18n.language as keyof typeof localeMap] || ko;

  // 중국어인 경우, 전체 요일 이름 사용 (date-fns의 기본 중국어 요일 표시 형식이 간략화된 형태이므로)
  if (i18n.language === 'zh' || i18n.language === 'cn') {
    return format(new Date(date), 'yyyy.MM.dd (EEEE)', { locale });
  }

  return format(new Date(date), 'MM.dd / EEE', {
    locale,
  });
};

// MM.dd (EEE) 형식
export const ConvertStringToParenthesisDate = (date: string, useYear?: boolean) => {
  if (!date || isNaN(new Date(date).getTime())) {
    return '';
  }

  const localeMap = {
    ko,
    en: enUS,
    jp: ja,
    ja: ja, // 일본어 (i18에서는 'ja'로 설정됨) 
    cn: zhCN,
    zh: zhCN, // 중국어 (i18에서는 'zh'로 설정됨)
  };

  const locale = localeMap[i18n.language as keyof typeof localeMap] || ko;

  const customZhCN = {
    ...zhCN,
    localize: {
      ...zhCN.localize,
      day: (n: number) => ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"][n],
    },
  };
  // 중국어인 경우, 전체 요일 이름 사용 (date-fns의 기본 중국어 요일 표시 형식이 간략화된 형태이므로)
  if (i18n.language === 'zh' || i18n.language === 'cn') {
    const day = format(new Date(date), 'yyyy.MM.dd (EEEE)', { locale: customZhCN as Locale });

    return day;
  }
  return format(new Date(date), useYear ? 'yy.MM.dd (EEE)' : 'MM.dd (EEE)', {
    locale,
  });
};

/**
 * 한글 주소 간략화
 * @param address 원본 주소 (예: 서울특별시 종로구 자하문로4길 21, 대림미술관)
 * @returns 간략화된 주소 (예: 서울시 종로구 자하문로4길)
 */

export const getSimplifiedKoreanAddress = (address?: string) => {
  if (!address) return '';

  let simplifiedAddress = address.replace(/^대한민국\s+/, '');

  // 시/도 이름 간략화
  simplifiedAddress = simplifiedAddress
    .replace(/서울특별시|서울시/, '서울')
    .replace(/부산광역시|부산시/, '부산')
    .replace(/대구광역시|대구시/, '대구')
    .replace(/인천광역시|인천시/, '인천')
    .replace(/광주광역시|광주시/, '광주')
    .replace(/대전광역시|대전시/, '대전')
    .replace(/울산광역시|울산시/, '울산')
    .replace(/세종특별자치시|세종시/, '세종')
    .replace(/제주특별자치도|제주도/, '제주')
    .replace(/경기도/, '경기')
    .replace(/강원도/, '강원')
    .replace(/충청북도/, '충북')
    .replace(/충청남도/, '충남')
    .replace(/전라북도/, '전북')
    .replace(/전라남도/, '전남')
    .replace(/경상북도/, '경북')
    .replace(/경상남도/, '경남');

  const parts = simplifiedAddress.split(' ');

  if (parts[0].endsWith('구') || parts[0].endsWith('군')) {
    parts.unshift('서울');
    simplifiedAddress = parts.join(' ');
  }

  const cityName = parts[0];
  const district = parts.find((part, index) => index > 0 && (part.includes('구') || part.includes('군'))) || '';

  const roadParts = parts.filter(
    (part) => part.includes('길') || part.includes('로') || part.includes('대로'),
  );

  if (roadParts.length > 0) {
    const roadName = roadParts[roadParts.length - 1];
    return `${cityName} ${district} ${roadName}`.trim();
  } else {
    return `${cityName} ${district}`.trim();
  }
};

/**
 * 영문 주소 간략화
 * @param address 원본 영문 주소 (예: 21 Jahamun-ro 4-gil, Jongno-gu, Seoul, Republic of Korea)
 * @returns 간략화된 주소 (예: Jongno-gu, Seoul)
 */
export const getSimplifiedEnglishAddress = (address?: string) => {
  if (!address) return '';

  const parts = address.split(',').map((part) => part.trim());

  const districtPart = parts.find(
    (part) =>
      part.endsWith('-gu') ||
      part.endsWith('-si') ||
      part.endsWith('-gun') ||
      part.endsWith('-dong'),
  );

  const cityPart = parts.find(
    (part) =>
      part === 'Seoul' ||
      part === 'Busan' ||
      part === 'Incheon' ||
      part === 'Daegu' ||
      part === 'Daejeon' ||
      part === 'Gwangju' ||
      part === 'Ulsan' ||
      part === 'Sejong' ||
      part.includes('Province') ||
      part.includes('Jeju'),
  );

  if (districtPart && cityPart) {
    return `${districtPart}, ${cityPart}`;
  } else if (districtPart) {
    return districtPart;
  } else if (cityPart) {
    return cityPart;
  } else {
    const filteredParts = parts.filter(
      (part) => !part.includes('Korea') && !part.includes('Republic'),
    );

    if (filteredParts.length >= 2) {
      return `${filteredParts[filteredParts.length - 2]}, ${filteredParts[filteredParts.length - 1]}`;
    } else if (filteredParts.length === 1) {
      return filteredParts[0];
    } else {
      return parts.slice(0, Math.min(2, parts.length)).join(', ');
    }
  }
};

/**
 * URL 파라미터를 관리하는 유틸리티 함수들
 */

// URL에서 특정 파라미터 값을 가져오는 함수
export const getUrlParam = (paramName: string): string | null => {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(paramName);
};

// URL에서 여러 파라미터 값을 한번에 가져오는 함수
export const getUrlParams = (paramNames: string[]): Record<string, string | null> => {
  const searchParams = new URLSearchParams(window.location.search);
  return paramNames.reduce(
    (acc, paramName) => {
      acc[paramName] = searchParams.get(paramName);
      return acc;
    },
    {} as Record<string, string | null>,
  );
};

// URL 파라미터 업데이트하는 함수
export const updateUrlParams = (params: Record<string, string>): void => {
  const searchParams = new URLSearchParams(window.location.search);
  Object.entries(params).forEach(([key, value]) => {
    searchParams.set(key, value);
  });
  window.history.replaceState(null, '', `?${searchParams.toString()}`);
};

// 날짜 형식 문자열에서 이스케이프가 필요한 문자를 처리
const dateFormats = {
  en: 'MMM d, yyyy',
  ko: 'yyyy년 MM월 dd일', // 'n'을 이스케이프 처리
  ja: 'yyyy年 MM月 dd日',
  zh: 'yyyy年 MM月 dd日',
};

const localeMap = {
  ko,
  en: enUS,
  jp: ja,
  cn: zhCN,
};

// 현재 언어에 맞는 locale 객체를 반환하는 함수
export const getLocale = () => {
  return localeMap[i18n.language as keyof typeof localeMap] || enUS;
};

export const formatDateRange = (startDate: string, endDate: string, useSimple?: boolean) => {
  try {
    const locale = getLocale();

    // 날짜 형식 문자열 안전하게 가져오기
    const dateFormat = dateFormats[i18n.language as keyof typeof dateFormats] || 'MMM d, yyyy';

    // 'n'과 같은 특수 문자가 있는 경우 이스케이프 처리
    const safeFormat = (dateStr: string, formatStr: string) => {
      // 한국어, 일본어, 중국어의 경우 직접 문자열 조합
      if (['ko', 'ja', 'zh'].includes(i18n.language)) {
        const date = new Date(dateStr);

        if (i18n.language === 'ko') {
          return useSimple
            ? `${date.getFullYear().toString().slice(2)}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getDate().toString().padStart(2, '0')}`
            : `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`;
        } else {
          return useSimple
            ? `${date.getFullYear().toString().slice(2)}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getDate().toString().padStart(2, '0')}`
            : `${date.getFullYear()}年 ${date.getMonth() + 1}月 ${date.getDate()}日`;
        }
      }

      // 그 외 언어는 미국 포맷(MM/DD/YY) 사용
      return useSimple
        ? format(new Date(dateStr), 'MM/dd/yy', { locale })
        : format(new Date(dateStr), formatStr, { locale });
    };

    const format1 = safeFormat(startDate, dateFormat);
    const format2 = safeFormat(endDate, dateFormat);

    return `${format1} - ${format2}`;
  } catch (error) {
    console.error('Date formatting error:', error);
    return `${startDate} - ${endDate}`;
  }
};

/**
 * 숫자를 천 단위 구분자(,)로 포맷팅
 * @param value
 * @returns 포맷팅된 문자열
 */
export const formatThousandSeparator = (value: number): string => {
  const number = typeof value === 'string' ? parseInt(value) : value;
  return number.toLocaleString('ko-KR');
};

// 날짜, 시간 분리 (예: 24.10.09 (금) 3:00 PM)
export const separateDateAndTime = (dateTimeStr: string) => {
  if (!dateTimeStr) return { date: '', time: '' };

  const timeRegex = /\d{1,2}:\d{2}(:\d{2})?\s*(AM|PM)?/i;
  const timeMatch = dateTimeStr.match(timeRegex);

  if (!timeMatch) return { date: dateTimeStr, time: '' };

  const time = timeMatch[0];
  const date = dateTimeStr.replace(time, '').trim();

  return { date, time };
};

// getRouteConfig 함수를 컴포넌트 내부에서 정의
export const getRouteConfig = (mainRoutes: RoutePropInterface[]) => {
  // 현재 경로 가져오기
  const currentPath = location.pathname;

  // 현재 경로에서 locale 부분 제거
  const pathWithoutLocale = currentPath.replace(/^\/[a-z]{2}/, '');

  // 현재 경로와 일치하는 라우트 설정 찾기
  const currentRoute = mainRoutes?.find(route => {
    // 원래 경로에서 locale 파라미터 부분 제거
    const routePathWithoutLocale = route.path.replace('/:locale?', '');

    // 정확한 경로 일치 확인
    if (currentPath === route.path) {
      return true;
    }

    // locale이 포함된 경로 확인 (예: /en/book/1 -> /book/1)
    if (pathWithoutLocale === routePathWithoutLocale) {
      return true;
    }

    // 한글이 기본일 때(locale 코드가 없을 때) 경로 확인
    if (route.path.includes('/:locale?')) {
      const fullRoutePattern = route.path
        .replace('/:locale?', '') // locale 부분 제거
        .replace(/:[^/]+/g, '[^/]+') // 다른 파라미터 정규식으로 변환
        .replace(/\*/g, '.*'); // 와일드카드 처리

      const fullRegex = new RegExp(`^${fullRoutePattern}$`);
      if (fullRegex.test(currentPath)) {
        return true;
      }
    }

    // 동적 파라미터가 있는 경로 확인 (예: /book/1 -> /book/:listingId)
    const routePathPattern = routePathWithoutLocale
      .replace(/:[^/]+/g, '[^/]+') // :listingId와 같은 파라미터를 정규식 패턴으로 변환
      .replace(/\*/g, '.*'); // * 와일드카드를 정규식 패턴으로 변환

    const regex = new RegExp(`^${routePathPattern}$`);
    return regex.test(pathWithoutLocale);
  });

  return {
    showGnb: currentRoute?.showGnb ?? true, // 기본값 설정
    showTab: currentRoute?.showTab === false ? false : true, // 기본값 설정  
    useBusinessInfo: currentRoute?.useBusinessInfo ?? false, // 기본값 설정
    // 필요한 다른 라우트 속성들
  };
};

/**
 * 특정 날짜의 D-day를 계산하는 함수
 * @param targetDate 목표 날짜 (문자열 형식)
 * @returns D-day 문자열 (예: 'D-5', 'D-day', '만료됨')
 */
export const getDday = (targetDate?: string): string => {
  if (!targetDate) return 'D-?';

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const target = new Date(targetDate);
  target.setHours(0, 0, 0, 0);

  const diffTime = target.getTime() - today.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays < 0) return '만료됨';
  if (diffDays === 0) return 'D-day';
  return `D-${diffDays}`;
};

/**
 * 생년월일 문자열을 YYYY-MM-DD 형식으로 포맷팅
 * @param value 숫자로만 이루어진 문자열
 * @returns 포맷팅된 날짜 문자열
 */
export const formatBirthday = (value: string): string => {
  // 숫자만 추출 (최대 8자리)
  const numbers = value.replace(/[^\d]/g, '').slice(0, 8);

  // 빈 입력값 처리 
  if (numbers.length === 0) return '';

  // YYYY-MM-DD 형식으로 변환
  let formattedDate = numbers;
  if (numbers.length >= 4) {
    formattedDate = numbers.slice(0, 4) + '-' + numbers.slice(4);
  }
  if (numbers.length >= 6) {
    formattedDate = formattedDate.slice(0, 7) + '-' + numbers.slice(6);
  }

  return formattedDate;
};
