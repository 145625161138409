export const countryCodes = [
    {
        name: "South Georgia",
        localName: "South Georgia",
        languages: "English",
        iso2: "GS",
        iso3: "SGS",
        countryCode: "+500",
    },
    {
        name: "Grenada",
        localName: "Grenada",
        languages: "English",
        iso2: "GD",
        iso3: "GRD",
        countryCode: "+1473",
    },
    {
        name: "Switzerland",
        localName: "Schweiz",
        languages: "German, French, Italian, Romansh",
        iso2: "CH",
        iso3: "CHE",
        countryCode: "+41",
    },
    {
        name: "Sierra Leone",
        localName: "Sierra Leone",
        languages: "English",
        iso2: "SL",
        iso3: "SLE",
        countryCode: "+232",
    },
    {
        name: "Hungary",
        localName: "Magyarország",
        languages: "Hungarian",
        iso2: "HU",
        iso3: "HUN",
        countryCode: "+36",
    },
    {
        name: "Taiwan",
        localName: "台灣",
        languages: "Chinese",
        iso2: "TW",
        iso3: "TWN",
        countryCode: "+886",
    },
    {
        name: "Wallis and Futuna",
        localName: "Wallis et Futuna",
        languages: "French",
        iso2: "WF",
        iso3: "WLF",
        countryCode: "+681",
    },
    {
        name: "Barbados",
        localName: "Barbados",
        languages: "English",
        iso2: "BB",
        iso3: "BRB",
        countryCode: "+1246",
    },
    {
        name: "Pitcairn Islands",
        localName: "Pitcairn Islands",
        languages: "English",
        iso2: "PN",
        iso3: "PCN",
        countryCode: "+64",
    },
    {
        name: "Ivory Coast",
        localName: "Côte d'Ivoire",
        languages: "French",
        iso2: "CI",
        iso3: "CIV",
        countryCode: "+225",
    },
    {
        name: "Tunisia",
        localName: "تونس",
        languages: "Arabic",
        iso2: "TN",
        iso3: "TUN",
        countryCode: "+216",
    },
    {
        name: "Italy",
        localName: "Italia",
        languages: "Italian",
        iso2: "IT",
        iso3: "ITA",
        countryCode: "+39",
    },
    {
        name: "Benin",
        localName: "Bénin",
        languages: "French",
        iso2: "BJ",
        iso3: "BEN",
        countryCode: "+229",
    },
    {
        name: "Indonesia",
        localName: "Indonesia",
        languages: "Indonesian",
        iso2: "ID",
        iso3: "IDN",
        countryCode: "+62",
    },
    {
        name: "Cape Verde",
        localName: "Cabo Verde",
        languages: "Portuguese",
        iso2: "CV",
        iso3: "CPV",
        countryCode: "+238",
    },
    {
        name: "Saint Kitts and Nevis",
        localName: "Saint Kitts and Nevis",
        languages: "English",
        iso2: "KN",
        iso3: "KNA",
        countryCode: "+1869",
    },
    {
        name: "Laos",
        localName: "ປະເທດລາວ",
        languages: "Lao",
        iso2: "LA",
        iso3: "LAO",
        countryCode: "+856",
    },
    {
        name: "Caribbean Netherlands",
        localName: "Caribisch Nederland",
        languages: "Dutch",
        iso2: "BQ",
        iso3: "BES",
        countryCode: "+599",
    },
    {
        name: "Uganda",
        localName: "Uganda",
        languages: "English",
        iso2: "UG",
        iso3: "UGA",
        countryCode: "+256",
    },
    {
        name: "Andorra",
        localName: "Andorra",
        languages: "Catalan",
        iso2: "AD",
        iso3: "AND",
        countryCode: "+376",
    },
    {
        name: "Burundi",
        localName: "Burundi",
        languages: "French, Kirundi",
        iso2: "BI",
        iso3: "BDI",
        countryCode: "+257",
    },
    {
        name: "South Africa",
        localName: "South Africa",
        languages:
            "Afrikaans, English, Southern Ndebele, Northern Sotho, Southern Sotho, Swazi, Tswana, Tsonga, Venda, Xhosa, Zulu",
        iso2: "ZA",
        iso3: "ZAF",
        countryCode: "+27",
    },
    {
        name: "France",
        localName: "France",
        languages: "French",
        iso2: "FR",
        iso3: "FRA",
        countryCode: "+33",
    },
    {
        name: "Libya",
        localName: "ليبيا",
        languages: "Arabic",
        iso2: "LY",
        iso3: "LBY",
        countryCode: "+218",
    },
    {
        name: "Mexico",
        localName: "México",
        languages: "Spanish",
        iso2: "MX",
        iso3: "MEX",
        countryCode: "+52",
    },
    {
        name: "Gabon",
        localName: "Gabon",
        languages: "French",
        iso2: "GA",
        iso3: "GAB",
        countryCode: "+241",
    },
    {
        name: "Northern Mariana Islands",
        localName: "Northern Mariana Islands",
        languages: "English, Carolinian, Chamorro",
        iso2: "MP",
        iso3: "MNP",
        countryCode: "+1670",
    },
    {
        name: "North Macedonia",
        localName: "Северна Македонија",
        languages: "Macedonian",
        iso2: "MK",
        iso3: "MKD",
        countryCode: "+389",
    },
    {
        name: "China",
        localName: "中国",
        languages: "Chinese",
        iso2: "CN",
        iso3: "CHN",
        countryCode: "+86",
    },
    {
        name: "Yemen",
        localName: "اليمن",
        languages: "Arabic",
        iso2: "YE",
        iso3: "YEM",
        countryCode: "+967",
    },
    {
        name: "Saint Barthélemy",
        localName: "Saint-Barthélemy",
        languages: "French",
        iso2: "BL",
        iso3: "BLM",
        countryCode: "+590",
    },
    {
        name: "Guernsey",
        localName: "Guernsey",
        languages: "English",
        iso2: "GG",
        iso3: "GGY",
        countryCode: "+44",
    },
    {
        name: "Solomon Islands",
        localName: "Solomon Islands",
        languages: "English",
        iso2: "SB",
        iso3: "SLB",
        countryCode: "+677",
    },
    {
        name: "Svalbard and Jan Mayen",
        localName: "Svalbard og Jan Mayen",
        languages: "Norwegian",
        iso2: "SJ",
        iso3: "SJM",
        countryCode: "+4779",
    },
    {
        name: "Faroe Islands",
        localName: "Føroyar",
        languages: "Faroese",
        iso2: "FO",
        iso3: "FRO",
        countryCode: "+298",
    },
    {
        name: "Uzbekistan",
        localName: "Oʻzbekiston",
        languages: "Uzbek",
        iso2: "UZ",
        iso3: "UZB",
        countryCode: "+998",
    },
    {
        name: "Egypt",
        localName: "مصر",
        languages: "Arabic",
        iso2: "EG",
        iso3: "EGY",
        countryCode: "+20",
    },
    {
        name: "Senegal",
        localName: "Sénégal",
        languages: "French",
        iso2: "SN",
        iso3: "SEN",
        countryCode: "+221",
    },
    {
        name: "Sri Lanka",
        localName: "ශ්‍රී ලංකාව",
        languages: "Sinhala",
        iso2: "LK",
        iso3: "LKA",
        countryCode: "+94",
    },
    {
        name: "Palestine",
        localName: "فلسطين",
        languages: "Arabic",
        iso2: "PS",
        iso3: "PSE",
        countryCode: "+970",
    },
    {
        name: "Bangladesh",
        localName: "বাংলাদেশ",
        languages: "Bengali",
        iso2: "BD",
        iso3: "BGD",
        countryCode: "+880",
    },
    {
        name: "Peru",
        localName: "Perú",
        languages: "Spanish, Quechua, Aymara",
        iso2: "PE",
        iso3: "PER",
        countryCode: "+51",
    },
    {
        name: "Singapore",
        localName: "Singapore",
        languages: "English, Chinese, Malay, Tamil",
        iso2: "SG",
        iso3: "SGP",
        countryCode: "+65",
    },
    {
        name: "Turkey",
        localName: "Türkiye",
        languages: "Turkish",
        iso2: "TR",
        iso3: "TUR",
        countryCode: "+90",
    },
    {
        name: "Afghanistan",
        localName: "افغانستان",
        languages: "Dari",
        iso2: "AF",
        iso3: "AFG",
        countryCode: "+93",
    },
    {
        name: "Aruba",
        localName: "Aruba",
        languages: "Dutch",
        iso2: "AW",
        iso3: "ABW",
        countryCode: "+297",
    },
    {
        name: "Cook Islands",
        localName: "Cook Islands",
        languages: "English",
        iso2: "CK",
        iso3: "COK",
        countryCode: "+682",
    },
    {
        name: "United Kingdom",
        localName: "United Kingdom",
        languages: "English",
        iso2: "GB",
        iso3: "GBR",
        countryCode: "+44",
    },
    {
        name: "Zambia",
        localName: "Zambia",
        languages: "English",
        iso2: "ZM",
        iso3: "ZMB",
        countryCode: "+260",
    },
    {
        name: "Finland",
        localName: "Suomi",
        languages: "Finnish",
        iso2: "FI",
        iso3: "FIN",
        countryCode: "+358",
    },
    {
        name: "Niger",
        localName: "Niger",
        languages: "French",
        iso2: "NE",
        iso3: "NER",
        countryCode: "+227",
    },
    {
        name: "Christmas Island",
        localName: "Christmas Island",
        languages: "English",
        iso2: "CX",
        iso3: "CXR",
        countryCode: "+61",
    },
    {
        name: "Tokelau",
        localName: "Tokelau",
        languages: "English",
        iso2: "TK",
        iso3: "TKL",
        countryCode: "+690",
    },
    {
        name: "Guinea-Bissau",
        localName: "Guiné-Bissau",
        languages: "Portuguese",
        iso2: "GW",
        iso3: "GNB",
        countryCode: "+245",
    },
    {
        name: "Azerbaijan",
        localName: "Azərbaycan",
        languages: "Azerbaijani",
        iso2: "AZ",
        iso3: "AZE",
        countryCode: "+994",
    },
    {
        name: "Réunion",
        localName: "La Réunion",
        languages: "French",
        iso2: "RE",
        iso3: "REU",
        countryCode: "+262",
    },
    {
        name: "Djibouti",
        localName: "Djibouti",
        languages: "French, Arabic",
        iso2: "DJ",
        iso3: "DJI",
        countryCode: "+253",
    },
    {
        name: "North Korea",
        localName: "조선",
        languages: "Korean",
        iso2: "KP",
        iso3: "PRK",
        countryCode: "+850",
    },
    {
        name: "Mauritius",
        localName: "Mauritius",
        languages: "English",
        iso2: "MU",
        iso3: "MUS",
        countryCode: "+230",
    },
    {
        name: "Montserrat",
        localName: "Montserrat",
        languages: "English",
        iso2: "MS",
        iso3: "MSR",
        countryCode: "+1664",
    },
    {
        name: "United States Virgin Islands",
        localName: "United States Virgin Islands",
        languages: "English",
        iso2: "VI",
        iso3: "VIR",
        countryCode: "+1340",
    },
    {
        name: "Colombia",
        localName: "Colombia",
        languages: "Spanish",
        iso2: "CO",
        iso3: "COL",
        countryCode: "+57",
    },
    {
        name: "Greece",
        localName: "Ελλάδα",
        languages: "Greek",
        iso2: "GR",
        iso3: "GRC",
        countryCode: "+30",
    },
    {
        name: "Croatia",
        localName: "Hrvatska",
        languages: "Croatian",
        iso2: "HR",
        iso3: "HRV",
        countryCode: "+385",
    },
    {
        name: "Morocco",
        localName: "المغرب",
        languages: "Arabic",
        iso2: "MA",
        iso3: "MAR",
        countryCode: "+212",
    },
    {
        name: "Algeria",
        localName: "الجزائر",
        languages: "Arabic",
        iso2: "DZ",
        iso3: "DZA",
        countryCode: "+213",
    },
    {
        name: "Antarctica",
        localName: "Antarctica",
        languages: "",
        iso2: "AQ",
        iso3: "ATA",
        countryCode: "undefined",
    },
    {
        name: "Netherlands",
        localName: "Nederland",
        languages: "Dutch",
        iso2: "NL",
        iso3: "NLD",
        countryCode: "+31",
    },
    {
        name: "Sudan",
        localName: "السودان",
        languages: "Arabic",
        iso2: "SD",
        iso3: "SDN",
        countryCode: "+249",
    },
    {
        name: "Fiji",
        localName: "Fiji",
        languages: "English",
        iso2: "FJ",
        iso3: "FJI",
        countryCode: "+679",
    },
    {
        name: "Liechtenstein",
        localName: "Liechtenstein",
        languages: "German",
        iso2: "LI",
        iso3: "LIE",
        countryCode: "+423",
    },
    {
        name: "Nepal",
        localName: "नेपाल",
        languages: "Nepali",
        iso2: "NP",
        iso3: "NPL",
        countryCode: "+977",
    },
    {
        name: "Puerto Rico",
        localName: "Puerto Rico",
        languages: "Spanish",
        iso2: "PR",
        iso3: "PRI",
        countryCode: "+1787",
    },
    {
        name: "Georgia",
        localName: "საქართველო",
        languages: "Georgian",
        iso2: "GE",
        iso3: "GEO",
        countryCode: "+995",
    },
    {
        name: "Pakistan",
        localName: "پاکستان",
        languages: "Urdu",
        iso2: "PK",
        iso3: "PAK",
        countryCode: "+92",
    },
    {
        name: "Monaco",
        localName: "Monaco",
        languages: "French",
        iso2: "MC",
        iso3: "MCO",
        countryCode: "+377",
    },
    {
        name: "Botswana",
        localName: "Botswana",
        languages: "English",
        iso2: "BW",
        iso3: "BWA",
        countryCode: "+267",
    },
    {
        name: "Lebanon",
        localName: "لبنان",
        languages: "Arabic",
        iso2: "LB",
        iso3: "LBN",
        countryCode: "+961",
    },
    {
        name: "Papua New Guinea",
        localName: "Papua New Guinea",
        languages: "English",
        iso2: "PG",
        iso3: "PNG",
        countryCode: "+675",
    },
    {
        name: "Mayotte",
        localName: "Mayotte",
        languages: "French",
        iso2: "YT",
        iso3: "MYT",
        countryCode: "+262",
    },
    {
        name: "Dominican Republic",
        localName: "República Dominicana",
        languages: "Spanish",
        iso2: "DO",
        iso3: "DOM",
        countryCode: "+1809",
    },
    {
        name: "Norfolk Island",
        localName: "Norfolk Island",
        languages: "English",
        iso2: "NF",
        iso3: "NFK",
        countryCode: "+672",
    },
    {
        name: "Bouvet Island",
        localName: "Bouvetøya",
        languages: "Norwegian",
        iso2: "BV",
        iso3: "BVT",
        countryCode: "+47",
    },
    {
        name: "Qatar",
        localName: "قطر",
        languages: "Arabic",
        iso2: "QA",
        iso3: "QAT",
        countryCode: "+974",
    },
    {
        name: "Madagascar",
        localName: "Madagascar",
        languages: "Malagasy",
        iso2: "MG",
        iso3: "MDG",
        countryCode: "+261",
    },
    {
        name: "India",
        localName: "भारत",
        languages: "Hindi",
        iso2: "IN",
        iso3: "IND",
        countryCode: "+91",
    },
    {
        name: "Syria",
        localName: "سوريا",
        languages: "Arabic",
        iso2: "SY",
        iso3: "SYR",
        countryCode: "+963",
    },
    {
        name: "Montenegro",
        localName: "Црна Гора",
        languages: "Montenegrin",
        iso2: "ME",
        iso3: "MNE",
        countryCode: "+382",
    },
    {
        name: "Eswatini",
        localName: "Eswatini",
        languages: "English",
        iso2: "SZ",
        iso3: "SWZ",
        countryCode: "+268",
    },
    {
        name: "Paraguay",
        localName: "Paraguay",
        languages: "Spanish",
        iso2: "PY",
        iso3: "PRY",
        countryCode: "+595",
    },
    {
        name: "El Salvador",
        localName: "El Salvador",
        languages: "Spanish",
        iso2: "SV",
        iso3: "SLV",
        countryCode: "+503",
    },
    {
        name: "Ukraine",
        localName: "Україна",
        languages: "Ukrainian",
        iso2: "UA",
        iso3: "UKR",
        countryCode: "+380",
    },
    {
        name: "Isle of Man",
        localName: "Isle of Man",
        languages: "English",
        iso2: "IM",
        iso3: "IMN",
        countryCode: "+44",
    },
    {
        name: "Namibia",
        localName: "Namibia",
        languages: "English",
        iso2: "NA",
        iso3: "NAM",
        countryCode: "+264",
    },
    {
        name: "United Arab Emirates",
        localName: "الإمارات",
        languages: "Arabic",
        iso2: "AE",
        iso3: "ARE",
        countryCode: "+971",
    },
    {
        name: "Bulgaria",
        localName: "България",
        languages: "Bulgarian",
        iso2: "BG",
        iso3: "BGR",
        countryCode: "+359",
    },
    {
        name: "Greenland",
        localName: "Kalaallit Nunaat",
        languages: "Greenlandic",
        iso2: "GL",
        iso3: "GRL",
        countryCode: "+299",
    },
    {
        name: "Germany",
        localName: "Deutschland",
        languages: "German",
        iso2: "DE",
        iso3: "DEU",
        countryCode: "+49",
    },
    {
        name: "Cambodia",
        localName: "កម្ពុជា",
        languages: "Khmer",
        iso2: "KH",
        iso3: "KHM",
        countryCode: "+855",
    },
    {
        name: "Iraq",
        localName: "العراق",
        languages: "Arabic",
        iso2: "IQ",
        iso3: "IRQ",
        countryCode: "+964",
    },
    {
        name: "French Southern and Antarctic Lands",
        localName: "Terres australes et antarctiques françaises",
        languages: "French",
        iso2: "TF",
        iso3: "ATF",
        countryCode: "+262",
    },
    {
        name: "Sweden",
        localName: "Sverige",
        languages: "Swedish",
        iso2: "SE",
        iso3: "SWE",
        countryCode: "+46",
    },
    {
        name: "Cuba",
        localName: "Cuba",
        languages: "Spanish",
        iso2: "CU",
        iso3: "CUB",
        countryCode: "+53",
    },
    {
        name: "Kyrgyzstan",
        localName: "Кыргызстан",
        languages: "Kyrgyz",
        iso2: "KG",
        iso3: "KGZ",
        countryCode: "+996",
    },
    {
        name: "Russia",
        localName: "Россия",
        languages: "Russian",
        iso2: "RU",
        iso3: "RUS",
        countryCode: "+73",
    },
    {
        name: "Malaysia",
        localName: "Malaysia",
        languages: "Malay",
        iso2: "MY",
        iso3: "MYS",
        countryCode: "+60",
    },
    {
        name: "São Tomé and Príncipe",
        localName: "São Tomé e Príncipe",
        languages: "Portuguese",
        iso2: "ST",
        iso3: "STP",
        countryCode: "+239",
    },
    {
        name: "Cyprus",
        localName: "Κύπρος",
        languages: "Greek",
        iso2: "CY",
        iso3: "CYP",
        countryCode: "+357",
    },
    {
        name: "Canada",
        localName: "Canada",
        languages: "English",
        iso2: "CA",
        iso3: "CAN",
        countryCode: "+1",
    },
    {
        name: "Malawi",
        localName: "Malawi",
        languages: "English",
        iso2: "MW",
        iso3: "MWI",
        countryCode: "+265",
    },
    {
        name: "Saudi Arabia",
        localName: "المملكة العربية السعودية",
        languages: "Arabic",
        iso2: "SA",
        iso3: "SAU",
        countryCode: "+966",
    },
    {
        name: "Bosnia and Herzegovina",
        localName: "Bosna i Hercegovina",
        languages: "Bosnian",
        iso2: "BA",
        iso3: "BIH",
        countryCode: "+387",
    },
    {
        name: "Ethiopia",
        localName: "ኢትዮጵያ",
        languages: "Amharic",
        iso2: "ET",
        iso3: "ETH",
        countryCode: "+251",
    },
    {
        name: "Spain",
        localName: "España",
        languages: "Spanish",
        iso2: "ES",
        iso3: "ESP",
        countryCode: "+34",
    },
    {
        name: "Slovenia",
        localName: "Slovenija",
        languages: "Slovene",
        iso2: "SI",
        iso3: "SVN",
        countryCode: "+386",
    },
    {
        name: "Oman",
        localName: "عمان",
        languages: "Arabic",
        iso2: "OM",
        iso3: "OMN",
        countryCode: "+968",
    },
    {
        name: "Saint Pierre and Miquelon",
        localName: "Saint-Pierre-et-Miquelon",
        languages: "French",
        iso2: "PM",
        iso3: "SPM",
        countryCode: "+508",
    },
    {
        name: "Macau",
        localName: "澳門",
        languages: "Chinese",
        iso2: "MO",
        iso3: "MAC",
        countryCode: "+853",
    },
    {
        name: "San Marino",
        localName: "San Marino",
        languages: "Italian",
        iso2: "SM",
        iso3: "SMR",
        countryCode: "+378",
    },
    {
        name: "Lesotho",
        localName: "Lesotho",
        languages: "English",
        iso2: "LS",
        iso3: "LSO",
        countryCode: "+266",
    },
    {
        name: "Marshall Islands",
        localName: "Marshall Islands",
        languages: "Marshallese",
        iso2: "MH",
        iso3: "MHL",
        countryCode: "+692",
    },
    {
        name: "Sint Maarten",
        localName: "Sint Maarten",
        languages: "Dutch",
        iso2: "SX",
        iso3: "SXM",
        countryCode: "+1721",
    },
    {
        name: "Iceland",
        localName: "Ísland",
        languages: "Icelandic",
        iso2: "IS",
        iso3: "ISL",
        countryCode: "+354",
    },
    {
        name: "Luxembourg",
        localName: "Luxemburg",
        languages: "Luxembourgish",
        iso2: "LU",
        iso3: "LUX",
        countryCode: "+352",
    },
    {
        name: "Argentina",
        localName: "Argentina",
        languages: "Spanish",
        iso2: "AR",
        iso3: "ARG",
        countryCode: "+54",
    },
    {
        name: "Turks and Caicos Islands",
        localName: "Turks and Caicos Islands",
        languages: "English",
        iso2: "TC",
        iso3: "TCA",
        countryCode: "+1649",
    },
    {
        name: "Nauru",
        localName: "Nauru",
        languages: "Nauruan",
        iso2: "NR",
        iso3: "NRU",
        countryCode: "+674",
    },
    {
        name: "Cocos (Keeling) Islands",
        localName: "Cocos (Keeling) Islands",
        languages: "English",
        iso2: "CC",
        iso3: "CCK",
        countryCode: "+61",
    },
    {
        name: "Western Sahara",
        localName: "الصحراء الغربية",
        languages: "Arabic",
        iso2: "EH",
        iso3: "ESH",
        countryCode: "+2125288",
    },
    {
        name: "Dominica",
        localName: "Dominica",
        languages: "English",
        iso2: "DM",
        iso3: "DMA",
        countryCode: "+1767",
    },
    {
        name: "Costa Rica",
        localName: "Costa Rica",
        languages: "Spanish",
        iso2: "CR",
        iso3: "CRI",
        countryCode: "+506",
    },
    {
        name: "Australia",
        localName: "Australia",
        languages: "English",
        iso2: "AU",
        iso3: "AUS",
        countryCode: "+61",
    },
    {
        name: "Thailand",
        localName: "ประเทศไทย",
        languages: "Thai",
        iso2: "TH",
        iso3: "THA",
        countryCode: "+66",
    },
    {
        name: "Haiti",
        localName: "Haïti",
        languages: "French",
        iso2: "HT",
        iso3: "HTI",
        countryCode: "+509",
    },
    {
        name: "Tuvalu",
        localName: "Tuvalu",
        languages: "English",
        iso2: "TV",
        iso3: "TUV",
        countryCode: "+688",
    },
    {
        name: "Honduras",
        localName: "Honduras",
        languages: "Spanish",
        iso2: "HN",
        iso3: "HND",
        countryCode: "+504",
    },
    {
        name: "Equatorial Guinea",
        localName: "Guinea Ecuatorial",
        languages: "Spanish",
        iso2: "GQ",
        iso3: "GNQ",
        countryCode: "+240",
    },
    {
        name: "Saint Lucia",
        localName: "Saint Lucia",
        languages: "English",
        iso2: "LC",
        iso3: "LCA",
        countryCode: "+1758",
    },
    {
        name: "French Polynesia",
        localName: "Polynésie française",
        languages: "French",
        iso2: "PF",
        iso3: "PYF",
        countryCode: "+689",
    },
    {
        name: "Belarus",
        localName: "Беларусь",
        languages: "Belarusian",
        iso2: "BY",
        iso3: "BLR",
        countryCode: "+375",
    },
    {
        name: "Latvia",
        localName: "Latvija",
        languages: "Latvian",
        iso2: "LV",
        iso3: "LVA",
        countryCode: "+371",
    },
    {
        name: "Palau",
        localName: "Palau",
        languages: "Palauan",
        iso2: "PW",
        iso3: "PLW",
        countryCode: "+680",
    },
    {
        name: "Guadeloupe",
        localName: "Guadeloupe",
        languages: "French",
        iso2: "GP",
        iso3: "GLP",
        countryCode: "+590",
    },
    {
        name: "Philippines",
        localName: "Pilipinas",
        languages: "Filipino",
        iso2: "PH",
        iso3: "PHL",
        countryCode: "+63",
    },
    {
        name: "Gibraltar",
        localName: "Gibraltar",
        languages: "English",
        iso2: "GI",
        iso3: "GIB",
        countryCode: "+350",
    },
    {
        name: "Denmark",
        localName: "Danmark",
        languages: "Danish",
        iso2: "DK",
        iso3: "DNK",
        countryCode: "+45",
    },
    {
        name: "Cameroon",
        localName: "Cameroun",
        languages: "French",
        iso2: "CM",
        iso3: "CMR",
        countryCode: "+237",
    },
    {
        name: "Guinea",
        localName: "Guinée",
        languages: "French",
        iso2: "GN",
        iso3: "GIN",
        countryCode: "+224",
    },
    {
        name: "Bahrain",
        localName: "البحرين",
        languages: "Arabic",
        iso2: "BH",
        iso3: "BHR",
        countryCode: "+973",
    },
    {
        name: "Suriname",
        localName: "Suriname",
        languages: "Dutch",
        iso2: "SR",
        iso3: "SUR",
        countryCode: "+597",
    },
    {
        name: "DR Congo",
        localName: "République démocratique du Congo",
        languages: "French",
        iso2: "CD",
        iso3: "COD",
        countryCode: "+243",
    },
    {
        name: "Somalia",
        localName: "Soomaaliya",
        languages: "Somali",
        iso2: "SO",
        iso3: "SOM",
        countryCode: "+252",
    },
    {
        name: "Czechia",
        localName: "Česko",
        languages: "Czech",
        iso2: "CZ",
        iso3: "CZE",
        countryCode: "+420",
    },
    {
        name: "New Caledonia",
        localName: "Nouvelle-Calédonie",
        languages: "French",
        iso2: "NC",
        iso3: "NCL",
        countryCode: "+687",
    },
    {
        name: "Vanuatu",
        localName: "Vanuatu",
        languages: "Bislama",
        iso2: "VU",
        iso3: "VUT",
        countryCode: "+678",
    },
    {
        name: "Saint Helena, Ascension and Tristan da Cunha",
        localName: "Saint Helena",
        languages: "English",
        iso2: "SH",
        iso3: "SHN",
        countryCode: "+290",
    },
    {
        name: "Togo",
        localName: "Togo",
        languages: "French",
        iso2: "TG",
        iso3: "TGO",
        countryCode: "+228",
    },
    {
        name: "British Virgin Islands",
        localName: "British Virgin Islands",
        languages: "English",
        iso2: "VG",
        iso3: "VGB",
        countryCode: "+1284",
    },
    {
        name: "Kenya",
        localName: "Kenya",
        languages: "English",
        iso2: "KE",
        iso3: "KEN",
        countryCode: "+254",
    },
    {
        name: "Niue",
        localName: "Niuē",
        languages: "Niuean",
        iso2: "NU",
        iso3: "NIU",
        countryCode: "+683",
    },
    {
        name: "Heard Island and McDonald Islands",
        localName: "Heard Island and McDonald Islands",
        languages: "English",
        iso2: "HM",
        iso3: "HMD",
        countryCode: "undefined",
    },
    {
        name: "Rwanda",
        localName: "Rwanda",
        languages: "Kinyarwanda",
        iso2: "RW",
        iso3: "RWA",
        countryCode: "+250",
    },
    {
        name: "Estonia",
        localName: "Eesti",
        languages: "Estonian",
        iso2: "EE",
        iso3: "EST",
        countryCode: "+372",
    },
    {
        name: "Romania",
        localName: "România",
        languages: "Romanian",
        iso2: "RO",
        iso3: "ROU",
        countryCode: "+40",
    },
    {
        name: "Trinidad and Tobago",
        localName: "Trinidad and Tobago",
        languages: "English",
        iso2: "TT",
        iso3: "TTO",
        countryCode: "+1868",
    },
    {
        name: "Guyana",
        localName: "Guyana",
        languages: "English",
        iso2: "GY",
        iso3: "GUY",
        countryCode: "+592",
    },
    {
        name: "Timor-Leste",
        localName: "Timor-Leste",
        languages: "Portuguese",
        iso2: "TL",
        iso3: "TLS",
        countryCode: "+670",
    },
    {
        name: "Vietnam",
        localName: "Việt Nam",
        languages: "Vietnamese",
        iso2: "VN",
        iso3: "VNM",
        countryCode: "+84",
    },
    {
        name: "Uruguay",
        localName: "Uruguay",
        languages: "Spanish",
        iso2: "UY",
        iso3: "URY",
        countryCode: "+598",
    },
    {
        name: "Vatican City",
        localName: "Città del Vaticano",
        languages: "Latin",
        iso2: "VA",
        iso3: "VAT",
        countryCode: "+3906698",
    },
    {
        name: "Hong Kong",
        localName: "香港",
        languages: "Chinese",
        iso2: "HK",
        iso3: "HKG",
        countryCode: "+852",
    },
    {
        name: "Austria",
        localName: "Österreich",
        languages: "German",
        iso2: "AT",
        iso3: "AUT",
        countryCode: "+43",
    },
    {
        name: "Antigua and Barbuda",
        localName: "Antigua and Barbuda",
        languages: "English",
        iso2: "AG",
        iso3: "ATG",
        countryCode: "+1268",
    },
    {
        name: "Turkmenistan",
        localName: "Türkmenistan",
        languages: "Turkmen",
        iso2: "TM",
        iso3: "TKM",
        countryCode: "+993",
    },
    {
        name: "Mozambique",
        localName: "Moçambique",
        languages: "Portuguese",
        iso2: "MZ",
        iso3: "MOZ",
        countryCode: "+258",
    },
    {
        name: "Panama",
        localName: "Panamá",
        languages: "Spanish",
        iso2: "PA",
        iso3: "PAN",
        countryCode: "+507",
    },
    {
        name: "Micronesia",
        localName: "Micronesia",
        languages: "English",
        iso2: "FM",
        iso3: "FSM",
        countryCode: "+691",
    },
    {
        name: "Ireland",
        localName: "Éire",
        languages: "Irish",
        iso2: "IE",
        iso3: "IRL",
        countryCode: "+353",
    },
    {
        name: "Curaçao",
        localName: "Curaçao",
        languages: "Dutch",
        iso2: "CW",
        iso3: "CUW",
        countryCode: "+599",
    },
    {
        name: "French Guiana",
        localName: "Guyane française",
        languages: "French",
        iso2: "GF",
        iso3: "GUF",
        countryCode: "+594",
    },
    {
        name: "Norway",
        localName: "Norge",
        languages: "Norwegian",
        iso2: "NO",
        iso3: "NOR",
        countryCode: "+47",
    },
    {
        name: "Åland Islands",
        localName: "Åland",
        languages: "Swedish",
        iso2: "AX",
        iso3: "ALA",
        countryCode: "+35818",
    },
    {
        name: "Central African Republic",
        localName: "République centrafricaine",
        languages: "French",
        iso2: "CF",
        iso3: "CAF",
        countryCode: "+236",
    },
    {
        name: "Burkina Faso",
        localName: "Burkina Faso",
        languages: "French",
        iso2: "BF",
        iso3: "BFA",
        countryCode: "+226",
    },
    {
        name: "Eritrea",
        localName: "إرتريا",
        languages: "Arabic",
        iso2: "ER",
        iso3: "ERI",
        countryCode: "+291",
    },
    {
        name: "Tanzania",
        localName: "Tanzania",
        languages: "Swahili",
        iso2: "TZ",
        iso3: "TZA",
        countryCode: "+255",
    },
    {
        name: "South Korea",
        localName: "대한민국",
        languages: "Korean",
        iso2: "KR",
        iso3: "KOR",
        countryCode: "+82",
    },
    {
        name: "Jordan",
        localName: "الأردن",
        languages: "Arabic",
        iso2: "JO",
        iso3: "JOR",
        countryCode: "+962",
    },
    {
        name: "Mauritania",
        localName: "موريتانيا",
        languages: "Arabic",
        iso2: "MR",
        iso3: "MRT",
        countryCode: "+222",
    },
    {
        name: "Lithuania",
        localName: "Lietuva",
        languages: "Lithuanian",
        iso2: "LT",
        iso3: "LTU",
        countryCode: "+370",
    },
    {
        name: "United States Minor Outlying Islands",
        localName: "United States Minor Outlying Islands",
        languages: "English",
        iso2: "UM",
        iso3: "UMI",
        countryCode: "+268",
    },
    {
        name: "Slovakia",
        localName: "Slovensko",
        languages: "Slovak",
        iso2: "SK",
        iso3: "SVK",
        countryCode: "+421",
    },
    {
        name: "Angola",
        localName: "Angola",
        languages: "Portuguese",
        iso2: "AO",
        iso3: "AGO",
        countryCode: "+244",
    },
    {
        name: "Kazakhstan",
        localName: "Қазақстан",
        languages: "Kazakh",
        iso2: "KZ",
        iso3: "KAZ",
        countryCode: "+76",
    },
    {
        name: "Moldova",
        localName: "Moldova",
        languages: "Romanian",
        iso2: "MD",
        iso3: "MDA",
        countryCode: "+373",
    },
    {
        name: "Mali",
        localName: "Mali",
        languages: "French",
        iso2: "ML",
        iso3: "MLI",
        countryCode: "+223",
    },
    {
        name: "Falkland Islands",
        localName: "Falkland Islands",
        languages: "English",
        iso2: "FK",
        iso3: "FLK",
        countryCode: "+500",
    },
    {
        name: "Armenia",
        localName: "Հայաստան",
        languages: "Armenian",
        iso2: "AM",
        iso3: "ARM",
        countryCode: "+374",
    },
    {
        name: "Samoa",
        localName: "Samoa",
        languages: "Samoan",
        iso2: "WS",
        iso3: "WSM",
        countryCode: "+685",
    },
    {
        name: "Jersey",
        localName: "Jersey",
        languages: "English",
        iso2: "JE",
        iso3: "JEY",
        countryCode: "+44",
    },
    {
        name: "Japan",
        localName: "日本",
        languages: "Japanese",
        iso2: "JP",
        iso3: "JPN",
        countryCode: "+81",
    },
    {
        name: "Bolivia",
        localName: "Bolivia",
        languages: "Spanish",
        iso2: "BO",
        iso3: "BOL",
        countryCode: "+591",
    },
    {
        name: "Chile",
        localName: "Chile",
        languages: "Spanish",
        iso2: "CL",
        iso3: "CHL",
        countryCode: "+56",
    },
    {
        name: "United States",
        localName: "United States",
        languages: "English",
        iso2: "US",
        iso3: "USA",
        countryCode: "+1201",
    },
    {
        name: "Saint Vincent and the Grenadines",
        localName: "Saint Vincent and the Grenadines",
        languages: "English",
        iso2: "VC",
        iso3: "VCT",
        countryCode: "+1784",
    },
    {
        name: "Bermuda",
        localName: "Bermuda",
        languages: "English",
        iso2: "BM",
        iso3: "BMU",
        countryCode: "+1441",
    },
    {
        name: "Seychelles",
        localName: "Sesel",
        languages: "Seychellois Creole",
        iso2: "SC",
        iso3: "SYC",
        countryCode: "+248",
    },
    {
        name: "British Indian Ocean Territory",
        localName: "British Indian Ocean Territory",
        languages: "English",
        iso2: "IO",
        iso3: "IOT",
        countryCode: "+246",
    },
    {
        name: "Guatemala",
        localName: "Guatemala",
        languages: "Spanish",
        iso2: "GT",
        iso3: "GTM",
        countryCode: "+502",
    },
    {
        name: "Ecuador",
        localName: "Ecuador",
        languages: "Spanish",
        iso2: "EC",
        iso3: "ECU",
        countryCode: "+593",
    },
    {
        name: "Martinique",
        localName: "Martinique",
        languages: "French",
        iso2: "MQ",
        iso3: "MTQ",
        countryCode: "+596",
    },
    {
        name: "Tajikistan",
        localName: "Тоҷикистон",
        languages: "Tajik",
        iso2: "TJ",
        iso3: "TJK",
        countryCode: "+992",
    },
    {
        name: "Malta",
        localName: "Malta",
        languages: "Maltese",
        iso2: "MT",
        iso3: "MLT",
        countryCode: "+356",
    },
    {
        name: "Gambia",
        localName: "Gambia",
        languages: "English",
        iso2: "GM",
        iso3: "GMB",
        countryCode: "+220",
    },
    {
        name: "Nigeria",
        localName: "Nigeria",
        languages: "English",
        iso2: "NG",
        iso3: "NGA",
        countryCode: "+234",
    },
    {
        name: "Bahamas",
        localName: "Bahamas",
        languages: "English",
        iso2: "BS",
        iso3: "BHS",
        countryCode: "+1242",
    },
    {
        name: "Kosovo",
        localName: "Kosovë",
        languages: "Albanian",
        iso2: "XK",
        iso3: "UNK",
        countryCode: "+383",
    },
    {
        name: "Kuwait",
        localName: "الكويت",
        languages: "Arabic",
        iso2: "KW",
        iso3: "KWT",
        countryCode: "+965",
    },
    {
        name: "Maldives",
        localName: "Dhivehi Raajje",
        languages: "Maldivian",
        iso2: "MV",
        iso3: "MDV",
        countryCode: "+960",
    },
    {
        name: "South Sudan",
        localName: "South Sudan",
        languages: "English",
        iso2: "SS",
        iso3: "SSD",
        countryCode: "+211",
    },
    {
        name: "Iran",
        localName: "ایران",
        languages: "Persian",
        iso2: "IR",
        iso3: "IRN",
        countryCode: "+98",
    },
    {
        name: "Albania",
        localName: "Shqipëri",
        languages: "Albanian",
        iso2: "AL",
        iso3: "ALB",
        countryCode: "+355",
    },
    {
        name: "Brazil",
        localName: "Brasil",
        languages: "Portuguese",
        iso2: "BR",
        iso3: "BRA",
        countryCode: "+55",
    },
    {
        name: "Serbia",
        localName: "Србија",
        languages: "Serbian",
        iso2: "RS",
        iso3: "SRB",
        countryCode: "+381",
    },
    {
        name: "Belize",
        localName: "Belize",
        languages: "English",
        iso2: "BZ",
        iso3: "BLZ",
        countryCode: "+501",
    },
    {
        name: "Myanmar",
        localName: "မြန်မာ",
        languages: "Burmese",
        iso2: "MM",
        iso3: "MMR",
        countryCode: "+95",
    },
    {
        name: "Bhutan",
        localName: "འབྲུག",
        languages: "Dzongkha",
        iso2: "BT",
        iso3: "BTN",
        countryCode: "+975",
    },
    {
        name: "Venezuela",
        localName: "Venezuela",
        languages: "Spanish",
        iso2: "VE",
        iso3: "VEN",
        countryCode: "+58",
    },
    {
        name: "Liberia",
        localName: "Liberia",
        languages: "English",
        iso2: "LR",
        iso3: "LBR",
        countryCode: "+231",
    },
    {
        name: "Jamaica",
        localName: "Jamaica",
        languages: "English",
        iso2: "JM",
        iso3: "JAM",
        countryCode: "+1876",
    },
    {
        name: "Poland",
        localName: "Polska",
        languages: "Polish",
        iso2: "PL",
        iso3: "POL",
        countryCode: "+48",
    },
    {
        name: "Cayman Islands",
        localName: "Cayman Islands",
        languages: "English",
        iso2: "KY",
        iso3: "CYM",
        countryCode: "+1345",
    },
    {
        name: "Brunei",
        localName: "بروني",
        languages: "Malay",
        iso2: "BN",
        iso3: "BRN",
        countryCode: "+673",
    },
    {
        name: "Comoros",
        localName: "جزر القمر",
        languages: "Arabic",
        iso2: "KM",
        iso3: "COM",
        countryCode: "+269",
    },
    {
        name: "Guam",
        localName: "Guam",
        languages: "Chamorro",
        iso2: "GU",
        iso3: "GUM",
        countryCode: "+1671",
    },
    {
        name: "Tonga",
        localName: "Tonga",
        languages: "Tongan",
        iso2: "TO",
        iso3: "TON",
        countryCode: "+676",
    },
    {
        name: "Kiribati",
        localName: "Kiribati",
        languages: "Gilbertese",
        iso2: "KI",
        iso3: "KIR",
        countryCode: "+686",
    },
    {
        name: "Ghana",
        localName: "Ghana",
        languages: "English",
        iso2: "GH",
        iso3: "GHA",
        countryCode: "+233",
    },
    {
        name: "Chad",
        localName: "Tchad",
        languages: "French",
        iso2: "TD",
        iso3: "TCD",
        countryCode: "+235",
    },
    {
        name: "Zimbabwe",
        localName: "Zimbabwe",
        languages: "English",
        iso2: "ZW",
        iso3: "ZWE",
        countryCode: "+263",
    },
    {
        name: "Saint Martin",
        localName: "Saint-Martin",
        languages: "French",
        iso2: "MF",
        iso3: "MAF",
        countryCode: "+590",
    },
    {
        name: "Mongolia",
        localName: "Монгол улс",
        languages: "Mongolian",
        iso2: "MN",
        iso3: "MNG",
        countryCode: "+976",
    },
    {
        name: "Portugal",
        localName: "Portugal",
        languages: "Portuguese",
        iso2: "PT",
        iso3: "PRT",
        countryCode: "+351",
    },
    {
        name: "American Samoa",
        localName: "American Samoa",
        languages: "Samoan",
        iso2: "AS",
        iso3: "ASM",
        countryCode: "+1684",
    },
    {
        name: "Republic of the Congo",
        localName: "République du Congo",
        languages: "French",
        iso2: "CG",
        iso3: "COG",
        countryCode: "+242",
    },
    {
        name: "Belgium",
        localName: "Belgique",
        languages: "French",
        iso2: "BE",
        iso3: "BEL",
        countryCode: "+32",
    },
    {
        name: "Israel",
        localName: "ישראל",
        languages: "Hebrew",
        iso2: "IL",
        iso3: "ISR",
        countryCode: "+972",
    },
    {
        name: "New Zealand",
        localName: "Aotearoa",
        languages: "Māori",
        iso2: "NZ",
        iso3: "NZL",
        countryCode: "+64",
    },
    {
        name: "Nicaragua",
        localName: "Nicaragua",
        languages: "Spanish",
        iso2: "NI",
        iso3: "NIC",
        countryCode: "+505",
    },
    {
        name: "Anguilla",
        localName: "Anguilla",
        languages: "English",
        iso2: "AI",
        iso3: "AIA",
        countryCode: "+1264",
    },
];