/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { PaymentDto, ReservationDto, TosspaymentRequestDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class ReservationService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags ReservationService
   * @name CreateReservation
   * @summary 예약 생성
   * @request POST:/v1/reservations
   * @secure
   */
  createReservation = (
    query: {
      locale: string;
    },
    data: ReservationDto,
    params: RequestParams = {},
  ) =>
    this.http.request<TosspaymentRequestDto, any>({
      path: `/v1/reservations`,
      method: 'POST',
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ReservationService
   * @name GetReservation
   * @summary 예약 정보
   * @request GET:/v1/reservations/{reservationId}
   * @secure
   */
  getReservation = (reservationId: number, params: RequestParams = {}) =>
    this.http.request<ReservationDto, any>({
      path: `/v1/reservations/${reservationId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ReservationService
   * @name GetPaymentByReservation
   * @summary 결제 정보
   * @request GET:/v1/reservations/{reservationId}/payment
   * @secure
   */
  getPaymentByReservation = (reservationId: number, params: RequestParams = {}) =>
    this.http.request<PaymentDto, any>({
      path: `/v1/reservations/${reservationId}/payment`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ReservationService
   * @name GetUpcomingReservations
   * @summary 다가오는 예약 목록
   * @request GET:/v1/reservations/upcoming
   * @secure
   */
  getUpcomingReservations = (params: RequestParams = {}) =>
    this.http.request<ReservationDto[], any>({
      path: `/v1/reservations/upcoming`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ReservationService
   * @name GetPastReservations
   * @summary 지난 예약 목록
   * @request GET:/v1/reservations/past
   * @secure
   */
  getPastReservations = (params: RequestParams = {}) =>
    this.http.request<ReservationDto[], any>({
      path: `/v1/reservations/past`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ReservationService
   * @name GetCurrentReservations
   * @summary 현재 예약 목록
   * @request GET:/v1/reservations/current
   * @secure
   */
  getCurrentReservations = (params: RequestParams = {}) =>
    this.http.request<ReservationDto[], any>({
      path: `/v1/reservations/current`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ReservationService
   * @name GetCanceledReservations
   * @summary 취소한 예약 목록
   * @request GET:/v1/reservations/canceled
   * @secure
   */
  getCanceledReservations = (params: RequestParams = {}) =>
    this.http.request<ReservationDto[], any>({
      path: `/v1/reservations/canceled`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * @description 예약을 취소하고 관련된 결제도 처리합니다.
   *
   * @tags ReservationService
   * @name CancelReservation
   * @summary 예약 취소
   * @request DELETE:/v1/reservations/{reservationId}/cancel
   * @secure
   */
  cancelReservation = (reservationId: number, params: RequestParams = {}) =>
    this.http.request<ReservationDto, any>({
      path: `/v1/reservations/${reservationId}/cancel`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...params,
    });
}
