import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { SearchFieldProps } from './types';
import { useTranslation } from 'react-i18next';

export const SearchField: React.FC<SearchFieldProps> = ({ onSearch, onSubmit }) => {
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  const handleReset = () => {
    setSearchValue('');
  };

  // 실시간 검색 처리 (입력값이 변경될 때마다 검색 실행)
  useEffect(() => {
    const searchTerm = searchValue.toLowerCase().trim();
    onSearch(searchTerm);
  }, [searchValue]);

  return (
    <SearchForm onSubmit={handleSubmit} role="search">
      <SearchContainer>
        <InputWrapper>
          <Label htmlFor="couponSearch" className="visually-hidden">
            {t('couponSearch')}
          </Label>
          <InputContainer>
            <SearchInput
              id="couponSearch"
              type="text"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={t('couponSearchPlaceholder')}
              aria-label={t('couponSearch')}
            />
            {searchValue && (
              <ResetButton type="button" onClick={handleReset}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <circle cx="9" cy="9" r="9" fill="#C6C6C6" />
                  <path
                    d="M6.41016 11.6902L11.6902 6.41016"
                    stroke="white"
                    stroke-width="1.2"
                    strokeLinecap="round"
                  />
                  <path
                    d="M6.40945 6.41016L11.6895 11.6902"
                    stroke="white"
                    stroke-width="1.2"
                    strokeLinecap="round"
                  />
                </svg>
              </ResetButton>
            )}
          </InputContainer>
        </InputWrapper>
        <SearchButton type="submit">{t('search')}</SearchButton>
      </SearchContainer>
    </SearchForm>
  );
};

const SearchForm = styled.form`
  width: 100%;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 12px;
  width: 100%;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Label = styled.label`
  font-size: 15px;
  font-weight: 500;
  color: var(--Grayscale-70, #555);
  margin-bottom: 5px;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const ResetButton = styled.button`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  border: 1px solid var(--4-e-433-c, #4e433c);
  padding: 14px 16px;
  font-size: 15px;
`;

const SearchButton = styled.button`
  height: 48px;
  padding: 0 16px;
  border-radius: 8px;
  border: 1px solid var(--Grayscale-20, #e4e4e4);
  background-color: #fff;
  color: var(--Grayscale-70, #555);
  font-weight: 500;
  min-width: 84px;
  cursor: pointer;
`;
