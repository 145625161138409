import * as React from 'react';
import styled from 'styled-components';

interface FormFieldProps {
  id: string;
  type: string;
  placeholder?: string;
  label?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  error?: string;
  isValid: boolean | null;
  defaultValue?: string;
}

export function FormField({
  id,
  type,
  placeholder,
  label,
  required,
  onChange,
  onBlur,
  error,
  defaultValue,
  isValid,
}: FormFieldProps) {
  return (
    <FieldContainer>
      <LabelContainer>
        <Label htmlFor={id}>{label}</Label>
        {required && <RequiredMark aria-label="Required field">*</RequiredMark>}
      </LabelContainer>
      <InputContainer>
        <Input
          type={type}
          id={id}
          placeholder={placeholder}
          required={required}
          aria-required={required}
          onChange={onChange}
          onBlur={onBlur}
          hasError={!!error}
          isValid={isValid}
          defaultValue={defaultValue}
        />
        {error && <ErrorText>{error}</ErrorText>}
      </InputContainer>
    </FieldContainer>
  );
}

const FieldContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 32px;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Label = styled.label`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.04px;
`;

const RequiredMark = styled.span`
  color: var(--error, #f35064);
  font-size: 13px;
  letter-spacing: -0.03px;
`;

const InputContainer = styled.div``;

const Input = styled.input<{ hasError?: boolean; isValid: boolean | null }>`
  width: 100%;
  min-height: 48px;
  padding: 19px 16px;
  border-radius: 8px;
  background: var(--Grayscale-5, #f8f8f8);
  font-size: 15px;
  color: var(--Grayscale-90, #1d1d1d);
  letter-spacing: -0.04px;
  border: 1px solid ${(props) => (props.hasError ? 'red' : '#DDDDDD')};

  &:focus {
    border-color: ${(props) => (props.hasError ? 'red' : '#000000')};
  }

  ${(props) =>
    props.isValid === true &&
    `
    border-color: #28a745;
    &:focus {
      border-color: #28a745;
      box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
    }
  `}
  ${(props) =>
    props.isValid === false &&
    `
    border-color: #dc3545;
    &:focus {
      border-color: #dc3545;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
    }
  `}
`;

const ErrorText = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;
