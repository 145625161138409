/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  AmenityDto,
  BulkPriceRequest,
  CalendarDay,
  LandmarkDto,
  ListingBedDto,
  ListingDto,
  ListingLowestPriceDto,
  ListingPriceDto,
  OptionDto,
  TagDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class ListingService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags ListingService
   * @name GetBulkPrices
   * @request POST:/v1/listings/bulk-prices
   * @secure
   */
  getBulkPrices = (data: BulkPriceRequest, params: RequestParams = {}) =>
    this.http.request<ListingLowestPriceDto[], any>({
      path: `/v1/listings/bulk-prices`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ListingService
   * @name GetListings
   * @summary Get Listings
   * @request GET:/v1/listings
   * @secure
   */
  getListings = (
    query: {
      /** @format date */
      startDate?: string;
      /** @format date */
      endDate?: string;
      /** @format int32 */
      guestNumber?: number;
      locale: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ListingDto[], any>({
      path: `/v1/listings`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ListingService
   * @name GetListingById
   * @summary Get Listing by ID
   * @request GET:/v1/listings/{listingId}
   * @secure
   */
  getListingById = (
    listingId: number,
    query: {
      locale: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ListingDto, any>({
      path: `/v1/listings/${listingId}`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ListingService
   * @name GetTagsByListing
   * @summary Get Tags
   * @request GET:/v1/listings/{listingId}/tags
   * @secure
   */
  getTagsByListing = (
    listingId: number,
    query: {
      locale: string;
      searchKeyword?: string;
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 20
       */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<TagDto[], any>({
      path: `/v1/listings/${listingId}/tags`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ListingService
   * @name GetRelatedListings
   * @summary Get Related Listings
   * @request GET:/v1/listings/{listingId}/relations
   * @secure
   */
  getRelatedListings = (
    listingId: number,
    query: {
      locale: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ListingDto[], any>({
      path: `/v1/listings/${listingId}/relations`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ListingService
   * @name GetPriceByListing
   * @summary Get Listing Price
   * @request GET:/v1/listings/{listingId}/price
   * @secure
   */
  getPriceByListing = (
    listingId: number,
    query?: {
      /** @format date */
      startingDate?: string;
      /** @format date */
      endingDate?: string;
      /** @format int32 */
      adults?: number;
      /** @format int32 */
      children?: number;
      /** @format int32 */
      version?: number;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ListingPriceDto, any>({
      path: `/v1/listings/${listingId}/price`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ListingService
   * @name GetOptionsByListing
   * @summary Get Options by Listing
   * @request GET:/v1/listings/{listingId}/options
   * @secure
   */
  getOptionsByListing = (
    listingId: number,
    query: {
      locale: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<OptionDto[], any>({
      path: `/v1/listings/${listingId}/options`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ListingService
   * @name GetLowestPriceByListing
   * @summary Get Lowest Price by Listing
   * @request GET:/v1/listings/{listingId}/lowest-price
   * @secure
   */
  getLowestPriceByListing = (
    listingId: number,
    query?: {
      /** @format date */
      startingDate?: string;
      /** @format date */
      endingDate?: string;
      /** @format int32 */
      adults?: number;
      /** @format int32 */
      children?: number;
      /** @format int32 */
      version?: number;
      /** @default false */
      includeActualPrice?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ListingLowestPriceDto, any>({
      path: `/v1/listings/${listingId}/lowest-price`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ListingService
   * @name GetLandmarksByListing
   * @summary Get Landmarks for a Listing
   * @request GET:/v1/listings/{listingId}/landmarks
   * @secure
   */
  getLandmarksByListing = (
    listingId: number,
    query: {
      locale: string;
      searchKeyword?: string;
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 20
       */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<LandmarkDto[], any>({
      path: `/v1/listings/${listingId}/landmarks`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ListingService
   * @name GetCalendarDaysByListing
   * @summary Get Listing Calendar
   * @request GET:/v1/listings/{listingId}/calendar
   * @secure
   */
  getCalendarDaysByListing = (
    listingId: number,
    query: {
      /**
       * Start date for the calendar range (YYYY-MM-DD).
       * @example "2024-01-01"
       */
      startDate: string;
      /**
       * End date for the calendar range (YYYY-MM-DD). Must be after startDate.
       * @example "2024-07-01"
       */
      endDate: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<CalendarDay[], any>({
      path: `/v1/listings/${listingId}/calendar`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ListingService
   * @name GetBedTypesByListing
   * @summary Get Bed Types
   * @request GET:/v1/listings/{listingId}/bed-types
   * @secure
   */
  getBedTypesByListing = (
    listingId: number,
    query: {
      locale: string;
      searchKeyword?: string;
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 20
       */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ListingBedDto[], any>({
      path: `/v1/listings/${listingId}/bed-types`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags ListingService
   * @name GetAmenitiesByListing
   * @summary Get Amenities
   * @request GET:/v1/listings/{listingId}/amenities
   * @secure
   */
  getAmenitiesByListing = (
    listingId: number,
    query: {
      locale: string;
      searchKeyword?: string;
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 20
       */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<AmenityDto[], any>({
      path: `/v1/listings/${listingId}/amenities`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
