import { makeAutoObservable, toJS } from 'mobx';
import { ListingDto, ListingLowestPriceDto } from 'services/data-contracts';
import { FilterType } from 'types/CommonTypes';

// interface LowestPrice {
//   id: number;
//   price: number;
// }

class FilterStore {
  isLoading: boolean = true;

  listings: ListingDto[];

  filteredListings: ListingDto[] | undefined;

  isFiltered: boolean = false;

  lowestPrices: ListingLowestPriceDto[] = [];

  isReadyLowestPrices: boolean = false;

  loadingRate: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  setListings(listings: ListingDto[]) {
    this.listings = listings;
    this.isLoading = false;
  }

  setFilteredListings(filteredListings?: ListingDto[]) {
    this.filteredListings = filteredListings;
  }

  setFiltered(isFiltered: boolean) {
    this.isFiltered = isFiltered;
  }

  // setLowestPrice(id: number, price: number) {
  //   // 이미 존재하는 id인지 확인
  //   const existingIndex = this.lowestPrices.findIndex((item) => item.id === id);

  //   if (existingIndex >= 0) {
  //     // 기존 가격 업데이트
  //     const updatedPrices = [...this.lowestPrices];
  //     updatedPrices[existingIndex] = { id, price };
  //     this.lowestPrices = updatedPrices;
  //   } else {
  //     // 새로운 가격 추가
  //     this.lowestPrices = [...this.lowestPrices, { id, price }];
  //   }

  //   // 로드율 계산 (listings이 있을 경우에만)
  //   if (this.listings?.length > 0) {
  //     this.loadingRate = Math.floor((this.lowestPrices.length / this.listings.length) * 100);
  //   }

  //   // listings와 lowestPrices의 갯수 비교
  //   if (this.lowestPrices?.length === this.listings?.length) {
  //     this.isReadyLowestPrices = true;
  //   }
  // }

  setLowestPrices(lowestPrices: ListingLowestPriceDto[]) {
    this.lowestPrices = lowestPrices;
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  // setSort(order: 'asc' | 'desc', target: 'price' | 'newest') {
  //   if (!this.listings) return;

  //   let sortedListings = [...this.listings];

  //   if (target === 'price') {
  //     // 가격 기준 정렬
  //     sortedListings.sort((a, b) => {
  //       const priceA = this.lowestPrices.find((item) => item.listingId === a.id)?.price || 0;
  //       const priceB = this.lowestPrices.find((item) => item.listingId === b.id)?.price || 0;

  //       return order === 'asc' ? priceA - priceB : priceB - priceA;
  //     });
  //   } else if (target === 'newest') {
  //     // 최신순 정렬 (id가 높을수록 최신이라고 가정)
  //     sortedListings.sort((a, b) => {
  //       return order === 'asc' ? (b.hostawayId ?? 0) - (a.hostawayId ?? 0) : (a.hostawayId ?? 0) - (b.hostawayId ?? 0);
  //     });
  //   }

  //   if (this.isFiltered && this.filteredListings) {
  //     // 필터링된 목록이 있는 경우 해당 목록도 정렬
  //     let sortedFilteredListings = [...this.filteredListings];

  //     if (target === 'price') {
  //       sortedFilteredListings.sort((a, b) => {
  //         const priceA = this.lowestPrices.find((item) => item.listingId === a.id)?.price || 0;
  //         const priceB = this.lowestPrices.find((item) => item.listingId === b.id)?.price || 0;

  //         return order === 'asc' ? priceA - priceB : priceB - priceA;
  //       });
  //     } else if (target === 'newest') {
  //       sortedFilteredListings.sort((a, b) => {
  //         return order === 'asc' ? (a.id ?? 0) - (b.id ?? 0) : (b.id ?? 0) - (a.id ?? 0);
  //       });
  //     }

  //     this.filteredListings = sortedFilteredListings;
  //   }

  //   this.filteredListings = sortedListings;
  // }

  applyFilters(filterOptions?: FilterType) {
    // 필터 옵션이 없는 경우 모든 리스팅 반환
    if (!filterOptions) {
      this.setFilteredListings(this.listings);
      return this.listings;
    }

    // 필터 옵션에서 지역 ID 배열 가져오기
    const selectedRegionIds = filterOptions.regions || [];
    // 가격 범위 옵션 가져오기
    const priceRange = filterOptions.priceOptions || {};
    // 옵션 ID 배열 가져오기
    const selectedOptions = filterOptions.options || [];
    // 정렬 옵션 가져오기
    const sortOption = filterOptions.sort || { key: 'newest', value: 'Newest' };

    // 필터 조건이 하나도 없고 정렬만 있는 경우
    const hasFilterConditions =
      (selectedRegionIds.length > 0 && !selectedRegionIds.includes(0)) ||
      (priceRange.from !== undefined || priceRange.to !== undefined) ||
      (selectedOptions.length > 0);

    // 필터 조건이 없고 정렬만 있는 경우 모든 리스팅에 정렬 적용
    if (!hasFilterConditions && sortOption) {
      const sortedListings = this.applySorting(this.listings, sortOption);
      this.setFilteredListings(sortedListings);
      return sortedListings;
    }
    // 필터 조건이 있는 경우 필터링 진행
    const filteredListings = this.listings.filter((listing) => {
      // 지역 필터 검사
      let passRegionFilter = true;
      if (selectedRegionIds.length > 0 && !selectedRegionIds.includes(0)) {
        if (selectedRegionIds.includes(100)) {
          passRegionFilter = listing.region?.id === 100 || !listing.region;
        } else {
          passRegionFilter = selectedRegionIds.includes(listing.region?.id ?? 0);
        }
      }

      // 가격 필터 검사
      let passPriceFilter = true;
      if (priceRange.from !== undefined || priceRange.to !== undefined) {
        const itemPrice = this.lowestPrices.find((item) => item.listingId === listing.id)?.price;

        if (itemPrice !== undefined) {
          if (priceRange.from !== undefined && itemPrice < priceRange.from) {
            passPriceFilter = false;
          }
          if (priceRange.to !== undefined && itemPrice > priceRange.to) {
            passPriceFilter = false;
          }
        }
      }

      // 옵션 필터 검사
      let passOptionsFilter = true;
      if (selectedOptions.length > 0) {
        passOptionsFilter = selectedOptions.every((optionId) =>
          listing.amenities?.some((option) => option.id === optionId),
        );
      }

      return passRegionFilter && passPriceFilter && passOptionsFilter;
    });

    // 필터링 후 정렬 적용
    const sortedListings = this.applySorting(filteredListings, sortOption);

    // 정렬된 결과 저장
    this.setFilteredListings(sortedListings);

    return sortedListings;
  }

  // 정렬 함수 구현
  applySorting(listings: ListingDto[], sortOption: { key: string; value: string }) {
    if (!listings || listings.length === 0) {
      return [];
    }

    // 정렬된 목록의 복사본 생성 (원본 변경 방지)
    const listingsToSort = [...listings];

    switch (sortOption.value) {
      case 'PriceLowToHigh': // 가격 오름차순
        return listingsToSort.sort((a, b) => {
          const priceA = this.lowestPrices.find(item => item.listingId === a.id)?.price || 0;
          const priceB = this.lowestPrices.find(item => item.listingId === b.id)?.price || 0;
          return priceA - priceB;
        });

      case 'PriceHighToLow': // 가격 내림차순
        return listingsToSort.sort((a, b) => {
          const priceA = this.lowestPrices.find(item => item.listingId === a.id)?.price || 0;
          const priceB = this.lowestPrices.find(item => item.listingId === b.id)?.price || 0;
          return priceB - priceA;
        });

      case 'Newest': // 최신순
        return listingsToSort.sort((a, b) => {
          const dateA = a.hostawayId ? new Date(a.hostawayId).getTime() : 0;
          const dateB = b.hostawayId ? new Date(b.hostawayId).getTime() : 0;
          return dateB - dateA;
        });

      default: // 기본 정렬 (ID 기준)
        return listingsToSort;
    }
  }

  resetFilters() {
    this.isFiltered = false;
    this.filteredListings = this.listings;
  }

  resetFiltered() {
    this.isFiltered = false;
    this.filteredListings = this.listings;
  }

  resetReadyLowestPrices() {
    this.isReadyLowestPrices = false;
    this.lowestPrices = [];
  }
}

export default FilterStore;
