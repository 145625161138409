import styled from 'styled-components';
import React from 'react';
import Subtitle from 'components/commons/Subtitle';
import Row from 'saladsoft-primitive/dist/controls/Row';
import { useMediaContext } from 'saladsoft-primitive';

const Section = ({
  title,
  children,
  style,
  rightAction,
}: {
  title: React.ReactNode;
  children: React.ReactNode;
  style?: React.CSSProperties;
  rightAction?: React.ReactNode;
}) => {
  const { isMobile } = useMediaContext();

  return (
    <SectionWrapper style={style}>
      <TitleContainer>
        {isMobile ? (
          <MobileTitle>{title}</MobileTitle>
        ) : (
          <Subtitle type="B" style={{ marginBottom: '16px' }}>
            {title}
          </Subtitle>
        )}

        {rightAction && <RightAction>{rightAction}</RightAction>}
      </TitleContainer>
      {children}
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div`
  margin: 60px 0;

  @media ${(props) => props.theme.media.mobile} {
    margin: 48px 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const MobileTitle = styled.div`
  font-size: 15px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.037px;
  margin-bottom: 12px;
`;

const RightAction = styled.div`
  color: var(--gray-gray-60, #717171);
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
`;

export default Section;
