import React from 'react';
import styled from 'styled-components';
import { Row, useMediaContext } from 'saladsoft-primitive';
import BodyText from '../BodyText';
import { style } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { Popover } from '@mui/material';
import { CountInner } from '../CountInner';
import { useStoreWrapper } from 'stores/StoreHelper';
import { useSearchParams } from 'react-router-dom';
import { is } from 'date-fns/locale';

const CountButtonSimple = ({
  disabled = false,
  count,
  maxCount,
  onChange,
}: {
  disabled?: boolean;
  count: number;
  maxCount: number;
  onChange: (guest: number) => void;
}) => {
  const { t } = useTranslation();
  const { listingStore } = useStoreWrapper();
  const { isMobile } = useMediaContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [guestCount, setGuestCount] = useState<number>(1);
  const [maxGuest, setMaxGuest] = useState<number>(maxCount || 12);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    const guestParam = searchParams.get('guests');
    if (guestParam) {
      const guestValue = parseInt(guestParam, 10);
      if (!isNaN(guestValue)) {
        setGuestCount(guestValue);
        onChange?.(guestValue);
      }
    } else if (count) {
      setGuestCount(count);
    }
  }, [count, searchParams, onChange]);

  useEffect(() => {
    if (listingStore.listing) {
      setMaxGuest(listingStore.listing.maxGuest ?? maxCount ?? 12);
    } else {
      setMaxGuest(maxCount || 12);
    }
  }, [listingStore.listing, maxCount]);

  return (
    <>
      <PeopleLayout selected={anchorEl !== null} onClick={(e: any) => handleClick(e)}>
        <Row
          {...style}
          gap={10}
          alignItems="center"
          width="100%"
          borderRadius={10}
          background="#F8F8F8"
          padding="0 6px"
        >
          <CountBoxContainer
            style={{
              width: isMobile ? 'auto' : '100%',
              alignItems: 'center',
              cursor: 'pointer',
            }}
          >
            <Row gap={5} alignItems="center" justifyContent="flex-start">
              <img src="/images/people.svg" alt="people" />
              <>{!isMobile && <BodyText type="C">{t('People')}</BodyText>}</>
              <BodyText type="C">
                {guestCount}
                <>{isMobile && <>{t('PeopleCount')}</>}</>
              </BodyText>
            </Row>
          </CountBoxContainer>
        </Row>
      </PeopleLayout>

      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <CountInner
          onChange={(count: number) => {
            const newSearchParams = new URLSearchParams(searchParams.toString());
            newSearchParams.set('guests', count.toString());
            setSearchParams(newSearchParams);

            onChange?.(count);
            setGuestCount(count);
          }}
          count={guestCount}
          maxCount={maxGuest}
          onClose={handleClose}
        />
      </Popover>
    </>
  );
};

export default CountButtonSimple;

const PeopleLayout = styled.div<{ selected: boolean }>``;

const CountBoxContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  height: 44px;
  padding: 20px 16px;
  flex-shrink: 0;
`;
