import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Row } from 'saladsoft-primitive';
import styled from 'styled-components';

const AdminLayout = ({ children }: { children: ReactNode }) => {
  const routes = [
    { route: '/admin/listings', name: '숙소관리' },
    { route: '/admin/checkin-guied', name: '체크인가이드' },
    { route: '/admin/settings', name: '설정' },
  ];

  return (
    <Container>
      <Row gap="30px" height="60px" justifyContent="center" width={'100%'} position="sticky" top={0} backgroundColor="var(--background-color)">
        <Row width="400px" position="absolute" left={10} justifyContent="flex-start" gap="20px">
          <img src="/images/butlerleelogo.png" alt="butlerlee" style={{ width: '200px' }} />
          {/* <div style={{ fontSize: '14px', fontWeight: 'bold' }}>PROPERTY MANAGER</div> */}
        </Row>
        <>
          {routes.map((route) => (
            <ButtonStyle>
              <Link to={route.route}>{route.name}</Link>
            </ButtonStyle>
          ))}
        </>
      </Row>
      <Row
        gap="10px"
        flexGrow={1}
        alignItems="flex-start"
        justifyContent="flex-start"
        padding={20}
        boxSizing="border-box"
      >
        <div style={{ width: '300px' }}>
        </div>
        <div style={{ flexGrow: 1 }}>{children}</div>
      </Row>
    </Container>
  );
};

const Container = styled.div``;

const ButtonStyle = styled.div``;

export default AdminLayout;
