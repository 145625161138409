import React, { useId, useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import { Scrollbar, Navigation } from 'swiper/modules';
import { ListingDto } from 'services/data-contracts';
import { useMediaContext } from 'saladsoft-primitive';
// MUI 아이콘 임포트
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CloseIcon from '@mui/icons-material/Close';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { motion } from 'framer-motion';
import { styled as muiStyled } from '@mui/material/styles';

interface imageType {
  src: string;
  rows: number;
  cols: number;
}

// ImageListItem을 motion 컴포넌트로 래핑
const MotionImageListItem = motion(muiStyled(ImageListItem)``);

// 애니메이션 variants 정의
const containerVariants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1 // 각 아이템 사이의 딜레이
    }
  }
};

const itemVariants = {
  hidden: {
    opacity: 0,
    y: 20,
    scale: 0.9
  },
  show: (index: number) => ({
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.5,
      delay: index * 0.15, // 각 아이템마다 0.15초씩 딜레이 증가
      ease: "easeOut"
    }
  })
};

const GalleryViewer = ({ listing, useSlider = true, onClose, initialIndex = 0 }:
  { listing?: ListingDto, useSlider?: boolean, onClose: () => void, initialIndex: number }) => {
  const { isMobile } = useMediaContext();
  // 고유 ID 생성 (React 18 이상)
  const uniqueId = useId().replace(/:/g, '');
  const prevBtnId = `gallery-prev-${uniqueId}`;
  const nextBtnId = `gallery-next-${uniqueId}`;

  const [imageList, setImageList] = useState<imageType[]>([]);
  const [rowHeight, setRowHeight] = useState(121);

  // 이미지 수에 따른 패턴 정의
  const patterns = {
    1: [
      { cols: 4, rows: 4 }  // 1장일 때는 전체 화면
    ],
    2: [
      { cols: 2, rows: 4 },  // 좌측 전체
      { cols: 2, rows: 4 }   // 우측 전체
    ],
    3: [
      { cols: 2, rows: 4 },  // 좌측 전체
      { cols: 2, rows: 2 },  // 우측 상단
      { cols: 2, rows: 2 }   // 우측 하단
    ],
    4: [
      { cols: 2, rows: 2 },  // 좌상단
      { cols: 2, rows: 2 },  // 우상단
      { cols: 2, rows: 2 },  // 좌하단
      { cols: 2, rows: 2 }   // 우하단
    ],
    default: [
      { cols: 2, rows: 3 },  // 큰 이미지
      { cols: 1, rows: 1 },
      { cols: 1, rows: 1 },
      { cols: 1, rows: 2 },
      { cols: 1, rows: 1 },
      { cols: 1, rows: 1 },
      { cols: 2, rows: 1 },
      { cols: 2, rows: 2 },
      // 9장 이상은 1x1 크기로 통일
    ]
  };

  useEffect(() => {
    if (!listing?.images) return;

    const imageCount = listing.images.length;
    const selectedPattern = patterns[imageCount as keyof typeof patterns] || patterns.default;

    const newImageList = listing.images.map((item, index) => {
      const pattern = index < selectedPattern.length
        ? selectedPattern[index]
        : { cols: 1, rows: 1 };  // 패턴보다 많은 이미지는 1x1로 처리

      return {
        src: item?.url || '',
        rows: pattern.rows,
        cols: pattern.cols
      };
    });

    setImageList(newImageList);
  }, [listing]);

  useEffect(() => {
    // 화면 크기에 따라 rowHeight 계산
    const calculateRowHeight = () => {
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      const aspectRatio = 16 / 9; // 이미지 비율

      // 뷰포트 높이의 90%를 4행으로 나누어 계산
      const height = Math.floor((viewportHeight * 0.9) / 4);
      setRowHeight(height);
    };

    calculateRowHeight();
    window.addEventListener('resize', calculateRowHeight);

    return () => window.removeEventListener('resize', calculateRowHeight);
  }, []);

  return (
    <Overlay >
      <CloseButton onClick={(e) => {
        e.stopPropagation();
        onClose();
      }}>
        <CloseIcon />
      </CloseButton>
      <ImageList
        sx={{
          width: '90%',
          height: '90vh',
          overflow: 'hidden'
        }}
        variant="quilted"
        cols={4}
        rowHeight={rowHeight}
        component={motion.div}
        variants={containerVariants}
        initial="hidden"
        animate="show"
      >
        {imageList.map((item, index) => (
          <MotionImageListItem
            key={item.src}
            cols={item.cols}
            rows={item.rows}
            variants={itemVariants}
            custom={index}
            initial="hidden"
            animate="show"
          >
            <img
              srcSet={`${item.src}?w=161&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.src}?w=161&fit=crop&auto=format`}
              alt={`Gallery image ${item.src}`}
              loading="lazy"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </MotionImageListItem>
        )) || []}
      </ImageList>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1000;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// 닫기 버튼 스타일
const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10002;
  transition: background-color 0.3s ease;
  padding: 0;
  
  svg {
    color: white;
    font-size: 24px;
  }
  
  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  @media (max-width: 768px) {
    top: 15px;
    right: 15px;
    width: 36px;
    height: 36px;
    
    svg {
      font-size: 20px;
    }
  }
`;

export default GalleryViewer;
