import BTButton from 'components/input/BTButton';
import PageContainer from 'containers/pc/PageContainer';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import i18n from 'i18n';
import { getUrlParam } from 'libs/helper/utils';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ServiceHelper from 'stores/ServiceHelper';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
const PaymentSuccessPage = () => {
  const navigate = useLocaleNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const paymentkey = getUrlParam('paymentKey');
    const orderId = getUrlParam('orderId');
    const amount = getUrlParam('amount');
    const reservationKey = getUrlParam('reservationkey');

    if (paymentkey && orderId && amount) {
      ServiceHelper.paymentService
        ?.approveTosspay({ paymentKey: paymentkey, orderId, amount: Number(amount) })
        .then((res) => {
          if (res.status === 200) {
            navigate(
              `/reservation-complete?orderId=${res.data.hostawayReservationId}&reservationkey=${reservationKey}`,
            );
            return;
          } else {
            const errorRes = res as any;
            const errorCode = errorRes?.error?.data?.errorCode;
            let code = 'ER_1000'; // 기본 에러 코드

            switch (errorCode) {
              case 'RESERVATION.NOT_FOUND':
                code = 'ER_0001'; // 예약 정보를 찾을 수 없습니다
                break;
              case 'PAYMENT.NOT_FOUND':
                code = 'ER_0002'; // 결제 정보를 찾을 수 없습니다
                break;
              case 'USER.NOT_FOUND':
                code = 'ER_0003'; // 사용자를 찾을 수 없습니다
                break;
              case 'PRODUCT.NOT_FOUND':
                code = 'ER_0004'; // 상품을 찾을 수 없습니다
                break;
              case 'OPTION.NOT_FOUND':
                code = 'ER_0005'; // 선택한 옵션을 찾을 수 없습니다
                break;
              case 'OPTION.INVALID_QUANTITY':
                code = 'ER_0006'; // 선택한 옵션 수량이 허용되지 않습니다
                break;
              case 'OPTION.NOT_ACTIVATED':
                code = 'ER_0007'; // 선택한 옵션이 활성화되지 않았습니다
                break;
              case 'VOUCHER.NOT_FOUND':
                code = 'ER_0008'; // 바우처를 찾을 수 없습니다
                break;
              case 'USER_COUPON.NOT_FOUND':
                code = 'ER_0009'; // 사용자 쿠폰을 찾을 수 없습니다
                break;
              case 'RESERVATION.INVALID':
                code = 'ER_0010'; // 예약 정보가 유효하지 않습니다
                break;
              case 'GUEST.NAME_REQUIRED':
                code = 'ER_0011'; // 게스트 이름이 필요합니다
                break;
              case 'GUEST.EMAIL_REQUIRED':
                code = 'ER_0012'; // 게스트 이메일이 필요합니다
                break;
              case 'GUEST.PHONE_REQUIRED':
                code = 'ER_0013'; // 게스트 전화번호가 필요합니다
                break;
              case 'GUEST.ADULT_INFO_REQUIRED':
                code = 'ER_0014'; // 성인 인원 정보가 필요합니다
                break;
              case 'LANGUAGE.CODE_REQUIRED':
                code = 'ER_0015'; // 언어 코드가 필요합니다
                break;
              case 'LANGUAGE.INVALID_CODE':
                code = 'ER_0016'; // 유효하지 않은 언어 코드입니다
                break;
              case 'DATE.INVALID':
                code = 'ER_0017'; // 유효하지 않은 날짜입니다
                break;
              case 'COUNTRY.INVALID_CODE':
                code = 'ER_0018'; // 유효하지 않은 국가 코드입니다
                break;
              case 'PHONE.INVALID':
                code = 'ER_0019'; // 유효하지 않은 전화번호입니다
                break;
              case 'AMOUNT.REQUIRED':
                code = 'ER_0020'; // 총 금액 정보가 필요합니다
                break;
              case 'ROOM.CAPACITY_EXCEEDED':
                code = 'ER_0021'; // 객실 수용 인원을 초과했습니다
                break;
              case 'PAYMENT.INVALID_AMOUNT':
                code = 'ER_0022'; // 총 금액이 유효하지 않습니다
                break;
              case 'PAYMENT.CREATE_FAILED':
                code = 'ER_0023'; // 결제 생성에 실패했습니다
                break;
              case 'RESERVATION.NOT_AVAILABLE':
                code = 'ER_0024'; // 예약이 불가능합니다
                break;
              default:
                code = 'ER_1000'; // 알 수 없는 오류가 발생했습니다
                break;
            }
            navigate(`/${i18n.language}/fail?code=${code}`);
            return;
          }
        });
    } else {
      setLoading(false);
      toast.warning(t('invalidAccess'));
      return;
    }
  }, [location.search]);

  return (
    <PageContainer>
      <div className="page-container" style={{ textAlign: 'center' }}>
        <LoadingWrapper>
          {loading ? (
            <img
              style={{ width: '100px' }}
              src="/images/butlerlee-loading.gif"
              alt="butlerlee-loading"
            />
          ) : (
            <div></div>
          )}
        </LoadingWrapper>
      </div>
    </PageContainer>
  );
};

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

export default PaymentSuccessPage;
