import React, { CSSProperties, useEffect, useState } from 'react';

const FONT_FAMILY = 'Pretendard';

/**
 * 텍스트 스타일링을 위한 컴포넌트
 * @param {Object} props
 * @param {React.ReactNode} props.children - 텍스트 내용
 * @param {CSSProperties} [props.style] - 추가 스타일 속성
 * @param {('A'|'B'|'C'|'D'|'E'|'F'|'G'|'H'|'I')} [props.type] - 텍스트 스타일 타입
 * @returns {JSX.Element}
 *
 * @typedef {'A'|'B'|'C'|'D'|'E'|'F'|'G'|'H'|'I'} TextType
 * @description 텍스트 타입 옵션:
 * - A: 15px (0.9375rem) - Bold (600)
 * - B: 15px (0.9375rem) - Medium (500)
 * - C: 15px (0.9375rem) - Regular (400)
 * - D: 14px (0.875rem) - Bold (600)
 * - E: 14px (0.875rem) - Medium (500)
 * - F: 14px (0.875rem) - Regular (400)
 * - G: 13px (0.8125rem) - Bold (600)
 * - H: 13px (0.8125rem) - Medium (500)
 * - I: 13px (0.8125rem) - Regular (400)
 */
const BodyText = ({
  children,
  style,
  type,
}: {
  children: any;
  style?: CSSProperties;
  type?: 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I';
}) => {
  const [textStyle, setTextStyle] = useState<CSSProperties>({});

  useEffect(() => {
    let tempStyle: CSSProperties = { fontFamily: FONT_FAMILY };
    switch (type) {
      // 볼드체의 일반 텍스트 (15px)
      case 'A':
        tempStyle = { ...tempStyle, fontSize: '0.9375rem', fontWeight: '600' }; // 15px
        break;
      case 'B':
        tempStyle = { ...tempStyle, fontSize: '0.9375rem', fontWeight: '500' }; // 15px
        break;
      case 'C':
        tempStyle = { ...tempStyle, fontSize: '0.9375rem', fontWeight: '400' }; // 15px
        break;
      case 'D':
        tempStyle = { ...tempStyle, fontSize: '0.875rem', fontWeight: '600' }; // 14px
        break;
      case 'E':
        tempStyle = { ...tempStyle, fontSize: '0.875rem', fontWeight: '500' }; // 14px
        break;
      case 'F':
        tempStyle = { ...tempStyle, fontSize: '0.875rem', fontWeight: '400' }; // 14px
        break;
      case 'G':
        tempStyle = { ...tempStyle, fontSize: '0.8125rem', fontWeight: '600' }; // 13px
        break;
      case 'H':
        tempStyle = { ...tempStyle, fontSize: '0.8125rem', fontWeight: '500' }; // 13px
        break;
      case 'I':
        tempStyle = { ...tempStyle, fontSize: '0.8125rem', fontWeight: '400' }; // 13px
        break;
      default:
        tempStyle = { ...tempStyle, fontSize: '1rem' };
        break;
    }

    setTextStyle(tempStyle);
  }, [type]);
  return <div style={{ ...textStyle, ...style }}>{children}</div>;
};

export default BodyText;
