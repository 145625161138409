import AuthStore from './AuthStore';
import FilterStore from './FilterStore';
import ListingStore from './ListingStore';
import CalendarStore from './CalendarStore';

interface ILocalStore {
  authStore: AuthStore;
  filterStore: FilterStore;
  listingStore: ListingStore;
  calendarStore: CalendarStore;
}

const LocalStore: ILocalStore = {
  authStore: new AuthStore(),
  filterStore: new FilterStore(),
  listingStore: new ListingStore(),
  calendarStore: new CalendarStore(),
};

export { ILocalStore, LocalStore };
