/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AmenityDto } from './data-contracts';
import { HttpClient, RequestParams } from './http-client';

export class AmenityService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags AmenityService
   * @name GetAmenitiesByFilter
   * @summary Get Filter Amenities
   * @request GET:/v1/amenities/by-filter
   * @secure
   */
  getAmenitiesByFilter = (
    query: {
      locale: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<AmenityDto[], any>({
      path: `/v1/amenities/by-filter`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
