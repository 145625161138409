import { useEffect } from "react";

const useExitClearSession = () => {
  useEffect(() => {
    const clearSessionStorage = () => {

      // 🚀 sessionStorage 데이터를 백엔드로 전송 후 삭제 (크롬 경고창 방지)
      const sessionData = JSON.stringify(sessionStorage);
      navigator.sendBeacon("/api/session-clear", sessionData); // 서버로 데이터 전송

      // ✅ sendBeacon() 이후 세션 스토리지 삭제 (경고창 없이!)
      sessionStorage.clear();
    };

    window.addEventListener("pagehide", clearSessionStorage);
    return () => {
      window.removeEventListener("pagehide", clearSessionStorage);
    };
  }, []);
};

export default useExitClearSession;