import React, { useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { UserCouponDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import { useUserInfo } from 'providers/UserInfoProvider';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n';
import { CouponCard } from './CouponCard';
import { SearchField } from './SearchField';
import { EmptyState } from 'components/commons/EmptyState';
import { useMediaContext } from 'saladsoft-primitive';
import MyInfoTabName from 'components/commons/mobile/MyInfoTabName';

export const CouponManagement: React.FC = () => {
  const { isMobile } = useMediaContext();

  const [coupons, setCoupons] = useState<UserCouponDto[]>([]);
  const [filteredCoupons, setFilteredCoupons] = useState<UserCouponDto[]>([]);

  const { loginStatus } = useUserInfo();
  const { t } = useTranslation();

  // API로 쿠폰 리스트 가져오기
  const fetchCoupons = () => {
    ServiceHelper.myAccountService
      ?.getMyCoupons({
        locale: i18n.language,
      })
      .then((res) => {
        if (res.status === 200) {
          setCoupons(res.data);
          setFilteredCoupons(res.data);
        } else {
          toast.warning(t('fail fetch coupons'));
        }
      });
  };

  // 쿠폰 검색 처리
  const handleSearch = (value: string) => {
    const searchTerm = value.toLowerCase().trim();
    if (!searchTerm) {
      setFilteredCoupons(coupons);
      return;
    }

    const filtered = coupons.filter(
      (userCoupon) =>
        userCoupon?.coupon?.name?.toLowerCase().includes(searchTerm) ||
        userCoupon?.code?.toLowerCase().includes(searchTerm),
    );
    setFilteredCoupons(filtered);
  };

  useEffect(() => {
    if (loginStatus) {
      fetchCoupons();
    }
  }, [loginStatus]);

  useLayoutEffect(() => {
    const footer = document.querySelector('.footer') as HTMLElement;
    if (footer) {
      footer.style.display = 'none';
    }
  }, []);

  return (
    <Container>
      {!isMobile && <PageTitle>{t('availableCoupons')}</PageTitle>}
      <MainContent>
        <SearchSection>
          <SearchField
            onSearch={handleSearch}
            onSubmit={() => {
              if (filteredCoupons.length === 0) {
                toast.error(t('no coupons found'));
              }
            }}
          />
          <CouponCount>
            <span>{t('availableCoupons')}</span>
            <CountWrapper>
              <Count>{filteredCoupons.length}</Count>
              <span>{t('couponUnit', { count: filteredCoupons.length })}</span>
            </CountWrapper>
          </CouponCount>
        </SearchSection>
        {filteredCoupons.length === 0 ? (
          <EmptyState message={t('no coupons found')} />
        ) : (
          <CouponList>
            {filteredCoupons.map((coupon) => (
              <CouponCard key={coupon.code} coupon={coupon} selectable={false} />
            ))}
          </CouponList>
        )}
        <InfoSection>
          <InfoTitle>{t('usageMethodAndNotes')}</InfoTitle>
          <InfoContent>
            <InfoList>
              <InfoItem>{t('couponNumberOnlyEnglishAndNumber')}</InfoItem>
              <InfoItem>{t('couponAvailableOnlyAtRelatedAccommodation')}</InfoItem>
              <InfoItem>{t('couponConsiderStayPeriodForReservation')}</InfoItem>
            </InfoList>
          </InfoContent>
        </InfoSection>
      </MainContent>
    </Container>
  );
};

const Container = styled.main`
  background-color: #fff;
  max-width: 825px;
  width: 100%;
`;

const PageTitle = styled.h1`
  font-size: 28px;
  font-weight: 600;
  color: var(--Grayscale-90, #1d1d1d);
  letter-spacing: -0.07px;
`;

const MainContent = styled.div`
  margin-top: calc(40px - 14px);
`;

const SearchSection = styled.section`
  margin-bottom: 16px;
`;

const CouponCount = styled.div`
  display: flex;
  gap: 4px;
  margin-top: 32px;
  font-size: 13px;
  color: var(--Grayscale-70, #555);
`;

const CountWrapper = styled.div`
  display: flex;
`;

const Count = styled.span`
  color: var(--sub-color, #1c51b6);
  font-weight: 600;
`;

const CouponList = styled.section`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 47px;

  @media ${(props) => props.theme.media.mobile} {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

const InfoSection = styled.section`
  margin-top: 47px;
  font-family: Pretendard, sans-serif;
`;

const InfoTitle = styled.h2`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 15px;
  font-weight: 600;
`;

const InfoContent = styled.div`
  margin-top: 16px;
`;

const InfoList = styled.div`
  background: var(--Grayscale-5, #f8f8f8);
  border-radius: 12px;
  padding: 20px 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

const InfoItem = styled.p`
  color: var(--Grayscale-50, #8e8e8e);
  font-size: 13px;
  padding: 0;
  margin: 0;
  / &:last-child {
    margin-bottom: 0;
  }
  word-break: keep-all;
  overflow-wrap: break-word;
  white-space: normal;
`;
