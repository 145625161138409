import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useToken from 'hooks/useToken';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import { toast } from 'react-toastify';
interface NavItemProps {
  icon: string;
  label: string;
  menuKey: number;
  isActive?: boolean;
  path: string;
}

interface BottomNavigationProps {
  activeTab?: number;
}

const navigationItems: NavItemProps[] = [
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/e25b0a60aea455fca13d05a38847f57f7643941da57491b5c18424ec50cb8203?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25',
    label: 'Reservation',
    menuKey: 0,
    isActive: true,
    path: '/reservation',
  },
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/938e492b1a142adc45884318276eee928148bcdb819ede5ec0c0c9f41e3a1ec9?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25',
    label: 'My Reservations',
    menuKey: 1,
    path: '/my-reservations',
  },
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/d92c78fe87f291261b13fb103be85dbf7869c121d34723d3304c34badebe9f06?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25',
    label: 'Promotion',
    menuKey: 2,
    path: '/promotion',
  },
  {
    icon: 'https://cdn.builder.io/api/v1/image/assets/TEMP/3658a7543d0fb51d1751c51e9d1fe73f9add460d16dbf1e736aba1719996f2fd?placeholderIfAbsent=true&apiKey=f79f911b3c8d4b5ba0fdd21149734b25',
    label: 'MY',
    menuKey: 3,
    path: '/my-page',
  },
];

const NavigationItem: React.FC<NavItemProps> = (props) => {
  const navigate = useLocaleNavigate();
  const { t, i18n } = useTranslation();
  const { validUser } = useToken();
  const currentLanguage = i18n.language;

  return (
    <NavItemWrapper
      isActive={props.isActive}
      onClick={() => {
        if (props.menuKey === 3 || props.menuKey === 1) {
          if (validUser()) {
            navigate(props.path);
          } else {
            if (props.menuKey === 1) {
              navigate('/guest-reservation');
              return;
            }
            navigate('/login');
            return;
          }
        } else {
          navigate(props.path);
        }
      }}
      isEnglish={currentLanguage.startsWith('en')}
    >
      <NavContent>
        <NavIcon loading="lazy" src={props.icon} alt={`${t(props.label)} icon`} />
        <NavLabel>{t(props.label)}</NavLabel>
      </NavContent>
    </NavItemWrapper>
  );
};

const Navigation: React.FC<BottomNavigationProps> = ({ activeTab = 0 }) => {
  return (
    <NavigationContainer>
      <NavigationTabs>
        {navigationItems.map((item) => (
          <NavigationItem
            key={item.menuKey}
            icon={item.icon}
            label={item.label}
            // isActive={index === activeTab}
            isActive={item.menuKey === activeTab}
            path={item.path}
            menuKey={item.menuKey}
          />
        ))}
      </NavigationTabs>
      <HomeIndicator role="presentation" aria-hidden="true" />
    </NavigationContainer>
  );
};

const NavigationContainer = styled.nav<{ isHidden?: boolean }>`
  background: var(--Grayscale-0, #fff);
  box-shadow:
    0 2px 12px rgba(0, 0, 0, 0.1),
    0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex-direction: column;
  color: var(--Grayscale-60, #717171);
  font:
    400 11px Pretendard,
    sans-serif;
  letter-spacing: -0.03px;
`;

const NavigationTabs = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 0 8px;
  justify-content: space-evenly;
  max-width: 400px;

  @media ${(props) => props.theme.media.mobile} {
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    padding: 0 16px;
  }
`;

const NavItemWrapper = styled.button<{ isActive?: boolean; isEnglish?: boolean }>`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 23%;
  padding: ${(props) => (props.isEnglish ? '0' : '6px 0')};
  background: none;
  border: none;
  cursor: pointer;
  color: ${(props) =>
    props.isActive ? 'var(--Grayscale-70, #555)' : 'var(--Grayscale-60, #717171)'};
  font-weight: ${(props) => (props.isActive ? '600' : '400')};

  &:focus-visible {
    outline: 2px solid var(--Grayscale-70, #555);
    border-radius: 4px;
  }
`;

const NavContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NavIcon = styled.img`
  width: 28px;
  height: 28px;
  object-fit: contain;
`;

const NavLabel = styled.span`
  margin-top: 4px;
`;

const HomeIndicator = styled.div`
  width: 134px;
  height: 5px;
  margin: 18px auto 0;
  background: var(--Grayscale-100, #000);
  border-radius: 100px;
`;

export default Navigation;
