import React from 'react';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton, Popover } from '@mui/material';
import { CSSProperties, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Row, useModelStateContext } from 'saladsoft-primitive';
import Subtitle from './Subtitle';

export const CountButtonSmall = ({
  count,
  maxCount,
  useZero,
  style,
  className,
  onChange,
}: {
  count?: number;
  maxCount?: number;
  useZero?: boolean;
  style?: CSSProperties;
  className?: string;
  onChange: (guest: number) => void;
}) => {
  const { t } = useTranslation();
  const { broadcast } = useModelStateContext();

  const [guestCount, setGuestCount] = useState<number>(count || 2);
  const [maxCount2, setMaxCount] = useState<number>(maxCount || 2);

  const handleDecrease = () => {
    setGuestCount((prev) => {
      if (prev > (useZero ? 0 : 1)) {
        onChange?.(prev - 1);
        return prev - 1;
      } else {
        return prev;
      }
    });
  };

  const handleIncrease = () => {
    setGuestCount((prev) => {
      if (prev < maxCount2) {
        onChange?.(prev + 1);
        broadcast({ id: 'ACTION' });
        return prev + 1;
      } else {
        toast.success(t('The maximum quantity has been exceeded.'));
        return prev;
      }
    });
  };

  useEffect(() => {
    if (count) setGuestCount(count);
  }, [count]);

  useEffect(() => {
    if (maxCount) setMaxCount(maxCount);
  }, [maxCount]);

  return (
    <>
      <DateInnerContainer style={style} className={className}>
        <Row gap={30}>
          <Row width={'100%'} justifyContent="space-between" gap={8}>
            <IconButton
              onClick={handleDecrease}
              disabled={guestCount <= (useZero ? 0 : 1)}
              style={{
                border: '1px solid var(--Grayscale-20, #e4e4e4)',
                width: '24px',
                height: '24px',
                padding: '0 10px',
                backgroundColor:
                  guestCount <= (useZero ? 0 : 1)
                    ? 'var(--Grayscale-10, #f0f0f0)'
                    : 'var(--Grayscale-0, #fff)',
              }}
            >
              <RemoveIcon />
            </IconButton>
            <div style={{ minWidth: '40px', textAlign: 'center' }}>
              <Subtitle type="E" style={{ padding: '0 10px' }}>
                {guestCount}
              </Subtitle>
            </div>

            <IconButton
              onClick={handleIncrease}
              style={{
                border: '1px solid var(--Grayscale-20, #e4e4e4)',
                backgroundColor:
                  guestCount >= maxCount2
                    ? 'var(--Grayscale-10, #f0f0f0)'
                    : 'var(--Grayscale-0, #fff)',
                width: '24px',
                height: '24px',
                padding: '0 10px',
              }}
            >
              <AddIcon />
            </IconButton>
          </Row>
        </Row>
      </DateInnerContainer>
    </>
  );
};

const DateInnerContainer = styled.div`
  background: #fff;
`;
