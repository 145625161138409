import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Row } from 'saladsoft-primitive';
import CountryCode from 'components/commons/CountryCode';
import useSetState from 'hooks/useSetState';
import { ReservationDto, UserInfoDto } from 'services/data-contracts';
import i18n from 'i18n';
import { FormControlLabel, Checkbox } from '@mui/material';
import { LanguageSelector } from './LanguageSelector';
import { FormField } from './FormField';

interface ContactFormProps {
  userInfo?: UserInfoDto;
  onChange: (data?: ReservationDto) => void;
}

export function ContactForm({ userInfo, onChange }: ContactFormProps) {
  const { t } = useTranslation();

  const [state, setState] = useSetState<ReservationDto>({
    guestCountryCode: 'ko',
  } as ReservationDto);
  const [phoneError, setPhoneError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [firstNameError, setFirstNameError] = useState<string>('');
  const [lastNameError, setLastNameError] = useState<string>('');

  // 실시간 유효성 상태용 state
  const [isPhoneValid, setIsPhoneValid] = useState<boolean | null>(null);
  const [isEmailValid, setIsEmailValid] = useState<boolean | null>(null);
  const [isFirstNameValid, setIsFirstNameValid] = useState<boolean | null>(null);
  const [isLastNameValid, setIsLastNameValid] = useState<boolean | null>(null);

  // 이름 유효성 검사를 위한 정규식
  const nameRegex = /^[a-zA-Z\u00C0-\u024F\u1100-\u11FF\u3130-\u318F\uAC00-\uD7AF]+$/;

  const [countryCodeError, setCountryCodeError] = useState<string>('');
  const [isCountryCodeValid, setIsCountryCodeValid] = useState<boolean | null>(null);

  // 체크박스 상태 관리
  const [termChecked, setTermChecked] = useState<boolean>(false);
  const [termError, setTermError] = useState<string>('');

  useEffect(() => {
    onChange(state);
  }, [state]);

  useEffect(() => {
    setState('guestCountryCode', 'ko');
  }, []);

  // 폼 제출 시 전체 유효성 검사
  const validateForm = () => {
    let isValid = true;

    // 국가 코드 검사
    if (!state?.guestCountryCode) {
      setCountryCodeError(t('select_country_code')); // 국가코드를 선택해주세요.
      setIsCountryCodeValid(false);
      isValid = false;
    }

    // 체크박스 검사
    if (!termChecked) {
      setTermError(t('required_field'));
      isValid = false;
    } else {
      setTermError('');
    }

    // ... 다른 필드들의 유효성 검사
    // 이름 검사
    if (!state?.guestFirstName) {
      setFirstNameError(t('guest_info_required')); // 필수 입력 항목입니다
      setIsFirstNameValid(false);
      isValid = false;
    }

    if (!state?.guestLastName) {
      setLastNameError(t('guest_info_required')); // 필수 입력 항목입니다
      isValid = false;
    }

    // 이메일 검사
    if (!state?.guestEmail) {
      setEmailError(t('enter_email')); // 이메일을 입력해주세요
      setIsEmailValid(false);
      isValid = false;
    }

    // 전화번호 검사
    if (!state?.guestPhoneNumber) {
      setPhoneError(t('guest_info_required')); // 필수 입력 항목입니다
      setIsPhoneValid(false);
      isValid = false;
    }

    return isValid;
  };

  // 이름과 성 필드 렌더링 함수
  const renderNameFields = () => {
    const firstNameField = (
      <FormField
        label={t('first_name')}
        required
        id="firstName"
        type="text"
        defaultValue={userInfo?.profile?.firstName}
        error={firstNameError}
        isValid={isFirstNameValid}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          setState('guestFirstName', value);
          setIsFirstNameValid(nameRegex.test(value));
        }}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          if (!nameRegex.test(value)) {
            setFirstNameError(t('no_special_chars_spaces')); // 특수문자와 공백을 포함할 수 없습니다
          } else {
            setFirstNameError('');
          }
        }}
      />
    );

    const lastNameField = (
      <FormField
        label={t('last_name')}
        required
        id="lastName"
        type="text"
        error={lastNameError}
        defaultValue={userInfo?.profile?.lastName}
        isValid={isLastNameValid}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          setState('guestLastName', value);
          setIsLastNameValid(nameRegex.test(value));
        }}
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value } = e.target;
          if (!nameRegex.test(value)) {
            setLastNameError(t('no_special_chars_spaces')); // 특수문자와 공백을 포함할 수 없습니다
          } else {
            setLastNameError('');
          }
        }}
      />
    );

    // 영어권은 이름-성 순서, 그 외 언어는 성-이름 순서
    return i18n.language === 'en' ? (
      <>
        {firstNameField}
        {lastNameField}
      </>
    ) : (
      <>
        {lastNameField}
        {firstNameField}
      </>
    );
  };

  return (
    <form
      role="form"
      aria-label="Contact Information Form"
      onSubmit={(e) => {
        e.preventDefault();
        if (validateForm()) {
          // 폼 제출 로직
        }
      }}
    >
      <FormContainer>
        <div
          style={{
            fontSize: 14,
            fontWeight: 500,
            letterSpacing: -0.04,
            color: '#1D1D1D',
            marginBottom: 12,
            marginTop: 32,
          }}
        >
          {t('contact')} <span style={{ color: '#F35064' }}>*</span>
        </div>
        <Row gap={5} alignItems="flex-start">
          <CountryCode
            defaultValue={userInfo?.profile?.countryCode}
            onChange={(value: string) => {
              setState('guestCountryCode', value);
              if (value) {
                setCountryCodeError('');
                setIsCountryCodeValid(true);
              }
            }}
            onBlur={() => {
              if (!state?.guestCountryCode) {
                setCountryCodeError(t('select_country_code')); // 국가코드를 선택해주세요
                setIsCountryCodeValid(false);
              }
            }}
            error={countryCodeError}
            isValid={isCountryCodeValid}
          />
          <FormField
            id="contact"
            type="tel"
            placeholder={t('enter_numbers_only')} // 숫자만 입력해주세요
            error={phoneError}
            isValid={isPhoneValid}
            defaultValue={userInfo?.profile?.phoneNumber?.replace(/[^0-9]/g, '')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target;
              setState('guestPhoneNumber', value);
              setIsPhoneValid(value.length >= 8 && value.length <= 11 && /^\d*$/.test(value));
            }}
            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target;

              if (!/^\d*$/.test(value)) {
                setPhoneError(t('numbers_only')); // 숫자만 입력해주세요
              } else if (value.length < 8 || value.length > 11) {
                setPhoneError(t('phone_length_error')); // 전화번호는 8-11자리여야 합니다
              } else {
                setPhoneError('');
              }

              if (!state?.guestCountryCode) {
                setCountryCodeError(t('select_country_code')); // 국가코드를 선택해주세요
                setIsCountryCodeValid(false);
              }
            }}
          />
        </Row>
        <FormField
          label={t('email')}
          id="email"
          type="email"
          required
          error={emailError}
          isValid={isEmailValid}
          defaultValue={userInfo?.username}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            setState('guestEmail', value);
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            setIsEmailValid(emailRegex.test(value));
          }}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = e.target;
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            if (!value) {
              setEmailError(t('enter_email')); // 이메일을 입력해주세요
            } else if (!emailRegex.test(value)) {
              setEmailError(t('invalid_email_format')); // 올바른 이메일 형식이 아닙니다
            } else {
              setEmailError('');
            }
          }}
        />
        <Row gap={5} alignItems="flex-start">
          {renderNameFields()}
        </Row>
        <LanguageSelector
          onChange={(value: string) => {
            setState('languageCode', value);
          }}
        />
      </FormContainer>
    </form>
  );
}

const FormContainer = styled.div`
  display: flex;
  max-width: 450px;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  justify-content: start;
  @media ${(props) => props.theme.media.mobile} {
    max-width: 100%;
  }
`;

const CheckboxInput = styled.input`
  display: flex;
  width: 16px;
  height: 16px;
  padding: 2px;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: var(--4-e-433-c, #4e433c);
  cursor: pointer;
`;

const TermWrapper = styled.div`
  margin-bottom: 6px;
`;

const TermDescription = styled.p`
  color: var(--Grayscale-50, #8e8e8e);
  font-family: Pretendard, sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.03px;
  margin: 3px 14px;
`;

const ErrorMessage = styled.div`
  color: #f35064;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 8px;
`;
