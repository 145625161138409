import React, { ReactNode } from 'react';

const UserLayout = ({ children }: { children: ReactNode }) => {
  const routes = [
    { route: '/', name: 'Home' },
    { route: '/overview', name: 'Overview', dropdown: false },
    { route: '/history', name: 'History', dropdown: false },
    { route: '/log', name: 'Log event' },
    { route: '/alarm', name: 'Alarm' },
    { route: '/report', name: 'Report' },
  ];

  return <>{children}</>;
};

export default UserLayout;
