import React from 'react';
import styled from 'styled-components';
import { UserStatsItemProps } from './types';

// types.ts와 중복 코드 주석 처리 (확인 필요)
// interface UserStatsItemProps {
//   icon: string;
//   label: string;
//   value?: string;
//   highlight?: boolean;
//   onClick?: () => void;
// }

export const UserStatsItem = ({ icon, label, value, highlight, onClick }: UserStatsItemProps) => {
  return (
    <StatsItemContainer onClick={onClick}>
      <StatsIcon src={icon} alt="" loading="lazy" />
      <StatsLabel>
        {label}
        {value && <StatsValue highlight={highlight}>{value}</StatsValue>}
      </StatsLabel>
    </StatsItemContainer>
  );
};

const StatsItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--Grayscale-20, #e4e4e4);
  background: var(--Grayscale-0, #fff);
  flex: 1;
  min-height: 81px;
  cursor: pointer;
`;

const StatsIcon = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`;

const StatsLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  margin-top: 4px;
  font-size: 13px;
  text-align: center;
  letter-spacing: -0.03px;
  color: var(--Grayscale-90, #1d1d1d);
`;

const StatsValue = styled.span<{ highlight?: boolean }>`
  font-weight: 600;
  color: ${(props) => (props.highlight ? 'var(--sub-color, var(--Color, #1c51b6))' : 'inherit')};
`;
