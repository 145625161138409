import React, { useEffect, useState, useRef } from 'react';
import { Route, Routes, useLocation, useNavigationType, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react';
import { AnimatePresence, motion } from 'framer-motion';
import mainRoutes from 'routes/mainRoutes';
import AuthContainer from 'containers/AuthContainer';
import UserLayout from 'layouts/UserLayout';
import AdminLayout from 'layouts/AdminLayout';
import { Footer } from 'components/commons/pc/footer/Footer';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Navigation from 'components/commons/mobile/Navigation';

import styled from 'styled-components';
import { ScrollUpButton, useMediaContext } from 'saladsoft-primitive';
import { getRouteConfig } from 'libs/helper/utils';


const LayoutWrapper = observer(() => {
  const navigationType = useNavigationType();
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useMediaContext();


  const [page, setPage] = useState<'book' | 'undefined'>('undefined');
  const [isSwipeNavigation, setIsSwipeNavigation] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isPageTransitioning, setIsPageTransitioning] = useState(false);
  const [isExiting, setIsExiting] = useState(false);

  // 스크롤 위치 관리를 위한 ref
  const scrollPositions = useRef<Record<string, number>>({});
  const isAnimating = useRef(false);

  const getLayout = (element: JSX.Element) => {
    if (window.location.pathname.includes('/admin')) {
      return <AdminLayout>{element}</AdminLayout>;
    } else {
      return <UserLayout>{element}</UserLayout>;
    }
  };

  const overlayRef = useRef<HTMLDivElement | null>(null);
  const pageContainerRef = useRef<HTMLDivElement | null>(null);
  const isTransitioningRef = useRef(false);

  // 페이지 변경 감지 및 오버레이 처리
  useEffect(() => {
    // 이미 트랜지션 중이면 중복 실행 방지
    if (isTransitioningRef.current) return;
    isTransitioningRef.current = true;

    // 기존 오버레이 제거
    if (overlayRef.current && overlayRef.current.parentNode) {
      document.body.removeChild(overlayRef.current);
      overlayRef.current = null;
    }

    // 새 오버레이 생성 및 즉시 표시
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = '#fff';
    overlay.style.zIndex = '9999';
    overlay.style.pointerEvents = 'none'; // 클릭 이벤트 통과

    document.body.appendChild(overlay);
    overlayRef.current = overlay;

    // 페이지 컨테이너 찾기
    const pageContainer = document.querySelector('.page-container') as HTMLDivElement;
    if (pageContainer) {
      pageContainerRef.current = pageContainer;

      // 페이지 컨테이너 초기 상태 설정 (투명)
      pageContainer.style.transition = 'opacity 0.4s ease';
      pageContainer.style.opacity = '0';
    }

    // 페이지 로드 후 오버레이 페이드 아웃 및 페이지 페이드 인
    setTimeout(() => {
      // 오버레이 페이드 아웃
      if (overlayRef.current) {
        overlayRef.current.style.transition = 'opacity 0.4s ease';
        overlayRef.current.style.opacity = '0';
      }

      // 페이지 컨테이너 페이드 인
      if (pageContainerRef.current) {
        pageContainerRef.current.style.opacity = '1';
      }

      // 트랜지션 완료 후 오버레이 제거
      setTimeout(() => {
        if (overlayRef.current && overlayRef.current.parentNode) {
          document.body.removeChild(overlayRef.current);
          overlayRef.current = null;
        }
        isTransitioningRef.current = false;
      }, 400);
    }, 50);

    // 컴포넌트 언마운트 시 오버레이 정리
    return () => {
      if (overlayRef.current && overlayRef.current.parentNode) {
        document.body.removeChild(overlayRef.current);
        overlayRef.current = null;
      }
    };
  }, [location.pathname]);

  // 페이지 전환 애니메이션 설정 (제거)
  // 직접 DOM 조작으로 애니메이션을 처리하므로 framer-motion 애니메이션은 제거

  useEffect(() => {
    if (location.pathname.includes('/book')) {
      setPage('book');
    } else {
      setPage('undefined');
    }
  }, [location.pathname]);

  useEffect(() => {
    const isMobileDevice = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    setIsSwipeNavigation(navigationType === 'POP' && isMobileDevice);

    // 페이지 이동 전 현재 스크롤 위치 저장
    return () => {
      if (!isAnimating.current) {
        scrollPositions.current[location.pathname] = window.scrollY;
      }
    };
  }, [navigationType, location]);

  // 페이지 로드 시 한 번만 실행
  useEffect(() => {
    // 세션 스토리지에서 저장된 스크롤 위치 복원
    try {
      const savedPositions = sessionStorage.getItem('scrollPositions');
      if (savedPositions) {
        scrollPositions.current = JSON.parse(savedPositions);
      }
    } catch (e) {
      console.error('Failed to load scroll positions', e);
    }

    // 페이지 언로드 시 스크롤 위치 저장
    const saveScrollPositions = () => {
      sessionStorage.setItem('scrollPositions', JSON.stringify(scrollPositions.current));
    };

    window.addEventListener('beforeunload', saveScrollPositions);
    return () => {
      window.removeEventListener('beforeunload', saveScrollPositions);
      saveScrollPositions();
    };
  }, []);

  // 이전 useEffect 대신 이 코드로 교체
  const prevLocationRef = useRef(location.pathname);
  const isInitialRender = useRef(true);

  // useEffect(() => {
  //   // 초기 렌더링 시에는 스킵
  //   if (isInitialRender.current) {
  //     isInitialRender.current = false;
  //     prevLocationRef.current = location.pathname;
  //     return;
  //   }

  //   // 실제 경로 변경이 있을 때만 처리
  //   if (prevLocationRef.current !== location.pathname) {

  //     // 뒤로가기 감지 (브라우저 히스토리 API 활용)
  //     if (navigationType === 'POP') {
  //       console.log('Back button was pressed');
  //       const savedPosition = scrollPositions.current[location.pathname];
  //       if (savedPosition !== undefined) {
  //         setTimeout(() => {
  //           window.scrollTo(0, savedPosition);
  //         }, 50);
  //       }
  //     } else {
  //       console.log('Forward navigation');
  //       // 필요한 로직 추가
  //     }

  //     // 현재 경로 저장
  //     prevLocationRef.current = location.pathname;
  //   }
  // }, [location.pathname, navigationType]);


  useEffect(() => {
    return () => {
      if (!location.pathname.includes('/listing')) {
        sessionStorage.removeItem('scroll_page');
      } else {
        sessionStorage.setItem('leavePath', location.pathname);
        return;
      }
      const lastLeavePath = sessionStorage.getItem('leavePath');
      if (lastLeavePath !== null) {
        sessionStorage.setItem('leavePath', location.pathname);
      }
    };
  }, [location.pathname]);

  // 애니메이션 이벤트 핸들러
  const handleAnimationStart = () => {
    setIsTransitioning(true);
  };

  const handleAnimationComplete = () => {
    setIsTransitioning(false);
  };

  // 페이지 변경 감지 및 오버레이 애니메이션 처리
  useEffect(() => {
    // 페이지 변경 시 상태 업데이트
    setIsExiting(true);
    setIsPageTransitioning(true);

    // 페이지 나갈 때 빠르게 오버레이 표시 (50ms)
    const exitTimer = setTimeout(() => {
      setIsExiting(false); // 나가는 애니메이션 완료

      // 페이지 들어올 때 페이드인 (400ms)
      const enterTimer = setTimeout(() => {
        setIsPageTransitioning(false); // 트랜지션 완료
      }, 400);

      return () => clearTimeout(enterTimer);
    }, 50);

    return () => clearTimeout(exitTimer);
  }, [location.pathname]);

  return (
    <div style={{ height: '100%', background: '#fff', position: 'relative' }}>
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          {mainRoutes.map((route, index: number) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={
                  <div className="page-container">
                    <AuthContainer isPublic={route.isPublic} title={route.name}>
                      {getLayout(<route.element />)}
                    </AuthContainer>
                  </div>
                }
              />
            );
          })}
        </Routes>
      </AnimatePresence>
      <ScrollUpButtonWapper className="scroll-up-button" bottom={getRouteConfig(mainRoutes).showTab ? 110 : 30}>
        <ArrowUpwardIcon />
      </ScrollUpButtonWapper>
      <ChannelTalkButton className="ch-launcher-button" bottom={!getRouteConfig(mainRoutes).showTab ? 10 : 90}>
        <img src={'/images/channelTalk_logo.webp'} alt="channelTalk" />
      </ChannelTalkButton>
      {isMobile && getRouteConfig(mainRoutes).showTab && (
        <NavagationWrapper>
          <Navigation />
        </NavagationWrapper>
      )}
      <Footer />
    </div>
  );
});

const ChannelTalkButton = styled.div<{ bottom?: number }>`
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 2;
  border-radius: 20px;
  overflow: hidden;
  cursor: pointer;
  width: 50px;
  height: 50px;
  img {
    width: 100%;
    height: 100%;
  }
  @media ${(props) => props.theme.media.mobile} {
    bottom: ${(props) => props.bottom}px;
  }
`;

const ScrollUpButtonWapper = styled(ScrollUpButton) <{ bottom?: number }>`
  position: fixed;
  right: 0px;
  left: unset;
  width: 40px;
  height: 40px;
  margin-bottom: 25px;
  left: unset !important;
  z-index: 1 !important;
  > button {
    width: 50px !important;
    height: 50px !important;
    border-radius: 20px !important;
    z-index: 1 !important;
  }
  @media ${(props) => props.theme.media.mobile} {
    margin-bottom: ${(props) => props.bottom}px;
  }
`;


const NavagationWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background: #fff;
  z-index: 2;
`;

// 트랜지션 오버레이 컴포넌트
const TransitionOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 9999;
`;

export default LayoutWrapper;
