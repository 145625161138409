import * as React from 'react';
import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import { CountButtonSmall } from 'components/commons/CountButtonSmall';
import { Row } from 'saladsoft-primitive';
import { OptionModel } from 'types/CommonTypes';
import useSetState from 'hooks/useSetState';
import { useTranslation } from 'react-i18next';

const OptionCard = ({
  option,
  onChange,
  onQuantityChange,
  isQuantityDisabled,
}: {
  option: OptionModel;
  onChange: (model: OptionModel) => void;
  onQuantityChange?: (optionId: number, qty: number) => void;
  isQuantityDisabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [state, setState] = useSetState<OptionModel>();

  const handleSelection = React.useCallback(() => {
    const newChecked = !state?.checked;
    setState({
      ...state,
      checked: newChecked,
      qty: newChecked ? 1 : 0,
    });
  }, [state?.checked]);

  React.useEffect(() => {
    if (option) {
      setState(option);
    }
  }, [option]);

  React.useEffect(() => {
    if (state) {
      onChange(state);
    }
  }, [state]);

  return (
    <CardContainer>
      <WineImage loading="lazy" src={option?.images?.[0]?.url} alt={option?.name} />
      <ContentWrapper>
        <ContentSection>
          <ProductInfo>
            <ProductDetails>
              <HeaderSection>
                <ProductTitle>{option?.name}</ProductTitle>
                <PriceContainer>
                  <PriceWrapper>
                    <Price>{option?.price?.toLocaleString()}</Price>
                    <Currency>{t('won')}</Currency>
                  </PriceWrapper>
                </PriceContainer>
              </HeaderSection>
              <Divider
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/90d196bd1523e9f7d20e0173a0b1095eeaa4460afc2bbd9aa4fd3171b314ae1f?apiKey=3721b445ce6f47eaaa05786c3675d5db&"
                alt="divider"
              />
              <DescriptionSection>
                <Description dangerouslySetInnerHTML={{ __html: option?.desc ?? '' }} />
              </DescriptionSection>
            </ProductDetails>
            <SelectionSection>
              <Row alignItems="center" gap={24} width="100%">
                <SelectButton type="button" onClick={handleSelection}>
                  <Checkbox
                    size="small"
                    checked={Boolean(state?.checked)}
                    onChange={handleSelection}
                    checkedIcon={
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          background: '#4E433C',
                          borderRadius: '2px',
                        }}
                      >
                        <path
                          d="M4.80078 7.99883L7.70604 10.79883L11.60078 4.79883"
                          stroke="white"
                          strokeWidth="1.2"
                          strokeLinecap="round"
                        />
                      </svg>
                    }
                    sx={{
                      padding: 0,
                      color: '#4e433c',
                      '& .MuiSvgIcon-root': {
                        width: '16px',
                        height: '16px',
                        border: '1px solid #8e8e8e',
                        borderRadius: '2px',
                        background: '#fff',
                        '& path': {
                          display: 'none',
                        },
                      },
                    }}
                  />
                  <SelectText>{t('Select')}</SelectText>
                </SelectButton>
                <>
                  {option.useQty && state?.checked && (
                    <CountButtonSmall
                      count={state?.qty}
                      maxCount={10}
                      onChange={(qty) => {
                        setState('qty', qty);
                      }}
                    />
                  )}
                </>
              </Row>
            </SelectionSection>
          </ProductInfo>
        </ContentSection>
      </ContentWrapper>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Grayscale-20, #e4e4e4);
  background: var(--Grayscale-0, #fff);
  display: flex;
  max-width: 792px;

  width: 100%;

  @media ${(props) => props.theme.media.mobile} {
    flex-direction: column;
  }
`;

const WineImage = styled.img`
  aspect-ratio: 1.32;
  object-fit: cover;
  object-position: center;
  width: 343px;
  flex: 0 0 45%;
  border-radius: 12px 0 0 12px;

  @media ${(props) => props.theme.media.mobile} {
    width: 100%;

    border-radius: 12px 12px 0 0;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  flex-basis: 0%;
  padding: 24px 0 16px;
`;

const ContentSection = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  justify-content: start;
  flex: 1;
  height: 100%;
  padding: 0 20px;
`;

const ProductInfo = styled.div`
  display: flex;
  min-width: 240px;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  flex-basis: 0%;
`;

const ProductDetails = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  justify-content: start;
`;

const HeaderSection = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  color: var(--Grayscale-90, #1d1d1d);
  justify-content: start;
  gap: 5px;
`;

const ProductTitle = styled.h2`
  margin: 0;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: -0.04px;
`;

const PriceContainer = styled.div`
  display: flex;
  min-height: 13px;
  width: 100%;
  align-items: center;
  font-weight: 600;
  white-space: nowrap;
  justify-content: start;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const PriceWrapper = styled.div`
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: start;
  margin: auto 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Price = styled.span`
  font-size: 19px;
  letter-spacing: -0.05px;
  align-self: stretch;
  margin: auto 0;
`;

const Currency = styled.span`
  font-size: 13px;
  letter-spacing: -0.03px;
  align-self: stretch;
  margin: auto 0;
`;

const Divider = styled.img`
  object-fit: contain;
  object-position: center;
  width: 100%;
  stroke-width: 1px;
  stroke: var(--Grayscale-10, #f0f0f0);
  margin-top: 20px;

  align-self: stretch;
  stroke-width: 1px;
  stroke: var(--Grayscale-10, #f0f0f0);
`;

const DescriptionSection = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  flex-direction: column;
  font-size: 13px;
  color: var(--Grayscale-70, #555);
  font-weight: 400;
  letter-spacing: -0.03px;
  justify-content: start;
`;

const Description = styled.p`
  margin: 0;
  color: var(--Grayscale-70, #555);
  font-family: Pretendard;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.032px;

  > ul {
    margin: 0;
    padding-left: 16px;
    list-style: disc;
  }
`;

const DetailText = styled.p`
  margin: 16px 0 0;
  line-height: 20px;
`;

const SelectionSection = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
  align-items: center;
  gap: 24px;
  justify-content: start;
`;

const SelectButton = styled.button`
  align-items: center;
  border-radius: 8px;
  background: var(--bg_color, #f7f3ef);
  align-self: stretch;
  display: flex;
  min-width: 240px;
  min-height: 40px;
  width: 100%;
  gap: 8px;
  overflow: hidden;
  justify-content: start;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
  padding: 12px;
  border: none;
  cursor: pointer;
  @media ${(props) => props.theme.media.mobile} {
  min-width: unset;

    /* background: var(--Grayscale-5, #f8f8f8); */
  }
`;

const SelectText = styled.span`
  align-self: stretch;
  min-height: 11px;
  color: var(--Grayscale-70, #555);
  white-space: nowrap;
  letter-spacing: -0.03px;
  margin: auto 0;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export default OptionCard;
