import React from 'react';
import styled from 'styled-components';
import { useMediaContext } from 'saladsoft-primitive';
import MyInfoTabName from 'components/commons/mobile/MyInfoTabName';
import { useTranslation } from 'react-i18next';
import { EmptyState } from 'components/commons/EmptyState';

const Guide = () => {
  const { isMobile } = useMediaContext();
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <PageContent>
        <EmptyState message={t('noContent')} />
      </PageContent>
    </PageWrapper>
  );
};

export default Guide;

const PageWrapper = styled.main`
  background-color: var(--Grayscale-0, #fff);
  display: flex;
  max-width: 825px;
  flex-direction: column;
  width: 100%;
`;

const PageTitle = styled.h1`
  color: var(--Grayscale-90, #1d1d1d);
  min-height: 48px;
  letter-spacing: -0.07px;
  padding: 14px 0;
  font:
    600 28px Pretendard,
    sans-serif;
`;

const PageContent = styled.div`
  display: flex;
  margin-top: calc(40px - 14px);
  width: 100%;
  flex-direction: column;
`;
