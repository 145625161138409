"use client";
import AnimateOnScroll from "components/commons/AnimateOnScroll";
import * as React from "react";
import { Row, useMediaContext } from "saladsoft-primitive";
import styled from "styled-components";

const Section04 = () => {
  const { isMobile } = useMediaContext();
  return (
    <div style={{ padding: isMobile ? '0px 20px' : '0px 0px' }}>
      <AnimateOnScroll direction="up" delay={0.2} style={{ position: 'relative' }}>
        <UnionWrapper>
          <img src="/images/union2.png" alt="union" />
        </UnionWrapper>
        <Row justifyContent="flex-start" gap={50} paddingLeft={isMobile ? '0px' : '60px'} alignItems="flex-start" paddingTop="100px" position="relative" zIndex={1} flexWrap="wrap">
          <>
            {!isMobile && (
              <img src="/images/section_05.png" alt="section_05" style={{ width: '100%' }} />
            )}
          </>
          <StayContainer>
            <ContentWrapper>
              <PointWrapper>
                <PointNumber>Point 02</PointNumber>
                <PointLine
                  src="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/108811236426305ded130e5aa71d8b9a3f2e9889e2fb0fddb22aa1f8a04e7745?placeholderIfAbsent=true"
                  alt=""
                />
              </PointWrapper>
              <StayTitle>한국전통문화를 알립니다</StayTitle>
            </ContentWrapper>
            <StayDescription>
              차별화된 서비스로 최고의 경험을 제공하며
              <br />
              내, 외국인 투숙객에게 한국 전통문화를 알립니다.
            </StayDescription>
          </StayContainer>
        </Row>

      </AnimateOnScroll>
      <AnimateOnScroll direction="up" delay={0.2} style={{ position: 'relative' }}>
        <UnionWrapper2>
          <img src="/images/union3.png" alt="union" />
        </UnionWrapper2>
        <div style={{ textAlign: 'right', marginTop: isMobile ? 40 : 100, position: 'relative', zIndex: 1 }}>
          {isMobile && (
            <img src="/images/section_05.png" alt="section_05" style={{ width: '100%', marginBottom: 10 }} />
          )}
          <img src="/images/section_06.png" alt="section_06" style={{ width: '100%' }} />
        </div>
      </AnimateOnScroll>
    </div>
  );
};

const StayContainer = styled.div`
  max-width: 449px;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const PointWrapper = styled.div`
  display: flex;
  width: 98px;
  flex-direction: column;
  align-items: stretch;
  font-family:
    Montserrat,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-size: 24px;
  color: #92897b;
  font-weight: 600;
  letter-spacing: -0.06px;
  justify-content: start;
`;

const PointNumber = styled.div`
  leading-trim: both;
  text-edge: cap;
  align-self: center;
`;

const PointLine = styled.img`
  aspect-ratio: 100;
  object-fit: contain;
  object-position: center;
  width: 100%;
  stroke-width: 1px;
  stroke: #92897b;
  margin-top: 10px;
`;

const StayTitle = styled.div`
  color: #1d1d1d;
  leading-trim: both;
  text-edge: cap;
  font-family:
    Pretendard,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: -0.09px;
  margin-top: 32px;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 24px;
  }
`;

const StayDescription = styled.div`
  color: #555;
  leading-trim: both;
  text-edge: cap;
  font-family:
    Pretendard,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.05px;
  margin-top: 46px;
  @media ${(props) => props.theme.media.mobile} {
    font-size: 16px;
  }
`;

const UnionWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  bottom: -200px;
  left: -150px;
`;

const UnionWrapper2 = styled.div`
    position: absolute;
  width: 100%;
  height: 300px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  bottom: -120px;
  right: -150px;
`;

export default Section04;
