import React, { useState } from 'react';
import styled from 'styled-components';
import useLocaleNavigate from 'hooks/useLocaleNavigate';

// interface NoticeListItemProps {
//   title: string;
//   date: string;
//   isNew?: boolean;
// }

// 현재 공지사항 내용은 임시 데이터로 표시되어 있음

export const NoticeList = () => {
  return (
    <>
      <NoticeContainer>
        <NoticeItem />
      </NoticeContainer>
    </>
  );
};

const NoticeItem = () => {
  const navigate = useLocaleNavigate();

  const handleNoticeListClick = (noticeId: number) => {
    navigate(`/my-info/notice/${noticeId}`);
  };

  return (
    <ItemContainer onClick={() => handleNoticeListClick(1)}>
      <ItemDetails>
        <ItemText>버틀러리 환불 규정 변경</ItemText>
        <DateWrapper>
          <ItemDate>2025.04.01</ItemDate>
          <img src="/images/new-icon.png" alt="new" />
        </DateWrapper>
      </ItemDetails>
      <ItemButton>
        <img src="/images/more-button.png" alt="더보기 버튼" />
      </ItemButton>
    </ItemContainer>
  );
};

const NoticeContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (${(props) => props.theme.media.mobile}) {
    padding: 0;
  }
`;

// NoticeItem
const ItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #f0f0f0;
  width: 100%;
  padding: 20px 0;
  gap: 16px;
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  flex: 1;
  cursor: pointer;
`;

const ItemText = styled.p`
  color: var(--Grayscale-90, #1d1d1d);
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.042px;

  @media (${(props) => props.theme.media.mobile}) {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: -0.037px;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  & img {
    width: 12px;
    height: 12px;
  }
`;

const ItemDate = styled.p`
  color: var(--Grayscale-50, #8e8e8e);
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.035px;

  @media (${(props) => props.theme.media.mobile}) {
    letter-spacing: -0.03px;
    font-size: 12px;
  }
`;

const ItemButton = styled.button`
  background-color: transparent;
  border: none;

  cursor: pointer;
  & img {
    width: 20px;
    height: 20px;
  }
`;
