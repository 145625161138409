import { makeAutoObservable, toJS } from 'mobx';
import { ListingDto } from 'services/data-contracts';
import { BookInfo } from 'types/CommonTypes';

class CalendarStore {
  calendarShow?: boolean = false;
  mobileCalendarShow?: boolean = false;
  bookInfo?: BookInfo;
  currentListing?: ListingDto;
  actionType?: 'search' | 'select';
  isListingPicker?: boolean = false;
  useImmediateSearch?: boolean = false;
  constructor() {
    makeAutoObservable(this);
  }

  setCalendarShow(show: boolean) {
    this.calendarShow = show;
  }

  setMobileCalendarShow(show: boolean, isListingPicker?: boolean, useImmediateSearch?: boolean) {
    this.mobileCalendarShow = show;
    if (show) {
      this.isListingPicker = isListingPicker;
      this.useImmediateSearch = useImmediateSearch;
    } else {
      this.isListingPicker = false;
      this.useImmediateSearch = false;
    }
  }

  setCurrentListing(listing: ListingDto) {
    this.currentListing = listing;
  }

  setBookInfo(bookInfo: BookInfo, actionType?: 'search' | 'select') {
    this.bookInfo = bookInfo;
    this.actionType = actionType;
  }

  unMount() {
    this.bookInfo = undefined;
    this.currentListing = undefined;
    this.actionType = undefined;
    this.isListingPicker = false;
    this.useImmediateSearch = false;
  }
}

export default CalendarStore;
