/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  ListingDto,
  PointEventDto,
  UserCouponDto,
  UserInfoDto,
  UserProfileDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class MyAccountService<SecurityDataType = unknown> {
  http: HttpClient<SecurityDataType>;

  constructor(http: HttpClient<SecurityDataType>) {
    this.http = http;
  }

  /**
   * No description
   *
   * @tags MyAccountService
   * @name GetMyProfile
   * @summary Get My Profile
   * @request GET:/v1/me/profile
   * @secure
   */
  getMyProfile = (params: RequestParams = {}) =>
    this.http.request<UserProfileDto, any>({
      path: `/v1/me/profile`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyAccountService
   * @name SaveMyProfile
   * @summary Save or Update My Profile
   * @request POST:/v1/me/profile
   * @secure
   */
  saveMyProfile = (data: UserProfileDto, params: RequestParams = {}) =>
    this.http.request<UserProfileDto, any>({
      path: `/v1/me/profile`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyAccountService
   * @name AddFavoriteListing
   * @summary Add Favorite Listing
   * @request POST:/v1/me/favorite-listings/{listingId}
   * @secure
   */
  addFavoriteListing = (listingId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v1/me/favorite-listings/${listingId}`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags MyAccountService
   * @name RemoveFavoriteListing
   * @summary Remove Favorite Listing
   * @request DELETE:/v1/me/favorite-listings/{listingId}
   * @secure
   */
  removeFavoriteListing = (listingId: number, params: RequestParams = {}) =>
    this.http.request<void, any>({
      path: `/v1/me/favorite-listings/${listingId}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags MyAccountService
   * @name GetMyAccount
   * @summary Get My Account
   * @request GET:/v1/me
   * @secure
   */
  getMyAccount = (params: RequestParams = {}) =>
    this.http.request<UserInfoDto, any>({
      path: `/v1/me`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyAccountService
   * @name GetMyPoint
   * @summary Get My Point
   * @request GET:/v1/me/point
   * @secure
   */
  getMyPoint = (params: RequestParams = {}) =>
    this.http.request<number, any>({
      path: `/v1/me/point`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyAccountService
   * @name GetMyPointEvents
   * @summary Get My Point events
   * @request GET:/v1/me/point-events
   * @secure
   */
  getMyPointEvents = (
    query?: {
      status?: 'EARN' | 'USE';
      /**
       * Zero-based page index (0..N)
       * @min 0
       * @default 0
       */
      page?: number;
      /**
       * The size of the page to be returned
       * @min 1
       * @default 20
       */
      size?: number;
      /** Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported. */
      sort?: string[];
    },
    params: RequestParams = {},
  ) =>
    this.http.request<PointEventDto[], any>({
      path: `/v1/me/point-events`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyAccountService
   * @name GetFavoriteListings
   * @summary Get Favorite Listings
   * @request GET:/v1/me/favorite-listings
   * @secure
   */
  getFavoriteListings = (
    query: {
      locale: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<ListingDto[], any>({
      path: `/v1/me/favorite-listings`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
  /**
   * No description
   *
   * @tags MyAccountService
   * @name GetMyCoupons
   * @summary Get My Coupons
   * @request GET:/v1/me/coupons
   * @secure
   */
  getMyCoupons = (
    query: {
      /** @format int64 */
      listingId?: number;
      /** @format date */
      arrivalDate?: string;
      locale: string;
    },
    params: RequestParams = {},
  ) =>
    this.http.request<UserCouponDto[], any>({
      path: `/v1/me/coupons`,
      method: 'GET',
      query: query,
      secure: true,
      format: 'json',
      ...params,
    });
}
