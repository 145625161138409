import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { IconButton } from '@mui/material';

import styled from 'styled-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Row from 'components/layouts/Row';
import OverlayStyle from 'resources/styles/OverlayStyle';
import { useStore } from 'saladsoft-primitive';

const Drawer = observer(() => {
  const { uiStore } = useStore();
  const defautWidth = useRef<number>(600);

  const handleClickClose = async () => {
    uiStore?.drawer.close();
    // navigate(`/${channelStore.channel?.channelName}/${uiStores.userHomeStore?.currentBoard?.id}`);
  };

  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if (uiStore?.drawer.drawerShow) {
        body.style.overflow = 'hidden';
      } else {
        body.style.overflow = 'auto';
      }
    }
  }, [uiStore?.drawer.drawerShow]);

  return (
    <>
      <OverlayStyle isShow={uiStore?.drawer.drawerShow as boolean} onClick={handleClickClose} />
      <DrawerContainerStyle
        width={`${uiStore?.drawer.options?.width || 550}px`}
        on={uiStore?.drawer.drawerShow as boolean}
        style={uiStore?.drawer.options?.style}
      >
        <HeaderContainerStyle>
          <Row style={{ justifyContent: 'space-evenly', height: '100%' }}>
            <IconButton onClick={handleClickClose} style={{ width: 50, height: 50 }}>
              <ArrowBackIcon />
            </IconButton>
            <TitleStyle>{uiStore?.drawer.options?.title}</TitleStyle>
          </Row>
        </HeaderContainerStyle>
        {typeof uiStore?.drawer.options?.description !== 'undefined' && (
          <DescriptionStyle>{uiStore?.drawer.options?.description}</DescriptionStyle>
        )}
        <ChildrenContainerStyle>{uiStore?.drawer.options?.children}</ChildrenContainerStyle>
        {/* TODO: Mobile인지 판단하여 "Preview"버튼을 상시 표시할것 */}
      </DrawerContainerStyle>
    </>
  );
});

const DrawerContainerStyle = styled.div<{ width: any; on: boolean }>`
  position: fixed;
  right: ${(props) => (props.on ? '0px' : `-${props.width}`)};
  /* opacity: ${(props) => (props.on ? '1' : '0')}; */
  top: 0;
  width: ${(props) => `${props.width}`};
  bottom: 0;
  z-index: 2;
  background: #fff;
  overflow-y: auto;
  z-index: 10000000;

  transition: all 0.3s ease;
  animation-delay: 0.5s;
`;

const HeaderContainerStyle = styled.div`
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 10000;
  height: 56px;
`;
const TitleStyle = styled.div`
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  flex: 1;
  margin-right: 50px !important;
`;

const ChildrenContainerStyle = styled.div``;

const DescriptionStyle = styled.div`
  font-weight: 400;
  font-size: 16px;
  padding-left: 50px;
  color: #6f6f6f;
`;

export default Drawer;
