import Subtitle from 'components/commons/Subtitle';
import { TabProps } from './types';
import React from 'react';
import styled from 'styled-components';

interface ReservationTabProps {
  label: string;
  isActive?: boolean;
  onClick?: () => void;
}

const TabButton = styled.button<ReservationTabProps>`
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
  color: ${props => props.isActive ? '#000000' : '#8B8B8B'};
  font-weight: ${props => props.isActive ? '600' : '400'};
  border-bottom: ${props => props.isActive ? '2px solid #000000' : 'none'};
  
  &:hover {
    color: #000000;
  }
`;

export const ReservationTab: React.FC<ReservationTabProps> = ({ label, isActive, onClick }) => {
  return (
    <TabButton label={label} isActive={isActive} onClick={onClick}>
      <Subtitle type='A'>{label}</Subtitle>
    </TabButton>
  );
};
