import React from 'react';
import styled from 'styled-components';
import useLocaleNavigate from 'hooks/useLocaleNavigate';

interface MyInfoTabNameProps {
  title?: string;
  onBack?: () => void;
}

const MyInfoTabName = ({ title, onBack }: MyInfoTabNameProps) => {
  const navigate = useLocaleNavigate();

  const handleBackMyPage = () => {
    // 백 버튼은 무조건 하나 뒤로만 이동하게
    navigate.goBack();
    // if (onBack) {
    //   onBack();
    // } else {
    //   navigate('/my-page');
    // }
  };

  return (
    <Container>
      <BackButton onClick={handleBackMyPage}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M15 5L7.85 11.5L15 18"
            stroke="#1D1D1D"
            strokeWidth="1.6"
            strokeLinecap="round"
          />
        </svg>
      </BackButton>
      <Title>{title}</Title>
    </Container>
  );
};

const Container = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--Grayscale-0, #fff);
  padding: 20px 0;
  z-index: 10;
  width: 100%;
`;

const BackButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;
  left: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
`;

const Title = styled.div`
  color: var(--Grayscale-90, #1d1d1d);
  text-align: center;
  font-family: Pretendard;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.042px;
  width: 100%;
  min-height: 24px;
`;

export default MyInfoTabName;
