import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Row } from 'saladsoft-primitive';
import styled from 'styled-components';

interface SearchFieldProps {
  isValid: boolean;
  buttonText?: string;
  showBalance: boolean;
  balance?: string;
  onSearch: (value: string) => void;
  onChangeValue?: (value: string) => void;
  sectionId: string;
  value?: string;
  placeholder?: string;
  errorMessage?: string;
  onBlur?: (value: string) => void;
  totalPoints?: number;
  usedPoints?: number;
}

export function SearchField({
  showBalance,
  balance,
  onSearch,
  onBlur,
  sectionId,
  placeholder,
  buttonText,
  isValid,
  value,
  onChangeValue,
  errorMessage,
  totalPoints,
  usedPoints = 0,
}: SearchFieldProps) {
  const { t } = useTranslation();
  const inputId = `search-field-${sectionId}`;

  const [inputValue, setValue] = React.useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSearch(inputValue);
  };

  React.useEffect(() => {
    setValue(value ?? '');
  }, [value]);

  // 사용 가능한 포인트 계산
  const getAvailablePoints = () => {
    const total = Number((totalPoints ?? balance ?? '0').toString().replace(/,/g, ''));
    return (total - usedPoints).toLocaleString();
  };

  // 전체 포인트 값 가져오기
  const getTotalPoints = () => {
    return Number((totalPoints ?? balance ?? '0').toString().replace(/,/g, ''));
  };

  return (
    <SearchForm onSubmit={handleSubmit}>
      <SearchContainer>
        <SearchInput
          id={inputId}
          isValid={isValid}
          disabled={!isValid}
          type="text"
          value={inputValue}
          onBlur={(e) => {
            onBlur?.(e.target.value === '' ? '0' : e.target.value);
          }}
          onChange={(e) => {
            setValue(e.target.value);
            onChangeValue?.(e.target.value);
          }}
          placeholder={placeholder}
          aria-label={`${sectionId} 검색어 입력`}
          aria-invalid={!!errorMessage}
        />
        {errorMessage && <ErrorText role="alert">{errorMessage}</ErrorText>}
        {showBalance && (
          <Row width="100%" alignItems="center" justifyContent="space-between">
            <BalanceText role="status" aria-live="polite">
              {t('Available Points')} : {getAvailablePoints()}P
            </BalanceText>
            <>
              {Number(getAvailablePoints().replace(/,/g, '')) > 0 && (
                <ApplyAllButton
                  onClick={() => {
                    const totalPointsValue = getTotalPoints().toString();
                    setValue(totalPointsValue);
                    onChangeValue?.(totalPointsValue);
                  }}
                >
                  {t('Apply All')}
                </ApplyAllButton>
              )}
            </>
          </Row>
        )}
      </SearchContainer>
      {inputValue && (
        <SearchButton isValid={isValid} type="submit" aria-label={`${sectionId}`}>
          {buttonText}
        </SearchButton>
      )}
    </SearchForm>
  );
}

const SearchForm = styled.form`
  display: flex;
  margin-top: 12px;
  width: 100%;
  align-items: start;
  gap: 12px;
`;

const SearchContainer = styled.div`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  font-weight: 400;
  flex: 1;
`;

const SearchInput = styled.input<{ isValid?: boolean }>`
  border-radius: 8px;
  background: ${(props) =>
    props.isValid ? 'var(--Grayscale-5, #f8f8f8)' : 'var(--Grayscale-10, #f0f0f0)'};
  min-height: 48px;
  width: 100%;
  font-size: 15px;
  color: ${(props) =>
    props.isValid ? 'var(--Grayscale-50, #8e8e8e)' : 'var(--Grayscale-30, #ccc)'};
  padding: 0 16px;
  border: none;

  &[aria-invalid='true'] {
    border: 1px solid var(--Error, #d32f2f);
    background: var(--Grayscale-0, #fff);
  }

  &:focus-visible {
    outline: 2px solid var(--Color, #1c51b6);
    outline-offset: 2px;
  }

  &:hover {
    background: var(--Grayscale-10, #f0f0f0);
  }
`;

const BalanceText = styled.p`
  margin-top: 12px;
  font-size: 13px;
  color: var(--sub-color, var(--Color, #1c51b6));
  letter-spacing: -0.03px;
  display: flex;
  align-items: center;
`;

const ErrorText = styled.p`
  margin-top: 8px;
  font-size: 13px;
  color: var(--Error, #d32f2f);
  letter-spacing: -0.03px;
`;

const SearchButton = styled.button<{ isValid?: boolean }>`
  border-radius: 8px;
  border: 1px solid var(--4-e-433-c, #4e433c);
  background: var(--Grayscale-0, #fff);
  min-height: 48px;
  font-size: 15px;
  color: ${(props) => (props.isValid ? 'var(--4-e-433-c, #4e433c)' : 'var(--Grayscale-30, #ccc)')};
  font-weight: 500;
  text-align: center;
  width: 100px;
  padding: 0 16px;
  cursor: pointer;
  opacity: ${(props) => (props.isValid ? 1 : 0.5)};

  &:focus-visible {
    outline: 2px solid var(--Color, #1c51b6);
    outline-offset: 2px;
  }

  &:hover {
    background: var(--Grayscale-5, #f8f8f8);
  }
`;

const ApplyAllButton = styled.div`
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-top: 9px;
`;
