import { getUserEmail } from 'libs/helper/utils';
import { useEffect, useRef, useState } from 'react';
import { isExpired, decodeToken } from 'react-jwt';

/**
 * @description 로그인 후 기본 채널을 설정한다.
 * https://www.npmjs.com/package/secure-web-storage
 * @returns
 */
function useToken() {
  /**
   * @description 현재 유저의 토큰이 유효하고 사용자 정보가 정상적인 지 판단한다.
   * @deprecated
   * */
  const [isValidUser, setIsValid] = useState<boolean>();
  const isSignIn = useRef<boolean>();

  /**
   * @description 현재 유저가 유효한 사용자인지 판단한다.
   */
  const checkIsValidUser = async () => {
    const accessToken = await localStorage.getItem('accessToken');
    if (accessToken) {
      const t = !isExpired(accessToken);
      const userInfo = decodeToken(accessToken) as any;
      if (t && userInfo) {
        // setUserInfo(userInfo?.sub);
        setIsValid(true);
        return true;
      } else {
        // setUserInfo(undefined);
        setIsValid(false);
        return false;
      }
    } else {
      // setIsValid(false);
      return false;
    }
  };

  const getUserInfo = () => {
    return getUserEmail();
  };

  const validUser = () => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      const expired = isExpired(accessToken);
      const userInfo = decodeToken(accessToken) as any;
      if (!expired && userInfo) {
        return true;
      } else {
        return false;
      }
    } else {
      // setIsValid(false);
      return false;
    }
  };

  useEffect(() => {
    if (typeof isValidUser !== 'undefined') {
      isSignIn.current = isValidUser;
    }
  }, [isValidUser]);

  const clearToken = () => {
    setIsValid(false);
  };

  useEffect(() => {
    // checkIsValidUser();
  }, []);

  return { isValidUser, getUserInfo, clearToken, checkIsValidUser, validUser };
}

export default useToken;
