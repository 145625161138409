import PageContainer from 'containers/pc/PageContainer';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import ServiceHelper from 'stores/ServiceHelper';
import i18n from 'i18n';
import { PromotionDto } from 'services/data-contracts';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import IconButton from '@mui/material/IconButton';
import { useMediaContext } from 'saladsoft-primitive';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
const PromotionDetailPage = () => {
  const { isMobile } = useMediaContext();
  const { t } = useTranslation();
  const navigate = useLocaleNavigate();
  const { promotionId } = useParams();

  const [promotion, setPromotion] = useState<PromotionDto>();

  const getPromotionDetail = (promotionId: string) => {
    ServiceHelper.promotionService
      ?.getPromotion(Number(promotionId), {
        locale: i18n.language,
      })
      .then((res) => {
        setPromotion(res.data);
      });
  };

  useEffect(() => {
    if (promotionId) {
      getPromotionDetail(promotionId);
    }
  }, [promotionId]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'instant',
    });
  }, []);

  return (
    <PageContainer>
      <DetailContainer className="page-container">
        <>
          <ContentWrapper>
            {promotion?.images?.[0]?.url && (
              <ImageContainer>
                {isMobile && (
                  <BackButtonOverlay>
                    <BackButton onClick={() => navigate.goBack()}>
                      <BackIcon />
                    </BackButton>
                  </BackButtonOverlay>
                )}
                <img src={promotion?.images?.[0]?.url} alt={promotion?.title} />
              </ImageContainer>
            )}
            <Header>
              <TitleRow>
                <div>
                  <Title>{promotion?.title}</Title>
                  <Period>{promotion?.period}</Period>
                </div>
              </TitleRow>
            </Header>
            <InfoSection>
              <InfoItem>
                <Label>{t('Period')}</Label>
                <Value>{promotion?.period}</Value>
              </InfoItem>
              {promotion?.address?.trim() !== '' && (
                <InfoItem>
                  <Label>{t('location')}</Label>
                  <Value>{promotion?.address}</Value>
                </InfoItem>
              )}
              {/* 추가 프로모션 정보들을 여기에 표시 */}
            </InfoSection>
            <Description
              style={{ marginTop: isMobile ? 40 : 50 }}
              dangerouslySetInnerHTML={{ __html: promotion?.desc || '' }}
            />
          </ContentWrapper>
        </>
      </DetailContainer>
    </PageContainer>
  );
};

const DetailContainer = styled.div`
  padding: 0 24px;
  height: auto;
  min-height: 100vh;

  @media ${(props) => props.theme.media.mobile} {
    margin-top: 0px;
    padding-bottom: 40px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  border-bottom: 1px solid var(--Grayscale-20, #e4e4e4);
  padding-bottom: 24px;

  @media ${(props) => props.theme.media.mobile} {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
    width: calc(100% + 48px);
    margin-bottom: 30px;
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.h1`
  font:
    600 22px Pretendard,
    sans-serif;
  color: var(--Grayscale-90, #1d1d1d);

  @media ${(props) => props.theme.media.mobile} {
    word-break: keep-all;
  }
`;

const Period = styled.p`
  font:
    400 13px Pretendard,
    sans-serif;
  color: var(--Grayscale-50, #8e8e8e);
  line-height: 150%;
  letter-spacing: -0.032px;
  margin-top: 15px;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const ImageContainer = styled.div`
  width: 100%;
  margin-bottom: 24px;

  img {
    width: 100%;
    max-width: 800px;
    height: auto;
  }

  @media ${(props) => props.theme.media.mobile} {
    margin-bottom: 18px;
    margin-left: -24px;
    margin-right: -24px;
    width: calc(100% + 48px);

    img {
      width: 100%;
      max-width: none;
    }
  }
`;

const BackButtonOverlay = styled.div`
  position: absolute;
  z-index: 1000;
`;

const BackIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M15 5L7.85 11.5L15 18" stroke="white" stroke-width="1.6" strokeLinecap="round" />
  </svg>
);

const BackButton = styled(IconButton)`
  background: none;
  cursor: pointer;
  top: 5px;
  left: 7px;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile} {
    margin-top: 35px;
  }
`;

const InfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const Label = styled.span`
  font:
    600 15px Pretendard,
    sans-serif;
  color: var(--Grayscale-90, #1d1d1d);
  width: 120px;

  @media ${(props) => props.theme.media.mobile} {
    width: auto;
  }
`;

const Value = styled.span`
  font:
    400 15px Pretendard,
    sans-serif;
  color: var(--Grayscale-70, #4d4d4d);
`;

const Description = styled.div`
  font:
    400 15px Pretendard,
    sans-serif;
  color: #555;
  line-height: 150%;
  letter-spacing: -0.037px;
  margin-bottom: 50px;

  ul {
    list-style: disc;
    padding-left: 20px;
    padding-top: 20px;
  }
`;

export default PromotionDetailPage;
