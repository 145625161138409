import { IconButton } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

const BottomSheetHeader = ({ onClose, title }: { onClose: () => void, title: string }) => {
    return <Header>
        <div style={{ position: 'absolute', left: 12, top: 5 }}>
            <IconButton onClick={() => {
                onClose();
            }} >
                <CloseIcon />
            </IconButton>
        </div>
        <div style={{ flex: 1, width: '100%', fontSize: 17, fontWeight: 600, textAlign: 'center' }}>
            {title}
        </div>
    </Header>;
};


const Header = styled.div`
  width: 100%;
  height: 48px;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding:20px 0;
`;

export default BottomSheetHeader;
