import React from 'react';
import styled from 'styled-components';
import { LevelIndicatorProps } from './types';
import { useTranslation } from 'react-i18next';
const MEMBERSHIP_LEVELS = [
  { name: 'CLASSIC', color: 'rgba(192, 177, 152, 1)' },
  { name: 'SILVER', color: 'rgba(198, 198, 198, 1)' },
  { name: 'GOLD', color: 'rgba(219, 190, 116, 1)' },
  { name: 'PLATINUM', color: 'rgba(45, 45, 45, 1)' },
];

export const LevelIndicator = ({ currentLevel }: LevelIndicatorProps) => {
  const { t } = useTranslation();
  const currentLevelIndex = MEMBERSHIP_LEVELS.findIndex(level => level.name === currentLevel);

  const calculateProgress = (index: number) => {
    const levelWidth = 100 / MEMBERSHIP_LEVELS.length;
    const progress = index * levelWidth + levelWidth / 2;
    return Math.max(8, Math.min(92, progress));
  };


  return (
    <ProgressContainer>
      <CurrentLevelTag $position={calculateProgress(currentLevelIndex)}>
        {t('currentLevel')}
      </CurrentLevelTag>
      <ProgressBarContainer>
        <ProgressBars>
          {MEMBERSHIP_LEVELS.map((level, index) => (
            <ProgressBar
              key={level.name}
              $backgroundColor={level.color}
              $isFirst={index === 0}
              $isLast={index === MEMBERSHIP_LEVELS.length - 1}
            />
          ))}
        </ProgressBars>
        <LevelLabels>
          {MEMBERSHIP_LEVELS.map((level) => (
            <LevelLabel key={level.name}>{level.name}</LevelLabel>
          ))}
        </LevelLabels>
      </ProgressBarContainer>
    </ProgressContainer>
  );
};

const ProgressContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  margin-top: 24px;
`;

const CurrentLevelTag = styled.div<{ $position: number }>`
  position: absolute;
  color: var(--Grayscale-0, #fff);
  background: var(--Grayscale-90, #1d1d1d);
  border-radius: 20px;
  padding: 6px 8px;
  font:
    500 12px Pretendard,
    sans-serif;
  white-space: nowrap;
  z-index: 1;

  left: ${({ $position }) => {
    return `${$position}%`;
  }};

  transform: translateX(-50%);
  top: -30px;

  &::after {
    content: '';
    position: absolute;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--Grayscale-90, #1d1d1d);
  }
`;

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
  align-items: center;
  justify-content: start;
`;

const ProgressBars = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  flex-wrap: wrap;
`;

const ProgressBar = styled.div<{
  $backgroundColor: string;
  $isFirst: boolean;
  $isLast: boolean;
}>`
  background-color: ${(props) => props.$backgroundColor};
  align-self: stretch;
  display: flex;
  height: 8px;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
  border-radius: ${(props) =>
    props.$isFirst ? '4px 0 0 4px' : props.$isLast ? '0 4px 4px 0' : '0'};
`;

const LevelLabels = styled.div`
  display: flex;
  margin-top: 13px;
  width: 100%;
  justify-content: space-between;
  color: var(--Grayscale-90, #1d1d1d);
  letter-spacing: -0.03px;
  font:
    700 12px Montserrat,
    sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const LevelLabel = styled.div`
  letter-spacing: -0.03px;
  align-self: stretch;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
  text-align: center;
`;
