import { makeAutoObservable } from 'mobx';
import { UserProfileDto } from 'services/data-contracts';

class AuthStore {
  userProfile: UserProfileDto;
  constructor() {
    makeAutoObservable(this);
  }

  setUserProfile(userProfile: UserProfileDto) {
    this.userProfile = userProfile;
  }
}

export default AuthStore;
