import * as React from 'react';
import styled from 'styled-components';
import { CapacityInfo } from './CapacityInfo';
import { ParkingInfo } from './ParkingInfo';
import { ListingDto } from 'services/data-contracts';
import FavoriteButton from 'components/favorite/FavoriteButton';

export const AccommodationCard = ({
  favoriteItem,
  onFavoriteChange,
}: {
  favoriteItem: ListingDto;
  onFavoriteChange?: () => void;
}) => {
  return (
    <Container>
      <AccommodationImage src={favoriteItem.images?.[0]?.url} alt={favoriteItem.name} />
      <ContentWrapper>
        <InfoSection>
          <AccommodationName>{favoriteItem.name}</AccommodationName>
          <AccommodationAddress>{favoriteItem.address}</AccommodationAddress>
          <DetailSection>
            <CapacityInfo
              standardCapacity={favoriteItem.minGuest}
              maxCapacity={favoriteItem.maxGuest}
            />
            <Divider />
            <ParkingInfo hasParking={favoriteItem?.hasParking || false} />
          </DetailSection>
        </InfoSection>
        <FavoriteButton
          listingId={favoriteItem.id as number}
          isFavorite={true}
          theme="light"
          onFavoriteChange={onFavoriteChange}
        />
      </ContentWrapper>
    </Container>
  );
};

const Container = styled.article`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
  padding: 10px 0;
`;

const AccommodationImage = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 4px;
  object-fit: cover;
  @media ${(props) => props.theme.media.mobile} {
    width: 110px;
    height: 110px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
`;

const AccommodationName = styled.h3`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.04px;
  margin: 0;
`;

const AccommodationAddress = styled.p`
  color: var(--Grayscale-50, #8e8e8e);
  font-size: 13px;
  letter-spacing: -0.03px;
  white-space: nowrap;
  word-break: keep-all;
  overflow-wrap: break-word;
  margin: 4px 0 10px 0;
`;

const DetailSection = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 4px;
`;

const Divider = styled.div`
  width: 1px;
  height: 16px;
  background-color: var(--Grayscale-20, #e4e4e4);
`;
