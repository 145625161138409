import MyReservationInfo from 'components/pages/myReservations/reservations/MyReservationInfo';
import PageContainer from 'containers/pc/PageContainer';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, useMediaContext } from 'saladsoft-primitive';
import ServiceHelper from 'stores/ServiceHelper';
import { NonMemberReservationDto, ReservationDto } from 'services/data-contracts';
import i18n from 'i18n';
import { format } from 'react-string-format';
import moment from 'moment';

import ReservationSummary from 'components/pages/bookPage/ReservationSummary';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { Header } from 'resources/styles/CommonStyles';
import CancellationPolicy from 'components/commons/CancellationPolicy';
import { Checkbox as MuiCheckbox } from '@mui/material';
import BTButton from 'components/input/BTButton';
import { styled as muiStyled } from '@mui/material/styles';
import { useStoreWrapper } from 'stores/StoreHelper';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import MyInfoTabName from 'components/commons/mobile/MyInfoTabName';
import useToken from 'hooks/useToken';

const CustomCheckbox = muiStyled(MuiCheckbox)({
  padding: '5px',
  color: '#4e433c',
  '&.MuiCheckbox-root': {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  '&.Mui-checked': {
    color: '#4E433C',
  },
  '& .MuiSvgIcon-root': {
    width: '16px',
    height: '16px',
    border: '1px solid #8e8e8e',
    borderRadius: '2px',
    background: '#fff',
    '& path': {
      display: 'none',
    },
  },
});

const ReservationCancelPage = () => {
  const { t } = useTranslation();
  const { reservationId } = useParams();
  const { uiStore } = useStoreWrapper();
  const navigate = useLocaleNavigate();
  const { validUser } = useToken();
  const { isMobile } = useMediaContext();
  const [reservationInfo, setReservationInfo] = useState<ReservationDto | null>(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  const handleClickCancelReservation = () => {
    uiStore?.confirm.show({
      title: t('reservation.cancelConfirmationMessage'),
      onConfirmed: () => {
        if (validUser()) {
          ServiceHelper?.myReservationService
            ?.cancelReservation(Number(reservationId))
            .then((res) => {
              navigate(`/my-reservations/${reservationId}/cancel/success`);
            });
        } else {
          const nonMemberReservationDto: NonMemberReservationDto = JSON.parse(sessionStorage.getItem('guestReservationInfo') || '{}');
          ServiceHelper?.nonReservationService?.cancelReservationForNonMember(nonMemberReservationDto)
            .then((res) => {
              navigate(`/my-reservations/${reservationId}/cancel/success`);
            });
        }
      },
      onCancel: () => {
        setIsBusy(false);
      },
    });
  };

  const calculateRefundAmount = (checkingInDate: string | undefined, totalAmount: number = 0) => {
    if (!checkingInDate || totalAmount <= 0) return { refundRate: 0, refundAmount: 0 };

    // 체크인 날짜와 오늘 날짜 사이의 일수 차이 계산
    const today = moment().startOf('day');
    const checkInDate = moment(checkingInDate).startOf('day');
    const daysUntilCheckIn = checkInDate.diff(today, 'days');

    let refundRate = 0;

    // 환불 정책에 따른 환불율 계산
    if (daysUntilCheckIn >= 10) {
      // 10일 이상 남은 경우 100% 환불
      refundRate = 100;
    } else if (daysUntilCheckIn >= 3) {
      // 3일 이상 9일 이하 남은 경우 (10 - daysUntilCheckIn) * 10%씩 차감
      refundRate = 100 - (10 - daysUntilCheckIn) * 10;
    } else {
      // 3일 미만 남은 경우 환불 불가
      refundRate = 0;
    }

    // 환불 금액 계산
    const refundAmount = Math.round(totalAmount * (refundRate / 100));

    return {
      refundRate,
      refundAmount,
      nonRefundableAmount: totalAmount - refundAmount,
    };
  };

  const getRefundMessage = () => {
    const { refundRate, refundAmount } = calculateRefundAmount(
      reservationInfo?.arrivalDate,
      reservationInfo?.totalAmount || 0,
    );

    const totalAmount = reservationInfo?.totalAmount || 0;
    const totalAmountFormatted = totalAmount.toLocaleString();
    const refundAmountFormatted = refundAmount.toLocaleString();

    if (refundRate === 0) {
      return (
        <RefundMessageContainer>
          {/* {t('reservation.refund.noRefund')} <BlueAmount>{totalAmountFormatted}</BlueAmount>
          {t('won')} {t('reservation.refund.noRefundEnd')} */}
          <Trans
            i18nKey="reservation.refund.noRefundMessage"
            values={{ totalAmount: totalAmountFormatted }}
            components={{ bold: <BlueAmount /> }}
          />
        </RefundMessageContainer>
      );
    } else if (refundRate === 100) {
      return (
        <RefundMessageContainer>
          {t('reservation.refund.fullRefundStart')} <BlueAmount>{totalAmountFormatted}</BlueAmount>
          {t('won')} {t('reservation.refund.fullRefundEnd')}
        </RefundMessageContainer>
      );
    } else {
      return (
        <RefundMessageContainer>
          <div>
            {t('reservation.refund.partialRefundStart')}{' '}
            <BlueAmount>
              {totalAmountFormatted}
              {t('won')}
            </BlueAmount>{' '}
            {t('reservation.refund.partialRefundMiddle')}
          </div>
          <div>
            <BlueAmount>
              {refundAmountFormatted}
              {t('won')}
            </BlueAmount>
            {t('reservation.refund.partialRefundEnd')}
          </div>
          <RefundRateText>
            ({refundRate}% {t('reservation.refund.refund')})
          </RefundRateText>
        </RefundMessageContainer>
      );
    }
  };

  useEffect(() => {
    if (reservationId) {

      if (validUser()) {
        ServiceHelper?.myReservationService
          ?.getReservation(Number(reservationId), { locale: i18n.language })
          .then((res) => {
            console.log('res!!!!', res);
            setReservationInfo(res.data);
          });

      } else {
        const userInfo = sessionStorage.getItem('guestReservationInfo');
        if (userInfo) {
          const nonMemberReservationDto: NonMemberReservationDto = JSON.parse(userInfo);
          ServiceHelper?.nonReservationService?.getReservationForNonMember({ locale: i18n.language }, nonMemberReservationDto)
            .then((res) => {
              setReservationInfo(res.data);
            });

        }
      }

    }
    window.scrollTo({
      top: 0,
    });
  }, [reservationId]);

  return (
    <PageContainer>
      <div className="page-container" style={{ marginBottom: '77px' }}>
        {isMobile ? <MyInfoTabName /> : <></>}
        <Row
          width="100%"
          justifyContent="space-between"
          alignItems="flex-start"
          gap="48px"
          flexWrap={isMobile ? 'wrap' : 'nowrap'}
        >
          <SummaryWrapper>
            {reservationInfo && (
              <ReservationSummary
                reservationInfo={reservationInfo}
                title={isMobile ? t('reservation.cancelReservation2') : t('reservation.history')}
              />
            )}
          </SummaryWrapper>
          <div style={{ flexGrow: 1, padding: isMobile ? '0 16px' : '0' }}>
            <div style={{ marginBottom: 34 }}>
              {!isMobile && <Header>{t('reservation.cancelReservation2')}</Header>}
              <Notice>{getRefundMessage()}</Notice>
            </div>
            <div style={{ borderBottom: '1px solid #F0F0F0', paddingBottom: 16 }} />
            <CancellationPolicy useText={false} style={{ marginTop: 30, marginBottom: 20 }} />
            <div style={{ marginTop: 4, marginBottom: 20 }}>
              <NewUl>
                <li>{t('reservation.cancelNotice1')}</li>
                <li>{t('reservation.cancelNotice2')}</li>
                <li>{t('reservation.cancelNotice3')}</li>
                <li>{t('reservation.cancelNotice4')}</li>
              </NewUl>
            </div>
            <div>
              <div style={{ borderBottom: '1px solid #F0F0F0', paddingBottom: 16 }} />
              <Row
                fontSize="14px"
                color="#555"
                justifyContent="flex-start"
                alignItems="center"
                gap="3px"
                marginTop="20px"
              >
                <CustomCheckbox
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                  checkedIcon={
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        background: '#4E433C',
                        borderRadius: '2px',
                      }}
                    >
                      <path
                        d="M4.80078 7.99883L7.70604 10.79883L11.60078 4.79883"
                        stroke="white"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                      />
                    </svg>
                  }
                />
                <div>{t('reservation.confirmation')}</div>
              </Row>
              <Notice
                style={{
                  background: '#F8F8F8',
                  justifyContent: 'flex-start',
                  marginTop: 5,
                  flexDirection: 'row',
                }}
              >
                <NewUl>
                  <li>{t('reservation.confirmation1')}</li>
                  <li>{t('reservation.confirmation2')}</li>
                  <li>{t('reservation.confirmation3')}</li>
                </NewUl>
              </Notice>
              <BTButton
                busy={isBusy}
                style={{ marginTop: 20, width: '100%' }}
                disabled={!isChecked}

                onClick={() => {
                  setIsBusy(true);
                  handleClickCancelReservation();
                }}
              >
                {t('reservation.cancelReservationButton')}
              </BTButton>
            </div>
          </div>
        </Row>
      </div>
    </PageContainer>
  );
};

const Notice = styled.div`
  display: flex;
  padding: 20px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  background: #edf1f9;
  border-radius: 12px;
  white-space: pre-wrap;
  font-size: 14px;
  color: #555;
  font-weight: 400;
  margin-top: 80px;
  @media ${(props) => props.theme.media.mobile} {
    margin-top: 0px;
  }
`;

const NewUl = styled.ul`
  list-style: none;
  font-size: 12px;
  color: #8e8e8e;
  white-space: pre-wrap;

  li {
    margin-top: 4px;
  }
`;

const SummaryWrapper = styled.div`
  position: sticky;
  top: -20px;
  width: 384px;

  @media ${(props) => props.theme.media.mobile} {
    position: relative;
    width: 100%;
    padding: 0 16px;
    top: 10px;
  }
`;

const RefundMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  line-height: 1.5;
`;

const BlueAmount = styled.span`
  font-weight: 600;
  color: #1c51b6;
`;

const RefundRateText = styled.div`
  font-size: 11px;
  color: #8e8e8e;
  margin-top: 4px;
`;
export default ReservationCancelPage;
