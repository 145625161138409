import React, { CSSProperties, useEffect, useState } from 'react';

const CaptionText = ({
  children,
  style,
  type,
}: {
  children: any;
  style?: CSSProperties;
  type?: 'A' | 'B' | 'C';
}) => {
  const [textStyle, setTextStyle] = useState<CSSProperties>({});

  useEffect(() => {
    let tempStyle: CSSProperties;
    switch (type) {
      case 'A':
        // 볼드체의 작은 텍스트 (12px)
        tempStyle = { fontSize: '0.75rem', fontWeight: '700' };
        break;
      case 'B':
        // 중간 굵기의 매우 큰 텍스트 (12px)
        tempStyle = {
          fontSize: '0.75rem',
          fontWeight: '500',
          letterSpacing: '-0.03px',
          color: 'var(--Grayscale-50, #8E8E8E)',
        };
        break;
      case 'C':
        // 일반 굵기의 작은 텍스트 (12px)
        tempStyle = { fontSize: '0.75rem', fontWeight: '400' };
        break;
      default:
        // 기본 텍스트 크기 (16px)
        tempStyle = { fontSize: '1rem' };
        break;
    }

    setTextStyle(tempStyle);
  }, []);
  return <div style={{ ...textStyle, ...style }}>{children}</div>;
};

export default CaptionText;
