import BTButton from 'components/input/BTButton';
import { CouponCard } from 'components/pages/myInfo/couponManagement/CouponCard';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Row } from 'saladsoft-primitive';
import { UserCouponDto } from 'services/data-contracts';
import ServiceHelper from 'stores/ServiceHelper';
import { useStoreWrapper } from 'stores/StoreHelper';

const CouponListModal = () => {
  const { t } = useTranslation();
  const { uiStore } = useStoreWrapper();
  const { listingId } = useParams();

  const [coupons, setCoupons] = useState<UserCouponDto[]>([]);
  const [checkedCoupon, setCheckedCoupon] = useState<UserCouponDto>();

  useEffect(() => {
    ServiceHelper.myAccountService
      ?.getMyCoupons({
        listingId: listingId ? parseInt(listingId) : undefined,
        locale: i18n.language,
      })
      .then((res) => {
        setCoupons(res.data);
      });
  }, []);

  return (
    <div style={{ padding: '16px' }}>
      {coupons.map((coupon) => (
        <div key={coupon?.code}>
          <CouponCard
            onClick={() => setCheckedCoupon(coupon)}
            coupon={coupon}
            checked={checkedCoupon?.code === coupon?.code}
          />
        </div>
      ))}
      <div style={{ width: '100%' }}>
        <Row width="100%" justifyContent="space-between" gap="10px">
          <BTButton type="outline" style={{ flex: 0.3 }} onClick={() => uiStore?.modal.close()}>
            {t('close')}
          </BTButton>
          <BTButton style={{ flex: 0.7 }} onClick={() => uiStore?.modal.confirm(checkedCoupon)}>
            {t('apply')}
          </BTButton>
        </Row>
      </div>
    </div>
  );
};

export default CouponListModal;
