import * as React from 'react';
import { Row } from 'saladsoft-primitive';
import MyReservationSummary from './MyReservationSummary';
import ReservationDetail from './ReservationDetail';
import { NonMemberReservationDto, PaymentDto } from 'services/data-contracts';
import { useMediaContext } from 'saladsoft-primitive';
import styled from 'styled-components';
import MyInfoTabName from 'components/commons/mobile/MyInfoTabName';

const MyReservationInfo = ({
  reservationInfo,
  model,
  paymentInfo,
}: {
  reservationInfo?: NonMemberReservationDto;
  model?: NonMemberReservationDto;
  paymentInfo?: PaymentDto;
}) => {
  const { isMobile } = useMediaContext();

  return (
    <Row
      minWidth={isMobile ? 'auto' : '1200px'}
      width={'100%'}
      alignItems="flex-start"
      gap={isMobile ? 0 : 50}
      flexDirection={isMobile ? 'column' : 'row'}
    >
      {isMobile ? <MyInfoTabName /> : <></>}
      <SummaryWrapper>
        <MyReservationSummary reservationInfo={reservationInfo} />
      </SummaryWrapper>
      <DetailWrapper>
        <ReservationDetail
          reservationInfo={reservationInfo}
          model={model}
          paymentInfo={paymentInfo}
        />
      </DetailWrapper>
    </Row>
  );
};

const SummaryWrapper = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    order: 2;
    width: 100%;
    padding: 0 24px;
  }
`;

const DetailWrapper = styled.div`
  @media ${(props) => props.theme.media.mobile} {
    order: 1;
    width: 100%;
    padding: 0 24px;
  }
`;

export default MyReservationInfo;
