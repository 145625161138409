import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ReservationDto } from 'services/data-contracts';
import styled from 'styled-components';
import useLocaleNavigate from 'hooks/useLocaleNavigate';
import { useMediaContext } from 'saladsoft-primitive';

interface InfoRowProps {
  label: string;
  value: string;
  multiline?: boolean;
}

const MyReservationSummary = ({ reservationInfo }: { reservationInfo?: ReservationDto }) => {
  const { t } = useTranslation();
  const navigate = useLocaleNavigate();
  const { isMobile } = useMediaContext();

  // 취소된 예약 및 지난 예약(날짜 기반) 여부 확인
  const isCancelled = () => {
    return reservationInfo?.status === 'CANCELLED';
  };
  const isPast = () => {
    const today = new Date();
    const departureDate = reservationInfo?.departureDate
      ? new Date(reservationInfo.departureDate)
      : null;

    return departureDate && departureDate < today;
  };

  const getName = () => {
    if (reservationInfo?.guestCountryCode === '+82') {
      return `${reservationInfo?.guestLastName} ${reservationInfo?.guestFirstName}`;
    }
    return `${reservationInfo?.guestFirstName} ${reservationInfo?.guestLastName}`;
  };

  const handleClickBack = () => {
    navigate.goBack();
  };

  return (
    <Container>
      {!isMobile && <PageTitle>{t('reservation.history')}</PageTitle>}
      <ContentWrapper>
        <ReservationSection>
          <SectionTitle>{t('reservation.guestInfo')}</SectionTitle>
          <InfoContainer>
            <InfoList>
              <InfoRow label={t('reservation.name')} value={getName()} />
              <InfoRow
                label={t('reservation.contact')}
                value={`${reservationInfo?.guestCountryCode}${reservationInfo?.guestPhoneNumber}`}
              />
              <InfoRow label={t('reservation.email')} value={reservationInfo?.guestEmail || ''} />
              <InfoRow label={t('reservation.request')} value={reservationInfo?.guestNote || ''} />
            </InfoList>
          </InfoContainer>
        </ReservationSection>
        <ButtonContainer>
          {!isCancelled() && !isPast() && (
            <SearchButton
              style={{ backgroundColor: '#F7F3EF', color: '#4E433C', marginBottom: '10px' }}
              tabIndex={0}
              role="button"
              onClick={() => {
                navigate(`/my-reservations/${reservationInfo?.id}/cancel`)
              }}
            >
              {t('reservation.cancelReservationButton')}
            </SearchButton>
          )}

          <SearchButton tabIndex={0} role="button" onClick={() => handleClickBack()}>
            {t('goBack')}
          </SearchButton>
        </ButtonContainer>
      </ContentWrapper>
    </Container>
  );
};

const InfoRow: React.FC<InfoRowProps> = ({ label, value, multiline }) => (
  <InfoRowWrapper>
    <InfoLabel>{label}</InfoLabel>
    <InfoValue multiline={multiline}>{value}</InfoValue>
  </InfoRowWrapper>
);

const Container = styled.div`
  display: flex;
  max-width: 370px;
  width: 100%;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  justify-content: start;
`;

const PageTitle = styled.h1`
  align-self: stretch;
  flex: 1;
  background: var(--Grayscale-0, #fff);
  min-height: 48px;
  width: 100%;
  font-size: 28px;
  color: var(--Grayscale-90, #1d1d1d);
  font-weight: 600;
  white-space: nowrap;
  letter-spacing: -0.07px;
  margin: 0;
`;

const ContentWrapper = styled.div`
  display: flex;
  margin-top: 66px;
  width: 100%;
  flex-direction: column;
  justify-content: start;
`;

const ReservationSection = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  gap: 15px;
`;

const SectionTitle = styled.h2`
  color: var(--Grayscale-90, #1d1d1d);
  font-size: 19px;
  font-weight: 600;
  letter-spacing: -0.05px;

  @media ${(props) => props.theme.media.mobile} {
    font-size: 17px;
  }
`;

const InfoContainer = styled.div`
  padding-top: 32px;
  border-radius: 0 0 12px 12px;
  border-top: 1px solid var(--Grayscale-70, #555);
  display: flex;
  width: 100%;
  flex-direction: column;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: -0.04px;
  justify-content: start;
`;

const InfoList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
`;

const InfoRowWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: start;
  gap: 8px;
  justify-content: start;
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
`;

const InfoLabel = styled.div`
  color: var(--Grayscale-50, #8e8e8e);
  width: 80px;
`;

const InfoValue = styled.div<{ multiline?: boolean }>`
  color: var(--Grayscale-90, #1d1d1d);
  flex: 1;
  line-height: ${(props) => (props.multiline ? '23px' : 'inherit')};
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 64px;
  width: 100%;
  flex-direction: column;
  font-size: 15px;
  color: var(--Grayscale-0, #fff);
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  letter-spacing: -0.04px;
  justify-content: center;
`;

const SearchButton = styled.div`
  align-self: stretch;
  border-radius: 8px;
  background: var(--4-e-433-c, #4e433c);
  min-height: 48px;
  width: 100%;
  padding: 14px 16px;
  cursor: pointer;

  &:focus {
    outline: 2px solid var(--Grayscale-90, #1d1d1d);
    outline-offset: 2px;
  }
`;

export default MyReservationSummary;
