import React, { useEffect } from 'react';
import { PointEventDto } from 'services/data-contracts';
import styled from 'styled-components';
import { formatThousandSeparator } from 'libs/helper/utils';
import { formatDateTime } from 'libs/helper/utils';

const PointsHistory = ({ points, status }: { points: PointEventDto[]; status: string }) => {
  return (
    <>
      <HistoryContainer>
        {points?.map((item, index) => (
          <HistoryItem
            key={index}
            description={item.description as string}
            transactionDate={item.transactionDate as string}
            status={item.status as string}
            point={item.point as number}
          />
        ))}
      </HistoryContainer>
    </>
  );
};

// 개별 item 컴포넌트
const HistoryItem = ({ description, transactionDate, point, status }: PointEventDto) => (
  <ItemContainer>
    <ItemDetails>
      <ItemText>{description}</ItemText>
      <ItemDate>{formatDateTime(transactionDate)}</ItemDate>
    </ItemDetails>
    <ItemPoints status={status}>
      {status === 'EARN' ? '+' : '-'}
      {formatThousandSeparator(Math.abs(point || 0))}P
    </ItemPoints>
  </ItemContainer>
);

const HistoryContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const ItemContainer = styled.div`
  align-items: flex-start;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  width: 100%;
  padding: 16px 0;
  gap: 16px;
  justify-content: space-between;
  @media (max-width: 640px) {
    padding: 16px 0;
    gap: 12px;
  }
`;

const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
`;

// p로 변경
const ItemText = styled.div`
  color: #1d1d1d;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: -0.04px;
  line-height: 1.4;
  @media (max-width: 640px) {
    font-size: 13px;
  }
`;

// time으로 변경
const ItemDate = styled.div`
  color: #8e8e8e;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.03px;
  line-height: 1.4;
  @media (max-width: 640px) {
    font-size: 11px;
  }
`;

// p로 변경
const ItemPoints = styled.div<{ status?: string }>`
  color: ${({ status }) => (status === 'EARN' ? '#1c51b6' : '#F35064')};
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.04px;
  white-space: nowrap;
  @media (max-width: 640px) {
    font-size: 14px;
  }
`;

export default PointsHistory;
