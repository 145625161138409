import { TextField, TextFieldProps } from '@mui/material';
import Required from 'components/commons/Required';
import Row from 'components/layouts/Row';
import React, { CSSProperties, forwardRef, useRef, useImperativeHandle } from 'react';

interface InputFieldProps extends Omit<TextFieldProps, 'onChange'> {
  label?: string;
  style?: CSSProperties;
  required?: boolean;
  onChange?: (value: string) => void;
}

const InputField = forwardRef(
  ({ label, style, required, onChange, helperText, placeholder, ...props }: InputFieldProps, ref: any) => {
    const titleRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => ({
      focus,
    }));

    const focus = () => {
      titleRef.current?.focus();
    };

    return (
      <div style={{ width: '100%', ...style }}>
        <Row style={{ justifyContent: 'flex-start', marginBottom: 5 }}>
          {/* <Text type="H4">{label}</Text> */}
          <>{required && <Required />}</>
        </Row>
        <TextField
          inputRef={titleRef}
          fullWidth
          helperText={helperText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange?.(e.target.value);
          }}
          {...props}
        />
      </div>
    );
  },
);

export default InputField;
