import { createPortal } from 'react-dom';
import CalendarModal from 'components/pages/listingPage/CalendarModal';
import React from 'react';
import styled from 'styled-components';
import { useStoreWrapper } from 'stores/StoreHelper';
import { observer } from 'mobx-react';
import { BookInfo } from 'types/CommonTypes';

const MobileCalendarPortal = observer(() => {
    const { calendarStore, listingStore } = useStoreWrapper();
    return (
        createPortal(
            <CalendarModalWrapper show={calendarStore.mobileCalendarShow}>
                {calendarStore.mobileCalendarShow && (
                    <CalendarModal
                        isListingPicker={calendarStore.isListingPicker}
                        minGuests={listingStore.listing?.minGuest ?? 2}
                        maxGuests={listingStore.listing?.maxGuest ?? 5}
                        useImmediateSearch={calendarStore?.useImmediateSearch ?? false}
                        onSearch={(bookInfo: BookInfo) => {
                            calendarStore.setMobileCalendarShow(false);
                            calendarStore.setBookInfo(bookInfo, 'search');
                        }}
                        onChange={(bookInfo: BookInfo) => {
                            calendarStore.setBookInfo(bookInfo, 'select');
                        }}
                        onClose={() => calendarStore.setMobileCalendarShow(false)}
                    />
                )}
            </CalendarModalWrapper >,
            document.body
        )
    )
})


const CalendarModalWrapper = styled.div<{ show?: boolean }>`
  height: 100vh;
  width: 100%;
  overflow: auto;
  display: ${({ show }) => (show ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2147483647;
  background-color: #fff;
  isolation: isolate;
`;

export default MobileCalendarPortal;