import * as React from 'react';
import { UserCouponDto } from 'services/data-contracts';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import { NumberFormat } from 'saladsoft-primitive';
import { useTranslation } from 'react-i18next';

const CheckedCoupon = ({
  title,
  price,
  onClose,
}: {
  title: string;
  price: string;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <CouponContainer>
      <CouponInfo>
        <CouponTitle>{title}</CouponTitle>
        <CouponAmount>
          <div style={{ fontFamily: 'Pretendard, sans-serif', fontWeight: 600 }}>{price}</div>
        </CouponAmount>
      </CouponInfo>
      <CloseIcon onClick={onClose} style={{ cursor: 'pointer' }} />
    </CouponContainer>
  );
};

const CouponContainer = styled.div`
  justify-content: end;
  align-items: start;
  align-self: stretch;
  border-radius: 8px;
  background: var(--Grayscale-5, #f8f8f8);
  background-color: var(--Grayscale-5, #f8f8f8);
  display: flex;
  gap: 8px;
  font-family: Pretendard, sans-serif;
  color: var(--Grayscale-70, #555);
  padding: 16px;
`;

const CouponInfo = styled.div`
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: start;
  flex: 1;
  flex-basis: 0%;
`;

const CouponTitle = styled.div`
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.03px;
`;

const CouponAmount = styled.div`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.04px;
`;

export default CheckedCoupon;
