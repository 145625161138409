import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUserInfo } from 'providers/UserInfoProvider';
import ServiceHelper from 'stores/ServiceHelper';
import { PointEventDto } from 'services/data-contracts';
import { getFullName } from 'libs/helper/utils';
import { PointsHeader } from './PointsHeader';
import { Tab } from './Tab';
import { EmptyState } from 'components/commons/EmptyState';
import PointHistory from './PointHistory';
import { useMediaContext } from 'saladsoft-primitive';
import { useTranslation } from 'react-i18next';

export default function Points() {
  const { t } = useTranslation();
  const { isMobile } = useMediaContext();

  const { loginStatus, myAccount } = useUserInfo();

  const [activeTab, setActiveTab] = useState<'earned' | 'used'>('earned');
  const [earnedPoints, setEarnedPoints] = useState<PointEventDto[]>([]);
  const [usedPoints, setUsedPoints] = useState<PointEventDto[]>([]);

  // 이벤트 포인트 조회
  const fetchEventPoints = async (status: 'EARN' | 'USE') => {
    const response = await ServiceHelper.myAccountService?.getMyPointEvents({
      status,
      size: 100,
    });
    if (response?.status === 200) {
      if (status === 'EARN') {
        setEarnedPoints(response.data);
      } else {
        setUsedPoints(response.data);
      }
    }
  };

  useEffect(() => {
    if (loginStatus === false) {
      return;
    }
    if (activeTab === 'used') {
      fetchEventPoints('USE');
    }
    if (activeTab === 'earned') {
      fetchEventPoints('EARN');
    }
  }, [activeTab, loginStatus]);

  return (
    <PageWrapper>
      <PageContent>
        <PointsHeader username={getFullName(myAccount?.profile)} points={myAccount?.point || 0} />
        <TabSection>
          <TabList role="tablist">
            <Tab isActive={activeTab === 'earned'} onClick={() => setActiveTab('earned')}>
              {t('earnedPoints')}
            </Tab>
            <Tab isActive={activeTab === 'used'} onClick={() => setActiveTab('used')}>
              {t('usedPoints')}
            </Tab>
          </TabList>
          <TabContent role="tabpanel">
            {activeTab === 'earned' ? (
              // '적립 내역' 탭인 경우
              <>
                {earnedPoints.length === 0 ? (
                  <EmptyState message={t('noEarnedPoints')} />
                ) : (
                  <PointHistory points={earnedPoints} status="EARN" />
                )}
              </>
            ) : (
              // '소진 내역' 탭인 경우
              <>
                {usedPoints.length === 0 ? (
                  <EmptyState
                    iconSrc="https://cdn.builder.io/api/v1/image/assets/f79f911b3c8d4b5ba0fdd21149734b25/11025f901c8bf1e455190d6731493ef6332b354b4435a23d9ce25e26a4b8a48f?apiKey=f79f911b3c8d4b5ba0fdd21149734b25&"
                    message={t('noUsedPoints')}
                  />
                ) : (
                  <PointHistory points={usedPoints} status="USE" />
                )}
              </>
            )}
          </TabContent>
        </TabSection>
      </PageContent>
    </PageWrapper>
  );
}

const PageWrapper = styled.main`
  background-color: var(--Grayscale-0, #fff);
  display: flex;
  max-width: 825px;
  width: 100%;
  flex-direction: column;
`;

const PageTitle = styled.h1`
  background: var(--Grayscale-0, #fff);
  min-height: 48px;
  color: var(--Grayscale-90, #1d1d1d);
  letter-spacing: -0.07px;
  padding: 14px 0;
  font:
    600 28px Pretendard,
    sans-serif;
`;

const PageContent = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100%;
  flex-direction: column;

  @media (${(props) => props.theme.media.mobile}) {
    margin-top: 15px;
  }
`;

const TabSection = styled.section`
  display: flex;
  margin-top: 48px;
  width: 100%;
  flex-direction: column;
  font-family: Pretendard, sans-serif;
  letter-spacing: -0.04px;
  @media (${(props) => props.theme.media.mobile}) {
    margin-top: 31.5px;
  }
`;

const TabList = styled.div`
  border-bottom: 1px solid var(--Grayscale-20, #e4e4e4);
  background: var(--white, #fff);
  display: flex;
  gap: 20px;
`;

const TabContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
