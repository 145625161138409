import _ from 'lodash';
import moment from 'moment';

export type InputFormat = 'text' | 'email' | 'number' | 'tel' | 'url';

type ValidationTargetType = {
  type: 'required' | 'url' | 'email' | 'tel' | 'number';
  value: string;
};

type ValidationType = {
  /** @description 정규식 */
  expression: RegExp;
  /** @description 에러 메시지 */
  message: string;
};

const expressions = {
  // name: lastname, firstname으로 나눠서 받을 것
  name: /^[가-힣a-zA-Z\s]{2,30}$/, // 한글, 영문, 공백 허용, 2-30자
  lastName: /^[가-힣a-zA-Z\s]{1,20}$/, // 한글, 영문 허용, 1-20자
  firstName: /^[가-힣a-zA-Z\s]{1,20}$/, // 한글, 영문 허용, 1-20자

  countryCode: /^\+\d{1,4}$/, // +1 ~ +9999 형식
  phoneNumber: /^[0-9]{9,11}$/, // 숫자만 9-11자리
  birthday: /^(19|20)\d{2}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/, // YYYY-MM-DD 서버 형식
  text: /[^(가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9)]/,
  // email: /[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}/,
  email: /^[a-zA-Z0-9]+(?:[_.-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:[_.-][a-zA-Z0-9]+)*\.[a-zA-Z]{2,4}$/,
  tel: /^(?:\d{2}-\d{4}-\d{4}|\d{3}-\d{4}-\d{4}|\d{2}-\d{3}-\d{4}|\d{9,11})$/,
  // tel: /\d{2,3}-\d{3,4}-\d{4}/,
  number: /^[0-9\b]+$/,
  // url: /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi,
  // url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm,
  url: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm,
  roman: /^[a-zA-Z0-9!@#$\-_]+$/,
  password: /^[A-Za-z0-9 !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/,
  youtube:
    /(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([a-zA-Z0-9_-]+)|(?:https?:\/\/)?youtu\.be\/([a-zA-Z0-9_-]+)/,
  timeRange: /^([01][0-9]|2[0-3]):[0-5][0-9]$/,
};

function validityCheck(value: string, format: InputFormat) {
  /**
   * null일경우 유효성 통과이므로 true를 반환한다.
   */
  return value.match((expressions as any)[format]) !== null;
}

function validityCustomCheck(value: string, validation: ValidationType) {
  /**
   * null일경우 유효성 통과이므로 true를 반환한다.
   */
  const result = value.match(validation.expression) !== null;
  return result;
}

/**
 * @description 필수값 체크
 * @param value
 * @returns boolean : true:정상, false:실패
 */
const required = (value?: string | number) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  if (typeof value !== 'number') {
    return value?.trim()?.length !== 0;
  } else {
    return value?.toString().length !== 0;
  }
};

/**
 * @description 날짜값 체크
 * @param value
 * @returns boolean : true:정상, false:실패
 */
const date = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  const dateTime = moment(value);
  return dateTime.isValid();
};

/**
 * @description 유효시간 체크
 * @param value
 * @returns boolean : true:정상, false:실패
 */
const timeRange = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  return value?.match(expressions.timeRange) !== null;
};

/** @description 공백 불가 */
const url = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  return value?.match(expressions.url) !== null;
};

/** @description 공백 허용 */
const urlOrEmpty = (value?: string) => {
  if (typeof value === 'undefined' || value?.length === 0 || value === null) {
    return true;
  }
  return value?.match(expressions.url) !== null;
};

const alphanumericWithSymbols = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  return value?.match(expressions.roman) !== null;
};

const name = (value?: string) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return false;
  }
  const result = value.match(expressions.name) !== null;
  return result;
};

const lastName = (value?: string) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return false;
  }
  return value?.match(expressions.lastName) !== null;
};

const firstName = (value?: string) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return false;
  }
  return value?.match(expressions.firstName) !== null;
};

const email = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  return value?.match(expressions.email) !== null;
};

const password = (value?: string) => {
  if (typeof value === 'undefined' || value === '') {
    return false;
  }
  return value?.match(expressions.password) !== null;
};

const tel = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  const result = value?.match(expressions.tel) !== null;
  return result;
};

const countryCode = (value?: string) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return false;
  }
  return true;
};

const phoneNumber = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  const result = value?.match(expressions.phoneNumber) !== null;
  return result;
};

// referrerEmail 은 선택 입력 (빈값 허용)
const birthday = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  const result = value.match(expressions.birthday) !== null;
  return result;
};

const referrerEmail = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return true;
  }
  return value?.match(expressions.email) !== null;
};

const number = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  const result = value?.match(expressions.number) !== null;
  return result;
};

const youtube = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  const result = value?.match(expressions.youtube) !== null;
  return result;
};

const text = (value?: string) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }
  const result = value?.match(expressions.text) !== null;
  return result;
};

const custom = (value: string, format: RegExp | RegExp[]) => {
  if (typeof value === 'undefined' || value === null) {
    return false;
  }

  // format이 배열일 경우
  if (Array.isArray(format)) {
    for (const regex of format) {
      if (value.match(regex)) {
        return true;
      }
    }
    return false;
  }

  // format이 단일 RegExp일 경우
  return value.match(format) !== null;
};

const validate = (values: Array<ValidationTargetType>) => {
  const t = _.groupBy(values, 'type');
  let result = false;
  if (typeof t.required !== 'undefined') {
    result = !(
      t.required.filter((target: ValidationTargetType) => target?.value?.length === 0).length > 0
    );
  }

  if (typeof t.url !== 'undefined') {
    result =
      t.url.filter(
        (target: ValidationTargetType) =>
          target?.value?.match((expressions as any)[target?.type]) !== null,
      )?.length > 0;

    // result = value.match((expressions as any)[format]) === null ? false : true;
  }
  return result;
};

const validationHelper = {
  required,
  url,
  urlOrEmpty,
  validate,
  tel,
  email,
  password,
  number,
  alphanumericWithSymbols,
  youtube,
  custom,
  date,
  timeRange,
  name,
  lastName,
  firstName,
  countryCode,
  phoneNumber,
  birthday,
  referrerEmail,
};

export { validityCheck, validityCustomCheck, validationHelper };
export type { ValidationTargetType };
